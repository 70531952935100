import React from "react";

import LeftAllFields from "./LeftAllFields";
import RightState from "./RightState";

const MainBody:React.FC<{setIsEstimateVisible:any,isEstimateVisible:any}>= ({setIsEstimateVisible,isEstimateVisible}) => {
  return (
    <div className="body">
      <LeftAllFields setIsEstimateVisible={setIsEstimateVisible} />
      <RightState isEstimateVisible={isEstimateVisible}/>
    </div>
  );
};

export default MainBody;
