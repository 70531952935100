import { useEffect, useState } from "react";

import layoutTwo from "../components/layout/layoutTwo";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";
import ListHeader from "../components/Events/ListHeader";
import EventsTab from "../components/Events/EventsTab";
import { useQueryParams } from "../helpers/HOC/useQueryParams";
import RequestsTab from "../components/Events/RequestsTab";
import { Tab, Tabs } from "@mui/material";

type TabType = "events" | "requests";

const Events = () => {
  const [searchParams, updateQueryParams] = useQueryParams();
  const queryTab = searchParams.get("tab");
  const isRightTab = queryTab == "events" || queryTab == "requests";

  const [tab, setTab] = useState<TabType>(
    isRightTab ? (queryTab as TabType) : "events"
  );

  useEffect(() => {
    if (!isRightTab) {
      updateQueryParams({ tab, page: "1" });
    }
  }, []);

  return (
    <div className="events-list">
      <EcnBreadCrumbs>
        <p>Events</p>
      </EcnBreadCrumbs>
      <h1 className="title">Events</h1>

      <ListHeader />
      <div className="tabs">
        <Tabs
          value={tab}
          onChange={(e, newVal) => {
            setTab(newVal);
            updateQueryParams({ tab: newVal, page: "1" });
          }}
          className="ecn-tabs"
        >
          <Tab label="Events" value="events" />
          <Tab label="Requests" value="requests" />
        </Tabs>
      </div>
      {tab == "events" ? <EventsTab /> : null}
      {tab == "requests" ? <RequestsTab /> : null}
    </div>
  );
};

export default layoutTwo(Events);
