import { useSearchParams } from "react-router-dom";

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParams = (updates: Record<string, string>) => {
    const currentParams = Object.fromEntries([...searchParams]);
    const newParams = { ...currentParams, ...updates };
    setSearchParams(newParams);
  };

  return [searchParams, updateQueryParams] as const;
}
