import { Icon } from "@iconify/react";
import { Button, Drawer } from "@mui/material";
import { Helmet } from "react-helmet";
import EcnButton from "../StyledComponents/V2/EcnButton";

const CertificatePreviewDarwer = ({
  drawerOpen,
  setDrawerOpen,
  title,
  eventName,
  heading,
  onSave
}: {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  eventName: string;
  heading: string;
  onSave: () => void;
}) => {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href={
            "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
          }
          rel="stylesheet"
        />
        <link
          href={
            "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Pinyon+Script&display=swap"
          }
          rel="stylesheet"
        />
      </Helmet>
      <div>
        <div
          onClick={() => setDrawerOpen(false)}
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#212121",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginTop: 42,
            marginLeft: 42,
            marginBottom: 21
          }}
        >
          <Icon
            icon="material-symbols:arrow-back"
            width={24}
            height={24}
            style={{
              marginRight: 16
            }}
          />
          Certificate Preview
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          fontFamily: "Inter, sans-serif",
          textAlign: "right",
          height: 440
        }}
      >
        <div
          style={{
            background:
              "url('https://s1.ecnstatic.com/ecn/images/cretificate_bg_2.png') no-repeat",
            backgroundSize: "cover",
            height: 800,
            width: 1145,
            transform: "scale(0.55)",
            marginLeft: -200,
            marginRight: -200
          }}
        >
          <div style={{ margin: 20 }}>
            <p
              style={{
                textAlign: "left",
                marginTop: 143,
                marginLeft: 140,
                fontSize: 18,
                fontWeight: 700
              }}
            >
              {String(new Date().getFullYear())}
            </p>
            <h2
              style={{
                marginRight: 95,
                lineHeight: 1.5,
                textTransform: "uppercase",
                color: "#121212",
                fontSize: 27.5,
                fontWeight: 400,
                letterSpacing: -1.3
              }}
            >
              {title}
            </h2>
          </div>
          <div style={{ margin: "120px auto 0 auto", marginRight: 120 }}>
            <h1
              style={{
                fontFamily: "Pinyon Script",
                fontSize: 48,
                color: "#121212"
              }}
            >
              Name Surname
            </h1>
            <h2
              style={{
                fontSize: 18,
                fontWeight: 500,
                lineHeight: "131%",
                color: "#121212",
                maxWidth: "90%",
                marginRight: 0,
                marginLeft: "auto"
              }}
            >
              {eventName}
            </h2>
            <h2
              style={{
                fontSize: 18,
                fontWeight: 700,
                lineHeight: "131%",
                color: "#121212",
                maxWidth: "80%",
                marginRight: 0,
                marginLeft: "auto",
                marginTop: "9px",
                wordWrap: "break-word"
              }}
            >
              {heading}
            </h2>
            <p
              style={{
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 131,
                color: "#121212",
                marginTop: 9,
                maxWidth: "85%",
                marginRight: 0,
                marginLeft: "auto"
              }}
            >
              Issued for
            </p>
            <h5 style={{ fontSize: 18, fontWeight: 400, marginTop: 9 }}>
              Issued on <span style={{ fontWeight: 700 }}>Issue Date</span>
            </h5>
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 80,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop: 130
            }}
          >
            <div style={{ marginRight: 120 }}>
              <img
                style={{ height: 115, width: 115, alignSelf: "flex-end" }}
                src="https://ecn.blob.core.windows.net/ecn/images/enterprise/QR.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 30,
          marginRight: 37
        }}
      >
        <Button
          onClick={() => {
            setDrawerOpen(false);
            onSave();
          }}
          sx={{
            borderRadius: "7px",
            padding: "10px 18px",
            boxShadow: "none",
            fontSize: 16,
            fontWeight: 500
          }}
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </div>
    </Drawer>
  );
};
export default CertificatePreviewDarwer;
