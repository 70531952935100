import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";
import { RootState } from "../../store";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

const allowedIds = [
  "660299e74b1e7ea4f4a8c678",
  "65f02c2b0d79a389f38f11d8"
  // Add more IDs here as needed
];

export const jobCreate = createAsyncThunk(
  "jobCreate",
  async (jobData: any, { getState }) => {
    const state = getState() as RootState;
    const { userInfo } = state.userDetails;
    try {
      if (allowedIds.includes(userInfo?.id)) {
        const { data } = await axios.post(
          `${GATEWAY_BASE + JOB_CREATE_BASE
          }/api/v1/enterprise/jobs/createOrUpdateDemo`,
          jobData
        );
        return data;
      }
      else if (!userInfo?.paid) {
        const { data } = await axios.post(
          `${GATEWAY_BASE + JOB_CREATE_BASE
          }/api/v1/enterprise/jobs/createOrUpdateFree`,
          jobData
        );
        return data;

      } else {
        const { data } = await axios.post(
          `${GATEWAY_BASE + JOB_CREATE_BASE
          }/api/v1/enterprise/jobs/createOrUpdate`,
          jobData
        );
        return data;
      }
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const jobCreateSlice = createSlice({
  name: "jobCreate",
  initialState: {
    loading: false,
    jobData: null,
    error: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(jobCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(jobCreate.fulfilled, (state, action) => {
      state.loading = false;
      state.jobData = action.payload;
    });
    builder.addCase(jobCreate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  }
});

export default jobCreateSlice.reducer;
