import { ResponsiveBar } from "@nivo/bar";
import React from "react";

const BarGraph: React.FC<{
  setHoverValue: any;
  customsColor: any;
  item: any;
}> = ({ setHoverValue, customsColor, item }) => {
  let mapData: any = [];
  let keys: any = [];

  function mapAry(val: any, name: any, per: any, index: any) {
    var obj: any = {};
    obj["id"] = index;
    obj[`${name}`] = val;
    obj["value"] = val;
    obj["label"] = name;

    mapData.push(obj);
  }

  item?.chart_results?.forEach((item: any, index: any) =>
    mapAry(item?.value, item?.label, item?.percentage, index)
  );
  item?.chart_results?.forEach((leb: any) => keys.push(leb?.label));

  const repeatedColors = Array.from(
    { length: Math.ceil(mapData.length / customsColor?.length) },
    () => customsColor
  ).flat();

  const customTickFormat = (value: string) => {
    return value?.substring(0, 5) + ".."; // Return first 5 characters
  };

  // Define tooltip content
  const CustomTooltip = ({ id, value, indexValue, label, color }: any) => (
    <div className="custom-tooltip">
      <div className="dots" style={{ backgroundColor: color }}></div>
      <p>
        {label}: <span>{value}</span>
      </p>
    </div>
  );

  return (
    <div
      className="gpa"
      style={{
        width: `${item?.width * 8}%`,
        background: `${
          item?.background_color === "" ? item?.background_color : "transparent"
        }`,
      }}
    >
      <div style={{ width: "100%", height: "320px" }}>
        <ResponsiveBar
          data={mapData}
          keys={keys}
          indexBy="label"
          colors={repeatedColors}
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.4}
          valueScale={{ type: "linear", min: 0, max: "auto" }}
          indexScale={{ type: "band", round: true }}
          borderRadius={6}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 7,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: 40,
            format: customTickFormat,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: -46,
          }}
          enableGridY={false}
          enableLabel={false}
          labelSkipWidth={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={(e) =>
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          }
          tooltip={CustomTooltip} // Custom tooltip component
          // Format tooltip content
        />
      </div>
    </div>
  );
};

export default BarGraph;
