import { KeyboardArrowDown } from "@mui/icons-material";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { reset } from "../../../../redux/slices/CandidatesSlices/candidatesDetialsSlice";
import { updateJobStatus } from "../../../../redux/slices/CandidatesSlices/updateJobStatusSlice";
import EcnButton from "../../../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";

const TopHeader: React.FC<{
  data: any;
  selectedJob: any;
  handlePrevious: () => void;
  handleNext: () => void;
}> = ({ data, selectedJob, handleNext, handlePrevious }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [click, setClick] = useState(false);

  const { updateStatus } = useAppSelector((state: any) => state.jobStatus);

  useEffect(() => {
    if (updateStatus && click) {
      enqueueSnackbar("Status updated successfully!", {
        variant: "success"
      });
    }
  }, [updateStatus]);

  useEffect(() => {
    if (data) {
      setStatus(data?.application_status);
    }
  }, [data]);

  const statusHandler = (event: any) => {
    setClick(true);
    setStatus(event.target.value);
    dispatch(
      updateJobStatus({
        id: data?._id,
        job_id: data?.job_id,
        application_status: event.target.value
      })
    );
  };

  return (
    <div className="top-header">
      <div className="breadcrumb">
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 20 20"
          fill="none"
          onClick={() => navigate("/dashboard")}
          style={{ cursor: "pointer" }}
        >
          <path
            d="M2.5 7.49935L10 1.66602L17.5 7.49935V16.666C17.5 17.108 17.3244 17.532 17.0118 17.8445C16.6993 18.1571 16.2754 18.3327 15.8333 18.3327H4.16667C3.72464 18.3327 3.30072 18.1571 2.98816 17.8445C2.67559 17.532 2.5 17.108 2.5 16.666V7.49935Z"
            stroke="#212121"
            strokeOpacity="0.6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke="#212121"
            strokeOpacity="0.6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="dots"></div>
        <p onClick={() => navigate("/jobs")} style={{ cursor: "pointer" }}>
          Jobs
        </p>
        <div className="dots"></div>
        <p
          onClick={() => {
            navigate(`/jobs?view=details&id=${data?.job_id} `);
            dispatch(reset());
          }}
          style={{ cursor: "pointer" }}
        >
          {selectedJob}
        </p>
        <div className="dots"></div>
        <p>
          {data?.created_by?.first_name + " " + data?.created_by?.last_name}
        </p>
      </div>
      {/* <h2>View candidate</h2> */}
      <div className="status">
        <h4>Status</h4>
        <div className="top-row">
          <div className="select">
            <FormControl>
              <Select
                size="small"
                variant="standard"
                displayEmpty={true}
                disableUnderline
                value={status}
                input={
                  <OutlinedInput
                    sx={{
                      border: "1px solid rgba(33, 33, 33, 0.20)",
                      "& fieldset": {
                        border: "1px solid rgba(33, 33, 33, 0.20) !important"
                      },
                      "&:hover fieldset": {
                        border: "1.7px solid  rgba(0, 52, 187, 0.70) !important"
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.7px solid rgba(0, 52, 187, 0.70) !important"
                      },
                      borderRadius: "8px"
                    }}
                  />
                }
                onChange={statusHandler}
                IconComponent={KeyboardArrowDown}
                renderValue={(selected) => {
                  if (selected === undefined) {
                    return <div className="placeholder">Select Status</div>;
                  }
                  const selectedStatus = statusText.find(
                    (item: any) => item.value === selected
                  );
                  return (
                    <div>
                      {selectedStatus ? selectedStatus?.text : ""}{" "}
                      {/* Render the text property */}
                    </div>
                  );
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                      borderRadius: "6px",
                      marginTop: "10px",
                      backgroundColor: "#FFF",
                      padding: "10px 10px"
                    }
                  }
                }}
              >
                {statusText?.map((item: any) => (
                  <MenuItem
                    value={item?.value}
                    sx={
                      item?.value === status
                        ? {
                            background: "rgba(0, 52, 187, 0.08)!important",
                            borderRadius: "9px",
                            marginBottom: "5px",
                            fontSize: "14px !important",
                            fontWeight: "400",
                            color: "#0034bb !important",
                            padding: "8px 15px"
                          }
                        : {
                            background: "#fff !important",
                            marginBottom: "5px",
                            fontSize: "14px !important",
                            color: "rgba(33,33,33,.7)",
                            fontWeight: "400",
                            padding: "8px 15px",
                            "&:hover": {
                              backgroundColor:
                                "rgba(0, 52, 187, 0.08) !important",
                              color: "rgba(0, 52, 187, 1)",
                              borderRadius: "9px"
                            }
                          }
                    }
                  >
                    {item?.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="btn-group">
            <EcnButton
              startIcon={<Icon icon="lucide:arrow-left" />}
              onClick={handlePrevious}
            >
              Previous
            </EcnButton>
            <EcnButton
              endIcon={<Icon icon="lucide:arrow-right" />}
              onClick={handleNext}
            >
              Next
            </EcnButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
const statusText = [
  {
    value: 0,
    text: " Not Yet Reviewed"
  },
  {
    value: 1,
    text: "Decline"
  },
  {
    value: 2,
    text: "Maybe"
  },
  {
    value: 3,
    text: "Invite for Interview"
  },
  {
    value: 4,
    text: "Offer"
  },
  {
    value: 5,
    text: "Offer Accepted"
  }
];
