import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useSearchParams } from "react-router-dom";

import {
  getJobPromoField,
  reset
} from "../redux/slices/JobPromotions/getJobPromoFieldSlice";

import CardGrid from "../components/Promotions/CardDetails/CardGrid";
import MainBody from "../components/Promotions/Body/MainBody";
import { jobDetails } from "../redux/slices/JobSlices/jobDetailsSlice";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps
} from "@mui/material";
import layoutTwo from "../components/layout/layoutTwo";

const Promotions = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { jobPromoFieldData, loading, errorData } = useAppSelector(
    (state: any) => state.getJobPromoField
  );

  const {
    promoRequestData,
    loading: promoRequestLoading,
    error
  } = useAppSelector((state: any) => state.promoRequestData);

  const [errorMessage, setErrorMessage] = useState<any>();
  const [isEstimateVisible, setIsEstimateVisible] = useState<any>(false);

  useEffect(() => {
    if (id) {
      dispatch(getJobPromoField(id));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(jobDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (errorData) {
      setErrorMessage(errorData);
    }
  }, [errorData, id]);

  return (
    <div className="job-promotion">
      {promoRequestLoading && (
        <div className="background">
          <FacebookCircularProgress />
        </div>
      )}

      {loading ||
      promoRequestData ||
      error ||
      errorMessage?.message === "promotion-not-allowed" ||
      errorMessage?.message === "request-already-submitted" ? (
        <CardGrid />
      ) : (
        <MainBody
          setIsEstimateVisible={setIsEstimateVisible}
          isEstimateVisible={isEstimateVisible}
        />
      )}
    </div>
  );
};

export default layoutTwo(Promotions);

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
        }}
        size={60}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round"
          }
        }}
        size={60}
        thickness={5}
        {...props}
      />
    </Box>
  );
}
