import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import EcnBreadCrumbs from "../../StyledComponents/EcnBreadCrumbs";

const Header = () => {
  const { templateData } = useAppSelector((state: any) => state.templateList);
  return (
    <div className="header-template">
      <EcnBreadCrumbs>
        <p>Templates</p>
      </EcnBreadCrumbs>
      <h1>Templates</h1>
      {templateData?.length != 0 && (
        <p className="total">
          {templateData?.length} template{templateData?.length > 1 ? "s" : ""}{" "}
          available
        </p>
      )}
    </div>
  );
};

export default Header;
