import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE || "";

export const getJobPromotionAnalytics = createAsyncThunk(
  "getJobPromotionAnalytics",
  async (jobId: string) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + ANALYTICS_BASE}/generate_promotions`,
        {
          job_id: jobId,
        }
      );
      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data;
    }
  }
);

const getJobPromotionAnalyticsSlice = createSlice({
  name: "getJobPromotionAnalytics",
  initialState: {
    loading: false,
    jobPromotionAnalytics: null as any,
    errorData: null as any,
  },
  reducers: {
    resetPromoAnalytics: (state) => {
      state.loading = false;
      state.jobPromotionAnalytics = null;
      state.errorData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobPromotionAnalytics.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobPromotionAnalytics.fulfilled, (state, action) => {
      state.loading = false;
      state.jobPromotionAnalytics = action.payload;
    });
    builder.addCase(getJobPromotionAnalytics.rejected, (state, action) => {
      state.loading = false;
      // Access the payload of the action to get the error information
      state.errorData = action.error;
    });
  },
});
export const { resetPromoAnalytics } = getJobPromotionAnalyticsSlice.actions;

export default getJobPromotionAnalyticsSlice.reducer;
