import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/UI/Loader";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userSignout } from "../redux/slices/userSlices/userSignoutSlice";

const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

export const Unauthorize = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userInfo, errorData, loading } = useAppSelector(
    (state: any) => state.userDetails
  );

  const { signOut, success } = useAppSelector((state: any) => state.signOut);

  const [click, setClick] = useState(false);

  const logutOutHanlder = () => {
    setClick(true);
    dispatch(userSignout(userInfo?.email));
  };

  useEffect(() => {
    if (signOut && click) {
      if (location.pathname === "/no-access") {
        window.location.href = `${SIGNIN_REDIRECT}?origin=${
          window.location.origin + "/dashboard"
        }`;
      } else {
        window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
      }
    }
  }, [signOut]);

  // useEffect(() => {
  //   if (
  //     userInfo &&
  //     userInfo?.licenses?.some(
  //       (license: any) =>
  //         license?.plan_id === "enterprise-recruiter-plan" ||
  //         userInfo?.licenses?.length != 0
  //     )
  //   ) {
  //     navigate("/dashboard");
  //   } else if (errorData?.message === "401") {
  //     if (location.pathname === "/no-access") {
  //       window.location.href = `${SIGNIN_REDIRECT}?origin=${
  //         window.location.origin + "/dashboard"
  //       }`;
  //     } else {
  //       window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
  //     }
  //   }
  // }, [location, userInfo]);
  useEffect(() => {
    if (
      userInfo?.licenses?.find(
        (license: any) => license?.plan_id === "enterprise-recruiter-plan"
      )?.plan_id === "enterprise-recruiter-plan" ||
      userInfo?.licenses?.length !== 0
    ) {
      navigate("/dashboard");
    } else if (errorData?.message === "401") {
      const redirectURL =
        location.pathname === "/no-access"
          ? `${SIGNIN_REDIRECT}?origin=${window.location.origin}/job`
          : `${SIGNIN_REDIRECT}?origin=${window.location.href}`;
      window.location.href = redirectURL;
    }
  }, [location, userInfo, errorData]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="Unauthorize">
      <div className="content">
        <p>You don't have the access contact administration </p>
        <Button
          variant="contained"
          color="info"
          onClick={() => window?.location.reload()}
          style={{ marginRight: "30px" }}
        >
          {" "}
          Refresh
        </Button>

        <Button variant="outlined" color="info" onClick={logutOutHanlder}>
          {" "}
          Log out
        </Button>
      </div>
    </div>
  );
};
