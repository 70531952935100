import { Icon } from "@iconify/react";
import { Breadcrumbs, BreadcrumbsProps } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EcnBreadCrumbs = (props: BreadcrumbsProps) => {
  const navigate = useNavigate();
  return (
    <Breadcrumbs
      {...props}
      separator={<Icon icon="lucide:dot" height={24} width={24} />}
      className="ecn-breadcrumb"
    >
      <Icon
        icon="lucide:home"
        className="icon"
        height={20}
        width={20}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/dashboard")}
      />
      {props.children}
    </Breadcrumbs>
  );
};

export default EcnBreadCrumbs;
