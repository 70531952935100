import getNotificationGroupSlice from "./getNotificationGroupSlice";
import getNotificationSlice from "./getNotificationSlice";
import getProductListSlice from "./getProductListSlice";
import getSearchAutocompleteSlice from "./getSearchAutocompleteSlice";
import getSearchHistorySlice from "./getSearchHistorySlice";
import getTimeZonesSlice from "./getTimeZonesSlice";
import getUnreadNotificationSlice from "./getUnreadNotificationSlice";
import getUserSettingsSlice from "./getUserSettingsSlice";
import postSearchSlice from "./postSearchSlice";
import updateUserSettingsSlice from "./updateUserSettingsSlice";

export const allHeaderSlices = {
  getProductList: getProductListSlice,
  getUserSettings: getUserSettingsSlice,
  getTimeZones: getTimeZonesSlice,
  updateUserSettings: updateUserSettingsSlice,
  getNotification: getNotificationSlice,
  getNotificationGroup: getNotificationGroupSlice,
  getUnreadNotification: getUnreadNotificationSlice,
  getSearchAutocomplete: getSearchAutocompleteSlice,
  postSearch: postSearchSlice,
  getSearchHistory: getSearchHistorySlice,
};
