import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const aiCvRelevancyFeedback = createAsyncThunk(
  "",
  async (feedbackData: any) => {
    const { data } = await axios.post(
      `${
        GATEWAY_BASE + JOB_CREATE_BASE
      }/api/v1/application/relevancy_score/feedback`,
      feedbackData
    );

    return data;
  }
);

const aiCvRelevancyFeedbackSlice = createSlice({
  name: "aiCvRelevancyFeedback",
  initialState: {
    loading: false,
    aiCvRelevancyFeedbackData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(aiCvRelevancyFeedback.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(aiCvRelevancyFeedback.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.aiCvRelevancyFeedbackData = action.payload;
    });
    builder.addCase(aiCvRelevancyFeedback.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export default aiCvRelevancyFeedbackSlice.reducer;
