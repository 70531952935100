import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getAllJob = createAsyncThunk("getAllJob", async () => {
  const { data } = await axios.get(
    `${
      GATEWAY_BASE + JOB_CREATE_BASE
    }/api/v1/enterprise/jobs/recruiter?skip=0&limit=9999`
  );
  return data;
});

const getAllJobSlice = createSlice({
  name: "getAllJob",
  initialState: {
    loading: false,
    getAllJobData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllJob.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllJob.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllJobData = action.payload;
    });
    builder.addCase(getAllJob.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getAllJobSlice.reducer;
