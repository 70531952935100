import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const scoreUpdate = createAsyncThunk(
  "scoreUpdate",
  async (scoreData: any) => {
    const { data } = await axios.put(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v2/application/update_score`,
      scoreData
    );

    return data;
  }
);

const scoreUpdateSlice = createSlice({
  name: "scoreUpdate",
  initialState: {
    loading: false,
    scoreUpdateData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(scoreUpdate.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(scoreUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.scoreUpdateData = action.payload;
    });
    builder.addCase(scoreUpdate.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export default scoreUpdateSlice.reducer;
