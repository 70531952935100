import React from "react";
import { useAppSelector } from "../../../redux/hooks";

import AllMetrics from "./V2/Metrics/AllMetrics";
import Level from "./V2/Level/Level";
import ListAndPie from "./V2/ListsAndPie/ListAndPie";
import OnlyLists from "./V2/OnlyList/OnlyLists";
import BarAndList from "./V2/ListsAndPie/BarAndList";

const Analytics: React.FC<{ active?: any }> = ({ active }) => {
  const { analyticsState } = useAppSelector((state: any) => state.analyticsData);

  const sortedData = [...analyticsState?.sections]?.sort(
    (a: any, b: any) => a.position - b.position
  );

  // Map over sortedData and render components dynamically based on position
  const renderedComponents = sortedData?.map((item: any) => {
    switch (item?.type) {
      case "L2":
        return <ListAndPie key={item.position} item={item} />;

      case "L4":
        return <AllMetrics key={item.position} item={item} />;
      case "L5":
        return <OnlyLists key={item.position} item={item} />;
      case "PL":
        return <Level key={item.position} item={item} />;
      case "BL":
        return <BarAndList key={item.position} item={item} />;
      default:
        return null;
    }
  });

  return (
    <div className="promotion-analytics">
      {/* <University data={analyticsState} />
      <GpaAndAi active={active}/>
      <CurrentLevel />
      <Diversity />
      <TopRated />
      <Experience />
      <AnaFunction />
      <AvgTime />
      <UniversityPartener />
      <GraduationYear active={active} /> */}
      {analyticsState?.sections?.length === 0 ? (
        <div className="fallback-message">No data available yer</div>
      ) : (
        renderedComponents
      )}
    </div>
  );
};

export default Analytics;
