import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const enableScore = createAsyncThunk(
  "enableScore",
  async (scoreData: any) => {
    const { data } = await axios.put(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/joblisting/scoring`,
      scoreData
    );

    return data;
  }
);

const enableScoreSlice = createSlice({
  name: "enableScore",
  initialState: {
    loading: false,
    enableScore: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(enableScore.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(enableScore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.enableScore = action.payload;
    });
    builder.addCase(enableScore.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export default enableScoreSlice.reducer;
