import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const editProfile = createAsyncThunk(
  "editProfile",
  async (profileData: any) => {
    try {
      const { data } = await axios.put(
        `${GATEWAY_BASE + ADMIN_BASE}/editProfile`,
        profileData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const editProfileSlice = createSlice({
  name: "editProfile",
  initialState: {
    loading: false,
    profile: null as any,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(editProfile.pending, (state, action) => {
      state.loading = true;
      state.errorData = false;
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.errorData = false;
    });
    builder.addCase(editProfile.rejected, (state, action) => {
      state.loading = false;
      state.errorData = action.error;
    });
  },
});

export default editProfileSlice.reducer;
