import React, { useEffect, useState } from "react";
import Header from "../components/Mails/Template/Header";
import layoutTwo from "../components/layout/layoutTwo";
import Body from "../components/Mails/Template/Body";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getTemplate } from "../redux/slices/MailsSlices/getTemplateSlice";
import CreateTemplate from "../components/Mails/Template/CreateTemplate";
import { useNavigate, useSearchParams } from "react-router-dom";

const Template = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const view = searchParams.get("view");
  const id = searchParams.get("id");
  const [selectedTemplate, setSelectedTemplate] = useState<any>([]);
  const { templateData } = useAppSelector((state: any) => state.templateList);

  const { createTemplateData } = useAppSelector(
    (state: any) => state.createAndUpdateTemplate
  );
  const { removeTemplateData } = useAppSelector(
    (state: any) => state.removeTemplate
  );

  useEffect(() => {
    dispatch(getTemplate());
  }, [removeTemplateData, createTemplateData]);

  useEffect(() => {
    if (id != "" && selectedTemplate?.length === 0 && view != "create") {
      navigate("?view=list");
    }
  }, [id]);

  return (
    <div className="template">
      {view === "list" && <Header />}
      {view === "list" && <Body setSelectedTemplate={setSelectedTemplate} />}
      {(view === "create" || view === "edit" || view === "view") && (
        <CreateTemplate selectedTemplate={selectedTemplate} />
      )}
    </div>
  );
};

export default layoutTwo(Template);
