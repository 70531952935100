import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deletedCreatedJob } from "../../../redux/slices/JobSlices/deleteCreatedJobSlice";
import { useQueryParams } from "../../../helpers/HOC/useQueryParams";
import { useRef } from "react";

const Cards: React.FC<{ item: any }> = ({ item }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, updateQueryParams] = useQueryParams();
  const id = searchParams.get("id") || "";

  const { deletedCreatedJobData, loading } = useAppSelector(
    (state: any) => state.deleteJob
  );

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [click, setClick] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (deletedCreatedJobData && click) {
      setDeleteOpen(false);
      enqueueSnackbar("Job deleted successfully!", {
        variant: "success"
      });
    }
  }, [deletedCreatedJobData]);

  const deleteHandler = () => {
    setDeleteOpen(true);
  };
  const deleteCloseHandler = () => {
    setDeleteOpen(false);
  };
  const confirmDeleteHandler = () => {
    setClick(true);
    dispatch(deletedCreatedJob(item?._id));
  };

  const jobClickHandler = () => {
    updateQueryParams({ id: item?._id, title: encodeURI(item?.job_title) });
    const parent = cardRef.current?.parentNode as HTMLElement;
    if (cardRef.current && parent) {
      const cardRect = cardRef.current.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();
      if (
        cardRect.bottom > parentRect.bottom ||
        cardRect.top < parentRect.top
      ) {
        cardRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest"
        });
      }
    }
  };

  useEffect(() => {
    if (cardRef.current && id === item?._id) {
      cardRef.current.scrollIntoView();
    }
  }, [cardRef]);

  return (
    <div
      className={`card ${id === item?._id ? "selected-card" : ""}`}
      onClick={jobClickHandler}
      ref={cardRef}
    >
      <div className="card-top">
        <h2>
          {item?.job_title.length > 40
            ? item?.job_title?.slice(0, 40) + "...."
            : item?.job_title}
        </h2>
      </div>
      <div>
        <div className="type-city">
          <p>{item?.role_type}</p>
          <div className="dots"></div>
          <p>{item?.address?.cities?.join(", ")}</p>
          <div className="dots"></div>
          <h2>{item?.applicants_count} applicants</h2>
          {!item?.draft_status?.is_draft ? (
            <div className="job-status-chip">
              {item?.closed ? (
                <p
                  className="closed"
                  style={{
                    background: "rgba(107, 114, 128,0.1)",
                    color: "rgb(107, 114, 128)"
                  }}
                >
                  Closed
                </p>
              ) : item?.request_public &&
                !item?.platform_approval &&
                !item?.under_review ? (
                <p
                  className="closed"
                  style={{
                    background: " rgba(186, 0, 0,0.1)",
                    color: "#ba0000 "
                  }}
                >
                  Not Approved
                </p>
              ) : item?.request_public &&
                item?.under_review &&
                !item?.platform_approval ? (
                <p
                  className="draft"
                  style={{
                    background: "rgba(254, 152, 0,.1)",
                    color: "#fe9800"
                  }}
                >
                  Under review
                </p>
              ) : (
                <p className="active">Active</p>
              )}
            </div>
          ) : (
            <p className="draft">Draft</p>
          )}
        </div>
        <div className="card-bottom"></div>
      </div>
      <Dialog
        open={deleteOpen}
        onClose={deleteCloseHandler}
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important"
            }
          }
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px"
            }}
          >
            Are you sure you want to Delete?{" "}
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "500",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px"
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)"

                  // Add any other styles you want to disable on hover
                }
              }}
              onClick={deleteCloseHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "500",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px"
              }}
              className="button"
              onClick={confirmDeleteHandler}
              loadingPosition="end"
              loading={loading}
              disabled={loading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)"

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px" // Add margin after the loading indicator
                  }
                }
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Cards;
