import { Icon } from "@iconify/react";
import { Button, IconButton } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Rating from "./Left/TalentScore/Rating";
import RightSideExpandView from "./RighSideExpandView";

const ExpandedView: React.FC<{ data: any; setActiveStep: any }> = ({
  data,
  setActiveStep,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const view = searchParams.get("view");
  const application_id = searchParams.get("application_id");
  const expandViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expandViewRef.current) {
      expandViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="expend-view" ref={expandViewRef}>
      <div className="top">
        <Button
          className="back"
          onClick={() => {
            navigate(`?id=${id}&application_id=${application_id}`);
            setActiveStep(1);
          }}
        >
          <Icon
            icon="material-symbols:arrow-back"
            className="icon"
            width={24}
            height={24}
          />
          Back
        </Button>

        <h2>Talent score</h2>
        <p>
          To rate the candidate, simply click on the scale below. The scale
          ranges from 1 to 10 and corresponds to the respective parameters.
        </p>
      </div>
      <div className="body">
        <Rating data={data} />
        <RightSideExpandView data={data} />
      </div>
    </div>
  );
};

export default ExpandedView;
