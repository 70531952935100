import React, { useEffect, useState } from "react";
import ParametersCard from "./ParametersCard";
const Parameter: React.FC<{ data: any }> = ({ data }) => {

  const [score, setScore] = useState<any>([]);

  useEffect(() => {
    if (data && data.relevancy_result && data.relevancy_result.rationales) {
      const dataArray = Object.keys(data.relevancy_result.rationales).map((key) => ({
        key,
        value: data.relevancy_result.rationales[key],
        score: data.relevancy_result.scores[key],
      }));
      setScore(dataArray);
    }
  }, [data]);
  
  return (
    <div className="parameter">
      {score?.map((item: any,index:any) => (
         <ParametersCard  item={item}/>
      ))}
    </div>
  );
};

export default Parameter;
