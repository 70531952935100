import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const generateJobDescription = createAsyncThunk(
  "generateJobDescription",
  async (jobDescriptionData: any) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/ai_features/generate_description`,
        jobDescriptionData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const generateJobDescriptionSlice = createSlice({
  name: "generateJobDescription",
  initialState: {
    loading: false,
    jobDescriptionData: null,
    error: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateJobDescription.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateJobDescription.fulfilled, (state, action) => {
      state.loading = false;
      state.jobDescriptionData = action.payload;
    });
    builder.addCase(generateJobDescription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default generateJobDescriptionSlice.reducer;
