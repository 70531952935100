import { BarChart } from "@mui/x-charts/BarChart";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import Fallback from "../../Fallback";
import ToolTips from "./toolTips";

const Candidates = () => {
  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

  // Convert full month names to short form
  const shortMonthNames: any = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  // Extract month names, total counts, and total quality counts
  const monthNames = Object?.keys(
    promoAnalytics?.application_monthly_count?.results === null
      ? ""
      : promoAnalytics?.application_monthly_count?.results
  );

  // Sort month names in ascending order
  const sortedMonthNames = monthNames?.sort(
    (a, b) =>
      (new Date(shortMonthNames[a] + " 1, 2000") as any) -
      (new Date(shortMonthNames[b] + " 1, 2000") as any)
  );

  // Use the sorted month names to get corresponding total counts and total quality counts
  const totalCounts = sortedMonthNames?.map(
    (month) =>
      promoAnalytics?.application_monthly_count?.results[month]?.total_count ||
      0
  );
  const totalQualityCounts = sortedMonthNames?.map(
    (month) =>
      promoAnalytics?.application_monthly_count?.results[month]
        ?.total_quality_count || 0
  );

  function allFieldsZero(data: any) {
    for (const day in data) {
      const counts = data[day];
      if (Object.values(counts).some((count) => count !== 0)) {
        return false; // At least one field is non-zero
      }
    }
    return true; // All fields are zero
  }
  const areAllFieldsZero = allFieldsZero(
    promoAnalytics?.application_monthly_count?.results
  );

  return (
    <div className="bar-graph">
      <h1>
        Candidates{" "}
        <ToolTips title={promoAnalytics.application_monthly_count?.tooltip} />
      </h1>

      {!areAllFieldsZero ? (
        <>
          <div className="pie-legend-list">
            {" "}
            <div className="pie-legend-list-item">
              <div
                className="dots"
                style={{ background: `rgba(147, 197, 253, 1)` }}
              ></div>
              <p> Candidates</p>
            </div>
            <div className="pie-legend-list-item">
              <div
                className="dots"
                style={{ background: `rgba(37, 99, 235, 1)` }}
              ></div>
              <p> Top rated Candidates</p>
            </div>
          </div>
          <BarChart
            width={700}
            height={250}
            disableAxisListener
            margin={{ top: 25, left: 40, right: 30, bottom: 30 }}
            series={[
              {
                data: totalCounts,
                label: "Candidates",
                id: "Candidates",
                color: "rgba(147, 197, 253, 1)",
              },
              {
                data: totalQualityCounts,
                label: "Top rated Candidates",
                id: "Top rated Candidates",
                color: "rgba(37, 99, 235, 1)",
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
            xAxis={[
              {
                data: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                scaleType: "band",
              },
            ]}
          />
        </>
      ) : (
        <Fallback message="No candidates data available yet" />
      )}
    </div>
  );
};

export default Candidates;
