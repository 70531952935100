import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const HeadBreadCrumbSearch = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const plan_name = searchParams.get("plan_name") || "";
  const plan = searchParams.get("product_id") || "";
  return (
    <div className="header-breadcrumbs">
      <div className="top">
        <Icon
          icon="lucide:home"
          className="icon"
          onClick={() => navigate("/dashboard")}
        />
        <div className="dots" />
        <p>Search</p>
      </div>
    </div>
  );
};

export default HeadBreadCrumbSearch;
