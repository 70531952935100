import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const createAndUpdateTemplate = createAsyncThunk(
  "createAndUpdateTemplate",
  async (tempData: any) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/templates/upsert`,
        tempData
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const createAndUpdateTemplateSlice = createSlice({
  name: "createAndUpdateTemplate",
  initialState: {
    loading: false,
    createTemplateData: null,
    error: null as any,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAndUpdateTemplate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createAndUpdateTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.createTemplateData = action.payload;
    });
    builder.addCase(createAndUpdateTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default createAndUpdateTemplateSlice.reducer;
