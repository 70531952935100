import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getJobPromoField = createAsyncThunk(
  "getJobPromoField",
  async (id: any) => {
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/promotions/get_fields/${id}`
      );
      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data;
    }
  }
);

const getJobPromoFieldSlice = createSlice({
  name: "getJobPromoField",
  initialState: {
    loading: false,
    jobPromoFieldData: null,
    errorData: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.jobPromoFieldData = null;
      state.errorData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobPromoField.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobPromoField.fulfilled, (state, action) => {
      state.loading = false;
      state.jobPromoFieldData = action.payload;
    });
    builder.addCase(getJobPromoField.rejected, (state, action) => {
      state.loading = false;
      // Access the payload of the action to get the error information
      state.errorData = action.error;
    });
  },
});
export const { reset } = getJobPromoFieldSlice.actions;

export default getJobPromoFieldSlice.reducer;
