import React, { useEffect, useState } from "react";
import BottomHeader from "../components/Analytics/Promotion/BottomHeader";
import TopHeader from "../components/Analytics/Promotion/TopHeader";

import layoutTwo from "../components/layout/layoutTwo";
import Body from "../components/Analytics/Promotion/Body";

import { promoAnalytis, reset } from "../redux/slices/Analytics/promotionSlice";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getJobList } from "../redux/slices/CandidatesSlices/jobListSlice";
import { analytics } from "../redux/slices/Analytics/analyticsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/UI/Loader";
import { jobDetails } from "../redux/slices/JobSlices/jobDetailsSlice";
import { getEventsList } from "../redux/slices/EventSlices/getEventListSlice";
import { Button } from "@mui/material";

const Analytics = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { promoAnalytics, loading, error } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

  const { analyticsState } = useAppSelector(
    (state: any) => state.analyticsData
  );

  const { getJobsListData } = useAppSelector((state: any) => state.jobsList);
  const { getEventsListData } = useAppSelector(
    (state: any) => state.getAllEvents
  );

  const { jobDetailsData } = useAppSelector(
    (state: any) => state.getJobDetails
  );

  const [active, setActive] = useState<any>(false);
  const [switchMode, setSwitchMode] = useState("");
  const [click, setClick] = useState(false);

  useEffect(() => {
    dispatch(getJobList({ skip: 0, limit: 10000 }));
    dispatch(reset());
  }, [id]);

  useEffect(() => {
    dispatch(getEventsList({skip: 0, limit: 10000}));
    dispatch(reset());
  }, [id]);

  useEffect(() => {
    if (switchMode === "" && getJobsListData && id) {
      const filteredArray = getJobsListData?.result?.filter(
        (item: any) => item?._id === id
      );

      if (filteredArray) {
        setSwitchMode("job");
        setClick(true);
      }
    }

    if (switchMode === "" && getEventsListData && id) {
      const filteredEvent = getEventsListData?.result?.find(
        (event: any) => event?._id === id
      );

      if (filteredEvent) {
        setSwitchMode("event");
        setClick(true);
      }
    }
  }, [getJobsListData, getEventsListData, id]);

  useEffect(() => {
    if (id && switchMode !== "") {
      dispatch(
        promoAnalytis({ id: id, analyticsType: switchMode?.toUpperCase() })
      );
    }
  }, [id, click, switchMode]);

  useEffect(() => {
    if (id && switchMode !== "") {
      dispatch(analytics({ id: id, analyticsType: switchMode?.toUpperCase() }));
    }
  }, [id, click]);

  useEffect(() => {
    if (id === null && getJobsListData) {
      navigate(`?id=${getJobsListData?.result[0]?._id}`);
    }
  }, [id, getJobsListData, getEventsListData]);

  if (id === null) {
    return <Loader />;
  }

  return (
    <div className="analytics">
      {loading ? (
        <Loader />
      ) : (
        id != null && (
          <>
            {error?.statusCode === 403 ? (
              <>
                <TopHeader
                  switchMode={switchMode}
                  setSwitchMode={setSwitchMode}
                />
                <div className="error-card">
                  <h1>Oh noo.. !</h1>
                  <p>You do not have access to view analytics for this job.</p>
                  <div className="image">
                    <img
                      src="https://s1.ecnstatic.com/ecn/images/common/MicrosoftTeams-image (27).png"
                      alt=""
                    />
                  </div>
                  <div className="button">
                    <Button
                      className="submit-button"
                      onClick={() => {
                        navigate("/jobs");
                      }}
                    >
                      View Jobs
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <TopHeader
                  switchMode={switchMode}
                  setSwitchMode={setSwitchMode}
                />
                <BottomHeader active={active} />
                {analyticsState && promoAnalytics && (
                  <Body setActive={setActive} active={active} />
                )}
              </>
            )}
          </>
        )
      )}
    </div>
  );
};

export default layoutTwo(Analytics);
