import getMemberListSlice from "./getMemberListSlice";
import createGroupSlice from "./createGroupSlice";
import getAllGroupSlice from "./getAllGroupSlice";
import updateGroupdataSlice from "./updateGroupdataSlice";
import deleteGroupSlice from "./deleteGroupSlice";
import assignedJobSlice from "./assignedJobSlice";
import removeAssignedJobToRecruiter from "./removeAssignedJobToRecruiterSlice";
import getMemberListPaginationSlice from "./getMemberListPaginationSlice";

export const allGroupCreationSlices = {
  getAllMembers: getMemberListSlice,
  getAllMembersPagination: getMemberListPaginationSlice,
  postCreateGroup: createGroupSlice,
  allGroups: getAllGroupSlice,
  updateGroups: updateGroupdataSlice,
  deleteGroups: deleteGroupSlice,
  assignedJob: assignedJobSlice,
  removeAssignJobToRecruiter: removeAssignedJobToRecruiter,
};
