import React, { useEffect, useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getPromoEstimate } from "../../../redux/slices/JobPromotions/getPromoEstimateSlice";
import { postSavePromoRequest } from "../../../redux/slices/JobPromotions/savePromoRequestSlice";
import { useSearchParams } from "react-router-dom";
import { reset } from "../../../redux/slices/JobPromotions/getJobPromoFieldSlice";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 22,
  height: 22,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 1px 0 rgba(16,22,26,.1)",
  backgroundColor: "transparent",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    backgroundColor: "transparent",
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0034BB",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 22,
    height: 22,
    borderRadius: 3,
    backgroundImage: `url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="mdi:tick"><path id="Vector" d="M13.125 4.37539L5.625 11.8754L2.1875 8.43789L3.06875 7.55664L5.625 10.1066L12.2437 3.49414L13.125 4.37539Z" fill="white"/></g></svg>')`,
    backgroundSize: "15px 15px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    content: '""',
  },
});

const LeftAllFields: React.FC<{ setIsEstimateVisible: any }> = ({
  setIsEstimateVisible,
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { jobPromoFieldData, loading } = useAppSelector(
    (state: any) => state.getJobPromoField
  );
  const {
    promoRequestData,
    loading: promoRequestLoading,
    error,
  } = useAppSelector((state: any) => state.promoRequestData);

  const { estData } = useAppSelector((state: any) => state.getEstimateData);
  const { userInfo } = useAppSelector((state: any) => state.userDetails);

  const [formData, setFormData] = useState<any>();

  useEffect(() => {
    if (jobPromoFieldData) {
      setFormData(jobPromoFieldData);
    }
  }, [jobPromoFieldData]);

  // useEffect(() => {
   
  // }, [promoRequestData]);

  useEffect(() => {
    if (Array.isArray(formData) && formData.length > 0) {
      const isEveryFieldEmpty = formData.every((field) => {
        // Ensure values is always an array
        const valuesArray = Array.isArray(field?.values) ? field.values : [];

        return (
          valuesArray.length === 0 || // Check for empty array
          (valuesArray.includes(undefined) && valuesArray.length === 1) // Check for a single undefined value
        );
      });

      // Perform actions based on whether every field is empty or not
      if (isEveryFieldEmpty) {
        // Actions for all fields being empty
        setIsEstimateVisible(true);
      } else {
        // Actions for at least one field not being empty
        setIsEstimateVisible(false);
      }
    }
  }, [formData, estData]);

  const multipleChipHandler = (e: any, n: number) => {
    // Make sure the index is valid
    if (n >= 0 && n < formData.length) {
      // Create a new array with the updated values
      const newArray = [...formData];

      // If "All" checkbox is clicked, update values with all options or set to undefined
      if (e.target.value === "All") {
        newArray[n] = {
          ...newArray[n],
          values:
            newArray[n].options &&
            newArray[n].options.length === newArray[n].values.length
              ? [] // Unselect all if all are selected
              : newArray[n].options || [], // Select all options
        };
      } else {
        // If a regular checkbox is clicked, update values array based on selection
        newArray[n] = {
          ...newArray[n],
          values: newArray[n].values.includes(e.target.value)
            ? newArray[n].values.filter(
                (value: any) => value !== e.target.value
              )
            : newArray[n].values.length === 1 &&
              newArray[n].values[0] === undefined
            ? [] // If the only value is undefined, set to empty array
            : newArray[n].values.includes(undefined)
            ? newArray[n].values.filter((value: any) => value !== undefined)
            : [...newArray[n].values, e.target.value],
        };
      }

      // Return the updated array
      dispatch(
        getPromoEstimate({
          distinct_id: userInfo?.email,
          job_id: id,
          fields: newArray,
        })
      );
      setFormData(newArray);
    }
  };

  const multipleSelectHandler = (e: any, n: number) => {
    
    // Make sure the index is valid
    if (n >= 0 && n < formData.length) {
      // Create a new array with the updated values
      const newArray = [...formData];
      newArray[n] = {
        ...newArray[n],
        values: newArray[n].values.includes(e.target.value)
          ? newArray[n].values.filter((value: any) => value !== e.target.value)
          : e.target.value,
      };

      // Return the updated array
      dispatch(
        getPromoEstimate({
          distinct_id: userInfo?.email,
          job_id: id,
          fields: newArray,
        })
      );
      setFormData(newArray);
    }
  };
  const isFormDataValid = () => {
    if (!Array.isArray(formData) || formData.length === 0) {
      return false; // Empty form data
    }

    const chipFields = formData.filter((field) => field.type === "chips");
    const dropdownFields = formData.filter(
      (field) => field.type === "dropdown"
    );

    // Check if all chip fields are required
    if (
      chipFields.length === 0 ||
      !chipFields.every(
        (chipField) =>
          Array.isArray(chipField.values) && chipField.values.length > 0
      )
    ) {
      return false; // Empty or invalid chip fields
    }

    let universityCount = false;
    let graduationYearCount = false;

    // Check dropdown fields
    for (const field of dropdownFields) {
      if (
        (field.name === "university-ug" || field.name === "university-mba") &&
        field.values !== null &&
        field.values !== undefined &&
        field.values.length > 0
      ) {
        universityCount = true;
      } else if (
        (field.name === "graduation-year-ug" ||
          field.name === "graduation-year-mba") &&
        field.values !== null &&
        field.values !== undefined &&
        field.values.length > 0
      ) {
        graduationYearCount = true;
      } else if (
        field.values === null ||
        field.values === undefined ||
        field.values.length === 0
      ) {
        if (
          field.name !== "university-ug" &&
          field.name !== "university-mba" &&
          field.name !== "graduation-year-ug" &&
          field.name !== "graduation-year-mba"
        ) {
          return false; // Other dropdowns must not be empty
        }
      }
    }

    // Check that exactly one of "university" and exactly one of "graduation year" is selected
    return universityCount && graduationYearCount;
  };

  function getQuestion(que: any, index: number) {
    switch (que.type) {
      case "heading":
        return <h3 className="question-title">{que?.name}</h3>;
      case "chips":
        return (
          <div className="chips">
            {que?.options?.map((item: any, ind: any) => (
              <FormControlLabel
                key={index}
                className="check-box"
                onClick={(e) => multipleChipHandler(e, index)}
                control={
                  <Checkbox
                    sx={{
                      "&:hover": { bgcolor: "transparent" },
                    }}
                    disableRipple
                    checked={formData[index]?.values?.includes(item) || false}
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ "aria-label": "Checkbox demo" }}
                  />
                }
                label={item}
                value={item}
              />
            ))}
            {que?.multiple_select && (
              <FormControlLabel
                key={index}
                className="check-box"
                onClick={(e) => multipleChipHandler(e, index)}
                control={
                  <Checkbox
                    sx={{
                      "&:hover": { bgcolor: "transparent" },
                    }}
                    disableRipple
                    color="default"
                    checked={
                      que?.options?.every((option: string) =>
                        formData[index]?.values?.includes(option)
                      ) || false
                    }
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    inputProps={{ "aria-label": "Checkbox demo" }}
                  />
                }
                label="All"
                value="All"
              />
            )}
          </div>
        );

      case "dropdown":
        return (
          <div className="select">
            <FormControl fullWidth>
              {formData[index]?.values.length === 0 && (
                <InputLabel
                  htmlFor={`select-${index}`}
                  shrink={
                    !!(
                      formData[index]?.values && formData[index]?.values.length
                    )
                  }
                  className="label"
                >
                  {que?.placeholder}
                </InputLabel>
              )}
              <Select
                size="small"
                placeholder={que?.placeholder}
                variant="standard"
                disableUnderline
                multiple
                value={formData[index]?.values || []}
                onChange={(e) => multipleSelectHandler(e, index)}
                IconComponent={KeyboardArrowDown}
                renderValue={(selected: any) => {
                  if (selected === "") {
                    return (
                      <div style={{ color: "red" }}>{que?.placeholder}</div>
                    );
                  }
                  return selected.join(", ");
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      boxShadow: 0,
                      border: "1px solid rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      marginTop: "10px",
                    },
                  },
                }}
              >
                {que?.options?.map((name: any) => (
                  <MenuItem key={name} value={name} className="list-item">
                    <Checkbox
                      sx={{
                        "&:hover": { bgcolor: "transparent" },
                      }}
                      disableRipple
                      checked={formData[index]?.values?.includes(name) || false}
                      color="default"
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );

      default:
        return (
          <div>
            <p>{que?.name}</p>
            <p> Unable to determine Question Type. Try again later.</p>
          </div>
        );
    }
  }



  const saveDataHandler = () => {
    dispatch(
      postSavePromoRequest({
        job_id: id,
        fields: formData,
      })
    );
  };

  return (
    <div className="left-body">
      <div className="wrapper">
        <h1 className="title">
          Promote your Job Post to the targeted candidates
        </h1>
        {formData &&
          formData?.map((que: any, index: number) => getQuestion(que, index))}
        <div className="button">
          <Button
            className="submit-button"
            disabled={!isFormDataValid()}
            onClick={saveDataHandler}
          >
            Promote Job
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeftAllFields;
