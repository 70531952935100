import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { useEffect, useRef, useState } from "react";

const Lists: React.FC<{ hoverValue: any; item: any }> = ({
  hoverValue,
  item,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [itemsPerRow, setItemsPerRow] = useState<number>(10); // Initial number of items per row

  // Initialize sortedList
  const sortedList: any = [...item?.chart_results]
    ?.slice()
    .sort((a: any, b: any) => b.value - a.value);

  // Log the length of sortedList

  const chunks = [];

  // Populate chunks array based on itemsPerRow
  for (let i = 0; i < sortedList?.length; i += itemsPerRow) {
    chunks.push(sortedList.slice(i, i + itemsPerRow));
  }


  useEffect(() => {
    if (hoverValue !== undefined) {
      const element = document.getElementById(`lists-item-${hoverValue}`);

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }

    // Smooth scroll using JavaScript animation
    if (scrollRef.current) {
      const scrollToSmoothly = (
        scrollElement: any,
        posX: any,
        posY: any,
        duration: any
      ) => {
        const startX = scrollElement.scrollLeft;
        const startY = scrollElement.scrollTop;
        const distanceX = posX - startX;
        const distanceY = posY - startY;
        const startTime = performance.now();

        const easeOutQuart = (t: any) => 1 - --t * t * t * t;

        const scroll = (currentTime: any) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          const ease = easeOutQuart(progress);

          scrollElement.scrollLeft = startX + distanceX * ease;
          scrollElement.scrollTop = startY + distanceY * ease;

          if (progress < 1) {
            requestAnimationFrame(scroll);
          }
        };

        requestAnimationFrame(scroll);
      };

      scrollToSmoothly(scrollRef.current, 0, 0, 1500); // Slow scroll to position (0, 10) in 1500 milliseconds
    }
  }, [hoverValue]);

  return (
    <div
      ref={scrollRef}
      className="lists"
      style={{ width: `${item?.width * 8.2}%` }}
    >
      <div className="lists-container">
        {chunks.map((chunk, index) => (
          <>
            <div
              key={index}
              className="item"
              style={
                index != chunks.length - 1 && index != chunks.length - 2
                  ? { borderBottom: "1px solid rgba(0,0,0,0.1)" }
                  : {}
              }
            >
              {chunk.map((itemData: any, itemIndex: any) => (
                <div
                  key={itemIndex}
                  id={`lists-item-${itemData.label}`}
                  className={
                    hoverValue === itemData.label
                      ? "item-list active"
                      : "item-list"
                  }
                >
                  <div className="list-name-v2">
                    <div className="name">
                      <p>{index * itemsPerRow + itemIndex + 1}.</p>
                      <LightTooltip title={itemData?.label} placement="top">
                        <p className="uni">{itemData?.label}</p>
                      </LightTooltip>
                    </div>
                    <button className="button">
                      {" "}
                      {item?.show_percentage && `${itemData?.percentage}%`}{" "}
                      {item?.show_value && `${itemData?.value}`}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Lists;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(225, 233, 245, 1)",
    color: "#121212",
    boxShadow: "none",
    fontSize: 10,
    fontWeight: 400,
    padding: "7px 10px",
    backgroundClip: "padding-box",
    marginRight: "140px",
  },
}));
