import { Dialog, DialogContent, Radio } from "@mui/material";
import EcnButton from "../StyledComponents/EcnButton";
import { useState } from "react";
import { allJobScoreParameters } from "../../helpers/TempData";

const AiResumeScreeningPreferencesDialog = ({
  open,
  onConfirm
}: {
  open: boolean;
  onConfirm: (
    preferenceOption: "default" | "custom",
    selectedParameters: string[]
  ) => void;
}) => {
  const [preferenceOption, setPreferenceOption] = useState<
    "default" | "custom"
  >("default");
  const [selectedParameters, setSelectedParameters] = useState<string[]>([]);
  const [shake, setShake] = useState(false);

  return (
    <Dialog
      className={`ai-resume-screening-preferences-dialog ${
        shake ? "dialog-shake" : ""
      }`}
      maxWidth="sm"
      open={open}
      onClose={() => {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 1000);
      }}
    >
      <DialogContent className="ai-resume-screening-preferences-dialog-content">
        <div className="header">
          <h2 className="title">AI Resume Screening</h2>
          <p className="sub-title">
            Set Parameters for AI to give you results based on your preferences
          </p>
        </div>
        <div className="separator" />
        <div className="options">
          <div
            onClick={() => {
              setPreferenceOption("default");
            }}
            className="option-container"
          >
            <Radio
              checked={preferenceOption === "default"}
              onChange={() => {
                setPreferenceOption("default");
              }}
              value="default"
              name="radio-buttons"
              inputProps={{ "aria-label": "Set Default" }}
              style={{
                marginLeft: -10
              }}
            />
            <div className="option-text">
              <div className="option-text-heading">Set Default</div>
              <div className="option-text-description">
                We will set the default parameters for the screening based on
                your job description
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setPreferenceOption("custom");
            }}
            className="option-container"
          >
            <Radio
              checked={preferenceOption === "custom"}
              value="custom"
              name="radio-buttons"
              inputProps={{
                "aria-label": "Select your own preferences"
              }}
              style={{
                marginLeft: -10
              }}
            />
            <div className="option-text">
              <div className="option-text-heading">
                Select your own preferences
              </div>
              <div className="option-text-description">
                You can select amongst the few presets
              </div>
            </div>
          </div>
        </div>
        <div
          className="parameters-section"
          data-visible={preferenceOption == "custom"}
        >
          <p className="title">Parameters*</p>
          <div className="parameters-list">
            {allJobScoreParameters.map((parameter) => {
              return (
                <div
                  className="parameter"
                  data-selected={selectedParameters.includes(parameter)}
                  onClick={() => {
                    if (selectedParameters.includes(parameter)) {
                      setSelectedParameters((prev) => {
                        return prev.filter((v) => v !== parameter);
                      });
                    } else {
                      setSelectedParameters((prev) => {
                        return [...prev, parameter];
                      });
                    }
                  }}
                >
                  {parameter}
                </div>
              );
            })}
          </div>
        </div>
        <div className="cta-wrapper">
          <EcnButton
            onClick={() => onConfirm(preferenceOption, selectedParameters)}
            className="confirm-button"
            variant="contained"
          >
            Confirm
          </EcnButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default AiResumeScreeningPreferencesDialog;
