import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const updateJobStatus = createAsyncThunk(
  "updateJobStatus",
  async (status: any) => {
    const { data } = await axios.put(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/update_status`,
      status
    );

    return data;
  }
);

const updateJobStatusSlice = createSlice({
  name: "updateJobStatus",
  initialState: {
    loading: false,
    updateStatus: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateJobStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateJobStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.updateStatus = action.payload;
    });
    builder.addCase(updateJobStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default updateJobStatusSlice.reducer;
