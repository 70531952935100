import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const getAccountInfo = createAsyncThunk("getAccountInfo", async () => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + ADMIN_BASE}/v1/getAccountInfo`
    );

    return data;
  } catch (error: any) {
    // Throw the entire error object to be caught in the rejected case
    throw error.response?.data.statusCode;
  }
});

const getAccountInfoSlice = createSlice({
  name: "getAccountInfo",
  initialState: {
    loading: false,
    accountInfos: null,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.accountInfos = action.payload;
    });
    builder.addCase(getAccountInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getAccountInfoSlice.reducer;
