import getAccountInfoSlice from "./getAccountInfoSlice";
import userDetailsSlice from "./userDetailsSlice";
import userSigninSlice from "./userSigninSlice";
import userSignoutSlice from "./userSignoutSlice";
import editUserSlice from "./editUserSlice";
import getLicensePlanSlice from "./getLicensePlanSlice";
import getLicenseListSlice from "./getLicenseListSlice";

export const allUserSlices = {
  userDetails: userDetailsSlice,
  userSignIn: userSigninSlice,
  signOut: userSignoutSlice,
  getAccountInfoData: getAccountInfoSlice,
  editUser: editUserSlice,
  getPlansLicense: getLicensePlanSlice,
  getLicenseLists: getLicenseListSlice,
};
