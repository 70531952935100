import { Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../redux/hooks";

const Placeholders: React.FC<{ setValue: any; value: any }> = ({
  setValue,
  value,
}) => {
  const { placeholderData } = useAppSelector(
    (state: any) => state.placeholderList
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div className="placeholder">
      <RadioGroup value={value} onChange={handleChange}>
        <div className={"card-wrapper"}>
          {placeholderData?.map((item: any) => (
            <div className={item === value ? "card active" : "card"}>
              <div className="radio-name">
                <Radio color="info" className="radio-btn" value={item} />
                <div className="text">
                  <h2>{item}</h2>
                  <p>
                    Use this placeholder to add '
                    {item
                      ?.replace(/_/g, " ")
                      .replace(/\b\w/g, (c: any) => c.toUpperCase())}
                    '
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default Placeholders;
