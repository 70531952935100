import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const updateNotification = createAsyncThunk(
  "updateNotification",
  async (notification: any) => {
    const { data } = await axios.patch(
      `${GATEWAY_BASE + ADMIN_BASE}/updateNotificationSettings`,
      notification
    );
    return data;
  }
);

const updateNotificationSlice = createSlice({
  name: "updateNotification",
  initialState: {
    loading: false,
    patchNotification: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.patchNotification = action.payload;
    });
    builder.addCase(updateNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default updateNotificationSlice.reducer;
