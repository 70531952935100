import React, { useEffect, useState } from "react";
import PromotionAnalytics from "./PromotionAnalytics";
import { Icon } from "@iconify/react";
import Analytics from "../AnalyticsData/Analytics";

const Body: React.FC<{ setActive: any; active: any }> = ({
  setActive,
  active,
}) => {
  return (
    <div className="body">
      <div className="analytics-switch">
        <div className="switch">
          <p
            className={!active ? "p active" : "p "}
            onClick={() => setActive(!active)}
          >
            {" "}
            {/* <Icon icon="fluent:data-pie-20-regular" className="icon" />{" "} */}
            Promotions
          </p>
          <p
            className={active ? "p active" : "p"}
            onClick={() => setActive(!active)}
          >
            {" "}
            {/* <Icon icon="cil:bar-chart" className="icon" />  */}
            Analytics
          </p>
        </div>
      </div>
      {!active ? <PromotionAnalytics active={active}/> : <Analytics active={active} />}
    </div>
  );
};

export default Body;
