import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const inviteUser = createAsyncThunk(
  "inviteUser",
  async (userDetails: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/inviteUsers`,
      userDetails
    );
    return data;
  }
);

const inviteUserSlice = createSlice({
  name: "inviteUser",
  initialState: {
    loading: false,
    inviteUserData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(inviteUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(inviteUser.fulfilled, (state, action) => {
      state.loading = false;
      state.inviteUserData = action.payload;
    });
    builder.addCase(inviteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default inviteUserSlice.reducer;
