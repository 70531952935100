import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import layoutTwo from "../components/layout/layoutTwo";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getEventDetails } from "../redux/slices/EventSlices/getEventDetailsSlice";
import { Button, IconButton, MenuItem, Select } from "@mui/material";
import { Icon } from "@iconify/react";
import EcnInput from "../components/StyledComponents/V2/EcnInput";
import { useDropzone } from "react-dropzone";
import {
  addTrainingMaterials,
  Module
} from "../redux/slices/EventSlices/addTrainingMaterialSlice";
import { enqueueSnackbar } from "notistack";

const AddTrainingMaterials = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);
  const {
    loading: loadingAddTrainingMaterials,
    response: responseAddTrainingMaterials,
    error: errorAddTrainingMaterials
  } = useAppSelector((state) => state.addTrainingMaterials);
  const [saveReqSent, setSaveReqSent] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);

  const [modules, setModules] = useState<Module[]>([]);

  useEffect(() => {
    if (eventId) {
      dispatch(getEventDetails({ eventId }));
      setModules([
        {
          event_id: eventId,
          paid: false,
          title: ""
        }
      ]);
    }
  }, [eventId]);

  useEffect(() => {
    if (saveReqSent && loadingChange && !loadingAddTrainingMaterials) {
      if (!errorAddTrainingMaterials) {
        enqueueSnackbar(`Added materials successfully!`, {
          variant: "success"
        });
      } else {
        enqueueSnackbar(`Unable to add material!`, {
          variant: "error"
        });
      }
      setLoadingChange(false);
      setSaveReqSent(true);
    }
  }, [
    setLoadingChange,
    saveReqSent,
    loadingAddTrainingMaterials,
    errorAddTrainingMaterials
  ]);

  useEffect(() => {
    setLoadingChange(true);
  }, [loadingAddTrainingMaterials]);

  const saveHandler = () => {
    for (let i = 0; i < modules.length; i++) {
      const module = modules[i];

      if (module.title == "") {
        enqueueSnackbar(`Please enter title for module ${i + 1}`, {
          variant: "error"
        });
        return;
      }
      if (!module.typeOfMaterial) {
        enqueueSnackbar(`Please select type of material for module ${i + 1}`, {
          variant: "error"
        });
        return;
      }
      if (!module.files) {
        enqueueSnackbar(`Please select file for module ${i + 1}`, {
          variant: "error"
        });
        return;
      }
    }
    setLoadingChange(false);
    setSaveReqSent(true);
    dispatch(addTrainingMaterials({ modules }));
  };

  return (
    <div className="add-training-materials-page">
      <EcnBreadCrumbs>
        <p onClick={() => navigate(`/events?tab=events`)}>Events</p>
        <p onClick={() => navigate(`/event/${eventId}/?tab=event-details`)}>
          {eventDetailsData?.name.slice(0, 25)}...
        </p>
        <p
          onClick={() => {
            navigate(`/event/${eventId}/training-materials`);
          }}
        >
          Training Materials
        </p>
        <p>Add Training Materials</p>
      </EcnBreadCrumbs>
      <div className="body">
        <h2 className="page-title">Add Training Materials</h2>
        {modules.map((module, index) => {
          return (
            <div key={index} className="form">
              {index > 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    background: "rgba(33, 33, 33, 0.20)",
                    margin: "50px auto"
                  }}
                ></div>
              ) : null}
              <div className="field">
                <label className="field-label">Module {index + 1} title</label>
                <EcnInput
                  className="field-input"
                  placeholder="Enter module title"
                  value={module.title}
                  onChange={(e) => {
                    setModules((prevM) => {
                      prevM[index].title = e.target.value;
                      return [...prevM];
                    });
                  }}
                />
              </div>
              <div className="field-group">
                <div className="field">
                  <label className="field-label">
                    Type of training Material
                  </label>
                  <Select
                    className="select-input"
                    value={module.typeOfMaterial || "default"}
                    onChange={(e) => {
                      setModules((prevM) => {
                        prevM[index].typeOfMaterial = e.target.value;
                        return [...prevM];
                      });
                    }}
                    renderValue={(selected) => {
                      if (selected == "default") {
                        return (
                          <p className="select-placeholder">Select Type</p>
                        );
                      }
                      return <p>{selected}</p>;
                    }}
                    MenuProps={{
                      sx: {
                        ".MuiPaper-root": {
                          boxShadow: "-7px 7px 40px 0px rgba(33, 33, 33, 0.10)",
                          border: "1px solid rgba(33,33,33,0.2)",
                          ".MuiList-root": {
                            ".MuiButtonBase-root": {
                              fontWeight: 400
                            }
                          }
                        },
                        ".MuiMenuItem-root": {
                          padding: "12px 14px",
                          borderRadius: "7px",
                          "&:hover": {
                            bgColor: "rgba(0, 52, 187, 0.05) !important",
                            color: "rgba(0, 52, 187, 1)"
                          }
                        }
                      }
                    }}
                  >
                    <MenuItem value={"default"} disabled>
                      Select Type
                    </MenuItem>
                    <MenuItem value={"pre-event"}>Pre-requisite</MenuItem>
                    <MenuItem value={"during-event"}>During </MenuItem>
                    <MenuItem value={"post-event"}>Post</MenuItem>
                  </Select>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="field">
                    <label className="field-label">Material Availability</label>
                    <div className="material-availability">
                      <div
                        className="item"
                        data-selected={!module.paid}
                        onClick={() => {
                          setModules((prevM) => {
                            prevM[index].paid = false;
                            return [...prevM];
                          });
                        }}
                      >
                        After Registering
                      </div>
                      <div
                        className="item"
                        data-selected={module.paid}
                        onClick={() => {
                          setModules((prevM) => {
                            prevM[index].paid = true;
                            console.log({ prevM });
                            return [...prevM];
                          });
                        }}
                      >
                        After Mark As Paid
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {module.files && module.files.length > 0 ? (
                <div className="file-uploaded">
                  <div className="file-name-discard">
                    <div className="file-name">
                      {Array.from(module.files).map((file, i) => {
                        return <div key={i}>{file.name}</div>;
                      })}
                    </div>
                    <IconButton
                      className="delete-file-btn"
                      onClick={() => {
                        setModules((prevM) => {
                          prevM[index].files = null;
                          return [...prevM];
                        });
                      }}
                    >
                      <Icon icon="ic:baseline-close" width={24} height={24} />
                    </IconButton>
                  </div>
                  <div className="uploaded-buttons">
                    <input
                      type="file"
                      accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                      style={{
                        position: "absolute",
                        visibility: "hidden",
                        width: 0,
                        height: 0
                      }}
                      id="replace-file-btn"
                      multiple={false}
                      onChange={(e) => {
                        setModules((prevM) => {
                          if (e.target.files && e.target.files.length > 0) {
                            prevM[index].files = Array.from(e.target.files);
                          }
                          return [...prevM];
                        });
                      }}
                    />
                    <label
                      className="replace-file-btn"
                      htmlFor="replace-file-btn"
                    >
                      Replace
                    </label>
                  </div>
                </div>
              ) : (
                <FileUploader
                  onFileDrop={(acceptedFiles) => {
                    setModules((prevM) => {
                      prevM[index].files = acceptedFiles;
                      return [...prevM];
                    });
                  }}
                />
              )}
            </div>
          );
        })}
        <Button
          variant="outlined"
          className="button"
          style={{
            width: "100%",
            color: "rgba(0, 52, 187, 1)",
            border: "1px solid rgba(33, 33, 33, 0.20)"
          }}
          onClick={() => {
            if (eventId) {
              setModules([
                ...modules,
                {
                  event_id: eventId,
                  paid: false,
                  title: ""
                }
              ]);
            }
          }}
        >
          <Icon icon="lucide:plus" />
          <span>Add Another Module</span>
        </Button>
        <div className="page-buttons">
          <Button
            className="button button-outlined"
            variant="outlined"
            style={{ padding: "10px 18px" }}
          >
            Save draft
          </Button>
          <Button
            className="button"
            variant="contained"
            style={{ padding: "10px 40px" }}
            onClick={saveHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const FileUploader = ({
  onFileDrop
}: {
  onFileDrop: (acceptedFiles: File[]) => void;
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      onFileDrop(acceptedFiles);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    accept: { "application/zip": [".zip"] }
  });
  return (
    <div className="file-uploader" {...getRootProps()}>
      <input {...getInputProps()} />
      <p className="drag-text">Drag Files here</p>
      <p className="or">or</p>

      <div style={{ position: "relative" }}>
        <input
          type="file"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          style={{
            position: "absolute",
            visibility: "hidden",
            width: 0,
            height: 0
          }}
          multiple={false}
          onChange={(ev) => {
            if (ev.target.files && ev.target.files.length > 0)
              onFileDrop(Array.from(ev.target.files));
          }}
          id="file-input"
        />
        <label className="select-from-device-btn" htmlFor="file-input">
          <Icon icon="lucide:monitor" width={18} height={18} />
          <span>Select from device</span>
        </label>
      </div>
      <div className="muted-info">
        Only upload one zip file that has all the material. Files should be
        either a slideshow, excel sheet or video file and should be named as per
        material title.
      </div>
    </div>
  );
};
export default layoutTwo(AddTrainingMaterials);
