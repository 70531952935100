import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import EcnInput from "../components/StyledComponents/EcnInput";
import { userSignin } from "../redux/slices/userSlices/userSigninSlice";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";
import { trackMixpanelEvents } from "../helpers/Analytics/mixpanelProxyApi";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    token,
    errorData,
    error: loginError,

    loading,
  } = useAppSelector((state: any) => state.userSignIn);

  const { userInfo, loading: userLoading } = useAppSelector(
    (state: any) => state.userDetails
  );

  const [userData, setUserData] = useState({
    email: "",
    password: "",
    enterprise: true,
    roles: ["recruiter"],
  });
  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  const [click, setClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (token && click) {
      dispatch(userDetails());
      // window.location.reload();
    }
  }, [token]);

  useEffect(() => {
    if (userInfo) {
      trackMixpanelEvents({
        eventName:
          "ENTERPRISE_RECRUITER_LEFT_NAVIGATION_BAR_DASHBOARD_REDIRECT",
        url: window.location.href,
        distinctId: userData.email,
        properties: {},
      });
      // window.location.href = "/dashboard";
    }
  }, [userInfo]);

  useEffect(() => {
    if (loginError) {
      setError({
        status: true,
        message:
          "Unable to sign in. If you have an invitation, please ensure you're using the correct email address.",
      });
    }
  }, [loginError, loading]);

  const emailHandler = (e: any) => {
    setUserData({ ...userData, email: e.target.value });
  };

  const passwordHandler = (e: any) => {
    setUserData({ ...userData, password: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    trackMixpanelEvents({
      eventName: "ENTERPRISE_RECRUITER_SIGNIN_CLICK",
      url: window.location.href,
      distinctId: userData.email,
      properties: {},
    });
    if (userData.email === "" || userData.password === "") {
      setError({
        status: true,
        message: "Please enter your email and password",
      });
    } else {
      setClick(true);
      dispatch(userSignin(userData));
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="signIn">
      <div className="container">
        <div className="wrapper">
          <img
            src="/elevate_enterprise.png"
            alt="Onefinnet"
            className="main_logo"
          />
          <div className="login-detail">
            <h1 className="title">Login to your recruiter account</h1>

            <div className="input-text">
              <p>Email</p>
              <EcnInput
                className="ecn-input"
                placeholder="Enter your Email"
                onChange={emailHandler}
                type="email"
              />
            </div>
            <div className="input-text">
              <p>Password</p>

              <EcnInput
                className="ecn-input"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={userData.password}
                onChange={passwordHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <IconButton>
                          <Icon icon="ph:eye-light" />{" "}
                        </IconButton>
                      ) : (
                        <IconButton>
                          <Icon icon="ph:eye-slash-light" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {error?.status && (
              <Alert
                severity="error"
                sx={{ marginBottom: "30px", width: "100%" }}
              >
                {error?.message}
              </Alert>
            )}
            <LoadingButton
              onClick={onSubmit}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              className="btn-contained"
            >
              <span>Log in</span>
            </LoadingButton>
          </div>
        </div>
        <div className="right">
          <img
            src={
              "https://s1.ecnstatic.com/ecn/images/enterprise/signup_placeholder.png"
            }
            alt="Placeholder"
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
