import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const BasicDetailsPreview: React.FC<{ jobData: any }> = ({ jobData }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";

  return (
    <div className="Basic-details-review">
      <div className="preview-top">
        <p>
          <span>1</span> of 3
        </p>
        <IconButton
          onClick={() =>
            navigate(
              `?view=basic-details${
                id === "" ? "" : "&id=" + id
              }&redirect=preview`
            )
          }
        >
          <Icon icon="lucide:pencil" color="rgba(0, 52, 187, 0.7)" />
        </IconButton>
      </div>
      <div className="row">
        <p className="label">Job title*</p>
        <div className="text">
          <p>
            {" "}
            {jobData?.job_title.length > 50
              ? jobData?.job_title?.slice(0, 50) + "...."
              : jobData?.job_title}
          </p>
        </div>
      </div>

      <div className="row">
        <p className="label">Industry*</p>
        <div className="text">
          <p>{jobData?.industries[0]}</p>
        </div>
      </div>

      <div className="row">
        <p className="label">Job role*</p>
        <div className="text">
          <p>{jobData?.job_roles[0]}</p>
        </div>
      </div>

      <div className="row">
        <p className="label">Location*</p>
        <div className="text">
          <p>
            {jobData?.address?.country}, {jobData?.address?.state},{" "}
            {jobData?.address?.cities[0]}
          </p>
        </div>
      </div>

      <div className="row">
        <p className="label">Role type*</p>
        <div className="text">
          <p>{jobData?.role_type}</p>
        </div>
      </div>

      {jobData?.internal_job_id !== "" && (
        <div className="row">
          <p className="label">Internal Job ID</p>
          <div className="text">
            <p>
              {jobData?.internal_job_id.length > 50
                ? jobData?.internal_job_id?.slice(0, 50) + "...."
                : jobData?.internal_job_id}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicDetailsPreview;
