import getJobPromoFieldSlice from "./getJobPromoFieldSlice";
import getJobPromotionAnalyticsSlice from "./getJobPromotionAnalyticsSlice";
import getPromoEstimateSlice from "./getPromoEstimateSlice";
import savePromoRequestSlice from "./savePromoRequestSlice";
import postPromotionRequestSlice from "./postPromotionRequestSlice";

export const allJobPromotionsSlices = {
  getJobPromoField: getJobPromoFieldSlice,
  getEstimateData: getPromoEstimateSlice,
  promoRequestData: savePromoRequestSlice,
  getJobPromotionAnalytics: getJobPromotionAnalyticsSlice,
  postPromotionRequest: postPromotionRequestSlice,
};
