import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export const getEventAttendeeDetail = createAsyncThunk(
  "getEventAttendeeDetail",
  async (eventId: string) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/registration/details/${eventId}`
    );
    return data;
  }
);

const getEventAttendeeDetailSlice = createSlice({
  name: "getEventAttendeeDetail",
  initialState: {
    loading: false,
    eventAttendeeDetail: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventAttendeeDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEventAttendeeDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.eventAttendeeDetail = action.payload;
      state.success = true;
    });
    builder.addCase(getEventAttendeeDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  },
});

export default getEventAttendeeDetailSlice.reducer;
