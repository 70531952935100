import {
  Dialog,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserDetail from "../components/InviteUser/UserDetail";
import RoleAssign from "../components/InviteUser/RoleAssign";
import Preview from "../components/InviteUser/Preview";
import MapingTable from "../components/InviteUser/MapingTable";
import layoutTwo from "../components/layout/layoutTwo";
import { useAppSelector } from "../redux/hooks";
import { BorderColor } from "@mui/icons-material";


const CustomConnector = styled(StepConnector)(({ theme }) => ({
  alternativeLabel: {
    top: 10, // Aligns the connector line properly with the step circles
  },
  active: {
    '& .MuiStepConnector-line': {
      borderColor: '#1E90FF !important', // Force apply the active color
    },
  },
  completed: {
    '& .MuiStepConnector-line': {
      borderColor: '#1E90FF !important', // Force apply the completed color
    },
  },
  line: {
    borderTopWidth: 3, // Increase line thickness
    borderRadius: 1, // Add subtle rounding to the line
    borderColor: '#ccc', // Default inactive color
  },
}));


const CustomStepIconRoot = styled('div')<{
  ownerState: { completed: boolean; active: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  color: '#ccc',
  display: 'flex',
  height: 22,
  width: 22,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: ownerState.completed || ownerState.active ? '#0034BB' : '#ccc',

}));


function CustomStepIcon(props: any) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? '✓' : ''}
    </CustomStepIconRoot>
  );
}





const InviteUser = () => {
  const navigate = useNavigate();

  const { userInfo, loading, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );


  const [searchParams] = useSearchParams();
  const view = searchParams.get("step");
  const tab = searchParams.get("tab");
  const [active, setActive] = useState<any>(0);
  const [mapData, setMapData] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [checkFiled, setCheckField] = useState({
    email: false,
    first_name: false,
    last_name: false,

  });

  const [data, setData] = useState<any>();
  const [userData, setUserData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>("");
  const steps = ["Email", "Permissions", "Invite"];
  const [role, setRole] = useState(["enterprise-member"]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (userData?.length === 0) {
      navigate("?view=invite-user&step=user-detail");
    }
  }, []);


  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div className="inviteUser">
      <h1 className="title">Invite users</h1>
      <div className="stepper">

        <Stepper
          alternativeLabel
          activeStep={active}
          connector={
            <StepConnector
              sx={{
                '& .MuiStepConnector-line': {
                  borderTopWidth: 2, // Set the line thickness
                  borderColor: '#ccc', // Default inactive color
                },
                '&.Mui-active .MuiStepConnector-line': {
                  borderColor: '#0034BB', // Active step line color
                },
                '&.Mui-completed .MuiStepConnector-line': {
                  borderColor: '#0034BB', // Completed step line color
                },
              }}
            />
          }
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      {/* {view === "user-detail" && (<SelectProduct />)} */}
      {view === "user-detail" && (
        <UserDetail
          setData={setData}
          setActive={setActive}
          setUserData={setUserData}
          userData={userData}
          setFileName={setFileName}
          fileName={fileName}
          setOpen={setOpen}
        />
      )}

      {view === "permission" && (
        <RoleAssign setActive={setActive} setUserData={setUserData} userData={userData} setRole={setRole} role={role} />
      )}
      {view === "preview" && (
        <Preview userData={userData} setActive={setActive} setUserData={setUserData} />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "100%",  // Set your width here
              borderRadius: "10px"

            },
          },
        }}
      >
        <MapingTable
          data={data}
          setUserData={setUserData}
          userData={userData}
          setActive={setActive}
          setMapData={setMapData}
          mapData={mapData}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setCheckField={setCheckField}
          checkFiled={checkFiled}
          setOpen={setOpen}

        />
      </Dialog>

    </div>
  );
};

export default layoutTwo(InviteUser);
