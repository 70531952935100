import React from "react";
import { Button, ButtonProps } from "@mui/material";

const EcnButton = (props: ButtonProps) => {
  

  return (
    <Button className={props.className} {...props}>
      {props.children}
    </Button>
  );
};

export default EcnButton;
