import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const scoringNotes = createAsyncThunk(
  "scoringNotes",
  async (noteData: any) => {
    const { data } = await axios.post(
      `${
        GATEWAY_BASE + JOB_CREATE_BASE
      }/api/v2/application/talent_score/addNotes`,
      noteData
    );

    return data;
  }
);

const scoringNotesSlice = createSlice({
  name: "scoringNotes",
  initialState: {
    loading: false,
    notesData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(scoringNotes.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(scoringNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.notesData = action.payload;
    });
    builder.addCase(scoringNotes.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export default scoringNotesSlice.reducer;
