import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export type Module = {
  event_id: string;
  paid: boolean;
  title: string;
  typeOfMaterial?: string;
  files?: File[] | null;
};

export const addTrainingMaterials = createAsyncThunk(
  "addTrainingMaterials",
  async (payload: { modules: Module[] }) => {
    const promises: Promise<any>[] = [];

    payload.modules.forEach((m) => {
      if (m?.files) {
        const fd = new FormData();
        Array.from(m.files).forEach((file) => {
          fd.append("file", file);
        });
        fd.append("event_id", m.event_id);
        fd.append("paid", String(m.paid));
        fd.append("title", m.title);
        fd.append("material_type", m.typeOfMaterial || "");
        fd.append("tags", "");

        promises.push(
          axios.postForm(
            `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/resourse/upload_module`,
            fd,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
        );
      }
    });
    const data = await Promise.all(promises);
    return data;
  }
);

const addTrainingMaterialsSlice = createSlice({
  name: "addTrainingMaterials",
  initialState: {
    loading: false,
    error: false,
    response: undefined as undefined | any[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addTrainingMaterials.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.response = undefined;
    });
    builder.addCase(addTrainingMaterials.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.response = action.payload;
    });
    builder.addCase(addTrainingMaterials.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.response = undefined;
    });
  }
});

export default addTrainingMaterialsSlice.reducer;
