import axios from "axios";
let isRefreshing = false;
let failedQueue = [] as any[];

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";
const KEYCLOCK_BASE = GATEWAY_BASE + AUTH_BASE;

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: KEYCLOCK_BASE,
});

/*axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
 
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
 
      originalRequest._retry = true;
      isRefreshing = true;
 
      const refreshToken = JSON.parse(
        localStorage.getItem("token")!
      ).refresh_token;
      return new Promise(function (resolve, reject) {
        axios
          .post(`${KEYCLOCK_BASE}/v1/keycloak/refreshToken`, {
            refresh_token: refreshToken,
            enterprise: true,
          })
          .then(({ data }) => {
            window.localStorage.setItem("token", JSON.stringify(data));
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.access_token;
            originalRequest.headers["Authorization"] =
              "Bearer " + data.access_token;
            processQueue(null, data.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
 
    return Promise.reject(error);
  }
);*/

export default axiosInstance;
