export const createImage = (url:any) =>
  new Promise((resolve, reject) => {
    const image:any = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error:any) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc:any,
  pixelCrop:any,

) {

  const image:any = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')


  if (!ctx) {
    return null
  }

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = 1080;
    canvas.height = 1080;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
)

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((file:any) => {
  //     resolve(URL.createObjectURL(file))
  //   }, 
  //   'image/jpeg')
  // })
}
