import { Icon } from "@iconify/react";

const EcnNumberInput: React.FC<{
  count: any;
  setCount: any;
  limit?: any;
  placeholder: any;
}> = ({ count, setCount, limit, placeholder }) => {
  const increaseCountHandler = () => {
    const parsedCount = parseInt(count, 10); // Parse count to an integer

    if (isNaN(parsedCount)) {
      // If count is not a valid number, set it to 1
      setCount(1);
    } else if (parsedCount < (limit?.upper || Infinity)) {
      // If parsedCount is less than the upper limit or count is an empty string
      setCount(parsedCount + 1);
    }
  };

  const decreaseCountHandler = () => {
    const parsedCount = parseInt(count, 10); // Parse count to an integer

    if (isNaN(parsedCount)) {
      // If count is not a valid number, set it to the lower limit
      setCount(limit?.lower || 0);
    } else if (parsedCount > limit?.lower || count === "") {
      // If count is greater than the lower limit or count is an empty string
      setCount(parsedCount - 1);
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const regex = /^[0-9\b]+$/;

    if (newValue === "") {
      setCount(""); // Allow empty input
    } else if (regex.test(newValue) && parseInt(newValue) <= limit?.upper) {
      setCount(parseInt(newValue)); // Allow valid numeric input less than or equal to 30
    } else {
      // If the entered value is not a valid number or exceeds 30, revert to the previous value
      e.target.value = count.toString();
    }
  };

  

  return (
    <div className="ecn-number-input-v2">
      <div
        className={
          count != "" && (count > limit.upper || count <= 0)
            ? // ? "input-icon error"
              // : "input-icon"
              "input-icon "
            : "input-icon"
        }
      >
        <input
          type="number"
          value={count}
          onChange={(e: any) => changeHandler(e)}
          placeholder={placeholder}
        />
        <div className="arrows">
          <Icon
            icon="lucide:chevron-up"
            className="icon"
            onClick={increaseCountHandler}
            style={{ cursor: "pointer" }}
          />
          <Icon
            icon="lucide:chevron-down"
            className="icon"
            onClick={decreaseCountHandler}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      {/* {count != "" && (count > limit.upper || count <= 0) && (
        <p
          style={{
            color: "#C53838",
            fontSize: "14px",
            fontWeight: "400",
            marginTop: "5px",
          }}
        >
          Uh oh! It seems {count} isn't valid here.
        </p>
      )} */}
    </div>
  );
};

export default EcnNumberInput;
