import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { animated, useSpring } from "react-spring";

import { colors } from "../../../../../helpers/TempData";

const PieChart: React.FC<{
  setHoverValue: any;
  customsColor: any;
  item: any;
}> = ({ setHoverValue, customsColor, item }) => {
  let mapData: any = [];

  function mapAry(val: any, name: any, per: any, index: any) {
    var obj: any = {};
    obj["id"] = name;
    obj["value"] = val;
    obj["label"] = name;
    obj["per"] = per;
    mapData.push(obj);
  }

  item?.chart_results?.map((item: any, index: any) =>
    mapAry(item?.value, item?.label, item?.percentage, index)
  );
  const repeatedColors = Array.from(
    { length: Math.ceil(mapData.length / customsColor?.length) },
    () => customsColor
  ).flat();

  const handleMouseEnter = (data: any, event: any) => {
    setHoverValue(data?.label);
    // Your custom logic here
  };

  const handleMouseLeave = () => {
    setHoverValue();
    // Your custom logic here
  };
  const animationProps = useSpring({
    opacity: 1,
  });

  return (
    <div className="lists-pie" style={{ width: `${item?.width * 8}%` }}>
      <ResponsivePie
        data={mapData}
        margin={{ top: 40, right: 30, bottom: 70, left: 0 }}
        startAngle={0}
        activeInnerRadiusOffset={15}
        activeOuterRadiusOffset={11}
        borderWidth={1.4}
        colors={[...repeatedColors]}
        borderColor={"#fff"}
        arcLinkLabelsTextOffset={7}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsOffset={-15}
        arcLinkLabelsSkipAngle={30}
        arcLinkLabelsDiagonalLength={20}
        arcLinkLabelsStraightLength={20}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={false}
        arcLabel={(e) => e.id + " (" + e.value + ")"}
        arcLabelsRadiusOffset={0}
        transitionMode="middleAngle"
        legends={[]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        arcLinkLabelComponent={(d: any) => {
          return (
            <animated.g style={animationProps}>
              <animated.path
                fill="none"
                stroke={d.style.linkColor}
                strokeWidth={d.style.thickness}
                d={d.style.path}
              />
              <animated.text
                key={`arcLinkLabel_segment_closurereasons_${d.label}`}
                transform={d.style.textPosition}
                textAnchor={d.style.textAnchor}
                dominantBaseline="central"
              >
                {/* Split the label into multiple lines if its length exceeds 30 characters */}

                <tspan x="0px" y="0px" style={{ fontSize: "10px" }}>
                  {d?.label.length >= 15
                    ? d?.label.substring(0, 15) + "..."
                    : d?.label}
                </tspan>

                <tspan x="0" dy="14px" style={{ fontSize: "10px" }}>
                  {item?.show_value && d?.datum?.data?.value} (
                  {item?.show_percentage && d?.datum?.data?.per}%)
                </tspan>
              </animated.text>
            </animated.g>
          );
        }}
        tooltip={(da: any) => (
          <div
            style={{
              background: "white",
              padding: "5px 10px",
              width: "fit-content",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                background: da?.datum?.color,
                borderRadius: "100%",
              }}
            ></div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#121212",
              }}
            >
              {da?.datum?.data.id}{" "}
              <span style={{ fontWeight: "600" }}>
                : {item?.show_value && da?.datum?.data?.value}
                {item?.show_percentage && `(${da?.datum?.data?.per}%)`}
              </span>
            </p>
          </div>
        )}
      />
    </div>
  );
};

export default PieChart;
