import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { Icon } from "@iconify/react";
import ToolTips from "./toolTips";
import Fallback from "../../Fallback";

const colors = [
  "#59A3F5",
  "#91DDFE",
  "#147CF7",
  "#2756CD",
  "#3D6FF2",
  "#3E85B8",
  "#0FA9FF",
  "#60A5FA",
  "#0E3FBF",
  "#ABE",
  "#7D97DB",
  "#88A9FF",
];

const Diversity = () => {
  let mapData: any = [];

  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

  function insertNewline(str: any, limit: any) {
    const words = str.split(" ");
    const result = [];
    let currentLine = "";

    words.forEach((word: any) => {
      if (currentLine.length + word.length <= limit) {
        currentLine += (currentLine.length > 0 ? " " : "") + word;
      } else {
        result.push(currentLine);
        currentLine = word;
      }
    });

    if (currentLine.length > 0) {
      result.push(currentLine);
    }

    return result.join("\n");
  }
  function mapAry(val: any, name: any, index: any) {
    var obj: any = {};
    obj["id"] = index;

    obj["value"] = val;
    obj["label"] = insertNewline(name, 20);
    obj["color"] = colors[index];
    mapData.push(obj);
  }

  promoAnalytics?.diversity?.results?.map((item: any, index: any) =>
    mapAry(item.count, item._id === "" ? "Other" : item._id, index)
  );

  return (
    <div className="job-function-diversity">
      <div className="chart-icon">
        <Icon icon="cil:bar-chart" className="icon" />
      </div>
      <h1>
        Diversity and Inclusion{" "}
        <ToolTips title={promoAnalytics?.diversity?.tooltip} />
      </h1>
      {promoAnalytics?.diversity?.results?.length ?? 0 ? (
        <div className="wrapper">
          <PieChart
            margin={{ top: 20, left: 20, right: 0, bottom: 20 }}
            series={[
              {
                data: [...mapData],
                innerRadius: 35,
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
            width={180}
            height={160}
          />
          <div className="wrapper-list">
            {mapData?.map((item: any) => {
              const total = mapData.reduce(
                (sum: any, currentItem: any) => sum + currentItem.value,
                0
              );
              const percentage =
                total !== 0 ? ((item.value / total) * 100).toFixed(0) : 0;

              return (
                <div className="pie-legend-list" key={item.label}>
                  <div className="pie-legend-list-item">
                    <div
                      className="dots"
                      style={{ background: `${item.color}` }}
                    ></div>
                    <p>{item.label}</p>
                  </div>

                  <span>{`${percentage}%`}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Fallback message="No diversity and inclusion data available yet" />
      )}
    </div>
  );
};

export default Diversity;
