import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

interface CandidateListProps {
  id: any;
  limit: number;
  st?: number;
  sort?: string;
  skip?: number;
}

export const candidatesList = createAsyncThunk(
  "candidatesList",
  async (reqData: CandidateListProps) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/list/${
        reqData?.id
      }?skip=${reqData?.skip ? reqData.skip : 0}&limit=${
        reqData.limit ?? "9999"
      }${reqData?.st === -1 ? "" : "&status=" + reqData?.st}${
        reqData.sort ? "&sort=" + reqData.sort : ""
      }`
    );

    return data;
  }
);

const candidatesListSlice = createSlice({
  name: "candidatesList",
  initialState: {
    loading: false,
    candidatesListData: null,
    error: false,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(candidatesList.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(candidatesList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.candidatesListData = action.payload;
    });
    builder.addCase(candidatesList.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  }
});

export default candidatesListSlice.reducer;
