import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getTopPickStatus = createAsyncThunk(
  "getTopPickStatus",
  async (jobId: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + JOBS_CREATE_BASE}/api/v1/application/relevancy_score/status`,
      {
        job_id: jobId,
      }
    );
    return data;
  }
);

const getTopPickStatusSlice = createSlice({
  name: "getTopPickStatus",
  initialState: {
    loading: false,
    topPickStatus: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopPickStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTopPickStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.topPickStatus = action.payload;
    });
    builder.addCase(getTopPickStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getTopPickStatusSlice.reducer;
