import React, { useEffect, useState } from "react";
import EcnInput from "../StyledComponents/EcnInput";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import { useQueryParams } from "../../helpers/HOC/useQueryParams";

const ListHeader = () => {
  const [searchParams, updateQueryParams] = useQueryParams();
  const [search, setSearch] = useState<string>(searchParams.get("q") || "");

  const searchHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateQueryParams({ q: search });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [search]);

  return (
    <div className="event-list-header">
      <EcnInput
        value={search}
        onChange={searchHandler}
        className="ecn-input-v2 event-search"
        placeholder="Search Events"
      />

      <div className="btn-group">
        <EcnButton
          startIcon={<Icon icon="lucide:plus" />}
          variant="contained"
          className="btn-contained-v2"
        >
          Create Event
        </EcnButton>
      </div>
    </div>
  );
};

export default ListHeader;
