import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getJobFilter = createAsyncThunk("getJobFilter", async () => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/dashboard/job_filters`
  );
  return data;
});

const getJobFilterSlice = createSlice({
  name: "getJobFilter",
  initialState: {
    loading: false,
    jobFilterData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobFilter.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.jobFilterData = action.payload;
    });
    builder.addCase(getJobFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getJobFilterSlice.reducer;
