import React from "react";

const List: React.FC<{ list: any }> = ({ list }) => {
  const sortedData = [...list?.chart_results]?.sort(
    (a: any, b: any) => b.value - a.value
  );


  return (
    <div
      className="level-list"
      style={{
        width: `${list?.width * 8.2}%`,
        background: `${
          list?.background_color === "" ? list?.background_color : "transparent"
        }`,
      }}
    >
      <h1 className="title">{list?.chart_label} </h1>
      <div className="level-list-body">
        {sortedData?.map((item: any, index: any) => (
          <div className="level-list-item">
            <div className="list-left">
              <p>{index + 1}.</p>
              <p>{item?.label}</p>
            </div>
            <button>
              {list?.show_value && item.value}
              {list?.show_percentage && item?.percentage}%
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default List;
