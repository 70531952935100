import { Icon } from "@iconify/react";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Drawer,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSearchParams } from "react-router-dom";
import { trackMixpanelEvents } from "../../../../../helpers/Analytics/mixpanelProxyApi";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { scoreParamList } from "../../../../../redux/slices/CandidatesSlices/scoreParamListSlice";
import { scoreParamUpdate } from "../../../../../redux/slices/CandidatesSlices/scoreParamUpdateSlice";
import EcnInput from "../../../../StyledComponents/EcnInput";

import Rating from "./Rating";

const Score: React.FC<{
  data: any;
  scoreModifier: any;
  setScoreModifier: any;
}> = ({ data, scoreModifier, setScoreModifier }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const job_id = searchParams.get("application_id");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const { candidatesDetailsData } = useAppSelector(
    (state: any) => state.candidateDetails
  );
  const { scoreParamListData } = useAppSelector(
    (state: any) => state.scoreParamList
  );
  const { scoreParamUpdateData } = useAppSelector(
    (state: any) => state.putScoreParamUpdate
  );

  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const { talentScore } = useAppSelector((state: any) => state.talentScoreHome);

  const [score, setScore] = useState<any[]>([]);
  const [scoreList, setScoreList] = useState<any[]>([]);
  const [add, setAdd] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });
  const [customParam, setCustomParam] = useState<any>("");
  const [customParamValue, setCustomParamValue] = useState<any>("");
  const [click, setClick] = useState(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [initialDataArray, setInitialDataArray] = useState<Array<any>>(
    candidatesDetailsData?.job_score_parameters || []
  );
  const [changedIndices, setChangedIndices] = useState<Set<number>>(new Set());

  useEffect(() => {
    dispatch(scoreParamList());
  }, []);

  useEffect(() => {
    if (candidatesDetailsData) {
      if (
        JSON.stringify(score) !==
        JSON.stringify(candidatesDetailsData?.job_score_parameters?.length)
      ) {
        setScore(candidatesDetailsData?.job_score_parameters);
        setScoreList(candidatesDetailsData?.job_score_parameters);
      }
    }
  }, [candidatesDetailsData]);

  useEffect(() => {
    if (scoreParamUpdateData && click) {
      setOpenConfirm(false);
      enqueueSnackbar("Updated successfully!", {
        variant: "success",
      });
      setScoreModifier(false);
    }
  }, [scoreParamUpdateData]);

  useEffect(() => {
    setInitialDataArray(candidatesDetailsData?.job_score_parameters);
  }, [scoreParamUpdateData]);

  useEffect(() => {
    const hasChanges = !arraysEqual(scoreList, initialDataArray);
    setHasChanges(hasChanges);
  }, [scoreList]);

  useEffect(() => {
    if (inputRefs.current.length > 0 && inputRefs.current[0]) {
      inputRefs.current[0]?.focus();
    }
  }, []);

  const arraysEqual = (arr1: Array<any>, arr2: Array<any>) => {
    if (arr1?.length !== arr2?.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      // Compare each object's properties
      for (const key in arr1[i]) {
        if (arr1[i][key] !== arr2[i][key]) return false;
      }
    }
    return true;
  };

  const toggleDrawer = () => {
    setScoreModifier(false);
  };

  const totalWeight = () => {
    let scores = scoreList?.slice();
    let total = 0;

    scores?.forEach((score: any) => {
      // Check if score.weight is a valid number before adding it
      if (!isNaN(score.weight)) {
        total += score.weight;
      }
    });

    return Math.round(total * 100);
  };

  const handleMultipleChoice = (event: any) => {
    let scores = scoreList.slice();

    if (
      scores?.find((sc: any) => sc.name.toLowerCase() === event.toLowerCase())
    ) {
      scores = scores.filter(
        (item: any) => item.name.toLowerCase() !== event.toLowerCase()
      );
    } else {
      scores.push({
        custom: false,
        name: event,
        key: event,
        min: 0,
        max: 10,
        value: 0,
        weight: 0,
        required: true,
      });
    }

    setScoreList(scores);
  };

  const addCustomParamHandler = (e: any) => {
    setCustomParam(e.target.value.toLowerCase());
  };

  const addCustomParamWeightHandler = (e: any) => {
    const inputValue = e.target.value;
    // Use regex to check if the input is a valid number
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // Set customParamValue only if it's a valid number
      setCustomParamValue(inputValue);
    }
  };

  const customParamHandler = () => {
    if (customParam != "" && customParamValue != "") {
      let scores = scoreList.slice();
      scores.push({
        custom: true,
        name: customParam,
        key: customParam,
        min: 0,
        max: 10,
        value: 0,
        weight: Number(customParamValue) / 100,
        required: true,
      });

      setScoreList(scores);
      setAdd(false);
    }
  };

  const orderHandler = (result: any) => {
    const items = Array.from(scoreList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setScoreList(items);
  };

  const saveHandler = () => {
    setClick(true);
    if (totalWeight() != 100) {
      setError({ status: true, message: "Total weight must be 100%" });
    } else if (scoreList.some((score) => score.weight === 0)) {
      setError({ status: true, message: "Weight cannot be 0 for any item" });
    } else if (
      scoreList?.length != candidatesDetailsData?.job_score_parameters?.length
    ) {
      setOpenConfirm(true);
    } else {
      trackMixpanelEvents({
        eventName: "ENTERPRISE_RECRUITER_SCORE_MODIFIER_SAVE_CHANGES",
        url: window.location.href,
        distinctId: userInfo ? userInfo.email : "",
        properties: {
          UPDATED_SCORE_PARAMS: scoreList.map((item: any) => [
            item.name,
            item.weight,
          ]),
        },
      });

      setScore(scoreList);
      dispatch(
        scoreParamUpdate({
          id: candidatesDetailsData?._id,
          job_id: candidatesDetailsData?.job_id,
          job_score_parameters: scoreList,
        })
      );
    }
  };

  const weightHandler = (e: React.ChangeEvent<HTMLInputElement>, n: number) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/\D/g, "");

    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2);
    }

    let newWeight = inputValue !== "" ? parseInt(inputValue, 10) / 100 : 0;

    setScoreList((prevScoreList) => {
      const updatedScores = [...prevScoreList];
      updatedScores[n] = { ...updatedScores[n], weight: newWeight };
      return updatedScores;
    });

    setChangedIndices((prevIndices) => {
      const newIndices = new Set(prevIndices);
      newIndices.add(n);
      return newIndices;
    });

    // Reset the input value
    e.target.value = inputValue;
  };

  const closeHandler = () => {
    trackMixpanelEvents({
      eventName: "ENTERPRISE_RECRUITER_SCORE_MODIFIER_CANCELLATION",
      url: window.location.href,
      distinctId: userInfo ? userInfo.email : "",
      properties: {},
    });
    setScoreList(score);
    setAdd(false);
    setScoreModifier(false);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Tab") {
      // Focus on the next input field
      if (
        inputRefs.current.length > index + 1 &&
        inputRefs.current[index + 1]
      ) {
        inputRefs.current[index + 1]?.focus();
      }
      event.preventDefault(); // Prevent the default tab behavior
    }
  };

  const autoSetWeightage = () => {
    const totalFields = scoreList.length;
    const changedIndicesArray = Array.from(changedIndices);

    const allEdited = changedIndicesArray.length === totalFields;

    if (allEdited) {
      const equalWeight = Math.floor(100 / totalFields);
      const remainder = 100 % totalFields;

      const newList = scoreList.map((item, index) => ({
        ...item,
        weight: equalWeight / 100 + (index < remainder ? 0.01 : 0),
      }));

      setScoreList(newList);
      setChangedIndices(new Set());
    } else {
      const editedWeight = scoreList.reduce((acc, item, index) => {
        if (changedIndices.has(index)) {
          acc += item.weight;
        }
        return acc;
      }, 0);

      const uneditedIndices = Array.from(Array(totalFields).keys()).filter(
        (index) => !changedIndices.has(index)
      );
      const uneditedItemCount = uneditedIndices.length;

      const remainingWeight = 1 - editedWeight;

      const equalWeight =
        Math.floor((remainingWeight / uneditedItemCount) * 100) / 100;

      const roundedWeights = [];
      let roundedTotal = 0;
      for (let i = 0; i < totalFields; i++) {
        if (changedIndices.has(i)) {
          roundedWeights.push(scoreList[i].weight);
          roundedTotal += scoreList[i].weight;
        } else {
          const rounded = Math.round(equalWeight * 100) / 100;
          roundedWeights.push(rounded);
          roundedTotal += rounded;
        }
      }

      const diff = Math.round((1 - roundedTotal) * 100) / 100;
      const lastIndex = uneditedIndices[uneditedItemCount - 1];
      roundedWeights[lastIndex] += diff;

      const roundedAndTrimmedWeights = roundedWeights.map((weight) =>
        parseFloat(weight.toFixed(2))
      );

      const newList = scoreList.map((item, index) => ({
        ...item,
        weight: roundedAndTrimmedWeights[index],
      }));

      setScoreList(newList);
    }
  };

  const confirmHandler = () => {
    dispatch(
      scoreParamUpdate({
        id: candidatesDetailsData?._id,
        job_id: candidatesDetailsData?.job_id,
        job_score_parameters: scoreList,
      })
    );
  };


  return (
    <div className="score-card">
      {data?.job_score_parameters?.length != 0 ? (
        <Rating data={talentScore} setScoreModifier={setScoreModifier} />
      ) : (
        <div className="no-data">
          <div className="message">
            <p>No parameters available!</p>
            <Button onClick={() => setScoreModifier(true)}>
              Add parameters
            </Button>
          </div>
        </div>
      )}

      <Drawer
        anchor="right"
        open={scoreModifier}
        onClose={toggleDrawer}
        className="score-modifier"
      >
        <div className="top">
          <IconButton className="icons" onClick={toggleDrawer}>
            <Icon icon="material-symbols:arrow-back" className="icon" />
          </IconButton>

          <IconButton className="icons" onClick={toggleDrawer}>
            <Icon
              icon="ic:baseline-close"
              className="icon"
              onClick={toggleDrawer}
            />
          </IconButton>
        </div>

        <div className="drawer-title">
          <h3>Talent score</h3>
          <p>
            Editing these parameter directly updates the associated name and
            score for the job. Review changes to ensure they align accurately
            with job requirements.
          </p>
        </div>

        <div className="chips">
          {scoreParamListData?.map((score: any) => (
            <Chip
              label={score.name}
              className={
                scoreList?.find(
                  (sc: any) =>
                    sc?.name?.toLowerCase() === score?.name?.toLowerCase()
                )
                  ? "chip filled"
                  : "chip"
              }
              onClick={() => handleMultipleChoice(score.name)}
              onDelete={() => handleMultipleChoice(score.name)}
              deleteIcon={
                scoreList?.find(
                  (sc: any) =>
                    sc?.name?.toLowerCase() === score?.name?.toLowerCase()
                ) && (
                  <Icon
                    icon="ic:baseline-close"
                    width={17}
                    height={17}
                    color="#fff"
                  />
                )
              }
            />
          ))}

          {scoreList
            ?.filter((obj) => obj.custom === true)
            ?.map((sco: any) => (
              <Chip
                label={sco.name}
                className={
                  scoreList?.find(
                    (sc: any) =>
                      sc.name.toLowerCase() === sco.name.toLowerCase()
                  )
                    ? "chip filled"
                    : "chip outlined"
                }
                onClick={() => handleMultipleChoice(sco.name)}
                onDelete={() => handleMultipleChoice(sco.name)}
              />
            ))}
          <Button className="add" onClick={() => setAdd(true)}>
            <Icon icon="lucide:plus" color="#0034bb" width={17} height={17} />{" "}
            Add more parameters
          </Button>
        </div>

        {add && (
          <div className="add-custom">
            <div className="row">
              <div className="row-left">
                <div className="field">
                  <p>Enter parameter name</p>
                  <EcnInput
                    placeholder="Parameter name"
                    className="ecn-input-v2"
                    size="small"
                    value={customParam}
                    sx={{
                      width: "100%",

                      "&:hover fieldset": {
                        border:
                          "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                      },
                      "&:focus-within fieldset": {
                        border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                      },
                    }}
                    onChange={addCustomParamHandler}
                    // error={customParam === ""}
                    // helperText={"Empty field"}
                  />
                </div>
              </div>
              <div className="row-right">
                <div className="field">
                  <p>Enter score</p>
                  <EcnInput
                    placeholder="Score"
                    className="ecn-input-v2"
                    value={customParamValue}
                    size="small"
                    sx={{
                      width: "100%",

                      "&:hover fieldset": {
                        border:
                          "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                      },
                      "&:focus-within fieldset": {
                        border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                      },
                    }}
                    onChange={addCustomParamWeightHandler}
                    // error={customParamValue === ""}
                    // helperText={"Empty field"}
                  />
                </div>
              </div>
            </div>

            <div className="button">
              <Button className="dis" onClick={() => setAdd(false)}>
                Discard
              </Button>
              <Button
                className="done"
                onClick={customParamHandler}
                disabled={customParam === "" || customParamValue === ""}
              >
                {" "}
                <Icon icon="lucide:check" className="icon" /> Done
              </Button>
            </div>
          </div>
        )}

        <div className="drawer-body">
          <DragDropContext onDragEnd={orderHandler}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {scoreList?.map((score: any, index: number) => (
                    <Draggable
                      key={index}
                      index={index}
                      draggableId={score.name}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="score-element"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Icon
                              icon="icon-park-outline:drag"
                              className="icon"
                              width={23}
                              height={23}
                              color="rgba(33, 33, 33, 0.6)"
                            />
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                fontSize: 16,
                                color: "rgba(33, 33, 33, 1)",
                              }}
                            >
                              {score.name}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <input
                              ref={(el) => {
                                if (el) {
                                  inputRefs.current[index] = el;
                                }
                              }}
                              value={score.weight * 100}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onChange={(e) => weightHandler(e, index)}
                              style={{
                                width: "55px",
                                height: "40px",
                                border: "1px solid rgba(0,0,0,0.2)",
                                textAlign: "center",
                                borderRadius: "8px",
                                padding: "7px 10px",
                                paddingTop: "8px",
                                paddingLeft: "13px",
                                color: "rgba(33, 33, 33, 1)",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            />
                            <p
                              style={{
                                display: "inline-block",
                                fontWeight: "500",
                                fontSize: 20,
                              }}
                            >
                              %
                            </p>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* 
          {totalWeight() <= 100 ? (
            <div className="wrapper">
              <p>Total:</p>
              <h2>{totalWeight()}%</h2>
            </div>
          ) : (
            <div className="exceed">
              <div className="exceed-wrapper">
                <p>Total:</p>
                <h2>{totalWeight()}%</h2>
              </div>
              <p className="notes">
                It seems the score you entered is higher than the maximum limit
                of 100%. Please review and adjust the score as needed.
              </p>
            </div>
          )} */}
        </div>
        <div className="total">
          <p>Total</p>
          <div className="right">
            <h2>{totalWeight()}%</h2>
            <button className="auto-set" onClick={autoSetWeightage}>
              Auto-Set Weightage
            </button>
          </div>
        </div>

        <div className="buttons">
          <Button className="cancel" onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            className="save"
            disabled={totalWeight() != 100}
            onClick={saveHandler}
          >
            Save changes
          </Button>
        </div>
      </Drawer>

      <Dialog
        open={openConfirm }
   
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "25px",
              fontWeight: "600",
              color: "#212121",
              textAlign: "center",
              marginBottom: "5px",
            }}
          >
            Are you sure you want to reset parameters
          </h2>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
            }}
          >
            Confirm to link scores and notes directly to set parameters.
            Changing parameters will delete existing data. Ready to proceed ?
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "40px",
              marginTop: "20px",
            }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#0034BB",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              onClick={confirmHandler}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",
                },
              }}
            >
              Confirm
            </Button>

            <Button
              style={{
                border: "1px solid #BA0000",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={() => setOpenConfirm(false)}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Score;
const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  padding: "8px 16px",
  margin: `0 0 10px 0`,
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "250px",
  width: "100%",
  borderBottom: "1px solid rgba(33, 33, 33, 0.20)",
  paddingBottom: "15px",

  // columnGap: "40px",
  ":last-child": {
    borderBottom: "none",
    paddingBottom: "0",
  },
  // change background colour if dragging
  background: isDragging ? "lightgray" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
