import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export const deleteEvent = createAsyncThunk(
  "deleteEvent",
  async (eventDeleteConfig: { eventId: string }) => {
    //TODO: Update the request type when api update
    await axios.post(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/event/delete`,
      {
        _id: eventDeleteConfig.eventId
      }
    );
  }
);

const deleteEventSlice = createSlice({
  name: "deleteEvent",
  initialState: {
    loading: false,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteEvent.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default deleteEventSlice.reducer;
