import React from "react";
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { Icon } from "@iconify/react";

const ToolTips: React.FC<{ title: any }> = ({ title }) => {
  return (
    <LightTooltip title={title} placement="top">
      <IconButton style={{ marginLeft: "-5px" }}>
        <Icon icon="lucide:info" color="#212121" width={15} height={15} />
      </IconButton>
    </LightTooltip>
  );
};

export default ToolTips;
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(225, 233, 245, 1)",
    color: "#000",
    boxShadow: "none",
    fontSize: 10,
    fontWeight: 400,
    padding: 10,
  },
}));
