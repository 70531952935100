import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export type PaymentStatusType = "Paid" | "Due";
export const updatePaymentStatus = createAsyncThunk(
  "updatePaymentStatus",
  async (payload: {
    event_id: string;
    reg_ids: string[];
    payment_status: PaymentStatusType;
  }) => {
    await axios.put(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/registration/update_status`,
      payload
    );
  }
);

const updatePaymentStatusSlice = createSlice({
  name: "updatePaymentStatus",
  initialState: {
    loading: false,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updatePaymentStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updatePaymentStatus.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updatePaymentStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default updatePaymentStatusSlice.reducer;
