import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import e from "express";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSearchParams } from "react-router-dom";

const UserCard: React.FC<{ data: any }> = ({ data }) => {
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [contactOpen, setContactOpen] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Copy");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    if (loadingProgress) {
      setLoading(true);
    }
  }, [loadingProgress]);

  const toggleDrawer = () => {
    setOpen(false);
    setLoading(false);
    setLoadingProgress(false);
    setContactOpen(false);
    setPageNumber(1);
    setZoom(1);
  };
  const zoomPercentage = Math.round(zoom * 100);

  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
    setLoadingProgress(true);
    setPageNumber(1);
  }
  const handleZoomIn = () => {
    if (zoomPercentage < 200) {
      setZoom((prevZoom) => prevZoom + 0.2); // Increase zoom by 20%
    }
  };

  const handleZoomOut = () => {
    if (zoomPercentage > 60) {
      setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.2)); // Decrease zoom by 20%, with minimum zoom of 20%
    }
  };

  const goToPreviousPage = () => {
    scrollToPreviousPage();
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const goToNextPage = () => {
    scrollToNextPage();
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const copyToClipBoard = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText("Copied!");
      setTimeout(() => setText("Copy"), 2000);
    } catch (err) {
      setText("Failed to copied!");
      setTimeout(() => setText("Copy"), 2000);
    }
  };

  const scrollToNextPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop + containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };
  const scrollToPreviousPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop - containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  // const handleScroll = () => {
  //   if (containerRef.current) {
  //     const scrollTop = containerRef.current.scrollTop;
  //     const pageHeight = containerRef.current.scrollHeight / numPages;
  //     const currentPage = Math.ceil(scrollTop / pageHeight);
  //     console.log("currentPage", currentPage, scrollTop / pageHeight);
  //     if (scrollTop === 0) {
  //       setPageNumber(1); // Ensure page number is 1 when scrollTop is zero
  //     } else {
  //       setPageNumber(currentPage);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.addEventListener("scroll", handleScroll);
  //     return () => {
  //       containerRef.current?.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [numPages]);

  const resumeHandler = (resume: any) => {
    let originalUrl = resume;
    let oldBaseUrl = "https://core.api.elevatecareernetwork.com/";
    let newBaseUrl = "https://api.onefinnet.com/core-backend/v1/";

    if (originalUrl.includes(oldBaseUrl)) {
      let updatedUrl = originalUrl.replace(oldBaseUrl, newBaseUrl);
      return updatedUrl;
    } else {
      return resume;
    }
  };

  return (
    <div className="user-card">
      <div className="user-top">
        <div className="user-left">
          <Avatar
            alt={data?.created_by?.first_name?.[0]}
            src={
              Array.isArray(data?.created_by?.display_pictures)
                ? data?.created_by?.display_pictures?.[0] ?? ""
                : ""
            }
            className="avatar"
          />

          <div className="name">
            <h2>
              {data?.created_by?.first_name + " " + data?.created_by?.last_name}
            </h2>
            <h3>
              {data?.created_by?.work_experiences &&
                data?.created_by?.work_experiences[0]?.company}
            </h3>
            <p>
              {data?.created_by?.work_experiences &&
                data?.created_by?.work_experiences[0]?.title}
            </p>
          </div>
        </div>
        {view != "expand" && (
          <div className="user-right">
            <p>{data?.created_by?.email}</p>
            <p>
              {data?.created_by?.work_experiences &&
                data?.created_by?.work_experiences[0]?.city}
            </p>
          </div>
        )}
      </div>
      {view != "expand" && (
        <div className="buttons">
          <Button onClick={() => setOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5002 1.66797H5.00016C4.55814 1.66797 4.13421 1.84356 3.82165 2.15612C3.50909 2.46868 3.3335 2.89261 3.3335 3.33464V16.668C3.3335 17.11 3.50909 17.5339 3.82165 17.8465C4.13421 18.159 4.55814 18.3346 5.00016 18.3346H15.0002C15.4422 18.3346 15.8661 18.159 16.1787 17.8465C16.4912 17.5339 16.6668 17.11 16.6668 16.668V5.83464L12.5002 1.66797Z"
                stroke="#0034BB"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.6667 1.66797V5.0013C11.6667 5.44333 11.8423 5.86725 12.1549 6.17981C12.4675 6.49237 12.8914 6.66797 13.3334 6.66797H16.6667M8.33341 7.5013H6.66675M13.3334 10.8346H6.66675M13.3334 14.168H6.66675"
                stroke="#0034BB"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            Resume
          </Button>
          <Button onClick={() => setContactOpen(true)}>
            {" "}
            View contact info{" "}
          </Button>
        </div>
      )}

      <div className="score">
        <div className="ug">
          <p>UG GPA</p>
          <h1>{data?.created_by?.gpa ?? ""}</h1>
        </div>
        <div className="ug">
          <p>SAT</p>
          <h1>{data?.created_by?.sat ?? ""}</h1>
        </div>

        <div className="ug">
          <p>ACT</p>
          <h1>{data?.created_by?.act ?? ""}</h1>
        </div>

        <div className="ug">
          <p>GMAT</p>
          <h1>{data?.created_by?.gmat ?? ""}</h1>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        className={!loading ? "drawer-resume fixed-height" : "drawer-resume"}
      >
        <div className="top">
          <Button className="icons" onClick={toggleDrawer}>
            <Icon icon="material-symbols:arrow-back" className="icon" />
            <h1 className="drawer-title">Applicant’s resume</h1>
          </Button>

          <Button
            className="download"
            onClick={() =>
              window.open(resumeHandler(data?.created_by?.resume), "_blank")
            }
          >
            {" "}
            <Icon icon="lucide:arrow-down" className="icons" /> Download
          </Button>
        </div>

        <div className="pdf-container">
          <div className="pdf-top">
            <div className="zoom">
              <p>{zoomPercentage}%</p>
              <Icon
                icon="lucide:zoom-out"
                className="icons"
                onClick={handleZoomOut}
              />
              <Icon
                icon="lucide:zoom-in"
                className="icons"
                onClick={handleZoomIn}
              />
            </div>
          </div>

          {!loading && (
            <div className="loader">
              <CircularProgress sx={{ color: "#0034bb" }} />
            </div>
          )}

          <div className={"container"}>
            <div ref={containerRef} className="inner-container">
              <div
                style={{
                  transformOrigin: "top center",
                  transform: `scale(${zoom})`,
                }}
              >
                <Document
                  file={resumeHandler(data?.created_by?.resume)}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={500}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
                </Document>
              </div>
            </div>
            <div className="page">
              <div className="page-number">
                <IconButton disabled={pageNumber === 1}>
                  <Icon
                    icon="lucide:chevron-left"
                    className="icons"
                    onClick={goToPreviousPage}
                  />
                </IconButton>
                <h1>
                  {pageNumber} <span>/{numPages}</span>
                </h1>
                <IconButton disabled={pageNumber === numPages}>
                  <Icon
                    icon="lucide:chevron-right"
                    className="icons"
                    onClick={goToNextPage}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={contactOpen}
        onClose={toggleDrawer}
        className={"drawer-link"}
      >
        <div className="top">
          <IconButton className="icons" onClick={toggleDrawer}>
            <Icon icon="material-symbols:arrow-back" className="icon" />
          </IconButton>

          <IconButton className="icons" onClick={toggleDrawer}>
            <Icon
              icon="ic:baseline-close"
              className="icon"
              onClick={toggleDrawer}
            />
          </IconButton>
        </div>
        <div className="title-download">
          <h1 className="drawer-title">Contact info</h1>
        </div>

        <div className="drawer-body">
          <div className="sub-card">
            <p>
              {" "}
              <Icon icon="lucide:mail" className="icon" /> Email address
            </p>
            <div className="copy">
              <h2 className={"h2"}>{data?.created_by?.email}</h2>
              <h2
                className={text === "Copy" ? "copied h2" : "h2"}
                onClick={() => copyToClipBoard(`${data?.created_by?.email}`)}
              >
                <Icon icon="lucide:copy" className="icon" /> {text}
              </h2>
            </div>
          </div>
          {data?.created_by?.socials?.map((item: any) => (
            <div className="sub-card">
              <p>
                {" "}
                <Icon icon="lucide:linkedin" className="icon" />
                {item?.name}
              </p>
              <div className="copy">
                <h2 className={"h2"}>{item?.url}</h2>
                <h2
                  className={"copied h2"}
                  onClick={() => window.open(item?.url, "_blank")}
                >
                  {" "}
                  <Icon icon="lucide:external-link" className="icon" /> Visit
                </h2>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
};

export default UserCard;
