import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getNotes = createAsyncThunk("getNotes", async (id: any) => {
  const { data } = await axios.get(
    `${
      GATEWAY_BASE + JOB_CREATE_BASE
    }/api/v1/application/notes/${id}`
  );
  return data;
});

const getNotesSlice = createSlice({
  name: "getNotes",
  initialState: {
    loading: false,
    getNotesData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.getNotesData = action.payload;
    });
    builder.addCase(getNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getNotesSlice.reducer;
