import React, { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { Button, Dialog, DialogContent, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { upsertNotes } from "../../../redux/slices/Notes/upsertNotesSlice";
import EcnInput from "../../StyledComponents/V2/EcnInput";
import EcnButton from "../../StyledComponents/V2/EcnButton";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useSnackbar } from "notistack";
import Loader from "../../UI/Loader";

const styles = makeStyles((theme) => ({
  wrapper: {
    height: "88vh",
    gap: "23px",
  },
  backBtn: {
    width: "fit-content",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "-10px",
  },
  card: {
    height: "82.5%",
    paddingTop: "20px",
  },

  detailcard: {
    paddingTop: "20px",
    height: "82.5%",
  },
  heading: {},
  row: {
    display: "flex",
    gap: "20px",
    "& > *": {
      flex: "1 1 45%",
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: "25px",
  },
  fieldnote: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: "0px",
    height: "85%",
  },
  label: {
    fontSize: "14px",
    color: "#212121",
  },
  selectInput: {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "11px 16px",
    font: "Poppins",
    fontSize: "16px",
    fontWeight: "500",

    borderRadius: "8px",
    background:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 9l6 6l6-6'/%3E%3C/svg%3E\") no-repeat right 16px center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundColor: "#fff",

    "&:hover": {
      borderColor: "#000",
    },
    "&:focus": {
      borderColor: "#0034BB",
    },
    "& option": {
      color: "red",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "0",
    right: "0",
    left: "0",
    padding: "16px 40px",
    boxShadow: "0px -4px 15px 0px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
  },
  cancelBtn: {
    padding: "5px 18px",
    minHeight: "44px",
    minWidth: "126px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    color: "#212121",
    fontSize: "16px",
    fontWeight: "500",
  },
  saveBtn: {
    padding: "5px 14px",
    minHeight: "44px",
    minWidth: "164px",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "500",
  },
  loadingText: {
    color: "#212121",
    fontSize: "25px",
    fontWeight: "600",
    textAlign: "center",
  },
  successImg: {
    height: "350px",
    width: "350px",
    display: "block",
    margin: "18% auto 0 auto",
  },
  errorHead: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
  },
  errorSub: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: "center",
    marginTop: "2px",
  },
  errorBtn: {
    marginTop: "25px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    padding: "10px 18px",
    color: "#212121",
  },
}));

const NotesDrawer: React.FC<{
  data: any;
  open: any;
  setOpen: any;
  setDeleteOpen: any;
  particularNote: any;
  setParticularNote: any;
}> = ({
  data,
  open,
  setOpen,
  setDeleteOpen,
  particularNote,
  setParticularNote,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const tab = searchParams.get("tab");
  const id = searchParams.get("id");
  const applicationId = searchParams.get("application_id");
  const notesId = searchParams.get("notes_id");
  const selectRef: any = useRef(null);
  const { getNotesData, loading: getNotesLoading } = useAppSelector(
    (state: any) => state.getNotes
  );
  const { upsertNotesData, loading: upsertNotesLoading } = useAppSelector(
    (state: any) => state.upsertNotes
  );

  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);

  useEffect(() => {
    if (particularNote) {
      setTitle(particularNote?.title);
      setNotes(particularNote?.body);
    } else {
      setTitle("");
      setNotes("");
    }
  }, [particularNote]);

  useEffect(() => {
    if (upsertNotesData && click) {
      setOpen(false);
      navigate(`?id=${id}&application_id=${applicationId}`);
      setClick(false);
      setParticularNote(null);
      setTitle("");
      setNotes("");
    }
  }, [upsertNotesData]);

  const cancelHandler = () => {
    setOpen(false);
    setDiscardOpen(false);
    navigate(`?id=${id}&application_id=${applicationId}`);
    setParticularNote(null);
    setTitle("");
    setNotes("");
  };

  const saveHandler = () => {
    let noteid = notesId ? notesId : "";
    setClick(true);
    dispatch(
      upsertNotes({
        id: noteid,
        title: title,
        body: notes,
        application_id: applicationId,
        user_name: `${data?.created_by?.first_name} ${data?.created_by?.last_name}`,
      })
    );
  };

  const editHandler = () => {
    navigate(
      `?id=${id}&application_id=${applicationId}&notes_id=${notesId}&tab=edit`
    );
  };

  const discardCloseHandler = () => {
    setDiscardOpen(false);
  };

  const deleteHandler = () => {
    setDeleteOpen(true);
  };

  const titleChangeHandler = (e: any) => {
    if (e.target.value.length < 201) {
      setTitle(e.target.value);
    }
  };

  const noteChangeHandler = (e: any) => {
    if (e.length < 10001) setNotes(e);
  };



  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        className="notes-drawer"
        onClose={cancelHandler}
        PaperProps={{
          sx: {
            borderRadius: "0",
            padding: "39px 42px",
            paddingBottom: "0px",
            minWidth: "800px",
            maxWidth: "800px",
            justifyContent: "flex-start",
            backgroundColor: "#fff",
          },
        }}
      >
        <div className={tab === "detail" ? "" : classes.wrapper}>
          {upsertNotesLoading ? (
            <Loader style={{ color: "#0034BB" }} />
          ) : (
            <>
              <EcnButton
                color="secondary"
                className={classes.backBtn}
                startIcon={
                  <Icon
                    icon="material-symbols:arrow-back"
                    height={24}
                    width={24}
                  />
                }
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                }}
                onClick={cancelHandler}
              >
                {tab === "detail"
                  ? "Note"
                  : notesId
                  ? "Edit note"
                  : "Create note"}
              </EcnButton>
              {tab === "edit" || tab === null ? (
                <div className={classes.card}>
                  <div className={classes.field}>
                    <label htmlFor="" className={classes.label}>
                      Enter title
                    </label>
                    <EcnInput
                      className="ecn-input"
                      color="info"
                      placeholder="Enter title"
                      value={title}
                      onChange={(e: any) => titleChangeHandler(e)}
                    />
                  </div>
                  <div className={classes.fieldnote}>
                    <label htmlFor="" className={classes.label}>
                      Note
                    </label>
                    <ReactQuill
                      onChange={(e: any) => noteChangeHandler(e)}
                      value={notes}
                      theme="snow"
                      bounds={".app"}
                      className="quill-candidatesdetail"
                      modules={modules}
                      ref={selectRef}
                      formats={formats}
                    />
                  </div>
                </div>
              ) : (
                <div className="notes-detail">
                  <div className="notes-detail-header">
                    <EcnButton
                      className="btn-outlined-v2 delete-btn"
                      variant="outlined"
                      onClick={deleteHandler}
                    >
                      <Icon icon="lucide:trash" />
                      Delete
                    </EcnButton>
                    <EcnButton
                      className="btn-outlined-v2 edit-btn"
                      variant="outlined"
                      onClick={editHandler}
                    >
                      <Icon icon="lucide:pencil" />
                      Edit
                    </EcnButton>
                  </div>
                  <p
                    className={
                      particularNote?.title?.length ? "title" : "no-title"
                    }
                  >
                    {particularNote?.title?.length
                      ? particularNote?.title
                      : "No title"}
                  </p>
                  <ReactMarkdown
                    className="markdown-data"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {particularNote?.body}
                  </ReactMarkdown>

                  <div className="notes-detail-footer">
                    <p className="create-username">
                      {particularNote?.updatedBy?.length
                        ? "Updated by"
                        : "Created by"}{" "}
                      :
                      <span>
                        {" "}
                        {particularNote?.updatedBy?.length
                          ? particularNote?.updatedBy
                          : particularNote?.createdBy}
                      </span>
                    </p>
                    <p className="create-datetime">
                      {moment
                        .unix(
                          particularNote?.updatedBy?.length
                            ? particularNote?.updatedAt
                            : particularNote?.createdAt
                        )
                        .format("D MMM")}{" "}
                      {new Date(
                        particularNote?.createdAt * 1000
                      ).getFullYear() !== new Date().getFullYear()
                        ? moment
                            .unix(
                              particularNote?.updatedBy?.length
                                ? particularNote?.updatedAt
                                : particularNote?.createdAt
                            )
                            .format("YYYY ")
                        : ""}
                      at{" "}
                      {moment
                        .unix(
                          particularNote?.updatedBy?.length
                            ? particularNote?.updatedAt
                            : particularNote?.createdAt
                        )
                        .format("h:m A")}
                    </p>
                  </div>
                </div>
              )}

              {(tab === "edit" || tab === null) && (
                <div className={classes.footer}>
                  <EcnButton
                    color="info"
                    variant="outlined"
                    className={classes.cancelBtn}
                    onClick={() => setDiscardOpen(true)}
                  >
                    Discard
                  </EcnButton>
                  <EcnButton
                    color="info"
                    variant="contained"
                    className={classes.saveBtn}
                    onClick={saveHandler}
                  >
                    {tab === "edit" ? "Save" : "Create"}
                  </EcnButton>
                </div>
              )}
            </>
          )}
        </div>
      </Drawer>
      <Dialog
        open={discardOpen}
        onClose={discardCloseHandler}
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to discard?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={discardCloseHandler}
            >
              Cancel
            </Button>

            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#0034BB",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={cancelHandler}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Discard
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotesDrawer;

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
