import { Icon } from "@iconify/react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import Cards from "./Cards";

const Body: React.FC<{ setSelectedTemplate: any }> = ({
  setSelectedTemplate,
}) => {
  const navigate = useNavigate();
  const { templateData } = useAppSelector((state: any) => state.templateList);

 

  return (
    <div className="template-body">
      <div className="default-card" onClick={() => {navigate("?view=create");setSelectedTemplate([])}}>
        <div>
          <Icon icon="lucide:plus-circle" width={24} height={24} />
          <p>Create new template</p>
        </div>
      </div>
      {templateData?.map((item: any) => (
        <Cards item={item} setSelectedTemplate={setSelectedTemplate} />
      ))}
    </div>
  );
};

export default Body;
