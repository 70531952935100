import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const candidatesDetails = createAsyncThunk(
  "candidatesDetails",
  async (id: any) => {
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/details/${id}`
      );

      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data;
    }
  }
);

const candidatesDetailsSlice = createSlice({
  name: "candidatesDetails",
  initialState: {
    loading: false,
    candidatesDetailsData: null,
    error: false,
    success: false,
    errorData: null,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.candidatesDetailsData = null;
      state.errorData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(candidatesDetails.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(candidatesDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.candidatesDetailsData = action.payload;
    });
    builder.addCase(candidatesDetails.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});
export const { reset } = candidatesDetailsSlice.actions;
export default candidatesDetailsSlice.reducer;
