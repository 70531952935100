import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const userDetails = createAsyncThunk("userDetails", async () => {
  try {
    const { data } = await axios.get(`${GATEWAY_BASE + ADMIN_BASE}/getMyInfo`);

    return data;
  } catch (error: any) {
    // Throw the entire error object to be caught in the rejected case
    throw error.response?.data.statusCode;
  }
});

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    loading: false,
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : null,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
   
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    });
    builder.addCase(userDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
      
    });
  },
});

export default userDetailsSlice.reducer;
