import React, { useState } from "react";
import ToolTips from "../../../Promotion/Graph/toolTips";
import PieChart from "./PieChart";
import List from "./List";

import { colors } from "../../../../../helpers/TempData";
import BarGraph from "../BarGraph";

const Level: React.FC<{ item: any }> = ({ item }) => {
  const [hoverValue, setHoverValue] = useState<any>("");

  const sortedData = [...item?.charts]?.sort(
    (a: any, b: any) => a.position - b.position
  );

  const renderedComponents = sortedData?.map((subitem: any, index: any) => {
    switch (subitem?.chart_type) {
      case "PIE":
        return (
          <PieChart
            list={subitem}
            setHoverValue={setHoverValue}
            hoverValue={hoverValue}
            customColor={
              index === 0
                ? colorsMBA
                : index === 1
                ? colorsSat
                : index === 2
                ? colorsGmat
                : colors
            }
          />
        );

      case "BAR":
        return (
          <BarGraph
            setHoverValue={setHoverValue}
            customsColor={colors}
            item={subitem}
          />
        );

      case "LIST2":
        return <List list={subitem} />;
      default:
        return null;
    }
  });

  function checkChartResultsNotEmpty(object: any) {
    for (const chart of object.charts) {
      if (chart?.chart_results?.length > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {checkChartResultsNotEmpty(item) && (
        <div
          className="level-v2"
          style={{
            background: `${
              item?.background_color === "" ? "#f8f8f8" : item?.background_color
            }`,
          }}
        >
          <h2>
            {item?.name} <ToolTips title={item?.tooltip} />
          </h2>
          <div className="body">{renderedComponents}</div>
        </div>
      )}
    </>
  );
};

export default Level;

export const colorsMBA = [
  "#FBB5AB",
  "#F5B497",
  "#FEB26E",
  "#F4D165",
  "#FFE38B",
  "#005AC0",
  "#007DB7",
  "#2E84E6",
  "#6A8ED9",
  "#6499BE",
  "#97B5E4",
  "#028D77",
  "#40AD9D",
  "#7FC6BB",
  "#B8E0D2",
  "#81C497",
  "#6EB285",
  "#7151BF",
  "#8C76C3",
  "#AA94DA",
  "#C793E3",
  "#C271C9",
  "#A660C7",

  "#E45F8D",
  "#FF6C6C",
  "#F58168",
  "#F88",
  "#F19585",
  "#FFA2A2",
  "#FFBEC1",
];

export const colorsSat = [
  "#E45F8D",
  "#FF6C6C",
  "#F58168",
  "#F88",
  "#F19585",
  "#FFA2A2",
  "#FFBEC1",
  "#005AC0",
  "#007DB7",
  "#2E84E6",
  "#6A8ED9",
  "#6499BE",
  "#97B5E4",
  "#028D77",
  "#40AD9D",
  "#7FC6BB",
  "#B8E0D2",
  "#81C497",
  "#6EB285",
  "#7151BF",
  "#8C76C3",
  "#AA94DA",
  "#C793E3",
  "#C271C9",
  "#A660C7",
  "#FBB5AB",
  "#F5B497",
  "#FEB26E",
  "#F4D165",
  "#FFE38B",
];

export const colorsGmat = [
  "#028D77",
  "#40AD9D",
  "#7FC6BB",
  "#B8E0D2",
  "#81C497",
  "#6EB285",
  "#7151BF",
  "#8C76C3",
  "#AA94DA",
  "#C793E3",
  "#C271C9",
  "#A660C7",

  "#E45F8D",
  "#FBB5AB",
  "#F5B497",
  "#FEB26E",
  "#F4D165",
  "#FFE38B",
  "#FF6C6C",
  "#F58168",
  "#F88",
  "#F19585",
  "#FFA2A2",
  "#FFBEC1",

  "#005AC0",
  "#007DB7",
  "#2E84E6",
  "#6A8ED9",
  "#6499BE",
  "#97B5E4",
];
