import { Icon } from "@iconify/react";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { candidatesList } from "../../../redux/slices/CandidatesSlices/candidatesListSlice";
import CandidateListTable from "./CandidateListTable";

const MemberDrawe: React.FC<{
  rowSelectionModel: any;
  setRowSelectionModel: any;
  rows: any;
  setRows: any;
  setOpenMember: any;
}> = ({ rowSelectionModel, setRowSelectionModel, rows, setOpenMember, setRows }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const st = searchParams.get("status") || -1;
  const back = searchParams.get("back-to");
  const email = searchParams.get("email_id") ?? "";

  const [status, setStatus] = useState("");

  const { candidatesListData, success } = useAppSelector(
    (state: any) => state.candidateLists
  );

  const statusHandler = (event: any) => {
    navigate(
      `?view=compose&id=${id}&status=${event.target.value}${email === "" ? "" : `&email_id=${email}`
      }&back-to=${back}`
    );
    setStatus(event.target.value);
  };

  return (
    <div className="member-list">
      <IconButton
        style={{ marginLeft: "-11px", marginTop: "20px" }}
        onClick={() => setOpenMember(false)}
      >
        <Icon icon="material-symbols:arrow-back" color="rgba(33, 33, 33, 1)" />{" "}
      </IconButton>
      <div className="name-heading">
        <h1 className="heading">Select applicants</h1>
        {rowSelectionModel?.length != 0 && (
          <p>
            {rowSelectionModel?.length === 1
              ? rowSelectionModel?.length + " applicant selected"
              : rowSelectionModel?.length + " applicants selected"}
          </p>
        )}
      </div>

      <div className="row">
        <div className="status">
          <h1>Select status</h1>
          <div className="select">
            <FormControl>
              <Select
                size="small"
                variant="standard"
                displayEmpty={true}
                disableUnderline
                value={Number(st)}
                input={
                  <OutlinedInput
                    sx={{
                      border: "1px solid rgba(33, 33, 33, 0.20)",
                      "& fieldset": {
                        border: "1px solid rgba(33, 33, 33, 0.20) !important",
                      },
                      "&:hover fieldset": {
                        border:
                          "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                      },
                      borderRadius: "8px",
                    }}
                  />
                }
                onChange={statusHandler}
                IconComponent={KeyboardArrowDown}
                renderValue={(selected) => {
                  if (selected === -1) {
                    return <div className="placeholder">Select Status</div>;
                  }
                  const selectedStatus = statusText.find(
                    (item: any) => item.value === selected
                  );
                  return (
                    <div>
                      {selectedStatus ? selectedStatus?.text : ""}{" "}
                      {/* Render the text property */}
                    </div>
                  );
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                      borderRadius: "6px",
                      marginTop: "10px",
                      backgroundColor: "#FFF",
                      padding: "10px 10px",
                    },
                  },
                }}
              >
                {statusText?.map((item: any) => (
                  <MenuItem
                    value={item?.value}
                    sx={
                      item?.value === status
                        ? {
                          background: "rgba(0, 52, 187, 0.08)!important",
                          borderRadius: "9px",
                          marginBottom: "5px",
                          fontSize: "14px !important",
                          fontWeight: "400",
                          color: "#0034bb !important",
                          padding: "8px 15px",
                        }
                        : {
                          background: "#fff !important",
                          marginBottom: "5px",
                          fontSize: "14px !important",
                          color: "rgba(33,33,33,.7)",
                          fontWeight: "400",
                          padding: "8px 15px",
                          "&:hover": {
                            backgroundColor:
                              "rgba(0, 52, 187, 0.08) !important",
                            color: "rgba(0, 52, 187, 1)",
                            borderRadius: "9px",
                          },
                        }
                    }
                  >
                    {item?.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>


      </div>
      <CandidateListTable
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        rows={rows}
        setRows={setRows}
      />
    </div>
  );
};

export default MemberDrawe;
const statusText = [
  {
    value: 0,
    text: " Not Yet Reviewed",
  },
  {
    value: 1,
    text: "Decline",
  },
  {
    value: 2,
    text: "Maybe",
  },
  {
    value: 3,
    text: "Invite for Interview",
  },
  {
    value: 4,
    text: "Offer",
  },
  {
    value: 5,
    text: "Offer Accepted",
  },
];
