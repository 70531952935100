import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";

const BottomHeader: React.FC<{ active: any }> = ({ active }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );
  const { analyticsState } = useAppSelector(
    (state: any) => state.analyticsData
  );

  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!active) {
      setData(promoAnalytics);
    } else {
      setData(analyticsState);
    }
  }, [active, id,promoAnalytics]);

  return (
    <div className="bottom-header">
      <div className="card-wrapper">
        <div className="top">
          <h3>{!active ? "Total Reach":"Total Applicants"}</h3>
        </div>
        <div className="card-wrapper-bottom">
          <h1>{data?.total_reach_count}</h1>
          <div className="image">
            <img
              src="https://ecn.blob.core.windows.net/ecn/images/common/Group 1000009947.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="card-wrapper">
        <div className="top">
          <h3>Job Post Views</h3>
        </div>
        <div className="card-wrapper-bottom">
          <h1>{data?.total_view_count}</h1>
          <div className="image">
            <img
              src="https://ecn.blob.core.windows.net/ecn/images/common/Group 1000009948.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="card-wrapper">
        <div className="top">
          <h3>High Quality Job Applications</h3>
        </div>
        <div className="card-wrapper-bottom">
          <h1>{data?.total_apply_quality_count}</h1>
          <div className="image">
            <img
              src="https://ecn.blob.core.windows.net/ecn/images/common/Group 1000009949.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
