import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const logoUpload = createAsyncThunk("logoUpload", async (imgData: any) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const { data } = await axios.post(
    `${GATEWAY_BASE + ADMIN_BASE}/uploadFile`,
    imgData,
    config
  );

  return data;
});

const logoUploadSlice = createSlice({
  name: "logoUpload",
  initialState: {
    loading: false,
    imgUploadData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoUpload.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logoUpload.fulfilled, (state, action) => {
      state.loading = false;
      state.imgUploadData = action.payload;
    });
    builder.addCase(logoUpload.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default logoUploadSlice.reducer;
