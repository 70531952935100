export const strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
);

export const correctEmail = new RegExp(
  "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
);

export const correctUrl = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  "(\\#[-a-z\\d_]*)?$",
  "i"
);
export const isIconString = (str: string) => {
  // Adjust the regex to fit the pattern of your icon strings if needed
  return /^[\w-]+:[\w-]+$/.test(str);
};

export const specialCharacter = new RegExp("[^A-Za-z 0-9]");

export const specialCharacterAndSpace = new RegExp("[^A-Za-z0-9]");

export const specialCharacterAndUnderscore = new RegExp("[^A-Za-z 0-9_]");

export const specialCharacterAndHyphen = new RegExp("[^A-Za-z0-9-]");



export const adminRedirect = (BASE: any) => {
  if (
    BASE === "https://api.dev.ecndev.io"
  )
    return "https://admin.dev.ecndev.io/";
  else if (BASE === "https://api.test.ecndev.io")
    return "https://admin.test.ecndev.io/";
  else if (BASE === "https://api.onefinnet.com")
    return "https://admin.onefinnet.com/";
}