import React, { ChangeEvent, useEffect, useState } from "react";

import { Icon } from "@iconify/react";

import EcnInput from "../StyledComponents/EcnInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete, Box, Button } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PhoneInput from "react-phone-input-2";

import EcnButton from "../StyledComponents/V2/EcnButton";
import { getLocation } from "../../redux/slices/JobSlices/getLocationSlice";
import { maskCountry } from "../../helpers/mask";
import 'react-phone-input-2/lib/style.css'

const detailTemplate = {
  country: "",
  mobile: "",
  email: "",
  roles: ["enterprise-member"]
};

const UserDetail: React.FC<{
  setData: any;
  setActive: any;
  setUserData: any;
  userData: any;
  setFileName: any;
  fileName: any;
  setOpen: any
}> = ({ setData, setActive, setUserData, userData, fileName, setFileName, setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { getLocationData } = useAppSelector(
    (state: any) => state.locationList
  );

  const [index, setIndex] = useState<any>(
    userData?.length === 0 ? 1 : userData?.length
  );
  const { userInfo, loading, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );


  const [addressList, setAddressList] = useState<any>([]);
  const [errors, setErrors] = useState(false);
  const [mobileError, setMobileError] = useState(false);

  const [error, setError] = useState({
    state: false,
    message: "",
  });
  const [isValidEmail, setIsValidEmail] = useState<any>(false);

  useEffect(() => {
    if (userData?.length === 0) {
      const tempWork = userData.slice();
      tempWork.push({ ...detailTemplate });
      setUserData(tempWork);
    }
  }, [userData]);

  useEffect(() => {

    let isError = userData?.every(areAllFieldsNotEmpty)
    setErrors(isError)


  }, [userData])

  useEffect(() => {
    if (tab === "mapped") {
      setIndex(1);
    }
  }, [tab]);

  useEffect(() => {
    if (getLocationData) {
      setAddressList(getLocationData);
    }
  }, [getLocationData]);

  const fileHandler = (e: any) => {
    const file: any = e.target.files ? e.target.files[0] : null;
    setOpen(true)
    setFileName(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const csvText = e.target.result;
        parseCSV(csvText);
      };

      reader.readAsText(file);
    }
  };

  const parseCSV = (csvText: any) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",");
    const parsedData: any = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");

      if (currentLine.length === headers.length) {
        const row: any = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j].trim()] = currentLine[j].trim();
        }
        parsedData.push(row);
      }
    }
    setData(parsedData);
  };

  const handleChange = (e: any, input: string, i: number, value?: any) => {
    const tempWork = userData.slice();
    if (!tempWork.length || !tempWork[i]) {
      tempWork.push({ ...detailTemplate });
    }

    let newValue: any = "";
    if (input === "first_name") {
      newValue = e.target.value;
    } else if (input === "last_name") {
      newValue = e.target.value;
    } else if (input === "designation") {
      newValue = e.target.value;
    } else {
      newValue = e.target.value;
      setIsValidEmail(false);
    }

    const newWork = tempWork.map((item: any, index: number) =>
      index === i
        ? {
          ...item, // <-- shallow copy element
          [input]: newValue, // <-- update property
        }
        : item
    );

    setUserData(newWork);
  };
  function areAllFieldsNotEmpty(obj: any) {

    for (const key in obj) {
      if (key === "email") {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {

          return false;
        }
      }
      if (key === "email") {
        if (
          !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            obj[key]
          )
        ) {
          setIsValidEmail(true);
          return false;
        }
      }
    }
    return true;
  }


  const nextHandler = () => {
    if (fileName === "") {
      const allFieldsNotEmpty = userData.every(areAllFieldsNotEmpty);

      if (allFieldsNotEmpty) {
        setIndex(userData?.length);
        setActive((next: any) => next + 1);
        navigate("?view=invite-user&step=permission");
      } else {
        setError({ ...error, state: true });
      }
    }
    else {
      setOpen(true)
    }

  };
  const addMoreHandler = () => {
    setIsValidEmail(false);
    setIndex((prev: any) => prev + 1);
    setError({ ...error, state: false });
    setUserData([...userData, detailTemplate]);
  };
  const deleteHandler = (i: any) => {
    setIndex((prev: any) => prev - 1);
    setError({ ...error, state: false });
    userData.splice(i, 1);
    setUserData(userData);
  };

  const addressListHandler = (e: any) => {
    if (e !== null) dispatch(getLocation(e.target.value));

  };

  const addressHandler = (e: any, newValue: any, n: any) => {
    const tempWork = userData.slice();
    if (!tempWork.length || !tempWork[n]) {
      tempWork.push({ ...detailTemplate });
    }

    tempWork[n] = {
      ...tempWork[n],
      country: newValue.address.municipality + " " +
        newValue.address.countrySubdivision + " " + newValue.address.country
    };

    setUserData(tempWork);
  };
  const mobileChangeHandler = (val: any, country: any, e: any, formatVal: any, n: any) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    const tempWork = userData.slice();
    if (!tempWork.length || !tempWork[n]) {
      tempWork.push({ ...detailTemplate });
    }

    tempWork[n] = {
      ...tempWork[n],
      mobile: formatVal
    };
    setUserData(tempWork);
  };

  const detailGrid = (n: number) => {
    return (
      <div className="row">
        <div className="col">
          <p>Add email address*</p>
          <EcnInput
            className="ecn-input"
            color="info"
            placeholder="Enter work email address"
            onChange={(e) => handleChange(e, "email", n)}
            value={tab === "mapped" ? "" : userData[n]?.email}
            style={{ height: "50px" }}
          />
          {error?.state && userData[n]?.email === "" && (
            <p className="error">Enter email</p>
          )}
          {isValidEmail &&
            !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              userData[n]?.email
            ) && <p className="error">Enter valid email</p>}
        </div>
        <div className="col">
          <p>Location</p>


          <div className="auto-box">
            <Autocomplete
              className="ecn-input"
              style={{ padding: "0px !important" }}
              forcePopupIcon={false}
              disableClearable
              options={[
                ...(customLocations[0]?.results ?? []),
                ...(addressList?.results ?? [])
              ]}
              value={userData[n]?.country}
              getOptionLabel={(option: any) =>
                option?.address
                  ? `${option.address.municipality}, ${option.address.countrySubdivision}, ${option.address.country}`
                  : `${userData[n].country}`

              }
              onInputChange={(e) => addressListHandler(e)}
              onChange={(e, newValue) => addressHandler(e, newValue, n)}
              renderInput={(params) => (
                <Box sx={{ position: "relative" }}>
                  <EcnInput
                    {...params}
                    placeholder="Enter location"
                    className="auto-box"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "57%",
                      right: "13px", // Adjust this value as needed to position the arrow icon
                      transform: "translateY(-50%) rotate(" + "0deg" + ")",
                      transition: "transform 0.3s ease", // Add transition for smooth rotation
                      pointerEvents: "none"
                    }}
                  >

                  </Box>
                </Box>
              )}
              renderOption={(props, option, { selected }) => (
                <Box
                  component="li"
                  {...props}
                  sx={{
                    margin: "0px 10px",
                    marginBottom: "0px !important",
                    borderRadius: "8px !important",
                    padding: "10px 15px !important",
                    bgcolor: selected
                      ? "rgba(0, 52, 187, 0.05) !important"
                      : "transparent", // Apply the "info" color for selected options
                    "&:hover": {
                      bgcolor: "rgba(0, 52, 187, 0.05) !important", // Lighten the color on hover
                      color: "rgba(0, 52, 187, 1)"
                    },

                  }}
                >
                  {`${option.address.municipality},
                ${option.address.countrySubdivision} ${option.address.countrySubdivision === "" ? "" : ","
                    } ${option.address.country}`}
                </Box>
              )}
            />
          </div>


        </div>
        <div className="col">
          <p>Phone number</p>

          <PhoneInput
            country="us"
            preferredCountries={[
              "gb",
              "fr",
              "es",
              "it",
              "cn",
              "in",
              "us",
              "ca",
            ]}
            containerClass="billing-mobile-input"
            specialLabel=""
            placeholder="Type your mobile number"
            masks={maskCountry}
            isValid={(inputNumber: any, country: any, countries: any) => {
              const valid = countries.some((country: any) => {
                return inputNumber.startsWith(country.dialCode);
              });
              if (!valid) setMobileError(true);
              return true;
            }}
            value={userData[n]?.mobile}
            onChange={(val: any, country: any, e: any, formatVal: any) =>
              mobileChangeHandler(val, country, e, formatVal, n)
            }
          />

        </div>


        {userData?.length != 1 &&
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none" style={{ marginTop: "25px", cursor: "pointer" }} onClick={() => deleteHandler(n)}>
            <path d="M5.83301 21C5.37467 21 4.98217 20.804 4.65551 20.412C4.32884 20.02 4.16579 19.5493 4.16634 19V6H3.33301V4H7.49967V3H12.4997V4H16.6663V6H15.833V19C15.833 19.55 15.6697 20.021 15.343 20.413C15.0163 20.805 14.6241 21.0007 14.1663 21H5.83301ZM14.1663 6H5.83301V19H14.1663V6ZM7.49967 17H9.16634V8H7.49967V17ZM10.833 17H12.4997V8H10.833V17Z" fill="#212121" fill-opacity="0.7" />
          </svg>}
      </div>
    );
  };
  const removeHandler = () => {
    window.location.reload();
    setFileName("");

  };




  return (
    <div className="user-detail">
      <div className="top">

        <h2 className="title">Add multiple users to your organisation</h2>
        {fileName?.length === 0 && (
          <div className="users-list">

            {[...Array(index)].map((n: number, ind: number) => (
              <div key={ind}>{detailGrid(ind)}</div>
            ))}

            <div className="button">
              <Button
                variant="outlined"
                className="btn-outlined"
                onClick={addMoreHandler}
              >
                Add more user
              </Button>

            </div>
          </div>
        )}
        {userInfo.paid && <div className="import">
          <h2>Import multiple users from CSV file</h2>
          <p>
            Please make sure the uploaded CSV file contain the work email
            IDs of the users you want to add
          </p>
          <div className="input-name">
            <div className="input">
              <label htmlFor="file-upload" className="custom-file-upload">
                <Icon
                  icon="bi:filetype-csv"
                  width={40}
                  height={40}
                  color="#374151"
                />
                Upload CSV file
                {fileName?.length === undefined && (
                  <h3 className="replace">replace</h3>
                )}
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={fileHandler}
                accept=".csv"
              />
            </div>
            {fileName?.length === undefined && (
              <h3>
                {fileName?.name}{" "}
                {fileName != undefined && (
                  <Icon
                    icon="mdi:delete-outline"
                    width={20}
                    height={20}
                    color="#374151"
                    onClick={removeHandler}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </h3>
            )}
          </div>
        </div>}
      </div>

      <div className="bottom-button">
        <p></p>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={nextHandler}
          disabled={!errors}
        >
          Next
        </EcnButton>
      </div>
    </div>
  );
};

export default UserDetail;
const customLocations: any = [
  {
    results: [
      {
        type: "Geography",
        id: "custom1",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "New York",
          countrySubdivision: "NY",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom2",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "Chicago",
          countrySubdivision: "IL",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom3",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "San Francisco",
          countrySubdivision: "CA",
          country: "USA"
        }
      },
      {
        type: "Geography",
        id: "custom4",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "London",
          countrySubdivision: "",
          country: "UK"
        }
      },
      {
        type: "Geography",
        id: "custom5",
        score: 1,
        entityType: "Municipality",
        matchConfidence: { score: 1 },
        address: {
          municipality: "Hong Kong",
          countrySubdivision: "",
          country: "China"
        }
      }
    ]
  }
];