import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Breadcrumb: React.FC<{
  label?: any;
  labelTwo?: any;
  labelLink?: any;
}> = ({ label, labelTwo, labelLink }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || null;

 
  return (
    <div className="breadcrumb">
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 20 20"
        fill="none"
        onClick={() => navigate("/dashboard")}
        style={{ cursor: "pointer", marginRight: "10px" }}
      >
        <path
          d="M2.5 7.49935L10 1.66602L17.5 7.49935V16.666C17.5 17.108 17.3244 17.532 17.0118 17.8445C16.6993 18.1571 16.2754 18.3327 15.8333 18.3327H4.16667C3.72464 18.3327 3.30072 18.1571 2.98816 17.8445C2.67559 17.532 2.5 17.108 2.5 16.666V7.49935Z"
          stroke="#212121"
          stroke-opacity="0.6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 18.3333V10H12.5V18.3333"
          stroke="#212121"
          stroke-opacity="0.6"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div className="dots"></div>
      {label != "" && (
        <p
          onClick={() => navigate(`${labelLink}`)}
          style={{ cursor: "pointer" }}
        >
          {label}
        </p>
      )}
      {id === null && (
        <>
          {labelTwo != "" && labelTwo != undefined && (
            <div className="dots"></div>
          )}
          <p>{labelTwo}</p>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
