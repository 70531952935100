import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getDashboardHomeData = createAsyncThunk(
  "getDashboardHomeData",
  async () => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOBS_CREATE_BASE}/api/v1/recruiter/home`
    );
    return data;
  }
);

export type State = {
  loading: boolean;
  error: boolean;
  dashboardHomeData: DashboardHomeData | null;
};
export type DashboardHomeData = {
  total_jobs: number;
  total_applicants: number;
  total_applicants_not_reviewed: number;
  recent_candidates: RecentCandidates[];
  top_jobs: TopJob[];
};
export type RecentCandidates = {
  application_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  job_name: string;
  ai_rating: number;
  applied_date: number;
};
export type TopJob = {
  job_id: string;
  name: string;
  total_applicants: number;
  total_applicants_not_reviewed: number;
};

const initialState: State = {
  loading: false,
  error: false,
  dashboardHomeData: null
};

const getDashboardHomeDataSlice = createSlice({
  name: "getDashboardHomeData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardHomeData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDashboardHomeData.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboardHomeData = action.payload;
    });
    builder.addCase(getDashboardHomeData.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default getDashboardHomeDataSlice.reducer;
