import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getJobPromotionAnalytics,
  resetPromoAnalytics,
} from "../redux/slices/JobPromotions/getJobPromotionAnalyticsSlice";
import {
  postPromotionRequest,
  resetPromoReq,
} from "../redux/slices/JobPromotions/postPromotionRequestSlice";

import layoutTwo from "../components/layout/layoutTwo";
import EcnButton from "../components/StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import Lottie from "react-lottie";
import PromotionHourGlass from "../assets/lottie/promotions_hourglass.json";
import PromotionStars from "../assets/lottie/promotions_stars.json";
import PromotionError from "../assets/lottie/promotion_error.json";
import PromotionSuccess from "../assets/lottie/promotion_success.json";
import PromoAnalytics from "../components/Analytics/Promotion/PromoAnalytics";
import { Drawer, IconButton, LinearProgress, TextField } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const getColor = (value: number) => {
  if (value === 100) return "#027A48";
  if (value < 25) return "#D100E5";
  return "#0034bb";
};

const JobPromotion = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { jobId } = useParams();

  const { jobPromotionAnalytics, loading, errorData } = useAppSelector(
    (state) => state.getJobPromotionAnalytics
  );
  const { promoReqRes } = useAppSelector((state) => state.postPromotionRequest);

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [viewDrawer, setViewDrawer] = useState(false);
  const [displayedText, setDisplayedText] = useState("");
  const indexRef = useRef(0); // useRef to keep track of the index
  const animationStarted = useRef(false); // New ref to track if the animation has started

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 95) {
          clearInterval(timer);
          return prevProgress;
        }
        return Math.min(prevProgress + 5, 95);
      });
    }, 500); // adjust the interval as needed

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (!loading && jobPromotionAnalytics) {
      setProgress(100);
    }
  }, [loading, jobPromotionAnalytics]);

  useEffect(() => {
    // This effect fetches the analytics data
    if (jobId && !animationStarted.current) {
      dispatch(getJobPromotionAnalytics(jobId));
    }
  }, [jobId, getJobPromotionAnalytics]);

  useEffect(() => {
    // This effect handles the typing animation
    if (jobPromotionAnalytics?.ai_message && !animationStarted.current) {
      animationStarted.current = true;
      const message = jobPromotionAnalytics.ai_message;
      const timer = setInterval(() => {
        if (indexRef.current === 0) {
          setDisplayedText(message.slice(0, 17));
          indexRef.current = 16;
        } else if (indexRef.current < message.length) {
          setDisplayedText((prev) => prev + message.charAt(indexRef.current));
          indexRef.current += 1;
        } else if (indexRef.current === message.length) {
          setDisplayedText(jobPromotionAnalytics.ai_message);
        } else {
          clearInterval(timer);
        }
      }, 10);

      return () => {
        clearInterval(timer);
        indexRef.current = 0; // Reset on cleanup if needed
        animationStarted.current = false; // Reset the flag when component unmounts or data changes
      };
    }
  }, [jobPromotionAnalytics]);

  useEffect(() => {
    if (promoReqRes) {
      setViewDrawer(false);
    }
  }, [promoReqRes]);

  const drawerCloseHandler = () => {
    setViewDrawer(false);
  };

  const promotionSubmitHandler = () => {
    dispatch(postPromotionRequest({ jobId, message }));
  };

  const backHandler = () => {
    dispatch(resetPromoAnalytics());
    dispatch(resetPromoReq());
    navigate(`/jobs?view=details&id=${jobId}`);
  };

  const color = getColor(progress);

  return (
    <div className="job-promotion-v2">
      <EcnButton
        color="secondary"
        className="back-btn"
        startIcon={<Icon icon="material-symbols:arrow-back" />}
        onClick={backHandler}
      >
        Back
      </EcnButton>

      <div className="promotion-body">
        {promoReqRes ? (
          <div className="loading-state">
            <div className="wrapper">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: PromotionSuccess,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={300}
                width={300}
              />
              <p className="heading">Thanks for contacting us!</p>
              <p className="sub" style={{ marginTop: "4px" }}>
                Your details have been sent to our sales team. They'll reach out
                to you at the earliest.
              </p>
              <EcnButton
                color="secondary"
                startIcon={
                  <Icon
                    icon="material-symbols:arrow-back"
                    height={24}
                    width={24}
                    color="#212121"
                  />
                }
                sx={{ fontSize: "18px", fontWeight: "600" }}
                onClick={backHandler}
              >
                Back
              </EcnButton>
            </div>
          </div>
        ) : loading ? (
          <div className="loading-state">
            <div className="wrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: PromotionHourGlass,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={350}
                width={350}
                speed={0.5}
              />
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: PromotionStars,
                }}
                height={350}
                width={350}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "calc(50% - 175px)",
                  zIndex: "-1",
                }}
              />

              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  background: "lavender",
                  height: "6px",
                  borderRadius: "8px",
                  marginBottom: "20px",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: color, // Use the color based on progress value
                  },
                }}
              />
              <p className="heading">AI's got this!</p>
              <p className="sub">
                Our AI's analyzing your job info for strategies to boost your
                reach....
              </p>
            </div>
          </div>
        ) : errorData?.message === "job promotion is already active" ? (
          <div className="loading-state">
            <div className="wrapper">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: PromotionSuccess,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={300}
                width={300}
              />
              <p className="heading">Your request is under progress!</p>
              <p className="sub" style={{ marginTop: "4px" }}>
                Your details have been sent to our sales team. They'll reach out
                to you at the earliest.
              </p>
              <EcnButton
                color="secondary"
                startIcon={
                  <Icon
                    icon="material-symbols:arrow-back"
                    height={24}
                    width={24}
                    color="#212121"
                  />
                }
                sx={{ fontSize: "18px", fontWeight: "600" }}
                onClick={backHandler}
              >
                Back
              </EcnButton>
            </div>
          </div>
        ) : errorData ? (
          <div className="error-state">
            <div className="wrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: PromotionError,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={350}
                width={350}
              />

              <p className="heading" style={{ marginTop: "20px" }}>
                Something went wrong!
              </p>
              <p className="sub">
                Please try again after sometime, or contact your admin
              </p>
            </div>
          </div>
        ) : jobPromotionAnalytics ? (
          <div className="promotion-data">
            <div className="ai-says">
              <div className="container">
                <div className="head">
                  <div className="icon-wrapper">
                    <Icon icon="fluent-emoji:sparkles" height={22} width={22} />
                  </div>
                  <p className="heading">AI Says...</p>
                </div>
                <ReactMarkdown
                  className="markdown-data body"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {displayedText}
                </ReactMarkdown>
              </div>
            </div>

            {displayedText === jobPromotionAnalytics?.ai_message ? (
              <>
                <div className="scores">
                  <div className="head">
                    <h4 className="score-heading">Know Your Reach!</h4>
                    <p className="info">
                      <Icon icon="lucide:info" height={14} width={14} />
                      The data provided are the estimated results
                    </p>
                  </div>

                  <div className="wrapper">
                    <div className="card first">
                      <h3 className="num">
                        {jobPromotionAnalytics?.total_users}
                      </h3>
                      <p className="helper">Target Reach</p>
                    </div>

                    <div className="card second">
                      <h3 className="num">
                        {jobPromotionAnalytics?.engagement_rate}%
                      </h3>
                      <p className="helper">Engagement Rate</p>
                    </div>

                    <div className="card third">
                      <h3 className="num">
                        {jobPromotionAnalytics?.total_impressions}
                      </h3>
                      <p className="helper">Potential Impressions</p>
                    </div>
                  </div>
                </div>

                <div className="analytics">
                  <PromoAnalytics
                    data={jobPromotionAnalytics?.analytics_sections}
                  />
                </div>

                <div className="sticky-cta">
                  <p className="cta-helper">
                    Connect with Us Now for Tailored Strategies and Support!
                  </p>
                  <EcnButton
                    className="bottom-cta"
                    variant="contained"
                    onClick={() => setViewDrawer(true)}
                  >
                    Connect with Expert
                  </EcnButton>
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </div>

      <Drawer
        anchor="right"
        open={viewDrawer}
        onClose={drawerCloseHandler}
        PaperProps={{
          sx: {
            borderRadius: "0",
            padding: "39px 42px",
            paddingBottom: "0px",
            minWidth: "800px",
            maxWidth: "800px",
            justifyContent: "flex-start",
            backgroundColor: "#fff",
            marginTop: "0",
          },
        }}
      >
        <IconButton
          style={{ width: "max-content" }}
          onClick={() => setViewDrawer(false)}
        >
          <Icon
            icon="material-symbols:arrow-back"
            height={24}
            width={24}
            color="#212121"
          />
        </IconButton>
        <h4
          style={{
            fontSize: "23px",
            fontWeight: "600",
            marginTop: "20px",
            color: "#212121",
          }}
        >
          Please leave a note for our experts!
        </h4>
        <TextField
          multiline
          rows={10}
          minRows={10}
          maxRows={10}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Tell us how we can help you reach your desired goals..."
          sx={{
            marginTop: "22px",
            ".MuiOutlinedInput-root": {
              padding: "19px 27px",
              borderRadius: "6px",

              "&:placeholder": {
                color: "rgba(33, 33, 33, 0.6)",
                fontSize: "16px",
              },
              "&:hover, &:focus-within": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0034bb",
                },
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(33, 33,33, 0.2)",
              },
            },
          }}
        />

        <div
          style={{
            marginTop: "22px",
            textAlign: "right",
          }}
        >
          <EcnButton
            variant="contained"
            sx={{
              padding: "8px 18px",
              minWidth: "174px",
              borderRadius: "7px",
              boxShadow: "none",
              outline: "0.5px solid #0034bb",
            }}
            onClick={promotionSubmitHandler}
          >
            Submit
          </EcnButton>
        </div>
      </Drawer>
    </div>
  );
};

export default layoutTwo(JobPromotion);
