import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const userSignout = createAsyncThunk(
  "userSignout",
  async (email: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/logout`,
      {
        enterprise: true,
        email: email,
      }
    );
    return data;
  }
);

const userSignoutSlice = createSlice({
  name: "userSignout",
  initialState: {
    loading: false,
    success: false,
    signOut: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSignout.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userSignout.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.signOut = action.payload;
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
    });
    builder.addCase(userSignout.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userSignoutSlice.reducer;
