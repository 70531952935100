import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const removeAssignJob = createAsyncThunk(
  "removeAssignJob",
  async (filterData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/removeJob`,
      filterData
    );
    return data;
  }
);

const removeAssignJobSlice = createSlice({
  name: "removeAssignJob",
  initialState: {
    loading: false,
    removeAssignJobData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeAssignJob.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeAssignJob.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.removeAssignJobData = action.payload;
    });
    builder.addCase(removeAssignJob.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default removeAssignJobSlice.reducer;
