import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import { useParams, useNavigate } from "react-router-dom";

import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";

import { Tab, Tabs } from "@mui/material";
import EventDetailsTab from "../components/EventDetail/EventDetailsTab";
import RegistrantsTab from "../components/EventDetail/RegistrantsTab";
import layoutTwo from "../components/layout/layoutTwo";
import { useQueryParams } from "../helpers/HOC/useQueryParams";
import PaymentsTab from "../components/EventDetail/PaymentsTab";
import { getEventDetails } from "../redux/slices/EventSlices/getEventDetailsSlice";

type TabType = "event-details" | "registrant-details" | "additional-details";

const EventDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, updateQueryParams] = useQueryParams();
  const { eventId } = useParams();

  const queryTab = searchParams.get("tab");

  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);

  const [tab, setTab] = useState<TabType>(
    isCorrectTab(queryTab) ? queryTab : "event-details"
  );

  useEffect(() => {
    if (!isCorrectTab(queryTab)) {
      updateQueryParams({ tab });
    } else if (queryTab !== tab) {
      setTab(queryTab);
    }
  }, [queryTab]);

  useEffect(() => {
    if (eventId) dispatch(getEventDetails({ eventId }));
  }, [eventId]);

  return (
    <div className="event-detail">
      <EcnBreadCrumbs>
        <p onClick={() => navigate(`/events?tab=events`)}>Events</p>
        <p onClick={() => navigate(`/event/${eventId}/?tab=event-details`)}>
          {eventDetailsData?.name.slice(0, 25)}...
        </p>
        <p>
          {tab == "event-details"
            ? "Details"
            : tab == "registrant-details"
            ? "Registrants"
            : "Additional Details"}
        </p>
      </EcnBreadCrumbs>

      <Tabs
        value={tab}
        onChange={(e, newVal) => {
          setTab(newVal);
          updateQueryParams({ tab: newVal });
        }}
        className="ecn-tabs"
      >
        <Tab label="Event Details" value="event-details" />
        <Tab label="Registrant Details" value="registrant-details" />
        <Tab label="Additional Details" value="additional-details" />
      </Tabs>
      <div className="body">
        {tab === "event-details" && eventId && (
          <EventDetailsTab eventId={eventId} />
        )}
        {tab === "registrant-details" && eventId && <RegistrantsTab />}
        {tab === "additional-details" && eventId && <PaymentsTab />}
      </div>
    </div>
  );
};

function isCorrectTab(tab?: string | null): tab is TabType {
  return (
    tab == "event-details" ||
    tab == "registrant-details" ||
    tab == "additional-details"
  );
}

export default layoutTwo(EventDetail);
