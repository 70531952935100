import { Icon } from "@iconify/react";
import { CircularProgress, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Education from "./Left/Education";
import ExperienceCard from "./Left/ExperienceCard";
import UserCard from "./Left/UserCard";

const RightSideExpandView: React.FC<{ data: any }> = ({ data }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null); // New state to handle errors
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(true);
  };

  const handleDocumentLoadError = (error: Error) => {
    setError(error);
    setLoading(false);
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
    setLoading(false);
  };

  const resumeHandler = (resume: any) => {
    let originalUrl = resume;
    let oldBaseUrl = "https://core.api.elevatecareernetwork.com/";
    let newBaseUrl = "https://api.onefinnet.com/core-backend/v1/";

    if (originalUrl.includes(oldBaseUrl)) {
      let updatedUrl = originalUrl.replace(oldBaseUrl, newBaseUrl);
      return updatedUrl;
    } else {
      return resume;
    }
  };

  return (
    <div className="right-side-expand">
      <Tabs value={activeStep} onChange={handleChange} className="tabs">
        <Tab label="Resume" />
        <Tab label="Profile" />
      </Tabs>

      {activeStep === 1 && (
        <div className="profile-tab">
          <UserCard data={data} />
          {data?.created_by?.work_experiences?.length != 0 && (
            <ExperienceCard data={data} />
          )}
          {data?.created_by?.education?.length != 0 && (
            <Education data={data} />
          )}
        </div>
      )}

      {activeStep === 0 && (
        <>
          <div
            className="pdf-container"
            style={
              !loading
                ? { height: "600px", overflow: "hidden" }
                : { height: "auto" }
            }
          >
            {!loading && (
              <div className="loader">
                <CircularProgress
                  sx={{ color: "#0034bb", marginTop: "-120px" }}
                />
              </div>
            )}
            {error && <div className="error-message">{error.message}</div>}

            <div className={"container"}>
              <div ref={containerRef} className="inner-container">
                <div
                  style={{
                    transformOrigin: "top center",
                    transform: `scale(${zoom})`,
                  }}
                >
                  <Document
                    file={resumeHandler(data?.created_by?.resume)}
                    onLoadSuccess={handleDocumentLoadSuccess}
                    onLoadError={handleDocumentLoadError}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={450}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    ))}
                  </Document>
                </div>
              </div>
              <div className="page">
                <div className="page-number">
                  <IconButton
                    disabled={pageNumber === 1}
                    onClick={handlePrevPage}
                  >
                    <Icon icon="lucide:chevron-left" className="icons" />
                  </IconButton>
                  <h1>
                    {pageNumber} <span>/{numPages}</span>
                  </h1>
                  <IconButton
                    disabled={pageNumber === numPages}
                    onClick={handleNextPage}
                  >
                    <Icon icon="lucide:chevron-right" className="icons" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RightSideExpandView;
