import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

type ObjectId = string;

interface PrivateUserRegister {
  event_id: ObjectId;
  user_id?: ObjectId;
  basic_details: PrivateUserBasicDetails;
  resume?: string;
  academic_scores: PrivateUserAcademicScores;
  education: Education[];
  work_experiences: Experience[];
}

interface PrivateUserBasicDetails {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  diversity?: string;
  partner_club?: string;
}

interface PrivateUserAcademicScores {
  act?: number;
  gpa?: number;
  sat?: number;
  gmat?: number;
}

interface Education {
  education_type: number;
  name?: string;
  major?: string;
  entry_year?: number;
  graduation_year?: number;
}

interface Experience {
  experience_type?: number;
  experience_status?: number;
  title?: string;
  company?: string;
  description?: string;
  position?: string;
  start_date?: Date;
  end_date?: Date;
  city: string;
  zip_code?: string;
}

export const registerPrivateUser = createAsyncThunk(
  "registerPrivateUser",
  async (payload: PrivateUserRegister) => {
    await axios.post(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/event/register/candidate`,
      payload
    );
  }
);

const registerPrivateUserSlice = createSlice({
  name: "registerPrivateUser",
  initialState: {
    loading: false,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerPrivateUser.pending, (state, action) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(registerPrivateUser.fulfilled, (state, action) => {
      state.error = false;
      state.loading = false;
    });
    builder.addCase(registerPrivateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default registerPrivateUserSlice.reducer;
