import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getAiCredits } from "../../../redux/slices/dashboardSlices/getAiCreditsSlice";
import { selectedAiGenerateAvgScore } from "../../../redux/slices/CandidatesSlices/selectedAiGenerateAvgScoreSlice";
import { selectedAiReGenerateAvgScore } from "../../../redux/slices/CandidatesSlices/selectedAiReGenerateAvgScoreSlice";
import { bulkForcedAiScore } from "../../../redux/slices/CandidatesSlices/bulkForcedAiScoreSlice";

import { Icon } from "@iconify/react";
import EcnCheckboxFilled from "../../StyledComponents/EcnCheckboxFilled";
import EcnButton from "../../StyledComponents/V2/EcnButton";
import { useQueryParams } from "../../../helpers/HOC/useQueryParams";
import { Button } from "@mui/material";

const ResumePopupTable = ({ selectedRows, rows, setOpen }: {
    selectedRows: any;
    rows: any;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [localSelectedRows, setLocalSelectedRows] = useState([...selectedRows]);
    const [localRows, setLocalRows] = useState([...rows]);

    const [showDeselect, setShowDeselect] = useState(false);

    const [searchParams] = useQueryParams();
    const job_id = searchParams.get("id");

    const { userInfo } = useAppSelector((state: any) => state.userDetails);

    const { candidatesListData, loading } = useAppSelector(
        (state: any) => state.candidateLists
    );

    const { aiCredits } = useAppSelector((state: any) => state.getAiCredits);
    const credits = aiCredits?.total_available_credits;

    useEffect(() => {
        dispatch(getAiCredits());
    }, [])

    const cellClickHandler = (id: string) => {
        if (localSelectedRows.includes(id)) {
            setLocalSelectedRows(localSelectedRows.filter((rowId: string) => rowId !== id));
        } else {
            setLocalSelectedRows([...localSelectedRows, id]);
        }
    }

    const selectAllHandler = () => {
        if (localSelectedRows.length === localRows.length) {
            setLocalSelectedRows([]);
        } else {
            setLocalSelectedRows(localRows.map((row: any) => row?._id));
        }
    }

    const searchHandler = (e: any) => {
        const inputValue = e.target.value.trim() || "";

        if (!inputValue || inputValue === "") {
            setLocalRows([...rows]);
            return;
        }

        const searchTerms = inputValue.split(" ").filter((term: string) => term !== "");

        const filteredRows = rows.filter((row: any) => {
            const name = `${row?.created_by?.first_name}`;

            const startsWithCheck = name.toLowerCase().startsWith(searchTerms[0].toLowerCase());

            return startsWithCheck;
        })

        if (e.type === "keydown" && (e as React.KeyboardEvent).key === "Enter") {
            if (filteredRows.length === 0) {
                setLocalRows([]);
                return;
            }
        }

        const sortedRows = filteredRows.sort((a: any, b: any) => {
            const fullNameA = `${a.first_name}`;
            const fullNameB = `${b.first_name}`;
            const startsWithA = fullNameA.toLowerCase().startsWith(searchTerms[0].toLowerCase());
            const startsWithB = fullNameB.toLowerCase().startsWith(searchTerms[0].toLowerCase());

            if (startsWithA && !startsWithB) return -1;
            if (!startsWithA && startsWithB) return 1;
            return 0;
        });

        setLocalRows(sortedRows);
    };

    const debouncedSearchHandler = (e: any) => {
        setTimeout(() => {
            searchHandler(e);
        }, 500);
    };

    const selectedUnreviewedHandler = () => {
        let unreviewedApplications: any = [];

        if (showDeselect) {
            setLocalSelectedRows([]);
        }
        else {
            rows.forEach((row: any) => {
                if (row?.application_status === 0) {
                    unreviewedApplications.push(row?._id);
                }
            })
        }

        setShowDeselect(!showDeselect);
        setLocalSelectedRows([...unreviewedApplications])
    }

    const selectedAiScoreHandler = () => {

        if (localSelectedRows?.length === candidatesListData?.total_job_applicants) {
            dispatch(bulkForcedAiScore({
                job_id: job_id,
                user_id: userInfo?.id
            }));
        }
        else {
            let reviewedApplications: any = [];
            let unreviewedApplications: any = [];
            rows.forEach((row: any) => {
                if (localSelectedRows.includes(row?._id)) {
                    if (row?.ai_score_generated == true) {
                        reviewedApplications.push(row?._id);
                    }
                    else {
                        unreviewedApplications.push(row?._id);
                    }
                }
            })

            if (unreviewedApplications.length > 0) {
                dispatch(selectedAiGenerateAvgScore({
                    job_id: job_id,
                    application_ids: unreviewedApplications
                }));
            }
            if (reviewedApplications.length > 0) {
                dispatch(selectedAiReGenerateAvgScore({
                    job_id: job_id,
                    application_ids: reviewedApplications
                }));
            }
        }
        if (localSelectedRows?.length > 0) {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.set('confirm', 'true');
            navigate(`?${currentParams.toString()}`);
        }

        localStorage.setItem("showAiScoreStatusSnackbar", "true");
        localStorage.setItem("finalSelectedRows", JSON.stringify(localSelectedRows));

        setOpen(false);
    }

    return (
        <div className="resume-popup-table">

            <div className="top">
                <div className="search-box">
                    <Icon
                        icon="lucide:search"
                        width={24}
                        height={24}
                    />
                    <input type="text" placeholder="Search Candidates"
                        onChange={debouncedSearchHandler}
                    />
                </div>

                <Button
                    variant="outlined"
                    className="unreviewed-btn"
                    onClick={() => { selectedUnreviewedHandler() }}
                >
                    {showDeselect ? "Deselect" : "Select"} All Unreviewed Candidates
                </Button>
            </div>

            <div className="table-body">
                <div className="top-bar">
                    <div>
                        <EcnCheckboxFilled
                            checked={localSelectedRows.length === rows.length}
                            onClick={() => selectAllHandler()}
                        />

                        <h5>
                            Selected <span>({localSelectedRows.length})</span>
                        </h5>
                    </div>

                    <div className="credit-count-cont">
                        <div className="credit-use">Credits in use <span>{localSelectedRows.length * 10}</span></div>
                        <div className="credit-total">Total Credits<span> {credits}
                        </span></div>
                    </div>

                </div>

                <div className="data-cont">
                    {localRows.map((row: any) => (
                        (selectedRows.includes(row?._id)) &&
                        <div className="data-row">
                            <EcnCheckboxFilled
                                checked={localSelectedRows.includes(row?._id)}
                                onClick={() => cellClickHandler(row?._id)}
                            />

                            <div className="details">
                                <div className="name">
                                    {row?.created_by?.first_name} {row?.created_by?.last_name}
                                </div>
                                {row?.created_by?.headline && <div className="role">
                                    {row?.created_by?.headline}
                                </div>}
                            </div>

                            <div className="email">
                                {row?.created_by?.email}
                            </div>
                        </div>
                    ))}

                    {localRows.map((row: any) => (
                        !(selectedRows.includes(row?._id)) &&
                        <div className="data-row">
                            <EcnCheckboxFilled
                                checked={localSelectedRows.includes(row?._id)}
                                onClick={() => cellClickHandler(row?._id)}
                            />

                            <div className="details">
                                <div className="name">
                                    {row?.created_by?.first_name} {row?.created_by?.last_name}
                                </div>
                                {row?.created_by?.headline && <div className="role">
                                    {row?.created_by?.headline}
                                </div>}
                            </div>

                            <div className="email">
                                {row?.created_by?.email}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="resume-popup-bottom">
                <EcnButton
                    variant="contained"
                    className="confirm-btn"
                    onClick={selectedAiScoreHandler}
                >
                    Confirm
                </EcnButton>
            </div>

        </div>
    )
}

export default ResumePopupTable
