import { Icon } from "@iconify/react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowHeightParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getGroups } from "../../../../redux/slices/Group Creation/getAllGroupSlice";
import { updateGroup } from "../../../../redux/slices/Group Creation/updateGroupdataSlice";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  ".MuiDataGrid-root": {
    borderWidth: "5px !important",
  },
  ".MuiDataGrid-columnHeaders": {
    backgroundColor: "rgba(33, 33, 33, 0.05)",
    borderRadius: "0px",
    border: "none !important",

    padding: "8px 11px !important",
    minHeight: "45px !important",
    margin: "20px 0px",
    marginBottom: "10px",

    ".MuiDataGrid-columnHeader ": {
      height: "45px !important",
    },
  },
  ".MuiDataGrid-main": {
    marginTop: "35px",
  },
  ".MuiDataGrid-pinnedColumnHeaders": {
    minHeight: "45px !important",
    boxShadow: "none !important",
    // backgroundColor: "",

    borderTopLeftRadius: "8px",
  },

  ".MuiDataGrid-columnHeader": {
    "&:focus-within": {
      outline: "none",
    },
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: "500",
    color: "rgba(18, 22, 25, 1)",
  },

  ".MuiDataGrid-columnSeparator": {
    height: "100%",
    ".MuiSvgIcon-root": {
      color: "transparent",
      width: "10px",
    },
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "none !important",
  },
  ".MuiDataGrid-virtualScroller": {},
  ".MuiDataGrid-row": {
    background: "#FFFFFF",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      background: "rgba(0, 52, 187, 0.05)",
    },
  },
  ".MuiDataGrid-cell": {
    height: "inherit !important",
    minHeight: "inherit !important",
    maxHeight: "inherit !important",
    "&:focus-within": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
  ".MuiDataGrid-cellContent": {
    fontWeight: "400",
    color: "#212121",
    "&:focus-within": {
      outline: "none",
    },
  },
  ".MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
  ".MuiIconButton-root": {
    fontSize: "26px",
  },

  ".MuiDataGrid-columnHeaderDraggableContainer": {
    height: "unset !important",
  },
  ".MuiDataGrid-panel": {
    display: "none",
  },
  ".MuiSvgIcon-root": {
    fontSize: "25px !important",
    color: "rgba(0, 0, 0, 1)",
  },
}));

const GroupTable: React.FC<{ groups: any }> = ({ groups }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const dispatch = useAppDispatch();
  const { updateGroupData, success } = useAppSelector(
    (state: any) => state.updateGroups
  );
  const columns: any = [
    {
      field: "first_name",
      headerName: "Candidate name",
      width: 300,

      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Groups name</p>
        </Stack>
      ),

      renderCell: (params: any) => (
        <Stack direction="row" spacing={1}>
          <>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  {params?.row?.name}
                </p>
              </div>
            </div>
          </>
        </Stack>
      ),
    },

    {
      field: "member",
      headerName: "Members",
      width: 300,

      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Members</p>
        </Stack>
      ),

      renderCell: (params: any) => (
        <Stack direction="row" spacing={1}>
          {params?.row?.member?.map(
            (item: any, index: any) =>
              index < 3 && (
                <Avatar
                  alt=""
                  src={item?.display_picture}
                  style={{ height: "30px", width: "30px", marginLeft: "-10px" }}
                />
              )
          )}
          {params?.row?.member.length > 3 && (
            <Avatar
              alt=""
              style={{
                height: "30px",
                width: "30px",
                fontSize: "12px",
                fontWeight: "500",
                color: "rgba(0, 52, 187, 1)",
                background: "rgba(0, 52, 187, 0.1)",
                marginLeft: "-10px",
              }}
            >
              {params?.row?.member.length - 3}
            </Avatar>
          )}
        </Stack>
      ),
    },

    {
      field: "role",
      headerName: "Role",
      width: 350,

      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Role</p>
        </Stack>
      ),

      renderCell: (params: any) => (
        <Stack direction="row" spacing={1}>
          {params?.row?.isEdit?.id === params?.row?._id &&
          params?.row?.isEdit?.type ? (
            <div className="select" style={{ width: "260px" }}>
              <FormControl>
                <Select
                  size="small"
                  variant="standard"
                  displayEmpty={true}
                  disableUnderline
                  value={params?.row?.updateRole}
                  input={
                    <OutlinedInput
                      sx={{
                        border: "1px solid rgba(33, 33, 33, 0.20)",
                        "& fieldset": {
                          border: "1px solid rgba(33, 33, 33, 0.20) !important",
                        },
                        "&:hover fieldset": {
                          border:
                            "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                        },
                        "&.Mui-focused fieldset": {
                          border:
                            "1.7px solid rgba(0, 52, 187, 0.70) !important",
                        },
                        borderRadius: "8px",
                      }}
                    />
                  }
                  onChange={statusHandler}
                  IconComponent={KeyboardArrowDown}
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <div className="placeholder">Select Role</div>;
                    }

                    return <div>{params?.row?.updateRole} </div>;
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: 300,
                        boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                        borderRadius: "6px",
                        marginTop: "10px",
                        backgroundColor: "#FFF",
                        padding: "10px 10px",
                      },
                    },
                  }}
                >
                  {statusText?.map((item: any) => (
                    <MenuItem
                      value={item?.text}
                      disabled={
                        item?.text ===
                        (params?.row?.job[0]?.role === `recruiter-admin`
                          ? "Admin"
                          : params?.row?.job[0]?.role === `recruiter-reader`
                          ? "Reviewer"
                          : "")
                      }
                      sx={
                        item?.text ===
                        (params?.row?.job[0]?.role === `recruiter-admin`
                          ? "Admin"
                          : params?.row?.job[0]?.role === `recruiter-reader`
                          ? "Reviewer"
                          : "")
                          ? {
                              background: "rgba(0, 52, 187, 0.08)!important",
                              borderRadius: "9px",
                              marginBottom: "5px",
                              fontSize: "14px !important",
                              fontWeight: "400",
                              color: "#0034bb !important",
                              padding: "8px 15px",
                            }
                          : {
                              background: "#fff !important",
                              marginBottom: "5px",
                              fontSize: "14px !important",
                              color: "rgba(33,33,33,.7)",
                              fontWeight: "400",
                              padding: "8px 15px",
                              "&:hover": {
                                backgroundColor:
                                  "rgba(0, 52, 187, 0.08) !important",
                                color: "rgba(0, 52, 187, 1)",
                                borderRadius: "9px",
                              },
                            }
                      }
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {item?.text}
                        <p style={{ fontSize: "12px", fontWeight: "400" }}>
                          {item?.subText}
                        </p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>
                {params?.row?.job[0]?.role === `recruiter-admin`
                  ? "Admin"
                  : params?.row?.job[0]?.role === `recruiter-reader`
                  ? "Reviewer"
                  : ""}
              </p>
            </div>
          )}
        </Stack>
      ),
    },

    {
      field: "actions",
      type: "actions",
      width: 30,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Icon icon="lucide:pencil" width={16} height={18} />}
          onClick={() => editHandler(params, "edit")}
          label={"Edit"}
          showInMenu
          key="1"
          style={{ fontSize: "14px", fontWeight: "500" }}
        />,
        <GridActionsCellItem
          icon={<Icon icon="lucide:trash-2" width={16} height={18} />}
          onClick={() => editHandler(params, "remove")}
          label={"remove"}
          showInMenu
          key="1"
          style={{ fontSize: "14px", fontWeight: "500" }}
        />,
      ],
    },
  ];
  const [rows, setRow] = useState([]);
  const [status, setStatus] = useState("");
  const [groupData, setGroupData] = useState<any>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [clickRemove, setClickRemove] = useState(false);
  const [addedRole, setAddedRole] = useState(false);
  const [addedRoleMessage, setAddedRoleMessage] = useState(false);
  const [isEdit, setIsEdit] = useState<any>({
    id: "",
    type: false,
  });

  useEffect(() => {
    if (groups) {
      let data1: any = [];
      groups.map((user: any, index: number) => {
        const matchedJobs = user?.jobs.filter((job: any) => job.job_id === id);
        data1.push({
          _id: user?.group_id,
          index: index,
          name: user?.name,
          member: user?.members,
          owners: user?.owners,
          job: matchedJobs,
          group: groups,
          isEdit: isEdit,
          updateRole: status,
        });
      });
      setRow(data1);
    }
  }, [groups, isEdit, status]);

  useEffect(() => {
    if (updateGroupData && addedRoleMessage) {
      setAddedRole(false);
      setAddedRoleMessage(false);

      enqueueSnackbar("Role Edit successfully!", {
        variant: "success",
      });
    }
  }, [updateGroupData]);

  useEffect(() => {
    if (updateGroupData && addedRole) {
      setAddedRole(false);
      setAddedRoleMessage(true);
      dispatch(
        updateGroup({
          group_id: groupData[0]?.group_id,
          added_jobs: [
            {
              job_id: id,
              role:
                status === "Admin"
                  ? "recruiter-admin"
                  : status === "Reviewer"
                  ? "recruiter-reader"
                  : "",
            },
          ],
          removed_jobs: [],
          added_member_ids: [],
          removed_member_ids: [],
          owners: groupData[0]?.owners?.map((obj: any) => obj?.member_id),
          description: groupData[0]?.description,
          name: groupData[0]?.name,
          tag: groupData[0]?.tag,
          private_group: groupData[0]?.private_group,
        })
      );
      enqueueSnackbar("Role Edit successfully!", {
        variant: "success",
      });
    }
  }, [updateGroupData]);

  useEffect(() => {
    if (updateGroupData && clickRemove) {
      setClickRemove(false);
      enqueueSnackbar("Delete successfully!", {
        variant: "success",
      });
    }
  }, [updateGroupData]);

  const statusHandler = (event: any) => {
    setStatus(event.target.value);
  };

  const editHandler = (params: any, type: any) => {
    if (type === "edit") {
      setGroupData(params?.row.group);
      setIsEdit({
        id: params?.row?._id,
        type: true,
      });
    }
    if (type === "remove") {
      setGroupData(params?.row?.group);
      setDeleteOpen(true);
    }
  };

  const saveHandler = () => {
    setAddedRole(true);
    dispatch(
      updateGroup({
        group_id: groupData[0]?.group_id,
        added_jobs: [],
        removed_jobs: [
          {
            job_id: id,
            role: groupData[0]?.added_jobs?.role,
          },
        ],
        added_member_ids: [],
        removed_member_ids: [],
        owners: groupData[0]?.owners?.map((obj: any) => obj?.member_id),
        description: groupData[0]?.description,
        name: groupData[0]?.name,
        tag: groupData[0]?.tag,
        private_group: groupData[0]?.private_group,
      })
    );
  };

  const deleteCloseHandler = () => {
    setDeleteOpen(false);
  };

  const confirmDeleteHandler = () => {
    setClickRemove(true);
    dispatch(
      updateGroup({
        group_id: groupData[0]?.group_id,
        added_jobs: [],
        removed_jobs: [
          {
            job_id: id,
            role: groupData[0]?.added_jobs?.role,
          },
        ],
        added_member_ids: [],
        removed_member_ids: [],
        owners: groupData[0]?.owners?.map((obj: any) => obj?.member_id),
        description: groupData[0]?.description,
        name: groupData[0]?.name,
        tag: groupData[0]?.tag,
        private_group: groupData[0]?.private_group,
      })
    );
  };

 

  return (
    <div className="job-access">
      {isEdit?.type && (
        <Button
          className="saved"
          onClick={saveHandler}
          // disabled={status === ""}
        >
          Save
        </Button>
      )}
      <Box
        sx={{
          height: "40vh",
          width: "100%",
          marginTop: "-45px",
          marginBottom: "50px",
          marginRight: "30px",
        }}
      >
        <StyledDataGrid
          disableRowSelectionOnClick
          hideFooter
          localeText={{ toolbarDensity: "Layout Density" }}
          getRowId={(row) => row._id}
          rows={rows}
          columns={columns}
          rowHeight={50}
          density="comfortable"
          slots={{
            columnSortedAscendingIcon: KeyboardArrowUp,
            columnSortedDescendingIcon: KeyboardArrowDown,
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
          }
          getRowHeight={({ id, densityFactor }: GridRowHeightParams) => {
            return 52 * densityFactor;
          }}
          // initialState={{
          //   pinnedColumns: {
          //     left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "first_name"],
          //   },
          // }}
        />
      </Box>

      <Dialog
        open={deleteOpen}
        onClose={deleteCloseHandler}
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "50vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to remove the permission for this group
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={deleteCloseHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "300px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDeleteHandler}
              loadingPosition="end"
              // loading={loading}
              // disabled={loading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px",
                  },
                },
              }}
            >
              Yes, I want to remove
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GroupTable;
const statusText = [
  {
    text: "Admin",
    subText: "Full access to everything",
  },
  {
    text: "Reviewer",
    subText: "Partial access, no edit & deletion rights ",
  },
  // {
  //   text: "Member",
  //   subText: "Viewing and commenting rights",
  // },
];
