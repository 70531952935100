import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

interface aiScoreGenerateApplicationStatusProps {
  job_id: string | null;
  application_ids: string[];
}

export const aiScoreGenerateApplicationStatus = createAsyncThunk(
  "aiScoreGenerateApplicationStatus",
  async (reqData: aiScoreGenerateApplicationStatusProps) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/status`,
      reqData
    );
    return data;
  }
);

const aiScoreGenerateApplicationStatusSlice = createSlice({
  name: "aiScoreGenerateApplicationStatus",
  initialState: {
    loading: false,
    aiScoreGenerateApplicationStatusData: null as any,
    error: false,
    success: false
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.aiScoreGenerateApplicationStatusData = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      aiScoreGenerateApplicationStatus.pending,
      (state, action) => {
        state.loading = true;
        state.success = false;
      }
    );
    builder.addCase(
      aiScoreGenerateApplicationStatus.fulfilled,
      (state, action) => {
        state.loading = false;
        state.success = true;
        state.aiScoreGenerateApplicationStatusData = action.payload;
      }
    );
    builder.addCase(
      aiScoreGenerateApplicationStatus.rejected,
      (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      }
    );
  }
});

export const { reset } = aiScoreGenerateApplicationStatusSlice.actions;
export default aiScoreGenerateApplicationStatusSlice.reducer;
