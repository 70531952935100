import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getTopPicks = createAsyncThunk("getTopPicks", async (jobId: string) => {
  const { data } = await axios.get(
    `${
      GATEWAY_BASE + JOBS_CREATE_BASE
    }/api/v1/dashboard/top_picks/${jobId}?skip=0&limit=5`
  );
  return data;
});

const getTopPicksSlice = createSlice({
  name: "getTopPicks",
  initialState: {
    loading: false,
    topPickList: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopPicks.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTopPicks.fulfilled, (state, action) => {
      state.loading = false;
      state.topPickList = action.payload;
    });
    builder.addCase(getTopPicks.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getTopPicksSlice.reducer;
