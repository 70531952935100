import { Icon } from "@iconify/react";
import { Button, Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import EcnInput from "../../../../StyledComponents/EcnInput";
import ReactQuill from "react-quill";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { scoringNotes } from "../../../../../redux/slices/CandidatesSlices/scoringNotesSlice";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const styles = makeStyles((theme) => ({
  wrapper: {
    height: "88vh",
    gap: "23px",
  },
  backBtn: {
    width: "fit-content",
    fontSize: "18px",
    fontWeight: "600",
    marginLeft: "-10px",
  },
  card: {
    padding: "30px",
    height: "82.5%",
    paddingTop: "20px",
  },

  detailcard: {
    paddingTop: "20px",
    height: "82.5%",
  },
  heading: {},
  row: {
    display: "flex",
    gap: "20px",
    "& > *": {
      flex: "1 1 45%",
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: "25px",
  },
  fieldnote: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: "0px",
    height: "85%",
  },
  label: {
    fontSize: "14px",
    color: "#212121",
  },

  cancelBtn: {
    padding: "5px 18px",
    minHeight: "44px",
    minWidth: "126px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    color: "#212121",
    fontSize: "16px",
    fontWeight: "500",
  },
  saveBtn: {
    padding: "5px 14px",
    minHeight: "44px",
    minWidth: "164px",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "500",
  },
  loadingText: {
    color: "#212121",
    fontSize: "25px",
    fontWeight: "600",
    textAlign: "center",
  },
  successImg: {
    height: "350px",
    width: "350px",
    display: "block",
    margin: "18% auto 0 auto",
  },
  errorHead: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "600",
    textAlign: "center",
  },
  errorSub: {
    color: "#212121",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: "center",
    marginTop: "2px",
  },
  errorBtn: {
    marginTop: "25px",
    borderRadius: "7px",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    padding: "10px 18px",
    color: "#212121",
  },
  title: {
    border: "1px solid rgba(33, 33, 33, 0.20)",
    borderRadius: "8px",
    padding: "10px 17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
  },
  name: {
    fontSize: "14px",
    fontWeight: "600",
    color: "rgba(33, 33, 33, 0.7)",
  },
  labels: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  show: {
    fontSize: "14px",
    fontWeight: "400",
    color: "rgba(0, 52, 187, 1)",
    textDecoration: "underline",
    cursor: "pointer",
    padding: "5px 10px",
    "&:hover": {
      background: "rgba(0, 52, 187, 0.1)",
      borderRadius: "6px",
    },
  },
  scoreCard: {
    width: "350px",
    padding: "20px",
    gap: "30px",
    height: "fit-content",
    border: "1px solid rgba(33, 33, 33, 0.20)",
    borderRadius: "8px",
    marginTop: "5px",
  },
  notes: {
    display: "flex",
    justifyContent: "space-between",
    border: " 1px solid rgba(33, 33, 33, 0.20)",
    padding: "20px",
    borderRadius: "8px",
  },
  showPara: {
    display: "flex",
    justifyContent: "space-between",

    fontSize: "14px",
    fontWeight: "500",
    color: "rgba(33, 33, 33, 1)",
    marginBottom: "14px",
  },
  avg: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgba(0, 52, 187, 0.05)",
    padding: "7px 15px",
    borderRadius: "7px",
    fontSize: "14px",
    fontWeight: "500",
  },
  out: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#BA0000",
    padding: "3px 15px",
    background: "rgba(186, 0, 0, 0.05)",
    width: "fit-content",
    borderRadius: "11px",
  },
}));

const AddNotesForUser: React.FC<{
  score: any;
  setOpen: any;
  open: any;
  editNotesData: any;
  setEditNotesData: any;
}> = ({ score, setOpen, open, editNotesData, setEditNotesData }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const application_id = searchParams.get("id");
  const job_id = searchParams.get("application_id");

  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const { talentScore } = useAppSelector((state: any) => state.talentScoreHome);
  const { notesData } = useAppSelector((state: any) => state.scoringNotes);

  const { candidatesDetailsData } = useAppSelector(
    (state: any) => state.candidateDetails
  );

  const [notes, setNotes] = useState("");
  const [showScore, setShowScore] = useState(false);
  const [newScore, setNewScore] = useState(false);
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (notesData && click) {
      enqueueSnackbar("Note has been added successfully", {
        variant: "success",
      });
      setOpen(false);
      setEditNotesData();
      setNewScore(false);
      setShowScore(false);
    }
  }, [notesData]);

  useEffect(() => {
    if (talentScore) {
      setNotes(talentScore?.my_talent_score_note?.body);
    }
  }, [talentScore]);

  const closedHandler = () => {
    setOpen(false);
    setEditNotesData();
    setNewScore(false);
    setShowScore(false);
  };

  const noteChangeHandler = (e: any) => {
    // Function to strip HTML tags
    const stripHTML = (html: string) => {
      const tmp = document.createElement("div");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    };

    // Get the stripped content
    const strippedContent = stripHTML(e);

    // Check the length of the original content and update notes accordingly
    if (strippedContent.length <= 1000) {
      if (strippedContent.trim() === "") {
        setNotes("");
      } else {
        setNotes(e);
      }
    }
  };

  const createHandler = () => {
    setClick(true);
    dispatch(
      scoringNotes({
        id: userInfo?.id,
        job_id: job_id,
        application_id: application_id,
        title: `${userInfo?.first_name +
          " " +
          "score" +
          " for " +
          candidatesDetailsData?.created_by?.first_name +
          "" +
          ":" +
          score?.avg_score
          }`,
        body: notes,
        user_name: userInfo?.first_name + " " + userInfo?.last_name,
      })
    );
  };

  const newScoreHandler = () => {
    setNewScore(true);
  };



  return (
    <div className="add-notes-for user">
      <Drawer
        anchor="right"
        open={open}
        onClose={closedHandler}
        className="notes-for-user"
      >
        <div className="top">
          <IconButton className="icons" onClick={closedHandler}>
            <Icon icon="material-symbols:arrow-back" className="icon" />
          </IconButton>
        </div>
        {editNotesData?.recruiter_id != userInfo?.id &&
          editNotesData?.recruiter_id != "000000000000000000000000" ? (
          <div className={classes.card}>
            <div className={classes.field}>
              <div className={classes.labels}>
                <label htmlFor="" className={classes.label}>
                  Title
                </label>

                <p
                  className={classes.show}
                  onClick={() => setShowScore(!showScore)}
                >
                  {showScore ? "Hide detailed score" : "Show detailed score"}
                </p>
              </div>
              <div className={classes?.title}>
                {editNotesData?.status === "outdated" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <p className={classes?.name}>{editNotesData?.title}</p>
                    <p className={classes.out}>Outdated</p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <p className={classes?.name}>{editNotesData?.title}</p>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.fieldnote}>
              <label htmlFor="" className={classes.label}>
                Note
              </label>
              <div className={classes.notes}>
                <div
                  className={
                    showScore ? "react-tool" : "react-tool large-width"
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: editNotesData?.body }}
                  />
                </div>
                {!showScore && (
                  <div className={classes?.scoreCard}>
                    {score?.job_score_parameters?.map((item: any) => (
                      <p className={classes.showPara}>
                        {item?.name} <span>{item?.value}</span>
                      </p>
                    ))}

                    <p className={classes.avg}>
                      Total{" "}
                      <span style={{ fontWeight: "600", color: "#0034BB" }}>
                        {score?.avg_score}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.card}>
              <div className={classes.field}>
                <div className={classes.labels}>
                  <label htmlFor="" className={classes.label}>
                    Title
                  </label>
                  {(newScore ||
                    talentScore?.my_talent_score_note?.status === "valid" ||
                    talentScore?.my_talent_score_note?.status === "") && (
                      <p
                        className={classes.show}
                        onClick={() => setShowScore(!showScore)}
                      >
                        {showScore
                          ? "Show detailed score"
                          : "Hide detailed score"}
                      </p>
                    )}
                </div>
                <div className={classes?.title}>
                  {!newScore &&
                    talentScore?.my_talent_score_note?.status === "outdated" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <p
                        className={classes?.name}
                        style={{ textTransform: "capitalize" }}
                      >
                        {editNotesData?.title?.toLowerCase()}
                      </p>
                      <p className={classes.out}>Outdated</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <p className={classes?.name}>
                        My Score : {score?.avg_score}
                      </p>
                    </div>
                  )}
                  {!newScore &&
                    talentScore?.my_talent_score_note?.status ===
                    "outdated" && (
                      <h1
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#212121",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={newScoreHandler}
                      >
                        <Icon icon="lucide:refresh-ccw" /> Fetch New Score
                      </h1>
                    )}
                </div>
              </div>
              <div className={classes.fieldnote}>
                <label htmlFor="" className={classes.label}>
                  Note
                </label>
                <div className={classes.notes}>
                  <div
                    className={
                      showScore ? "react-tool" : "react-tool large-width"
                    }
                  >
                    <ReactQuill
                      onChange={(e: any) => noteChangeHandler(e)}
                      value={notes}
                      theme="snow"
                      bounds={".app"}
                      className="quill-candidatesdetail"
                      modules={modules}
                      // ref={selectRef}
                      formats={formats}
                    />
                  </div>
                  {!showScore && (
                    <div className={classes?.scoreCard}>
                      {score?.job_score_parameters?.map((item: any) => (
                        <p className={classes.showPara}>
                          {item?.name} <span>{item?.value}</span>
                        </p>
                      ))}

                      <p className={classes.avg}>
                        Total{" "}
                        <span style={{ fontWeight: "600", color: "#0034BB" }}>
                          {score?.avg_score}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="notes-bottom">
              <Button className="dis" onClick={() => setOpen(false)}>
                Discard
              </Button>
              <Button
                className="cre"
                onClick={createHandler}
                disabled={notes === ""}
              >
                Create
              </Button>
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default AddNotesForUser;
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
