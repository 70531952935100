import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE || "";

export const jobAnalyticsMiscellaneous = createAsyncThunk(
  "jobAnalyticsMiscellaneous",
  async (analyticsData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ANALYTICS_BASE}/analyticsMiscellaneous`,
      analyticsData
    );
    return data;
  }
);

const jobAnalyticsMiscellaneousSlice = createSlice({
  name: "jobAnalyticsMiscellaneous",
  initialState: {
    loading: false,
    jobAnalyticsData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(jobAnalyticsMiscellaneous.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(jobAnalyticsMiscellaneous.fulfilled, (state, action) => {
      state.loading = false;
      state.jobAnalyticsData = action.payload;
    });
    builder.addCase(jobAnalyticsMiscellaneous.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default jobAnalyticsMiscellaneousSlice.reducer;
