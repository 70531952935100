import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getTemplate = createAsyncThunk("getTemplate", async () => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/templates`
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export type MailTemplate = {
  id: string;
  org_id: string;
  job_acknowlegement_template: boolean;
  title: string;
  subject: string;
  body: string;
  attachments: any[];
  createdAt: number;
  createdBy: string;
  updatedAt: number;
  updatedBy: string;
};

type State = {
  loading: boolean;
  error: any;
  templateData: Array<MailTemplate> | null;
};
const initialState: State = {
  loading: false,
  templateData: null,
  error: null as any
};

const getTemplateSlice = createSlice({
  name: "getTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTemplate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.templateData = action.payload;
    });
    builder.addCase(getTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  }
});

export default getTemplateSlice.reducer;
