import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const deleteNotes = createAsyncThunk(
  "deleteNotes",
  async (id: any) => {
    const { data } = await axios.delete(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/notes_delete/${id}`,     
    );
    return data;
  }
);

const deleteNotesSlice = createSlice({
  name: "deleteNotes",
  initialState: {
    loading: false,
    deleteNotesData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteNotes.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteNotesData = action.payload;
    });
    builder.addCase(deleteNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default deleteNotesSlice.reducer;
