import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

import EditIcon from "@mui/icons-material/Edit";

import RatingTabV2 from "./ReatingTabV2";
import { scoreUpdate } from "../../../../../redux/slices/CandidatesSlices/scoreUpdateSlice";

import { trackMixpanelEvents } from "../../../../../helpers/Analytics/mixpanelProxyApi";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Button, Tab, Tabs } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper/modules";
import AddNotesForUser from "./AddNotesForUser";
import moment from "moment";

const Rating: React.FC<{
  data: any;
  setScoreModifier?: any;
}> = ({ data, setScoreModifier }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const job_id = searchParams.get("application_id");
  const view = searchParams.get("view");

  const { userInfo } = useAppSelector((state: any) => state.userDetails);
  const { talentScore } = useAppSelector((state: any) => state.talentScoreHome);

  const [score, setScore] = useState<any>();
  const [tab, setTab] = useState(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [editNotesData, setEditNotesData] = useState<any>();

  useEffect(() => {
    if (tab === 0 && talentScore) {
      setScore(talentScore?.my_score);
    }

    if (tab === 1 && talentScore) {
      setScore(talentScore?.recent_scores_data[0]);
    }

    if (tab === 2 && talentScore) {
      setScore(talentScore?.recent_scores_data[0]);
    }
  }, [talentScore, tab]);

  const selectUserHandler = (index: any) => {
    setScore(talentScore?.recent_scores_data[index]);
  };

  const scoreHandler = (n: number, newValue: number) => {
    let scores = JSON.parse(JSON.stringify(score));

    if (scores.job_score_parameters === null) {
      scores.job_score_parameters = JSON.parse(
        JSON.stringify(talentScore?.default_job_score_params)
      ).map((param: any) => ({ ...param, value: 0 }));
    }

    const updatedParameter = {
      ...scores.job_score_parameters[n],
      value: newValue,
    };

    const updatedParameters = [...scores.job_score_parameters];
    updatedParameters[n] = updatedParameter;

    scores.job_score_parameters = updatedParameters;

    setScore(scores);
    dispatch(
      scoreUpdate({
        id: id,
        job_id: job_id,
        job_score_parameters: scores.job_score_parameters,
      })
    );
  };

  const totalScore = () => {
    let scores = score?.job_score_parameters?.map((score: any) => ({
      ...score,
    }));
    let total = 0;

    let totalWeight = scores?.reduce(
      (sum: any, score: any) => sum + score.weight,
      0
    );

    if (totalWeight !== 100 && totalWeight !== 0) {
      scores?.forEach((score: any) => {
        score.weight = (score.weight / totalWeight) * 100;
      });
    }

    scores?.forEach(
      (score: any) => (total += score.value * (score.weight / 100))
    );

    return Math.round(total * 100) / 100;
  };

  const editHandler = () => {
    setScoreModifier(true);
    trackMixpanelEvents({
      eventName:
        "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_EDIT_SCORE_PARAMS_REDIRECT",
      url: window.location.href,
      distinctId: userInfo ? userInfo.email : "",
      properties: {},
    });
  };

  const expandHandler = () => {
    navigate(`?id=${id}&application_id=${job_id}&view=expand`);
  };

  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  const openNotesHandler = (item: any) => {
    setOpen(true);
    setEditNotesData(item);
  };

  const notesHandler = () => {
    setOpen(true);
    setEditNotesData(talentScore?.my_talent_score_note);
  };

  console.log("score score", editNotesData);
  return (
    <div
      className={
        view === "expand"
          ? "score-card-rating score-card-rating-expend"
          : "score-card-rating"
      }
    >
      <div className="tabs">
        <div
          className={tab === 0 ? "tab active" : "tab"}
          onClick={() => setTab(0)}
        >
          {talentScore?.my_score?.scored_on != 0 && (
            <p className="last">
              Last scored on{" "}
              {moment.unix(talentScore?.my_score?.scored_on).format("Do MMMM")}
            </p>
          )}
          <div className="my-score">
            <h3>My Score</h3>
            <h3>{talentScore?.my_score?.avg_score}</h3>
          </div>
        </div>
        {/* {talentScore?.recent_scores_data?.length > 1 && (
          <div
            className={tab === 1 ? "tab active" : "tab"}
            onClick={() => setTab(1)}
          >
            <div className="tab-top">
              <p className="last">Most Recent</p>
              <p className="last">{talentScore?.recent_scores_data[0]?.role}</p>
            </div>
            <div className="my-score">
              <h3>{talentScore?.recent_scores_data[0]?.name}</h3>
              <h3>{talentScore?.recent_scores_data[0]?.avg_score}</h3>
            </div>
          </div>
        )} */}
        <div
          className={tab === 2 ? "tab active" : "tab"}
          onClick={() => setTab(2)}
        >
          {talentScore?.total_users != 0 && (
            <p className="last">{talentScore?.total_users} users</p>
          )}
          <div className="my-score">
            <h3>Average</h3>
            <h3>
              {talentScore?.avg_score === 0 ? "-" : talentScore?.avg_score}
            </h3>
          </div>
        </div>
      </div>
      <div className="rating-tab">
        {(tab === 2 && talentScore?.recent_scores_data?.length) === 0 ? (
          <div style={{ textAlign: "center", margin: "80px 0px" }}>
            <img
              src="https://s1.ecnstatic.com/ecn/images/score-fallback.png"
              alt=""
            />
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                marginLeft: "50px",
                marginTop: "15px",
              }}
            >
              No other user has rated this candidate yet!
            </p>
          </div>
        ) : (
          <div className="scores">
            {tab === 2 && (
              <div className="users-tab">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  grabCursor={true}
                  navigation={true}
                  keyboard={true}
                  modules={[Navigation, Keyboard, Pagination]}
                  breakpoints={{
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 1.5,
                    },
                    1440: {
                      slidesPerView: 2.1,
                    },
                    2260: {
                      slidesPerView: 3.2,
                    },
                  }}
                  className="container"
                >
                  {talentScore?.recent_scores_data?.map(
                    (item: any, index: any) => (
                      <SwiperSlide>
                        <div
                          className={
                            item?.recruiter_id === score?.recruiter_id
                              ? "user active "
                              : "user"
                          }
                          onClick={() => selectUserHandler(index)}
                        >
                          <div className="left">
                            {" "}
                            <Avatar
                              src={item?.display_picture}
                              className="avatar"
                            />
                            <h4>{item?.name}</h4>
                          </div>
                          <h4>{item?.avg_score}</h4>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            )}

            {view != "expand" && (
              <div
                className={
                  score?.avg_score === 0 || tab != 0
                    ? "top-bar right-align "
                    : "top-bar"
                }
              >
                {score?.avg_score != 0 && tab === 0 && (
                  <h2 onClick={notesHandler}>Add Notes</h2>
                )}
                <div className="edit" onClick={expandHandler}>
                  <Icon icon="lucide:expand" width={18} height={18} />
                  <h1>Expand</h1>
                </div>
              </div>
            )}

            {view === "expand" && score?.avg_score != 0 && tab === 0 && (
              <Button className="Add-notes" onClick={notesHandler}>
                Add Notes
              </Button>
            )}
            <div className="table-title">
              <h2>Parameters</h2>
              <h2>Weightage</h2>
            </div>
            {score?.job_score_parameters === null
              ? talentScore?.default_job_score_params?.map(
                  (record: any, index: number) => (
                    <RatingTabV2
                      key={index}
                      num={index}
                      data={record}
                      score={scoreHandler}
                      type="default"
                      userData={score}
                    />
                  )
                )
              : score?.job_score_parameters?.map(
                  (record: any, index: number) => (
                    <RatingTabV2
                      key={index}
                      num={index}
                      data={record}
                      score={scoreHandler}
                      userData={score}
                      type="value"
                    />
                  )
                )}
          </div>
        )}

        {score?.status != "valid" && userInfo?.id === score?.recruiter_id && (
          <div className="not-valid">
            <div className="container">
              <p>
                It seems that the parameters have been changed and it affected
                the total score. Please re-rate to get your talent score back.
              </p>
            </div>
          </div>
        )}

        {score?.status != "previous" && score?.status != undefined && (
          <div className="total-card">
            {score?.previous_avg_score != 0 &&
              userInfo?.id === score?.recruiter_id && (
                <div className="total">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#212121",
                    }}
                  >
                    Previous score
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#212121",
                    }}
                  >
                    {score?.previous_avg_score}
                  </p>
                </div>
              )}

            <div className="total">
              <p>Total Score</p>
              <p>{score?.avg_score}</p>
            </div>
          </div>
        )}
      </div>

      <div className="user-notes">
        {talentScore?.talent_score_notes?.length != 0 ||
          (talentScore?.my_talent_score_note?.body != "" && <h1>Notes</h1>)}
        <div className="nots-container">
          {talentScore?.my_talent_score_note?.body != "" && (
            <div
              className="notes-card"
              onClick={() =>
                openNotesHandler(talentScore?.my_talent_score_note)
              }
            >
              <div className="card-top">
                <h2>
                  {talentScore?.my_talent_score_note?.title?.toLowerCase()}
                </h2>
                {talentScore?.my_talent_score_note?.status != "valid" && (
                  <p>Outdated</p>
                )}
              </div>
              <div className="notes-body">
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: talentScore?.my_talent_score_note?.body,
                    }}
                  />
                </p>
              </div>
              <div className="notes-bottom">
                <div className="left">
                  <p>Scored by</p>
                  <span>
                    {moment
                      .unix(talentScore?.my_talent_score_note?.created_at)
                      .format("DD MMM")}
                  </span>
                </div>
                <div className="right">
                  <h3>{userInfo?.first_name + " " + userInfo?.last_name}</h3>
                  <span>
                    {moment
                      .unix(talentScore?.my_talent_score_note?.created_at)
                      .format("hh:mm A")}
                  </span>
                </div>
              </div>
            </div>
          )}

          {talentScore?.talent_score_notes?.map((item: any) => (
            <div className="notes-card" onClick={() => openNotesHandler(item)}>
              <div className="card-top">
                <h2>{item.title?.toLowerCase()}</h2>
                {item?.status != "valid" && <p>Outdated</p>}
              </div>
              <div className="notes-body">
                <p>
                  <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                </p>
              </div>
              <div className="notes-bottom">
                <div className="left">
                  <p>Scored by</p>
                  <span>{moment.unix(item?.created_at).format("DD MMM")}</span>
                </div>
                <div className="right">
                  <h3>{item?.name}</h3>
                  <span>{moment.unix(item?.created_at).format("hh:mm A")}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AddNotesForUser
        score={score}
        setOpen={setOpen}
        open={open}
        editNotesData={editNotesData}
        setEditNotesData={setEditNotesData}
      />
    </div>
  );
};

export default Rating;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "8px 16px",
  margin: `0 0 10px 0`,
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "250px",
  width: "100%",
  // columnGap: "40px",

  // change background colour if dragging
  background: isDragging ? "lightgray" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
