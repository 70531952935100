import React, { useCallback, useEffect, useMemo, useState, FC } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@mui/icons-material";
import { Icon } from "@iconify/react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { logoUpload } from "../../../redux/slices/JobSlices/logoUploadSlice";
import { LoadingButton } from "@mui/lab";
import EcnButton from "../../StyledComponents/EcnButton";
import { Button, IconButton } from "@mui/material";

const baseStyles = {
  display: "flex",
  width: "95%",
  alignItems: "center",
  padding: "20px",

  cursor: "pointer",
  color: "#ffff",
  transition: "border .3s ease-in-out",
  margin: "auto",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DargeAndUploadFile: FC<{
  setOpenAttach: any;
  setSmallFiles: any;
  smallFiles: any;
  setAllFiles: any;
  allFiles: any;
  setFileList: any;
}> = ({
  setOpenAttach,
  setSmallFiles,
  smallFiles,
  setAllFiles,
  allFiles,
  setFileList,
}) => {
  const [convertedFileData, setConvertedFileData] = useState<any>([]);
  const [filteredFileData, setFilteredFileData] = useState<any>([]);
  const [largeFiles, setLargeFiles] = useState<any>([]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const readFileAndConvertToBase64 = (file: any) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            if (typeof reader.result === "string") {
              const base64String = reader.result.split(",")[1]; // Splitting to get base64 part
              resolve(base64String);
            } else {
              reject(new Error("Failed to read file as a data URL."));
            }
          };

          reader.onerror = () => {
            reject(reader.error);
          };

          reader.readAsDataURL(file);
        });
      };

      // Convert and filter files
      const convertAndFilterFiles = async (files: File[]) => {
        // Filter out duplicate files by na
        const uniqueFiles: File[] = [];
        const uniqueFileData: Set<string> = new Set();

        // Check if the file is not already present in smallFiles or largeFiles
        const isUniqueFile = (fileName: string) => {
          return (
            !smallFiles.some(
              (file: any) => file.attachmentFileName === fileName
            ) &&
            !largeFiles.some(
              (file: any) => file.attachmentFileName === fileName
            )
          );
        };

        for (const newFile of files) {
          // Check if the file is unique based on name and size
          const fileData = `${newFile.name}_${newFile.size}`;
          if (!uniqueFileData.has(fileData) && isUniqueFile(newFile.name)) {
            uniqueFiles.push(newFile);
            uniqueFileData.add(fileData);
          }
        }

        // Calculate total size of uniqueFiles
        const totalSize = uniqueFiles.reduce((acc, file) => acc + file.size, 0);

        if (totalSize <= 20 * 1024 * 1024) {
          // Total size of uniqueFiles is less than or equal to 25 MB
          // Proceed to convert and filter duplicates

          // Convert and add new files
          const convertedFiles = await Promise.all(
            uniqueFiles.map(async (file: File) => {
              const base64Data = await readFileAndConvertToBase64(file);
              return {
                attachmentFileName: file.name,
                attachmentType: file.type,
                attachmentData: base64Data,
              };
            })
          );

          // Filter out duplicates from convertedFiles
          const uniqueConvertedFiles = convertedFiles.filter(
            (file, index, self) =>
              index ===
              self.findIndex(
                (t) => t.attachmentFileName === file.attachmentFileName
              )
          );

          // Calculate total size of existing smallFiles
          const existingSmallFilesSize = smallFiles.reduce(
            (acc: any, file: any) => acc + file.attachmentData.length,
            0
          );

          // Calculate total size of converted files
          const totalSmallFilesSize = existingSmallFilesSize + totalSize;

          if (totalSmallFilesSize <= 20 * 1024 * 1024) {
            // Total size of converted files and existing smallFiles is less than or equal to 25 MB
            // Store unique converted files in smallFiles
            setSmallFiles([...smallFiles, ...uniqueConvertedFiles]);
          } else {
            // Total size of converted files and existing smallFiles exceeds 25 MB
            // Store unique converted files in largeFiles
            setLargeFiles([...largeFiles, ...uniqueConvertedFiles]);
          }
        } else {
          // Total size of uniqueFiles exceeds 25 MB
          // Store all unique files in largeFiles
          setLargeFiles([...largeFiles, ...uniqueFiles]);
        }
      };
    
      // Call the convertAndFilterFiles function with accepted files
      convertAndFilterFiles(acceptedFiles);
    },
    [smallFiles, largeFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 5,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
      "application/msword": [".doc", ".docx"],
      "application/vnd.ms-excel": [".xls", ".xlsx"],
      "text/csv": [".csv"],
      "image/*": [".jpg", ".jpeg", ".png", ".gif"], // Image files
      "text/plain": [".txt"], // Text files
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"], // PowerPoint files
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyles,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const removeHandler = (indexToRemove: number) => {
    setAllFiles((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
    setSmallFiles((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const nextHandler = () => {
    setFileList(smallFiles);
    setOpenAttach(false);
  };



  return (
    <div className="upload-file">
      <div className="drop-zone">
        <div className="container">
          {smallFiles?.length != 0 || largeFiles?.length != 0 ? (
            <div className="filename">
              <div className="filename-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="65"
                  viewBox="0 0 65 65"
                  fill="none"
                  className="icon"
                >
                  <path
                    d="M43.332 5.41406V18.9557H56.8737"
                    stroke="#212121"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M56.8763 16.2474V33.8516C56.8763 36.0182 54.9805 37.9141 52.8138 37.9141H33.8555C31.6888 37.9141 29.793 36.0182 29.793 33.8516V9.47656C29.793 7.3099 31.6888 5.41406 33.8555 5.41406H46.043L56.8763 16.2474Z"
                    stroke="#212121"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.957 21.6641V45.4974C18.957 46.3099 19.4987 47.1224 20.0404 47.6641C20.582 48.2057 21.3945 48.7474 22.207 48.7474H40.6237"
                    stroke="#212121"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.125 32.5V56.3333C8.125 57.1458 8.66667 57.9583 9.20833 58.5C9.75 59.0417 10.5625 59.5833 11.375 59.5833H29.7917"
                    stroke="#212121"
                    stroke-opacity="0.6"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>pdf, ppt, word, excel, csv, jpg, png, txt, file supported</p>
              </div>
              <div className="all-files-preview">
                {smallFiles?.map((file: any, index: any) => (
                  <div className="file-name">
                    <div className="file-name-left">
                      {file.attachmentType ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                        <IconButton>
                          <Icon icon="vscode-icons:file-type-word" />
                        </IconButton>
                      ) : file.attachmentType === "application/pdf" ? (
                        <IconButton>
                          <Icon icon="vscode-icons:file-type-pdf2" />
                        </IconButton>
                      ) : file.attachmentType ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                        <IconButton>
                          <Icon icon="vscode-icons:file-type-excel" />
                        </IconButton>
                      ) : file.attachmentType === "image/png" ||
                        file.attachmentType === "image/jpeg" ? (
                        <IconButton>
                          <Icon icon="lucide:image" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M10.0013 1.33203H4.0013C3.64768 1.33203 3.30854 1.47251 3.05849 1.72256C2.80844 1.9726 2.66797 2.31174 2.66797 2.66536V13.332C2.66797 13.6857 2.80844 14.0248 3.05849 14.2748C3.30854 14.5249 3.64768 14.6654 4.0013 14.6654H12.0013C12.3549 14.6654 12.6941 14.5249 12.9441 14.2748C13.1942 14.0248 13.3346 13.6857 13.3346 13.332V4.66536L10.0013 1.33203Z"
                              stroke="#0034BB"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.33594 1.33203V3.9987C9.33594 4.35232 9.47641 4.69146 9.72646 4.94151C9.97651 5.19156 10.3156 5.33203 10.6693 5.33203H13.3359"
                              stroke="#0034BB"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </IconButton>
                      )}

                      <p>{file?.attachmentFileName}</p>
                    </div>
                    <IconButton onClick={() => removeHandler(index)}>
                      <Icon icon="ic:baseline-close" />
                    </IconButton>
                  </div>
                ))}
                <div className="error">
                  {largeFiles?.map((item: any, index: any) => (
                    <p className="item">{item?.attachmentFileName},</p>
                  ))}
                  {largeFiles?.length != 0 && (
                    <p>File limit has exceed.These files wont get uploaded</p>
                  )}
                </div>
              </div>

              <div className="buttons">
                <div {...getRootProps({ style })} className="upload">
                  <input {...getInputProps()} />
                  <div className="button">
                    <EcnButton startIcon={<Icon icon="lucide:plus" />}>
                      Select more files
                    </EcnButton>
                  </div>
                </div>
                <Button className="upload-file" onClick={nextHandler}>
                  Next
                </Button>
              </div>
            </div>
          ) : (
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />

              <div className={"centeral-part"}>
                <div className="button">
                  <EcnButton>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="65"
                      height="65"
                      viewBox="0 0 65 65"
                      fill="none"
                    >
                      <path
                        d="M43.332 5.41406V18.9557H56.8737"
                        stroke="#212121"
                        stroke-opacity="0.6"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M56.8763 16.2474V33.8516C56.8763 36.0182 54.9805 37.9141 52.8138 37.9141H33.8555C31.6888 37.9141 29.793 36.0182 29.793 33.8516V9.47656C29.793 7.3099 31.6888 5.41406 33.8555 5.41406H46.043L56.8763 16.2474Z"
                        stroke="#212121"
                        stroke-opacity="0.6"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.957 21.6641V45.4974C18.957 46.3099 19.4987 47.1224 20.0404 47.6641C20.582 48.2057 21.3945 48.7474 22.207 48.7474H40.6237"
                        stroke="#212121"
                        stroke-opacity="0.6"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.125 32.5V56.3333C8.125 57.1458 8.66667 57.9583 9.20833 58.5C9.75 59.0417 10.5625 59.5833 11.375 59.5833H29.7917"
                        stroke="#212121"
                        stroke-opacity="0.6"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </EcnButton>
                </div>

                <div className="text">
                  <p className="drag-file">Drag file here</p>
                  <span className="or">Or</span>
                  <Button className="sub-text">
                    {" "}
                    <Icon icon="lucide:monitor" /> Select from device
                  </Button>

                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      width: "250px",
                      color: "rgba(33, 33, 33, 0.6)",
                    }}
                  >
                    pdf, ppt, word, excel, csv, jpg, png, txt, file supported
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DargeAndUploadFile;
