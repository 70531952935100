import { Icon } from "@iconify/react";
import { Avatar } from "@mui/material";
import React from "react";
import {
  correctUrl,
  isIconString,
} from "../../helpers/Validations/BasicValidations";
import { useNavigate } from "react-router-dom";

interface SearchGroupProps {
  data: any;
  displayName: string;
}

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const renderAvatarContent = (item: any) => {
  // Check if display_picture is provided
  if (item?.display_picture) {
    if (isIconString(item.display_picture)) {
      return (
        <Avatar
          sx={{ background: "rgba(0, 52, 187, 0.13)", color: "#0034BB" }}
          className="avatar"
        >
          <Icon icon={item.display_picture} />
        </Avatar>
      );
    } else {
      return <Avatar src={item.display_picture} alt="Avatar" className="avatar" />;
    }
  } else if (item?.email) {
    // No display_picture, but item has an email. Render initials.
    const initials = item.first_name?.[0] + item.last_name?.[0];
    return (
      <Avatar
        sx={{ background: "rgba(0, 52, 187, 0.13)", color: "#0034BB" }}
        className="avatar"
      >
        {initials}
      </Avatar>
    );
  } else {
    // Fallback: Use the first character of the name if available
    return (
      <Avatar
        sx={{ background: "rgba(0, 52, 187, 0.13)", color: "#0034BB" }}
        className="avatar"
      >
        {item.name?.[0]}
      </Avatar>
    );
  }
};

const SearchPageGroup = ({ data, displayName }: SearchGroupProps) => {
  const navigate = useNavigate();

  const navigateHandler = (item: any) => {
    if (correctUrl.test(item.redirect_url)) {
      window.location.href = item.redirect_url;
    }
  };
  return (
    <div key={displayName} className="search-group">
      <div className="head">
        <h4 className="group-heading">{displayName.toLowerCase()}</h4>
        <p className="group-result-count">{data?.length} results</p>
      </div>
      <div className="search-results">
        {data.map((item: any) => (
          <div className="result" onClick={() => navigateHandler(item)}>
            {renderAvatarContent(item)}

            <div className="right">
              <p className="heading">
                {item.name || `${item.first_name} ${item.last_name}`}
              </p>
              <p className="sub">{item.email || item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchPageGroup;
