import { Translate } from "@mui/icons-material";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";
import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { Icon } from "@iconify/react";
import ToolTips from "./toolTips";
import Fallback from "../../Fallback";

const colors = [
  "#59A3F5",
  "#91DDFE",
  "#147CF7",
  "#2756CD",
  "#3D6FF2",
  "#3E85B8",
  "#0FA9FF",
  "#60A5FA",
  "#0E3FBF",
  "#ABE",
  "#7D97DB",
  "#88A9FF",
];

const GraduationYear: React.FC<{ active: any }> = ({ active }) => {
  let mapData: any = [];
  const [data, setData] = useState<any>();
  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

  const { analyticsState } = useAppSelector(
    (state: any) => state.analyticsData
  );

  useEffect(() => {
    if (!active) {
      setData(promoAnalytics);
    } else {
      setData(analyticsState);
    }
  }, [active,promoAnalytics]);

  function mapAry(val: any, name: any, index: any) {
    
    var obj: any = {};
    obj["id"] = `${name}`;
    obj["value"] = val;
    obj["label"] = `${name}`;
    obj["color"] = `${colors[index]}`;
    mapData.push(obj);
  }

  data?.graduation_year?.results?.map((item: any, index: any) =>
    mapAry(item.count, item._id === 0 ? "Other" : item._id, index)
  );

  return (
    <div className="job-function-year">
      <div className="chart-icon">
        <Icon icon="cil:bar-chart" className="icon" />
      </div>
      <h1>
        Graduation Year <ToolTips title={data?.graduation_year?.tooltip} />
      </h1>

      {data?.graduation_year?.results?.length ?? 0 ? (
        <>
          <div className="chart">
            <ResponsivePie
              data={mapData}
              margin={{ top: 0, left: 55, right: 55, bottom: 20 }}
              startAngle={-180}
              sortByValue={true}
              innerRadius={0.7}
              fit={false}
              colors={[
                "#59A3F5",
                "#91DDFE",
                "#147CF7",
                "#2756CD",
                "#3D6FF2",
                "#3E85B8",
                "#0FA9FF",
                "#60A5FA",
                "#0E3FBF",
                "#ABE",
                "#7D97DB",
                "#88A9FF",
              ]}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={0}
              arcLinkLabelsTextOffset={0}
              arcLinkLabelsTextColor="#ffffff"
              arcLinkLabelsOffset={-24}
              arcLinkLabelsDiagonalLength={0}
              arcLinkLabelsStraightLength={0}
              arcLinkLabelsThickness={0}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsTextColor="#404040"
              arcLabelsRadiusOffset={-0.5}
              arcLabelsSkipAngle={0}
              isInteractive={true}
              animate={false}
              legends={[]}
            />
          </div>
          <div className="wrapper-list">
            {mapData?.map((item: any) => (
              <div className="pie-legend-list">
                {" "}
                <div className="pie-legend-list-item">
                  <div
                    className="dots"
                    style={{ background: `${item?.color}` }}
                  ></div>
                  <p>{item?.label} </p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Fallback message="No graduation year data available yet" />
      )}
    </div>
  );
};

export default GraduationYear;
