import React from "react";
import ToolTips from "./toolTips";

import Fallback from "../../Fallback";

const University: React.FC<{ data: any }> = ({ data }) => {
  const universityData = data?.university?.results || [];

  // Calculate the total sum of counts
  const totalSum = universityData.reduce(
    (sum: any, item: any) => sum + (item.count || 0),
    0
  );

  return (
    <div className="university-card">
      <h1>
        Universities <ToolTips title={data?.university?.tooltip} />
      </h1>
      {universityData?.length != 0 ? (
        <div className="wrapper">
          <div className="university-card-left">
            {universityData.map((item: any, index: any) => {
              const distributionPercentage =
                (item.count / data?.total_reach_count) * 100;

              return (
                index < 7 && (
                  <div className="list" key={index}>
                    <p>{index < 9 ? `0${index + 1}` : index + 1}</p>
                    <p>{item?._id === "" ? "Other" : item?._id}</p>
                    <button>{distributionPercentage.toFixed(2)}%</button>
                  </div>
                )
              );
            })}
          </div>

          <div className="university-card-left university-card-right">
            {universityData.map((item: any, index: any) => {
              const distributionPercentage =
                (item.count / data?.total_reach_count) * 100;

              return (
                index > 6 && (
                  <div className="list" key={index}>
                    <p>{index < 9 ? `0${index + 1}` : index + 1}</p>
                    <p>{item?._id === "" ? "Other" : item?._id}</p>
                    <button>{distributionPercentage.toFixed(2)}%</button>
                  </div>
                )
              );
            })}
          </div>
        </div>
      ) : (
        <Fallback message="No universities analytics data available yet" />
      )}
    </div>
  );
};

export default University;
