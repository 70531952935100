import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import layoutTwo from "../components/layout/layoutTwo";
import EcnBreadCrumbs from "../components/StyledComponents/EcnBreadCrumbs";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getEventDetails } from "../redux/slices/EventSlices/getEventDetailsSlice";
import { Button } from "@mui/material";
import { Icon } from "@iconify/react";

const TrainingMaterials = () => {
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const { eventDetailsData } = useAppSelector((state) => state.getEventDetails);
  const navigate = useNavigate();

  useEffect(() => {
    if (eventId) {
      dispatch(getEventDetails({ eventId }));
    }
  }, [eventId]);

  return (
    <div className="training-materials-page">
      <EcnBreadCrumbs>
        <p onClick={() => navigate(`/events?tab=events`)}>Events</p>
        <p onClick={() => navigate(`/event/${eventId}/?tab=event-details`)}>
          {eventDetailsData?.name.slice(0, 25)}...
        </p>
        <p>Training Materials</p>
      </EcnBreadCrumbs>
      <div className="body">
        <h2 className="page-title">Training materials</h2>
        <div className="no-materials-found">
          <p className="text">No materials found!</p>
          <Button
            className="button"
            variant="contained"
            onClick={() => {
              navigate(`/event/${eventId}/training-materials/add`);
            }}
          >
            <Icon icon="lucide:plus" width={18} height={18} />
            <span>Add Training Material</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default layoutTwo(TrainingMaterials);
