import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getNotificationGroup = createAsyncThunk(
  "getNotificationGroup",
  async () => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + ADMIN_BASE}/getNotificationGroups`
    );
    return data;
  }
);

const getNotificationGroupSlice = createSlice({
  name: "getNotificationGroup",
  initialState: {
    loading: false,
    notificationGroup: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotificationGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.notificationGroup = action.payload;
    });
    builder.addCase(getNotificationGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getNotificationGroupSlice.reducer;
