import { Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAppSelector } from "../../../redux/hooks";

const TemplateLists: React.FC<{ template: any; setTemplate: any }> = ({
  template,
  setTemplate,
}) => {
  const { templateData } = useAppSelector((state: any) => state.templateList);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate((event.target as HTMLInputElement).value);
  };

  return (
    <div className="placeholder">
      <RadioGroup value={template} onChange={handleChange}>
        <div className={"card-wrapper"}>
          {templateData?.map((item: any) => (
            <div className={item?.id === template ? "card active" : "card"}>
              <div className="radio-name">
                <Radio color="info" className="radio-btn" value={item?.id} />
                <div className="text">
                  <h2>{item?.subject}</h2>
                  <Typography variant="h6" component="p">
                    <ReactMarkdown
                      className="markdown-data"
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    >
                      {item?.body}
                    </ReactMarkdown>
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default TemplateLists;
