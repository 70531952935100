import { Button, CircularProgress, Drawer, IconButton } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";

const ResumeDrawer = ({
  resume,
  setResume,
  openModal,
  setOpenModal
}: {
  resume: string;
  setResume: React.Dispatch<React.SetStateAction<string>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isResumeLoading, setIsResumeLoading] = useState(true);
  const [zoom, setZoom] = useState(1.6);
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const zoomPercentage = Math.round(zoom * 100);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const handleZoomIn = () => {
    if (zoomPercentage < 200) {
      setZoom((prevZoom) => prevZoom + 0.2); // Increase zoom by 20%
    }
  };

  const handleZoomOut = () => {
    if (zoomPercentage > 60) {
      setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.2)); // Decrease zoom by 20%, with minimum zoom of 20%
    }
  };

  const scrollToNextPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop + containerRef.current.clientHeight,
        behavior: "smooth"
      });
    }
  };
  const scrollToPreviousPage = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollTop - containerRef.current.clientHeight,
        behavior: "smooth"
      });
    }
  };

  const goToPreviousPage = () => {
    scrollToPreviousPage();
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const goToNextPage = () => {
    scrollToNextPage();
    if (pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={() => {
        setOpenModal(false);
        setResume("");
      }}
      // className={!loading ? "drawer-resume fixed-height" : "drawer-resume"}
      className={"drawer-resume"}
    >
      <div className="top">
        <Button
          className="icons"
          onClick={() => {
            setOpenModal(false);
            setResume("");
          }}
        >
          <Icon icon="material-symbols:arrow-back" className="icon" />
          <h1 className="drawer-title">Applicant’s resume</h1>
        </Button>

        <Button
          className="download"
          onClick={() => window.open(resume, "_blank")}
        >
          {" "}
          <Icon icon="lucide:arrow-down" className="icons" /> Download
        </Button>
      </div>
      {/* <div className="title-download">
          <h1 className="drawer-title">Applicant’s resume</h1>
        </div> */}
      <div className="pdf-container">
        <div className="pdf-top">
          <div className="zoom">
            <p>{zoomPercentage}%</p>
            <Icon
              icon="lucide:zoom-out"
              className="icons"
              onClick={handleZoomOut}
            />
            <Icon
              icon="lucide:zoom-in"
              className="icons"
              onClick={handleZoomIn}
            />
          </div>
        </div>

        {isResumeLoading && (
          <div className="loader">
            <CircularProgress sx={{ color: "#0034bb" }} />
          </div>
        )}

        <div className={"container"}>
          <div ref={containerRef} className="inner-container">
            <div
              style={{
                transformOrigin: "top center",
                transform: `scale(${zoom})`
              }}
            >
              <Document
                file={resume}
                onLoadSuccess={(props: any) => {
                  setNumPages(props.numPages);
                  setIsResumeLoading(false);
                  setPageNumber(1);
                }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={500}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                ))}
              </Document>
            </div>
          </div>
          <div className="page">
            <div className="page-number">
              <IconButton disabled={pageNumber === 1}>
                <Icon
                  icon="lucide:chevron-left"
                  className="icons"
                  onClick={goToPreviousPage}
                />
              </IconButton>
              <h1>
                {pageNumber} <span>/{numPages}</span>
              </h1>
              <IconButton disabled={pageNumber === numPages}>
                <Icon
                  icon="lucide:chevron-right"
                  className="icons"
                  onClick={goToNextPage}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default ResumeDrawer;
