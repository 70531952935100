import { Icon } from "@iconify/react";
import { Avatar, Box, Stack } from "@mui/material";
import {
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import EcnCheckboxFilled from "../../StyledComponents/EcnCheckboxFilled";
import EcnStyledDataGridUpdated from "../../StyledComponents/EcnStyledDataGridUpdated";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const CandidateListTable: React.FC<{
  rowSelectionModel: any;
  setRowSelectionModel: any;
  rows: any;
  setRows: any;
}> = ({ rowSelectionModel, setRowSelectionModel, rows, setRows }) => {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [data, setData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchRow, setSearchRow] = useState<any[]>([]);

  const { candidatesListData } = useAppSelector(
    (state: any) => state.candidateLists
  );

  useEffect(() => {
    if (candidatesListData) {
      const data1 = candidatesListData?.applications?.map((user: any) => ({
        key: user.created_by?._id ?? null,
        _id: user.created_by?._id ?? null,
        name: `${user.created_by?.first_name ?? "N/A"} ${user.created_by?.last_name ?? "N/A"
          }`,
        designation: user.work_experiences
          ? user.work_experiences[0]?.position
          : "",
        email: user.created_by?.email ?? "",
        ai: user.avg_relevancy_score,
      }));

      setRows(data1);
      setSearchRow(data1); // Initialize searchRow with all data
    }
  }, [candidatesListData, setRows]);

  // Search handler
  const searchHandler = (
    e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    const inputValue =
      e.target instanceof HTMLInputElement ? e.target.value.trim() : "";
    setSearchText(inputValue);

    if (!inputValue) {
      setSearchRow([...rows]); // Reset to all rows when search is cleared
      return;
    }

    const searchTerms = inputValue.toLowerCase().split(" ").filter(Boolean);

    const filteredRows = rows.filter((row: Record<string, any>) => {
      const fullName = `${row.name || ""}`.toLowerCase();
      return searchTerms.every((term) => fullName.includes(term)); // Match all terms
    });

    const sortedRows = filteredRows.sort((a: any, b: any) => {
      const fullNameA = `${a.name || ""}`.toLowerCase();
      const fullNameB = `${b.name || ""}`.toLowerCase();

      const startsWithA = fullNameA.startsWith(searchTerms[0]);
      const startsWithB = fullNameB.startsWith(searchTerms[0]);

      if (startsWithA && !startsWithB) return -1;
      if (!startsWithA && startsWithB) return 1;
      return 0;
    });

    setSearchRow(sortedRows);
  };

  const columns: any = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 317,
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Name</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <Stack direction="row" spacing={1}>
          <>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {params.row?.display_pictures?.length != 0 &&
                params.row?.display_pictures != null ? (
                <Avatar src={params?.row?.display_pictures[0]} />
              ) : (
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100%",
                    background: "#F2F4F8",
                    display: "flex",
                    justifyItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                  >
                    <path
                      d="M3.534 10.07..."
                      fill="#C1C7CD"
                    />
                  </svg>
                </div>
              )}
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(33, 33, 33, 1)",
                  }}
                >
                  {params?.row?.name?.length > 20
                    ? params?.row?.name?.slice(0, 20) + "..."
                    : params?.row?.name}
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "rgba(33, 33, 33, 1)",
                  }}
                >
                  {params?.row?.designation?.length > 30
                    ? params?.row?.designation?.slice(0, 30) + "..."
                    : params?.row?.designation}
                </p>
              </div>
            </div>
          </>
        </Stack>
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      minWidth: 317,
      flex: 1,

      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Email</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack sx={{ width: "90%" }} spacing={1}>
            {params?.row?.email}
          </Stack>
          <div></div>
        </div>
      ),
    },
    {
      field: "AI Score",
      headerName: "ai",
      minWidth: 317,
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>AI Score</p>
        </Stack>
      ),
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack sx={{ width: "90%" }} spacing={1}>
            {Math.round(params?.row?.ai * 10) + "%"}
          </Stack>
          <div></div>
        </div>
      ),
    },
  ];

  console.log("sortedRows", searchRow)

  return (
    <div>
      <div className="Search-box">
        <Icon
          icon="lucide:search"
          width={20}
          height={20}
          color="rgba(33, 33, 33, 0.6)"
        />
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={searchHandler}
        />
      </div>

      {searchRow?.length ? (
        <Box>
          <EcnStyledDataGridUpdated
            className="ecn-datagrid-group"
            key={JSON.stringify(searchRow)}
            rows={searchRow}
            getRowId={(row: any) => row.key}
            columns={columns}
            apiRef={apiRef}
            slots={{
              columnSortedAscendingIcon: () => (
                <Icon icon="ion:arrow-up" className="header-sort-icon" />
              ),
              columnSortedDescendingIcon: () => (
                <Icon icon="ion:arrow-down" className="header-sort-icon" />
              ),
              baseCheckbox: (props: any) => <EcnCheckboxFilled {...props} />,
            }}
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
          />
        </Box>
      ) : (
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(33, 33, 33, 0.6)",
            textAlign: "center",
            marginTop: "15%",
          }}
        >
          No applicants found
        </p>
      )}
    </div>
  );
};

export default CandidateListTable;
