import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const downloadAttachment = createAsyncThunk(
  "downloadAttachment",
  async (id: any) => {
    try {
      const { data } = await axios.get(
        `${
          GATEWAY_BASE + JOB_CREATE_BASE
        }/api/v1/emails/download_attachments/${id}`
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const downloadAttachmentSlice = createSlice({
  name: "downloadAttachment",
  initialState: {
    loading: false,
    downloadData: null,
    error: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.downloadData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadAttachment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(downloadAttachment.fulfilled, (state, action) => {
      state.loading = false;
      state.downloadData = action.payload;
    });
    builder.addCase(downloadAttachment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});
export const { reset } = downloadAttachmentSlice.actions;
export default downloadAttachmentSlice.reducer;
