import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const BASE = process.env.REACT_APP_API_GATEWAY_BASE;

// For patching state data
export const patchStateData = createAsyncThunk(
  "state/patchStateData",
  async (stateData: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.patch(
        `${BASE}/core-backend/v1/api/v1/state/update`,
        stateData
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

const patchStateSlice = createSlice({
  name: "state",
  initialState: {
    loading: false,
    stateData: null as any,
    stateToken: null as any,
    error: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(patchStateData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchStateData.fulfilled, (state, action) => {
      state.loading = false;
      state.stateData = action.payload;
    });
    builder.addCase(patchStateData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export default patchStateSlice.reducer;
