import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import EcnBreadCrumbs from "../../StyledComponents/EcnBreadCrumbs";

const TopHeader: React.FC<{ switchMode: any; setSwitchMode: any }> = ({
  switchMode,
  setSwitchMode,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { getJobsListData } = useAppSelector((state: any) => state.jobsList);
  const { jobDetailsData } = useAppSelector((state: any) => state.getJobDetails);
  const { getEventsListData } = useAppSelector((state: any) => state.getAllEvents);

  const [selectedId, setSelectedId] = useState<any>("");
  const [data, setData] = useState<any>();
  const [listData, setListData] = useState<any>();
  const [name, setName] = useState<any>("");
  const [eventName, setEventName] = useState("");

  const selectHandler = (e: any) => {
    if (switchMode === "job") {
      setSelectedId(e.target.value);
      navigate(`?id=${e.target.value}`);
    } else {
      setSelectedId(e.target.value);
      navigate(`?id=${e.target.value}`);
    }
  };

  const switchHandler = (e: any) => {
    setSelectedId("");
    setSwitchMode(e);
    navigate(`?id=${id}`);
  };

  useEffect(() => {
    if (id) {
      setSelectedId(id);
      if (switchMode == "job" && getJobsListData && getEventsListData) {
        const filteredArray = getJobsListData?.result?.filter(
          (item: any) => item._id === id
        );
        setData(filteredArray && filteredArray[0]?.job_title);
        setEventName("Jobs");
        if (filteredArray?.length > 0) {
          setSwitchMode("job");
        }
      } else {
        const filteredArray = getEventsListData?.result?.filter(
          (item: any) => item._id === id
        );
        if (filteredArray?.length > 0) {
          setSwitchMode("event");
        }
        setData(filteredArray && filteredArray[0]?.name);
        setEventName("Events");
      }
    }
  }, [id]);

  useEffect(() => {
    if (switchMode === "job") {
      setListData(getJobsListData?.result);
    } else {
      setListData(getEventsListData?.result);
    }
  }, [id, switchMode]);

  return (
    <div className="top-header">
      <div className="left">
        <EcnBreadCrumbs
          sx={{ marginTop: "0 !important", marginBottom: "0 !important" }}
        >
          <p>Analytics</p>
        </EcnBreadCrumbs>
      </div>

      <div className="analytics-right">
        <div className="switch">
          <Button
            className={switchMode === "job" ? "button active" : "button "}
            onClick={() => switchHandler("job")}
          >
            Jobs
          </Button>
          <Button
            className={switchMode === "event" ? "button active" : "button "}
            onClick={() => switchHandler("event")}
          >
            Events
          </Button>
        </div>
        <FormControl fullWidth>
          {selectedId === "" && (
            <InputLabel
              htmlFor={`select`}
              shrink={!!(selectedId === "")}
              className="label"
            >
              {switchMode === "job" ? "Select Other Job" : "Select Events"}
            </InputLabel>
          )}

          <Select
            size="small"
            placeholder={"SDsd"}
            variant="standard"
            disableUnderline
            value={selectedId}
            onChange={selectHandler}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },

              PaperProps: {
                sx: {
                  maxHeight: 300,
                  boxShadow: 0,
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderRadius: "8px",
                  marginTop: "10px",
                },
              },
            }}
          >
            {listData?.map((item: any) => (
              <MenuItem className="list-item" value={item?._id}>
                {switchMode === "job" ? item?.job_title : item?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default TopHeader;
