import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getPlaceholder = createAsyncThunk("getPlaceholder", async () => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/emails/get_placeholders`
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});

const getPlaceholderSlice = createSlice({
  name: "getPlaceholder",
  initialState: {
    loading: false,
    placeholderData: null,
    error: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlaceholder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPlaceholder.fulfilled, (state, action) => {
      state.loading = false;
      state.placeholderData = action.payload;
    });
    builder.addCase(getPlaceholder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getPlaceholderSlice.reducer;
