import { Icon } from "@iconify/react";
import {
  alpha,
  Button,
  Dialog,
  DialogContent,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Typography,
} from "@mui/material";

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Placeholders from "../Composed/Placeholders";
import { getPlaceholder } from "../../../redux/slices/MailsSlices/getPlaceholderSlice";
import { createAndUpdateTemplate } from "../../../redux/slices/MailsSlices/createAndUpdateTemplateSlice";

import DargeAndUploadFile from "../Composed/DargeAndUploadFile";
import { enqueueSnackbar } from "notistack";
import EcnButton from "../../StyledComponents/EcnButton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const CreateTemplate: React.FC<{ selectedTemplate: any }> = ({
  selectedTemplate,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") || "";
  const view = searchParams.get("view");
  const { placeholderData } = useAppSelector(
    (state: any) => state.placeholderList
  );

  const { createTemplateData } = useAppSelector(
    (state: any) => state.createAndUpdateTemplate
  );

  const quillRef = useRef<ReactQuill>(null);

  const [text, setText] = useState<any>("");
  const [titleText, setTitleText] = useState<any>("");
  const [emailText, setEmailText] = useState<any>("");
  const [openAttach, setOpenAttach] = useState<any>();
  const [smallFiles, setSmallFiles] = useState<any>([]);
  const [allFiles, setAllFiles] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [openPlaceHolder, setOpenPlaceHolder] = useState<any>(false);
  const [discard, setDiscard] = useState(false);
  const [click, setClick] = useState(false);

  const [value, setValue] = React.useState("");

  useEffect(() => {
    dispatch(getPlaceholder());
  }, []);

  useEffect(() => {
    if (selectedTemplate.length != 0) {
      setTitleText(selectedTemplate?.title);
      setText(selectedTemplate?.subject);
      setEmailText(selectedTemplate?.body);
      setFileList(selectedTemplate?.attachments);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (createTemplateData && click) {
      navigate("?view=list");
      enqueueSnackbar("Template create successfully!", {
        variant: "success",
      });
    }
  }, [createTemplateData]);

  const focusQuillEditor = useCallback(() => {
    if (quillRef && quillRef.current) {
      quillRef.current.focus(); // Focus the Quill editor
    }
  }, [quillRef]);

  useEffect(() => {
    const textBodyDiv = document.querySelector(".text-body");

    const handleClick = () => {
      focusQuillEditor(); // Call the function to focus the Quill editor
    };

    if (textBodyDiv) {
      textBodyDiv.addEventListener("click", handleClick);
    }

    // Cleanup function
    return () => {
      if (textBodyDiv) {
        textBodyDiv.removeEventListener("click", handleClick);
      }
    };
  }, [focusQuillEditor]);

  const toggleDrawer = () => {
    setOpenAttach(false);
    setOpenPlaceHolder(false);
  };

  const subjectHandler = (event: any) => {
    // Get the input value
    let inputText = event.target.value;

    // Limit the input length to 100 characters
    if (inputText.length > 140) {
      inputText = inputText.slice(0, 140);
    }

    // Update the state with the limited input value
    setText(inputText);
  };

  const removeHandler = (indexToRemove: number) => {
    setAllFiles((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
    setSmallFiles((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
    setFileList((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const placeHandler = () => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection(true);
      if (selection) {
        const textToInsert = `{{${value}}}`;
        editor.insertText(selection.index, textToInsert);
        setOpenPlaceHolder(false);
      }
    }
  };

  const titleHandler = (event: any) => {
    // Get the input value
    let inputText = event.target.value;

    // Limit the input length to 100 characters
    if (inputText.length > 140) {
      inputText = inputText.slice(0, 140);
    }

    // Update the state with the limited input value
    setTitleText(inputText);
  };

  const downloadAttachment = (
    attachmentData: any,
    attachmentFileName: string
  ) => {
    try {
      // Create a Blob from the base64 data
      const byteCharacters = atob(attachmentData);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: "application/octet-stream" });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = url;
      link.download = attachmentFileName;

      // Programmatically trigger a click on the anchor element
      link.click();

      // Clean up by revoking the URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading attachment:", error);
      // Handle the error gracefully, e.g., display an error message to the user
    }
  };

  const createHandler = () => {
    setClick(true);
    dispatch(
      createAndUpdateTemplate({
        id: id,
        title: titleText,
        subject: text,
        body: emailText,
        attachments: smallFiles,
      })
    );
  };

  const handleClose = () => {
    setDiscard(false);
  };

  return (
    <div className="create-template">
      <div className="top">
        <p className="back" onClick={() => navigate("?view=list")}>
          {" "}
          <Icon icon="carbon:arrow-left" />
          View template
        </p>
        {view != "view" ? (
          <Button onClick={() => setDiscard(true)}>Discard</Button>
        ) : (
          <Button
            onClick={() => navigate(`?view=edit&id=${selectedTemplate?.id}`)}
            style={{ color: "rgba(0, 52, 187, 1)" }}
          >
            <Icon icon="lucide:pencil" style={{ marginRight: "10px" }} /> Edit
            template
          </Button>
        )}
      </div>
      <div className="subject">
        <p>Template name</p>
        {view != "view" && (
          <input
            type="text"
            onChange={titleHandler}
            value={titleText}
            placeholder="Enter template name here"
          />
        )}
        {view === "view" && <p className="view-subject">{titleText}</p>}
      </div>

      <div className="subject">
        <p>Subject</p>
        {view != "view" && (
          <input
            type="text"
            onChange={subjectHandler}
            value={text}
            placeholder="Enter subject here"
          />
        )}
        {view === "view" && <p className="view-subject">{text}</p>}
      </div>

      <div className="text-body">
        <div className="quill">
          {view != "view" && (
            <ReactQuill
              onChange={(e: any) => setEmailText(e)}
              value={emailText}
              ref={quillRef}
              theme="snow"
              bounds={".app"}
              className="quill-candidatesdetail"
              modules={modules}
              formats={formats}
              placeholder="Start typing from here"
            />
          )}
          {view === "view" && (
            <div className="view-mark">
              <Typography variant="h6" component="p">
                <ReactMarkdown
                  className="markdown-data"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {emailText}
                </ReactMarkdown>
              </Typography>
            </div>
          )}

          {fileList?.length != 0 && (
            <div className="attach">
              {fileList?.map((item: any, index: any) => (
                <div className="file">
                  <p
                    onClick={() =>
                      downloadAttachment(
                        item?.attachmentData,
                        item?.attachmentFileName
                      )
                    }
                  >
                    {item?.attachmentFileName}
                  </p>
                  <Icon
                    icon="lucide:x-circle"
                    height={24}
                    width={24}
                    onClick={() => removeHandler(index)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {view != "view" && (
        <div className="buttons">
          <div className="button-left">
            <Button
              className="placeholder"
              onClick={() => {
                setOpenPlaceHolder(true);
                setValue("");
              }}
            >
              <Icon icon="lucide:braces" />
              Placeholder
            </Button>
            <Button onClick={() => setOpenAttach(true)}>
              <Icon icon="fluent:attach-20-filled" /> Attach
            </Button>
          </div>
          <div className="button-right">
            <Button
              variant="contained"
              onClick={createHandler}
              disabled={titleText === "" || text === "" || emailText === ""}
            >
              {view === "create" ? "Create template" : "Save Change"}
            </Button>
          </div>
        </div>
      )}

      <Drawer
        anchor="right"
        open={openAttach}
        onClose={toggleDrawer}
        className="open-attachment"
      >
        <div className="top" onClick={() => setOpenAttach(false)}>
          <IconButton onClick={() => setOpenAttach(false)}>
            <Icon icon="material-symbols:arrow-back" />{" "}
          </IconButton>
          <IconButton onClick={() => setOpenAttach(false)}>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        <h1 className="title">Attach document</h1>
        <DargeAndUploadFile
          setOpenAttach={setOpenAttach}
          setSmallFiles={setSmallFiles}
          smallFiles={smallFiles}
          setAllFiles={setAllFiles}
          allFiles={allFiles}
          setFileList={setFileList}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={openPlaceHolder}
        onClose={toggleDrawer}
        className="open-placeholder"
      >
        <div className="top" onClick={() => setOpenPlaceHolder(false)}>
          <IconButton onClick={() => setOpenPlaceHolder(false)}>
            <Icon icon="material-symbols:arrow-back" />{" "}
          </IconButton>
          <IconButton
            onClick={() => setOpenPlaceHolder(false)}
            style={{ marginRight: "30px" }}
          >
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        <h1 className="title">Select Placeholder</h1>
        <p className="total">{placeholderData?.length} templates available</p>
        <Placeholders setValue={setValue} value={value} />
        <div className="member-list-bottom">
          <Button className="cancel" onClick={toggleDrawer}>
            Cancel
          </Button>
          <Button className="select" onClick={placeHandler}>
            Use Placeholder
          </Button>
        </div>
      </Drawer>

      <Dialog
        open={discard}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "20px 80px",
              borderRadius: "7px",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "25px",
              fontWeight: "400",
              color: "rgba(33, 33, 33, 1)",
            }}
          >
            Are you sure you want to discard ?{" "}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              marginTop: "20px",
            }}
          >
            <EcnButton
              variant="outlined"
              className="btn-outlined"
              sx={{ maxWidth: "196px !important" }}
              onClick={handleClose}
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Cancel
            </EcnButton>
            <EcnButton
              variant="outlined"
              className="btn-contained"
              onClick={() => navigate("?view=list")}
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Yes
            </EcnButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateTemplate;

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
