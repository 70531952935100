import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE || "";

export const promoAnalytis = createAsyncThunk(
  "promoAnalytis",
  async (analData: any,{rejectWithValue}) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + ANALYTICS_BASE}/v2/promotions`,
        analData
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const promoAnalytisSlice = createSlice({
  name: "promoAnalytis",
  initialState: {
    loading: false,
    promoAnalytics: null,
    error: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.promoAnalytics = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(promoAnalytis.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(promoAnalytis.fulfilled, (state, action) => {
      state.loading = false;
      state.promoAnalytics = action.payload;
    });
    builder.addCase(promoAnalytis.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export const { reset } = promoAnalytisSlice.actions;

export default promoAnalytisSlice.reducer;
