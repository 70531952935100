import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Box,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  Button,
} from "@mui/material";
import ExperienceCard from "./ExperienceCard";
import Education from "./Education";
import Questions from "./Questions";
import Score from "./TalentScore/Score";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { deleteNotes } from "../../../../redux/slices/Notes/deleteNotesSlice";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import EcnButton from "../../../StyledComponents/V2/EcnButton";

const ProfileDetails: React.FC<{
  data: any;
  setActiveStep: any;
  activeStep: any;
  setOpen: any;
  deleteOpen: any;
  setDeleteOpen: any;
  setParticularNote: any;
}> = ({
  data,
  setActiveStep,
  activeStep,
  setOpen,
  deleteOpen,
  setDeleteOpen,
  setParticularNote,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const tab = searchParams.get("tab");
  const id = searchParams.get("id");
  const applicationId = searchParams.get("application_id");
  const notesId = searchParams.get("notes_id");

  const { getNotesData, loading: getNotesLoading } = useAppSelector(
    (state: any) => state.getNotes
  );

  const { deleteNotesData, loading: deleteNotesLoading } = useAppSelector(
    (state: any) => state.deleteNotes
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const [click, setClick] = useState<boolean>(false);
  const [active, setActive] = useState<number | null>(null);
  const [noteId, setNoteId] = useState<any>("");
  const [scoreModifier, setScoreModifier] = useState(false);

  useEffect(() => {
    if (notesId) {
      setNoteId(notesId);

      const particularnote = getNotesData?.find(
        (item: any) => item?.id === notesId
      );
      setParticularNote(particularnote);
    }
  }, [notesId]);

  useEffect(() => {
    if (tab === "edit") {
      setOpen(true);
      setActiveStep(3);
    }
    if (tab === "detail") {
      setOpen(true);
      setActiveStep(3);
    }
  }, [id, tab]);

  useEffect(() => {
    if (deleteNotesData && click) {
      setDeleteOpen(false);
      setOpen(false);
      navigate(`?id=${id}&application_id=${applicationId}`);
      setParticularNote(null);
      enqueueSnackbar("Note deleted successfully", {
        variant: "success",
      });
    }
  }, [deleteNotesData]);

  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: number) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenMenuId(id);
    setActive(id);
  };

  const handleMenuClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpenMenuId(null);
    setActive(null);
  };

  const viewHandler = (note: any) => {
    navigate(
      `?id=${id}&application_id=${applicationId}&notes_id=${note}&tab=detail`
    );
  };

  const confirmDeleteHandler = () => {
    dispatch(deleteNotes(noteId));
    setClick(true);
  };

  const deleteCloseHandler = () => {
    setDeleteOpen(false);
  };

  const deleteHandler = (id: any) => {
    setDeleteOpen(true);
    setOpenMenuId(null);
    setNoteId(id);
  };

  const createNoteHandler = () => {
    setOpen(true);
    setOpenMenuId(null);
  };

  const editHandler = (note: number) => {
    setOpen(true);
    setOpenMenuId(null);
    navigate(
      `?id=${id}&application_id=${applicationId}&notes_id=${note}&tab=edit`
    );
  };

  return (
    <div className="swipeable-view">
      <div className={"slider"}>
        <Tabs value={activeStep} onChange={handleChange} className="tabs">
          <Tab label="Profile overview" />
          <Tab label="Talent score" />
          <Tab label="Chats" />
          <Tab label="Notes" />
        </Tabs>
        {activeStep === 0 && (
          <div className="profile-tab">
            {data?.created_by?.work_experiences?.length != 0 && (
              <ExperienceCard data={data} />
            )}
            {data?.created_by?.education?.length != 0 && (
              <Education data={data} />
            )}
            {data?.job_questions && data?.job_questions?.length != 0 && (
              <Questions data={data} />
            )}
          </div>
        )}

        {activeStep === 1 && (
          <div className="score">
            <h1 className="score-title">
              <Icon icon="lucide:info" className="icon" />
              To rate the candidate, simply click on the scale below. The scale
              ranges from 1 to 10 and corresponds to the respective parameters.
            </h1>
            <p className="view-para" onClick={() => setScoreModifier(true)}>
              View Parameters
            </p>
            <Score
              data={data}
              setScoreModifier={setScoreModifier}
              scoreModifier={scoreModifier}
          
            />
          </div>
        )}
        {activeStep === 2 && (
          <div className="score1">
            <h2>Coming soon!</h2>
          </div>
        )}
        {activeStep === 3 && (
          <div className="notes-container">
            <div className="notes-header">
              <h2>Notes</h2>
              <div className="create-note">
                <p onClick={createNoteHandler} style={{ cursor: "pointer" }}>
                  Create note
                </p>
              </div>
            </div>
            {getNotesData?.length === 0 ? (
              <div className="fallback-screen">
                <h3>No notes yet!</h3>
                <EcnButton
                  className="btn-outlined-v2"
                  variant="outlined"
                  onClick={createNoteHandler}
                >
                  Start creating notes
                </EcnButton>
              </div>
            ) : (
              <div className="all-notes">
                {getNotesData?.map((item: any) => (
                  <div
                    className={
                      active === item?.id ? "notes-body active" : "notes-body"
                    }
                  >
                    {item?.title?.length === 0 ? (
                      <div className="notes-top-no-title">
                        <div className="note-description">
                          <ReactMarkdown
                            className="markdown-data"
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          >
                            {item?.body}
                          </ReactMarkdown>
                        </div>
                        <div>
                          <button
                            aria-label="more"
                            id={`long-button`}
                            onClick={(e) => handleMenuOpen(e, item?.id)}
                            className="notes-menu"
                          >
                            <MoreVertIcon
                              style={{ color: "rgba(33, 33, 33, 0.6)" }}
                            />
                          </button>
                          <Menu
                            id={`long-menu-${item?.id}`}
                            anchorEl={anchorEl}
                            open={openMenuId === item?.id}
                            onClose={(e) => handleMenuClose(e)}
                            className="notes-menu-item"
                            PaperProps={{
                              style: {
                                maxHeight: "200px",
                                width: "143px",
                              },
                            }}
                          >
                            <MenuItem onClick={() => editHandler(item?.id)}>
                              <Icon
                                icon="lucide:pencil"
                                width={20}
                                height={20}
                                className="icons"
                              />{" "}
                              <span>Edit</span>
                            </MenuItem>
                            <MenuItem onClick={() => viewHandler(item?.id)}>
                              <Icon
                                icon="lucide:eye"
                                className="icons"
                                width={20}
                                height={20}
                              />{" "}
                              <span>View</span>
                            </MenuItem>
                            <MenuItem onClick={() => deleteHandler(item?.id)}>
                              <Icon
                                icon="lucide:trash"
                                className="icons"
                                width={20}
                                height={20}
                              />{" "}
                              <span>Delete</span>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    ) : (
                      <div className="notes-top">
                        <div className="title-container">
                          <h4
                            style={{ width: "89%" }}
                            onClick={() => viewHandler(item?.id)}
                          >
                            {item?.title}
                          </h4>

                          <div>
                            <button
                              aria-label="more"
                              id={`long-button`}
                              onClick={(e) => handleMenuOpen(e, item?.id)}
                              className="notes-menu"
                            >
                              <MoreVertIcon
                                style={{ color: "rgba(33, 33, 33, 0.6)" }}
                              />
                            </button>
                            <Menu
                              id={`long-menu-${item?.id}`}
                              anchorEl={anchorEl}
                              open={openMenuId === item?.id}
                              onClose={handleMenuClose}
                              className="notes-menu-item"
                              PaperProps={{
                                style: {
                                  maxHeight: "200px",
                                  width: "143px",
                                },
                              }}
                            >
                              <MenuItem onClick={() => editHandler(item?.id)}>
                                <Icon
                                  icon="lucide:pencil"
                                  width={20}
                                  height={20}
                                  className="icons"
                                />{" "}
                                <span>Edit</span>
                              </MenuItem>
                              <MenuItem onClick={() => viewHandler(item?.id)}>
                                <Icon
                                  icon="lucide:eye"
                                  className="icons"
                                  width={20}
                                  height={20}
                                />{" "}
                                <span>View</span>
                              </MenuItem>
                              <MenuItem onClick={() => deleteHandler(item?.id)}>
                                <Icon
                                  icon="lucide:trash"
                                  className="icons"
                                  width={20}
                                  height={20}
                                />{" "}
                                <span>Delete</span>
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                        <div
                          onClick={() => viewHandler(item?.id)}
                          className="note-description"
                        >
                          <ReactMarkdown
                            className="markdown-data"
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          >
                            {item?.body?.length < 140
                              ? item?.body
                              : `${item?.body?.slice(0, 140)}...`}
                          </ReactMarkdown>
                        </div>
                      </div>
                    )}

                    <div
                      className="notes-bottom"
                      onClick={() => viewHandler(item?.id)}
                    >
                      <div className="left">
                        <p>
                          {item?.updatedBy?.length
                            ? "Updated by"
                            : "Created by"}
                        </p>
                        <p className="user-name">
                          {item?.updatedBy?.length
                            ? item?.updatedBy?.length < 35
                              ? item?.updatedBy
                              : `${item?.updatedBy.slice(0, 35)}...`
                            : item?.createdBy?.length < 35
                            ? item?.createdBy
                            : `${item?.createdBy.slice(0, 35)}...`}
                        </p>
                      </div>
                      <div className="right">
                        <p>
                          {moment
                            .unix(
                              item?.updatedBy?.length
                                ? item?.updatedAt
                                : item?.createdAt
                            )
                            .format("D MMM")}
                          {new Date(item?.createdAt * 1000).getFullYear() !==
                          new Date().getFullYear()
                            ? moment
                                .unix(
                                  item?.updatedBy?.length
                                    ? item?.updatedAt
                                    : item?.createdAt
                                )
                                .format(" YYYY ")
                            : ""}
                        </p>
                        <p>
                          {moment
                            .unix(
                              item?.updatedBy?.length
                                ? item?.updatedAt
                                : item?.createdAt
                            )
                            .format("h:m A")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={deleteOpen}
        onClose={deleteCloseHandler}
        sx={{
          "& .MuiDialog-container": {
            background: "rgba(33, 33, 33, 0.20)",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "42vw", // Set your width here
              padding: "20px 20px",
              borderRadius: "8px",
              boxShadow: "unset !important",
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#212121",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Are you sure you want to Delete?
          </h2>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <Button
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#212121",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 52, 187, 0.10)",

                  // Add any other styles you want to disable on hover
                },
              }}
              onClick={deleteCloseHandler}
            >
              Cancel
            </Button>

            <LoadingButton
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "18px",
                fontWeight: "600",
                color: "#BA0000",
                width: "200px",
                padding: "4px",
                borderRadius: "9px",
              }}
              className="button"
              onClick={confirmDeleteHandler}
              loadingPosition="end"
              loading={deleteNotesLoading}
              disabled={deleteNotesLoading}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(186, 0, 0, 0.10)",

                  // Add any other styles you want to disable on hover
                },
                "&.MuiLoadingButton-loading": {
                  "& .MuiLoadingButton-loadingIndicator": {
                    marginRight: "18px", // Add margin after the loading indicator
                  },
                },
              }}
            >
              Delete
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProfileDetails;
