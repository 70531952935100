import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const EVENTS_CREATE_BASE = process.env.REACT_APP_EVENTS_CREATE_BASE || "";

export const getEventDetails = createAsyncThunk(
  "getEventDetails",
  async (eventDetailsConfig: any) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + EVENTS_CREATE_BASE}/api/v1/event/details/${eventDetailsConfig.eventId}`
    );
    return data;
  }
);

const getEventDetailsSlice = createSlice({
  name: "getEventDetails",
  initialState: {
    loading: false,
    eventDetailsData: null as any,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventDetails.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getEventDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.eventDetailsData = action.payload;
    });
    builder.addCase(getEventDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default getEventDetailsSlice.reducer;
