import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const RightState: React.FC<{ isEstimateVisible: any }> = ({
  isEstimateVisible,
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { jobDetailsData } = useAppSelector(
    (state: any) => state.getJobDetails
  );
  const { estData } = useAppSelector((state: any) => state.getEstimateData);

  const [data, setData] = useState<any>();

  useEffect(() => {
    if (jobDetailsData) {
      setData(jobDetailsData);
    }
  }, [jobDetailsData]);

  

  return (
    <div className="right-body">
      <div className="job-card">
        <div className="top">
          {data?.under_review ? (
            <p className="under-review">Under Review</p>
          ) : (
            <p>Approved</p>
          )}
        </div>
        <h3 className="title">Job Post</h3>
        <div className="detail">
          <div className="logo">
            <img src={data?.display_picture} alt="" />
          </div>
          <div className="job-details">
            <h2>{data?.job_title}</h2>
            {data?.address?.cities?.length != 0 && (
              <h3>
                {data && data?.address?.cities[0] !== undefined
                  ? data?.address?.cities[0]
                  : ""}
              </h3>
            )}

            <div className="role">
              {data?.role_type != "" && <p>{data?.role_type}</p>}
              {data?.industries && data?.industries?.length != 0 && (
                <p>
                  {data.industries[0] !== undefined ? data.industries[0] : ""}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isEstimateVisible ? (
        <div className="estimate-card">
          <h1>Estimation based on your selections</h1>
          {estData && (
            <>
              <div className="reach">
                <p>Reach</p>
                <h1>
                  {estData?.reach}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="18"
                    viewBox="0 0 33 18"
                    fill="none"
                  >
                    <path
                      d="M22.2144 0.976074H31.6429V10.2153"
                      stroke="#027A48"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M31.6429 0.976074L18.325 14.0265C18.1047 14.2381 17.8085 14.3566 17.5 14.3566C17.1915 14.3566 16.8953 14.2381 16.675 14.0265L11.2536 8.71394C11.0333 8.50233 10.7371 8.3838 10.4286 8.3838C10.1201 8.3838 9.82388 8.50233 9.60357 8.71394L1 17.1447"
                      stroke="#027A48"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h1>
              </div>

              <div className="reach">
                <p>Job Post Views</p>
                <h1>
                  {estData?.post_views}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="18"
                    viewBox="0 0 33 18"
                    fill="none"
                  >
                    <path
                      d="M22.2144 0.976074H31.6429V10.2153"
                      stroke="#027A48"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M31.6429 0.976074L18.325 14.0265C18.1047 14.2381 17.8085 14.3566 17.5 14.3566C17.1915 14.3566 16.8953 14.2381 16.675 14.0265L11.2536 8.71394C11.0333 8.50233 10.7371 8.3838 10.4286 8.3838C10.1201 8.3838 9.82388 8.50233 9.60357 8.71394L1 17.1447"
                      stroke="#027A48"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h1>
              </div>

              <div className="reach">
                <p>High Quality Job Applications</p>
                <h1>
                  {estData?.applications}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="18"
                    viewBox="0 0 33 18"
                    fill="none"
                  >
                    <path
                      d="M22.2144 0.976074H31.6429V10.2153"
                      stroke="#027A48"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M31.6429 0.976074L18.325 14.0265C18.1047 14.2381 17.8085 14.3566 17.5 14.3566C17.1915 14.3566 16.8953 14.2381 16.675 14.0265L11.2536 8.71394C11.0333 8.50233 10.7371 8.3838 10.4286 8.3838C10.1201 8.3838 9.82388 8.50233 9.60357 8.71394L1 17.1447"
                      stroke="#027A48"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h1>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="fallback">
          <h1>Select segments to see estimations</h1>
          <div className="image">
            <img
              src="https://s1.ecnstatic.com/ecn/images/common/MicrosoftTeams-image (25).png"
              alt=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RightState;
