import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getPrivateJobBoardUrl = createAsyncThunk(
  "getPrivateJobUrl",
  async () => {
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/firms/getPrivateBoardUrl`,
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  },
);
type PrivateJobUrlRes = {
  existing_url: string;
  suggested_url: string;
};
type PrivateJobUrlState = {
  loading: boolean;
  privateJobBoardUrl: PrivateJobUrlRes | null;
  error: null | any;
};
const initialState = {
  loading: false,
  privateJobBoardUrl: null,
  error: null as any,
} satisfies PrivateJobUrlState as PrivateJobUrlState;

const getPrivateJobUrlSlice = createSlice({
  name: "getPrivateJobUrl",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrivateJobBoardUrl.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPrivateJobBoardUrl.fulfilled, (state, action) => {
      state.loading = false;
      state.privateJobBoardUrl = action.payload;
    });
    builder.addCase(getPrivateJobBoardUrl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getPrivateJobUrlSlice.reducer;
