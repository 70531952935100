import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const checkPrivateJobBoardUrl = createAsyncThunk(
  "checkPrivateJobUrl",
  async (proposed_url: string) => {
    try {
      const res = await axios.patch(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/firms/checkPrivateBoardUrl`,
        { proposed_url },
      );
      return res.data;
    } catch (error: any) {
      throw error.response.data;
    }
  },
);
type PrivateJobUrlState = {
  loading: boolean;
  error: null | any;
};
const initialState = {
  loading: false,
  error: null as any,
} satisfies PrivateJobUrlState as PrivateJobUrlState;

const checkPrivateJobUrlSlice = createSlice({
  name: "checkPrivateJobUrl",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkPrivateJobBoardUrl.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(checkPrivateJobBoardUrl.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(checkPrivateJobBoardUrl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default checkPrivateJobUrlSlice.reducer;
