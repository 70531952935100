import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getInbox = createAsyncThunk("getInbox", async (inboxData: any) => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/emails/folders/inbox?skip=${
        inboxData?.skip
      }&limit=${inboxData?.limit}&job_id=${inboxData?.job_id}`
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
});

const getInboxSlice = createSlice({
  name: "getInbox",
  initialState: {
    loading: false,
    inboxData: null,
    error: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInbox.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getInbox.fulfilled, (state, action) => {
      state.loading = false;
      state.inboxData = action.payload;
    });
    builder.addCase(getInbox.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getInboxSlice.reducer;
