import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const generateAugRelevanyScore = createAsyncThunk(
  "generateAugRelevanyScore",

  async (AvgData: any) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/relevancy_score`,
        AvgData
      );
      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data?.statusCode
      ;
    }
  }
);

const generateAugRelevanyScoreSlice = createSlice({
  name: "generateAugRelevanyScore",
  initialState: {
    loading: false,
    generateAugRelevanyScoreData: null,
    error: false,
    success: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateAugRelevanyScore.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(generateAugRelevanyScore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.generateAugRelevanyScoreData = action.payload;
    });
    builder.addCase(generateAugRelevanyScore.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default generateAugRelevanyScoreSlice.reducer;
