import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { colors } from "../../../../../helpers/TempData";

const GPA: React.FC<{
  subitem: any;
  setHoverValue: any;
  hoverValue: any;
  customColors: any;
}> = ({ subitem, setHoverValue, hoverValue, customColors }) => {
  let mapData: any = [];
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverValue !== undefined && scrollRef.current) {
      const element = document.getElementById(`item-${hoverValue}`);

      if (element) {
        const containerHeight = scrollRef.current.clientHeight;
        const elementRect = element.getBoundingClientRect();
        const scrollContainerRect = scrollRef.current.getBoundingClientRect();

        const elementTopRelativeToContainer =
          elementRect.top - scrollContainerRect.top;
        const elementBottomRelativeToContainer =
          elementRect.bottom - scrollContainerRect.top;

        let scrollTop = scrollRef.current.scrollTop;

        // If the matched element is below the visible area, scroll to bring it into view
        if (elementBottomRelativeToContainer > containerHeight) {
          scrollTop += elementBottomRelativeToContainer - containerHeight;
        }
        // If the matched element is above the visible area, scroll to bring it into view
        else if (elementTopRelativeToContainer < 0) {
          scrollTop += elementTopRelativeToContainer;
        }

        scrollRef.current.scrollTo({
          top: scrollTop,
          behavior: "smooth",
        });
      }
    }
  }, [hoverValue]);

  function mapAry(val: any, name: any, per: any, index: any) {
    var obj: any = {};
    obj["id"] = name;
    obj["value"] = val;
    obj["label"] = name;
    obj["per"] = per;
    mapData.push(obj);
  }

  subitem?.chart_results?.map((item: any, index: any) =>
    mapAry(item.value, item.label, item?.percentage, index)
  );

  const handleMouseEnter = (data: any, event: any) => {
    setHoverValue(data?.label);
  };

  const handleMouseLeave = () => {
    setHoverValue();
  };

  const animationProps = useSpring({
    opacity: 1,
  });

  return (
    <div
      className="gpa"
      style={{
        width: `${subitem?.width * 8.2}%`,
        background: `${
          subitem?.background_color === ""
            ? subitem?.background_color
            : "transparent"
        }`,
      }}
    >
      <div className="gpa-chart">
        <ResponsivePie
          data={mapData}
          margin={{ top: 62, right: 50, bottom: 62, left: 30 }}
          startAngle={0}
          sortByValue={true}
          innerRadius={0.6}
          borderWidth={1.4}
          borderColor={"#fff"}
          activeOuterRadiusOffset={8}
          arcLinkLabel={(e) => `${e.id}`}
          colors={customColors}
          arcLinkLabelsSkipAngle={20}
          arcLinkLabelsTextOffset={3}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsOffset={-15}
          arcLinkLabelsDiagonalLength={30}
          arcLinkLabelsStraightLength={5}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          enableArcLabels={false}
          arcLabelsRadiusOffset={0.2}
          arcLabelsSkipAngle={4}
          legends={[]}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          arcLinkLabelComponent={(d: any) => {
            return (
              <animated.g style={animationProps}>
                <animated.path
                  fill="none"
                  stroke={d.style.linkColor}
                  strokeWidth={d.style.thickness}
                  d={d.style.path}
                />
                <animated.text
                  key={`arcLinkLabel_segment_closurereasons_${d.label}`}
                  transform={d.style.textPosition}
                  textAnchor={d.style.textAnchor}
                  dominantBaseline="central"
                >
                  <tspan
                    x="0px"
                    y="0px"
                    style={{ fontSize: "10px", textDecoration: "underline" }}
                  >
                    {d?.label.length >= 10
                      ? d?.label.substring(0, 10) + "..."
                      : d?.label}
                  </tspan>
                  <tspan x="0" dy="14px" style={{ fontSize: "10px" }}>
                    {subitem?.show_value && d?.datum?.data?.value}
                    {subitem?.show_percentage && `(${d?.datum?.data?.per}%)`}
                  </tspan>
                </animated.text>
              </animated.g>
            );
          }}
          tooltip={(da: any) => (
            <div
              style={{
                background: "white",
                padding: "5px 10px",
                width: "fit-content",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  background: da?.datum?.color,
                  borderRadius: "100%",
                }}
              ></div>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#121212",
                }}
              >
                {da?.datum?.data.id}{" "}
                <span style={{ fontWeight: "600" }}>
                  : {subitem?.show_value && da?.datum?.data?.value}
                  {subitem?.show_percentage && `(${da?.datum?.data?.per}%)`}
                </span>
              </p>
            </div>
          )}
        />
      </div>
      <h2
        className={subitem?.chart_label === "GMAT" ? "center gmat" : "center"}
      >
        {subitem?.chart_label}
      </h2>
      <div className="gpa-legend" ref={scrollRef}>
        {subitem?.chart_results?.map((item: any, index: any) => (
          <div
            key={index}
            id={`item-${item.label}`}
            className={hoverValue === item.label ? "legend active" : "legend"}
          >
            <div
              className="dots"
              style={{ background: customColors[index] }}
            ></div>
            <p>{item?.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GPA;
