import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const postAiReview = createAsyncThunk(
  "postAiReview",
  async (jobId: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + JOBS_CREATE_BASE}/api/v1/application/relevancy_score/job`,
      {
        job_id: jobId,
      }
    );
    return data;
  }
);

const postAiReviewSlice = createSlice({
  name: "postAiReview",
  initialState: {
    loading: false,
    reviewResponse: null as any,
    error: false,
  },
  reducers: {
    resetReviewResponse: (state) => {
      state.loading = false;
      state.reviewResponse = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAiReview.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postAiReview.fulfilled, (state, action) => {
      state.loading = false;
      state.reviewResponse = action.payload;
    });
    builder.addCase(postAiReview.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetReviewResponse } = postAiReviewSlice.actions;

export default postAiReviewSlice.reducer;
