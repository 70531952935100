import React from "react";
import { useNavigate } from "react-router-dom";

import EcnButton from "../components/StyledComponents/EcnButton";

const DEV_ENV = process.env.REACT_APP_API_GATEWAY_BASE;

const NoAccess = () => {
  const navigate = useNavigate();
  const policyRedirectHandler = () => {
    window.open("https://onefinnet.com/privacy", "_blank");
  };

  const contactRedirectHandler = () => {
    window.open("https://onefinnet.com/support", "_blank");
  };

  const redirectHandler = () => {
    if (
      DEV_ENV === "https://api.onefinnet.com" ||
      DEV_ENV === "https://api.elevatecareernetwork.com"
    ) {
      window.location.href = "https://admin.onefinnet.com/signup/details";
    } else if (DEV_ENV === "https://api.test.ecndev.io") {
      window.location.href = "https://admin.test.ecndev.io/signup/details";
    } else {
      window.location.href = "https://admin.dev.ecndev.io/signup/details";
    }
  };

  return (
    <div className="no-access">
      <div className="bg_circle-wrapper">
        <div className="circle circle-one" />
        <div className="circle circle-two" />
        <div className="circle circle-three" />
        <div className="circle circle-four" />
        <div className="circle circle-five" />
        <div className="circle circle-six" />
      </div>

      <div className="content">
        <div className="image">
          <img
            src="https://s1.ecnstatic.com/ecn/images/fallback-screen.png"
            alt=""
          />
        </div>

        <h1>You do not have an Enterprise account</h1>

        <h2>
          <span onClick={redirectHandler}>Sign up</span> to try Enterprise
        </h2>
      </div>

      <footer>
        <EcnButton
          className="btn-text footer-btn"
          color="secondary"
          onClick={contactRedirectHandler}
        >
          Are you having any problems?
        </EcnButton>
        <EcnButton
          className="btn-text footer-btn"
          color="secondary"
          onClick={policyRedirectHandler}
        >
          Privacy Policy
        </EcnButton>
      </footer>
    </div>
  );
};

export default NoAccess;
