import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE || "";

export const customLabelDetail = createAsyncThunk(
  "customLabelDetail",
  async (id: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ANALYTICS_BASE}/analyticsDashboard`,
      id
    );
    return data;
  }
);

const customLabelDetailSlice = createSlice({
  name: "customLabelDetail",
  initialState: {
    loading: false,
    customData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(customLabelDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(customLabelDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.customData = action.payload;
    });
    builder.addCase(customLabelDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default customLabelDetailSlice.reducer;
