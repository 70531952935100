import moment from "moment";
import React from "react";

const Education: React.FC<{ data: any }> = ({ data }) => {
  const Current_level = () => {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    if (
      (data?.created_by?.education &&
        data?.created_by?.education[0]?.graduation_year < year) ||
      (data?.created_by?.education[0]?.graduation_year === year && month > 7)
    ) {
      if (data?.created_by?.education[0]?.education_type === 0)
        return "Professional Undergraduate";
      else return "Professional MBA";
    } else if (data?.created_by?.education[0]?.education_type === 0)
      return "Undergraduate";
    else return "MBA";
  };
  return (
    <div className="experience-card">
      <h1>Education</h1>

      {data?.created_by?.education?.map((item: any) => (
        <div className="experience-list">
          <h2>{item?.name}</h2>
          <h3>{Current_level()}</h3>
          <p>{item?.graduation_year}</p>
        </div>
      ))}
    </div>
  );
};

export default Education;
