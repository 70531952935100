import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import EcnButton from "../StyledComponents/EcnButton";

const StyledTableCell = styled(TableCell)(({ }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8F8F8",
    color: "#212121",
    borderRight: "1px solid rgba(0,0,0,0.2)",
    width: "200px",
    fontSize: 14,
    fontWeight: "500",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "400",
    color: "#212121",
    width: "200px",
  },
}));

const StyledTableRow = styled(TableRow)(({ }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MapingTable: React.FC<{
  data: any;
  setUserData: any;
  setActive: any;
  setMapData: any;
  mapData: any;
  setSelectedOption: any;
  selectedOption: any;
  setCheckField: any;
  checkFiled: any;
  userData: any
  setOpen: any
}> = ({
  data,
  setUserData,
  setActive,
  setMapData,
  mapData,
  setSelectedOption,
  selectedOption,
  setCheckField,
  checkFiled,
  userData,
  setOpen
}) => {
    const navigate = useNavigate();

    const data2: any = [];
    const headers: any = data?.length > 0 ? Object.keys(data[0]) : [];

    const [total, setTotal] = useState<any>();
    const [requireField, setRequiredField] = useState<any>();

    const [error, setError] = useState<any>(false);


    useEffect(() => {
      if (data) {
        const keys = Object.keys(data[0]).map((key) => key.toLowerCase());
        const requiredFields = ["email"];
        const missingFields = requiredFields.filter(
          (field) => !keys.includes(field.toLowerCase())
        );
        setRequiredField(missingFields);
        setTotal(keys.length);
      }
    }, [data]);

    useEffect(() => {
      if (mapData) {
        data?.map((item: any, index: any) => {
          pushToAry(item, index);
        });
      }
    }, [mapData]);

    function pushToAry(item: any, val: any) {
      var obj: any = {};
      let key: any = Object.keys(item);
      key?.map((keys: any) => {
        let a = mapData?.[keys];
        if (mapData?.[keys] === "mobile") {
          obj["mobile"] = item?.[keys];
          data2?.splice(1, 1, obj);
        }
        if (mapData?.[keys] === "country") {
          obj["country"] = item?.[keys];

          data2?.splice(1, 1, obj);
        }
        if (mapData?.[keys] === "email") {
          obj["email"] = item?.[keys];

          data2?.splice(1, 1, obj);
        }

        setUserData(data2);
      });
    }

    const selectHandler = (e: any, value: any, index: number) => {
      setError(false);

      const selectedValue = e.target.value;
      setMapData((prevMapData: any) => ({
        ...prevMapData,
        [value]: selectedValue,
      }));

      if (selectedOption.includes(selectedValue)) {
        setSelectedOption((prevOptions: any) =>
          prevOptions.filter((option: any) => option !== selectedValue)
        );
      } else {
        const updatedOptions = [...selectedOption];
        updatedOptions[index] = selectedValue;
        setSelectedOption(updatedOptions);
      }
    };


    const nextHandler = () => {
      if (
        selectedOption?.includes("email")
      ) {
        setActive((next: any) => next + 1);
        navigate("?view=invite-user&step=permission");
        setOpen(false)
      } else {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
        setError(true);
      }
    };

    const removeValue = (item: any) => {
      var filteredArray = selectedOption.filter((e: any) => e != mapData[item]);
      setSelectedOption(filteredArray);
    };

    const crossHandler = (value: any) => {
      setMapData({
        ...mapData,
        [value]: "",
      });
      removeValue(value);
    };

    console.log("userData", userData)

    return (
      <div className="maping-table">
        <div className="container">
          <h3 className="title">Mapping Details</h3>
          <p className="req">Required fields *</p>
          <h4 className="field">Email</h4>
          {error && <p style={{ fontSize: "12px", color: "red" }}>Email map is required</p>}
          {requireField?.length != 0 && (
            <div className="error">
              <p className="missing">
                {" "}
                {requireField?.map((item: any, index: any) => (
                  <>
                    <span>
                      {item}
                      {index != requireField?.length - 1 && ","}
                    </span>
                  </>
                ))}
                field is missing
              </p>
              <p
                className="replace"
                onClick={() => {
                  navigate("?view=invite-user&step=user-detail")
                  setOpen(false)
                }}
              >
                reupload the file?
              </p>
            </div>
          )}
          <h4 className="detected">{total} contacts detected</h4>

          <div>
            <TableContainer component={Paper} variant="outlined">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Column Name</StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      Preview Information
                    </StyledTableCell>
                    <StyledTableCell align="center">Mapped</StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ borderRight: "none" }}
                    >
                      Dashboard Property
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {headers?.map((item: any, index: any) => (
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}
                      >
                        {item}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          borderRight: "1px solid rgba(0,0,0,0.2)",
                          width: 100,
                        }}
                      >
                        {data?.map((value: any) => (
                          <p style={{ maxWidth: "300px", overflow: "scroll" }}>
                            {value?.[item]}
                          </p>
                        ))}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          borderRight: "1px solid rgba(0,0,0,0.2)",
                          color: "rgba(33, 33, 33, 0.5);",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "rgba(33, 33, 33, 0.5)" }}>
                          {mapData?.[item] === undefined ||
                            mapData?.[item] === "" ? (
                            "unmapped"
                          ) : (
                            <Icon
                              icon="lucide:check"
                              width={24}
                              height={24}
                              style={{ color: "#229651" }}
                            />
                          )}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ width: "300px" }}
                        style={{ display: "flex", width: "100%", }}
                      >
                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select"
                            size="small"
                            displayEmpty // Enables display when no option is selected
                            value={mapData && mapData[item] ? mapData[item] : ""} // Default value handling
                            onChange={(e) => selectHandler(e, item, index)}
                            sx={{
                              fontSize: "12px", // Reduced font size
                            }}
                            renderValue={(selected) =>
                              option.length === 0
                                ? "No Options Available" // Message when no options exist
                                : selected || "Select Dashboard Property" // Placeholder for selection
                            }
                          >
                            {option.length > 0 ? (
                              option.map((items: any) => (
                                <MenuItem
                                  key={items.field}
                                  value={items.field}
                                  disabled={selectedOption?.includes(items.field)} // Disable already selected
                                  sx={{
                                    fontSize: "12px", // Adjusted font size for options
                                  }}
                                >
                                  {items?.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled sx={{ display: "none" }} /> // Prevent actual clickable menu item
                            )}
                          </Select>
                        </FormControl>

                        {selectedOption?.length != 0 &&
                          selectedOption?.includes(mapData[item]) && (
                            <Icon
                              icon="basil:cross-outline"
                              width={40}
                              height={40}
                              onClick={() => crossHandler(item)}
                            />
                          )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>

        <div className="bottom">
          <p></p>
          <EcnButton
            variant="contained"
            className="btn-contained"
            onClick={nextHandler}
          >
            Confirm
          </EcnButton>
        </div>
      </div>
    );
  };

export default MapingTable;

const option = [

  {
    field: "email",
    name: "Email",
  },
  {
    field: "mobile",
    name: "Mobile",
  },
  {
    field: "country",
    name: "country",
  },


];
