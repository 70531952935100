import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { composedEmails } from "../../../redux/slices/MailsSlices/composedEmailsSlice";
import RepliedBody from "./RepliesBody";

const ReplyCard: React.FC<{ setIsReply: any; item: any; isReply: any }> = ({
  setIsReply,
  item,
  isReply,
}) => {
  const dispatch = useAppDispatch();
  const { repliesData } = useAppSelector((state: any) => state.allRepliesData);
  const { userInfo, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );
  const parentDivRef = useRef<HTMLDivElement>(null);
  const [draftData, setIsDraftData] = useState("");

  useEffect(() => {
    // Scroll to the bottom of the parent div when component mounts or when repliesData changes
    if (parentDivRef?.current) {
      parentDivRef.current.scrollTop = parentDivRef.current.scrollHeight;
      // Smooth scroll to the bottom
      parentDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, [repliesData]);

  // useEffect(() => {
  //   if (!isReply) {
  //     if (draftData?.length > 0) {
  //       dispatch(
  //         composedEmails({
  //           email_id: "",
  //           __comment__: "Keep the email id empty for new emails",
  //           job_id: item?.job_id,
  //           user_ids: [item?.from_user_id],
  //           sender_name: userInfo?.first_name + " " + userInfo?.last_name,
  //           subject: repliesData?.result[0]?.subject,
  //           body: draftData,
  //           is_draft: true,
  //           attachments: smallFiles,
  //         })
  //       );
  //     }
  //   }
  // }, [isReply, isDraft]);

  return (
    <div className="reply-card" ref={parentDivRef}>
      <Avatar className="avatar">
        {item?.from_name[0] + " " + item?.from_name[1]}
      </Avatar>
      <div className="reply-card-right">
        <div className="reply-top">
          <h3>{item?.from_name}</h3>
          <Button
            className="discard"
            onClick={() => {
              setIsReply(false);
            }}
          >
            Discard
          </Button>
        </div>
        <RepliedBody
          setIsReply={setIsReply}
          item={item}
          isReply={isReply}
          draftData={draftData}
          setIsDraftData={setIsDraftData}
        />
      </div>
    </div>
  );
};

export default ReplyCard;
