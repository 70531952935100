import React, { useEffect, useState } from "react";

import layoutTwo from "../components/layout/layoutTwo";
import TopHeader from "../components/Candidates/CandidatesDetailsV2/Left/TopHeader";
import UserCard from "../components/Candidates/CandidatesDetailsV2/Left/UserCard";
import ProfileDetails from "../components/Candidates/CandidatesDetailsV2/Left/ProfileDetails";
import AiRating from "../components/Candidates/CandidatesDetailsV2/Right/AiRating";

import PromotionHourGlass from "../assets/lottie/promotions_hourglass.json";
import PromotionStars from "../assets/lottie/promotions_stars.json";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  candidatesDetails,
  reset
} from "../redux/slices/CandidatesSlices/candidatesDetialsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@mui/material";
import { generateAugRelevanyScore } from "../redux/slices/CandidatesSlices/aiGenerateAvgRelevancyScoreSlice";
import { useSnackbar } from "notistack";
import Loader from "../components/UI/Loader";
import ExpandedView from "../components/Candidates/CandidatesDetailsV2/ExpandedView";
import { getJobList } from "../redux/slices/CandidatesSlices/jobListSlice";
import NotesDrawer from "../components/Candidates/CandidatesDetailsV2/NotesDrawer";
import { getNotes } from "../redux/slices/Notes/getNotesSlice";
import Lottie from "react-lottie";
import { talentScoreHome } from "../redux/slices/CandidatesSlices/talentScoreHomeSlice";
import { candidatesList } from "../redux/slices/CandidatesSlices/candidatesListSlice";
import { getAiCredits } from "../redux/slices/dashboardSlices/getAiCreditsSlice";

const CandidateDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id");
  const view = searchParams.get("view");
  const applicationId = searchParams.get("application_id");
  const notesId = searchParams.get("notes_id");
  const currentParams = new URLSearchParams(window.location.search);
  currentParams.set('ai-enable', 'true');

  const { candidatesDetailsData, loading } = useAppSelector(
    (state: any) => state.candidateDetails
  );
  const { candidatesListData, success } = useAppSelector(
    (state: any) => state.candidateLists
  );

  const { scoreParamUpdateData } = useAppSelector(
    (state: any) => state.putScoreParamUpdate
  );

  const { updateStatus } = useAppSelector((state: any) => state.jobStatus);

  const { scoreUpdateData } = useAppSelector(
    (state: any) => state.putScoreUpdate
  );

  const {
    generateAugRelevanyScoreData,
    errorData,
    loading: generateAugLoading
  } = useAppSelector((state: any) => state.relevancyScore);

  const {
    regenerateAvgRelevancyScoreData,
  } = useAppSelector(
    (state: any) => state.regenRelevancyScore
  );

  const { getJobsListData } = useAppSelector((state: any) => state.jobsList);
  const { deleteNotesData } = useAppSelector((state: any) => state.deleteNotes);
  const { upsertNotesData } = useAppSelector((state: any) => state.upsertNotes);
  const { notesData } = useAppSelector((state: any) => state.scoringNotes);
  const { aiCredits } = useAppSelector((state: any) => state.getAiCredits);

  const [data, setData] = useState<any>();
  const [click, setClick] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedJob, setSelectedJob] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [particularNote, setParticularNote] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    dispatch(reset());
  }, []);


  useEffect(() => {
    dispatch(getAiCredits());
  }, [])

  useEffect(() => {
    dispatch(talentScoreHome(id));
  }, [notesData, scoreUpdateData, scoreParamUpdateData]);

  useEffect(() => {
    if (id) {
      dispatch(candidatesList({ id: applicationId, st: -1, limit: 10000 }));
    }
  }, [id]);

  useEffect(() => {
    if (candidatesListData) {
      const index = candidatesListData?.applications?.findIndex(
        (item: any) => item?._id === id
      );
      if (index !== -1) {
        setCurrentIndex(index);

        setCurrentItem(candidatesListData[index]);
      }
    }
  }, [candidatesListData, id, currentItem]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, candidatesListData]);

  useEffect(() => {
    if (id) dispatch(candidatesDetails(id));
  }, [
    id,
    updateStatus,
    generateAugRelevanyScoreData,
    scoreParamUpdateData,
    regenerateAvgRelevancyScoreData,
    scoreUpdateData
  ]);

  useEffect(() => {
    dispatch(getJobList({ skip: 0, limit: 10000 }));
  }, []);

  useEffect(() => {
    if (errorData && errorData?.message === "500" && click) {
      setClick(false);
    }
  }, [errorData]);

  useEffect(() => {
    if (applicationId) {
      dispatch(getNotes(applicationId));
    }
  }, [applicationId, deleteNotesData, upsertNotesData]);

  useEffect(() => {
    getJobsListData?.result?.map((job: any) => {
      if (job?._id === data?.job_id) {
        setSelectedJob(job?.job_title);
      }
    });
  }, [getJobsListData, id]);

  useEffect(() => {
    if (candidatesDetailsData) {
      setData(candidatesDetailsData);
    }
  }, [candidatesDetailsData]);

  useEffect(() => {
    if (generateAugRelevanyScoreData && click) {
      enqueueSnackbar("Generate score successfully!", {
        variant: "success"
      });
    }
  }, [generateAugRelevanyScoreData]);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      navigate(
        `?id=${candidatesListData.applications?.[newIndex]?._id}&application_id=${applicationId}`
      );
      setCurrentItem(candidatesListData.applications?.[newIndex]);
      //window.location.reload();
    }
  };

  const handleNext = () => {
    if (currentIndex < candidatesListData?.applications?.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      navigate(
        `?id=${candidatesListData.applications?.[newIndex]?._id}&application_id=${applicationId}`
      );
      setCurrentItem(candidatesListData.applications?.[newIndex]);
      //window.location.reload();
    }
  };

  const generateAiRelevancyScore = () => {

    if (!aiCredits?.credit_functionalities[0]?.enabled) {
      navigate(`?${currentParams.toString()}`)
    }
    else {
      setClick(true);
      dispatch(
        generateAugRelevanyScore({
          job_id: data?.job_id,
          user_id: data?.created_by?._id
        })
      );
    }
  };

  return (
    <div className="candidates-v2">
      {data === undefined ? (
        <Loader style={{ color: "#0034bb" }} />
      ) : (
        <>
          {view != "expand" && (
            <TopHeader
              data={data}
              selectedJob={selectedJob}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
            />
          )}
          {view != "expand" && (
            <div className="body">
              <div className="candidates-v2-left">
                <UserCard data={data} />
                <ProfileDetails
                  data={data}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  setOpen={setOpen}
                  deleteOpen={deleteOpen}
                  setDeleteOpen={setDeleteOpen}
                  setParticularNote={setParticularNote}
                />
              </div>
              <div className="candidates-v2-right">
                {!data?.ai_score_generated ? (
                  <div className="refresh">
                    <h1 className="title">
                      Onefinnet AI{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        className="svg"
                      >
                        <g clip-path="url(#clip0_1876_3106)">
                          <g filter="url(#filter0_iii_1876_3106)">
                            <path
                              d="M5.20646 3.78924C5.14802 3.95859 4.94899 4.08496 4.71387 4.17245C4.04734 4.34385 3.52106 4.5091 3.08518 4.66924C2.69949 4.81122 2.70362 5.25966 3.09034 5.39959C3.54237 5.56305 4.1099 5.71987 4.74034 5.9061C4.90981 5.95598 5.1494 6.09719 5.20887 6.27089C5.40124 6.82246 5.63805 7.36492 5.91802 7.89531C6.13149 8.30129 6.65984 8.30077 6.87468 7.89531C7.15312 7.36859 7.36521 6.79991 7.60412 6.25887C7.63316 6.17677 7.68894 6.10126 7.76653 6.03902C7.84411 5.97678 7.9411 5.92972 8.04893 5.90201C8.59761 5.7557 9.13892 5.59451 9.67177 5.41878C10.1049 5.2768 10.1138 4.81608 9.68621 4.66515C9.15052 4.47504 8.60325 4.30355 8.04618 4.15122C7.9374 4.12231 7.83917 4.07494 7.75928 4.01286C7.67938 3.95078 7.62002 3.87571 7.5859 3.79359C7.32671 3.20266 7.1624 2.70126 6.88671 2.18094C6.67187 1.77522 6.10846 1.78775 5.89327 2.19322C5.61896 2.71201 5.42749 3.22799 5.20646 3.78924Z"
                              fill="url(#paint0_linear_1876_3106)"
                            />
                          </g>
                          <path
                            d="M5.20622 3.78924C5.14778 3.95859 4.94875 4.08496 4.71362 4.17245C4.04709 4.34385 3.52081 4.5091 3.08494 4.66924C2.69925 4.81122 2.70337 5.25966 3.09009 5.39959C3.54212 5.56305 4.10965 5.71987 4.74009 5.9061C4.90956 5.95598 5.14915 6.09719 5.20862 6.27089C5.40099 6.82246 5.63781 7.36492 5.91778 7.89531C6.13125 8.30129 6.65959 8.30077 6.87444 7.89531C7.15287 7.36859 7.36497 6.79991 7.60387 6.25887C7.63292 6.17677 7.6887 6.10126 7.76628 6.03902C7.84386 5.97678 7.94086 5.92972 8.04869 5.90201C8.59736 5.7557 9.13867 5.59451 9.67153 5.41878C10.1047 5.2768 10.1136 4.81608 9.68597 4.66515C9.15027 4.47504 8.60301 4.30355 8.04594 4.15122C7.93716 4.12231 7.83893 4.07494 7.75903 4.01286C7.67914 3.95078 7.61978 3.87571 7.58565 3.79359C7.32647 3.20266 7.16215 2.70126 6.88647 2.18094C6.67162 1.77522 6.10822 1.78775 5.89303 2.19322C5.61872 2.71201 5.42725 3.22799 5.20622 3.78924Z"
                            fill="url(#paint1_linear_1876_3106)"
                          />
                          <g filter="url(#filter1_ii_1876_3106)">
                            <path
                              d="M2.43812 2.43391C2.41922 2.48839 2.35528 2.52907 2.27965 2.55721C2.06515 2.61246 1.89569 2.66567 1.75544 2.71735C1.63134 2.76288 1.63237 2.90716 1.75715 2.95218C1.90256 3.00488 2.08509 3.05528 2.2879 3.11514C2.3429 3.13125 2.41956 3.17679 2.43915 3.23281C2.49794 3.40498 2.57837 3.58711 2.66706 3.75544C2.73581 3.88616 2.90597 3.88591 2.97506 3.75544C3.06444 3.58609 3.13319 3.40293 3.20984 3.22872C3.2192 3.20228 3.23718 3.17797 3.26218 3.15793C3.28719 3.1379 3.31844 3.12276 3.35319 3.11386C3.52984 3.06685 3.70412 3.01506 3.87569 2.95858C3.907 2.94817 3.93366 2.93131 3.95252 2.90999C3.97138 2.88867 3.98165 2.86378 3.98212 2.83826C3.98259 2.81274 3.97324 2.78765 3.95517 2.76595C3.9371 2.74426 3.91107 2.72686 3.88015 2.71581C3.73303 2.66414 3.55015 2.60402 3.35215 2.55056C3.31714 2.54126 3.28552 2.52601 3.2598 2.50603C3.23408 2.48604 3.21498 2.46188 3.204 2.43544C3.12047 2.24512 3.06753 2.08395 2.97884 1.91614C2.91009 1.78567 2.72825 1.78977 2.65915 1.92023C2.57081 2.08728 2.50928 2.2533 2.43812 2.43391Z"
                              fill="url(#paint2_linear_1876_3106)"
                            />
                          </g>
                          <path
                            d="M2.43825 2.43391C2.41934 2.48839 2.3554 2.52907 2.27978 2.55721C2.06528 2.61246 1.89581 2.66567 1.75556 2.71735C1.63146 2.76288 1.6325 2.90716 1.75728 2.95218C1.90268 3.00488 2.08521 3.05528 2.28803 3.11514C2.34303 3.13125 2.41968 3.17679 2.43928 3.23281C2.49806 3.40498 2.5785 3.58711 2.66718 3.75544C2.73593 3.88616 2.90609 3.88591 2.97518 3.75544C3.06456 3.58609 3.13331 3.40293 3.20996 3.22872C3.21932 3.20228 3.2373 3.17797 3.26231 3.15793C3.28731 3.1379 3.31857 3.12276 3.35331 3.11386C3.52996 3.06685 3.70424 3.01506 3.87581 2.95858C3.90712 2.94817 3.93378 2.93131 3.95264 2.90999C3.9715 2.88867 3.98177 2.86378 3.98224 2.83826C3.98271 2.81274 3.97336 2.78765 3.95529 2.76595C3.93723 2.74426 3.9112 2.72686 3.88028 2.71581C3.73315 2.66414 3.55028 2.60402 3.35228 2.55056C3.31726 2.54126 3.28564 2.52601 3.25992 2.50603C3.2342 2.48604 3.2151 2.46188 3.20412 2.43544C3.12059 2.24512 3.06765 2.08395 2.97896 1.91614C2.91021 1.78567 2.72837 1.78977 2.65928 1.92023C2.57093 2.08728 2.5094 2.2533 2.43825 2.43391Z"
                            fill="url(#paint3_linear_1876_3106)"
                          />
                          <g filter="url(#filter2_i_1876_3106)">
                            <path
                              d="M1.56432 6.08223C1.5382 6.15821 1.44882 6.21474 1.34329 6.25414C1.04423 6.33088 0.807731 6.40507 0.612137 6.47721C0.439231 6.5409 0.44095 6.74197 0.614544 6.80465C0.817356 6.87832 1.07207 6.94867 1.35498 7.03232C1.43129 7.05458 1.53854 7.11802 1.56536 7.19604C1.64786 7.436 1.75992 7.69028 1.88367 7.92511C1.97957 8.10725 2.21676 8.107 2.31301 7.92511C2.43814 7.68874 2.53301 7.43344 2.64026 7.19041C2.65329 7.15356 2.67834 7.11967 2.71317 7.09173C2.74801 7.0638 2.79156 7.04269 2.83998 7.03027C3.08636 6.96469 3.32944 6.89241 3.56873 6.8136C3.61228 6.79901 3.64934 6.77548 3.67554 6.74577C3.70175 6.71605 3.71601 6.68139 3.71666 6.64585C3.71731 6.61031 3.70432 6.57537 3.67921 6.54513C3.6541 6.5149 3.61792 6.49062 3.57492 6.47516C3.33452 6.38989 3.08893 6.31298 2.83895 6.24467C2.79009 6.2317 2.74598 6.21042 2.71012 6.18253C2.67425 6.15464 2.64763 6.12091 2.63235 6.08402C2.51582 5.81874 2.44226 5.59388 2.31851 5.36007C2.22226 5.17844 1.96892 5.18381 1.87267 5.36595C1.74961 5.59874 1.66332 5.83025 1.56432 6.08223Z"
                              fill="url(#paint4_radial_1876_3106)"
                            />
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_iii_1876_3106"
                            x="2.53292"
                            y="1.71708"
                            width="7.63477"
                            height="6.64788"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="0.165737" dy="-0.165737" />
                            <feGaussianBlur stdDeviation="0.165737" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.937255 0 0 0 0 0.482353 0 0 0 0 0.329412 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect1_innerShadow_1876_3106"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="0.165737" dy="0.165737" />
                            <feGaussianBlur stdDeviation="0.331473" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.737255 0 0 0 0 0.615686 0 0 0 0 0.415686 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="effect1_innerShadow_1876_3106"
                              result="effect2_innerShadow_1876_3106"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="-0.265179" dy="0.165737" />
                            <feGaussianBlur stdDeviation="0.132589" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 0.901961 0 0 0 0 0.458824 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="effect2_innerShadow_1876_3106"
                              result="effect3_innerShadow_1876_3106"
                            />
                          </filter>
                          <filter
                            id="filter1_ii_1876_3106"
                            x="1.59667"
                            y="1.82031"
                            width="2.4518"
                            height="2.0995"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="0.0662946" dy="0.0662946" />
                            <feGaussianBlur stdDeviation="0.0662946" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.803922 0 0 0 0 0.6 0 0 0 0 0.262745 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect1_innerShadow_1876_3106"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="-0.0662946" dy="0.0662946" />
                            <feGaussianBlur stdDeviation="0.0662946" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.423529 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="effect1_innerShadow_1876_3106"
                              result="effect2_innerShadow_1876_3106"
                            />
                          </filter>
                          <filter
                            id="filter2_i_1876_3106"
                            x="0.483398"
                            y="5.12712"
                            width="3.33272"
                            height="2.93538"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dx="0.099442" dy="-0.099442" />
                            <feGaussianBlur stdDeviation="0.099442" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 0.458824 0 0 0 0 0.462745 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect1_innerShadow_1876_3106"
                            />
                          </filter>
                          <linearGradient
                            id="paint0_linear_1876_3106"
                            x1="8.74984"
                            y1="3.83145"
                            x2="6.07489"
                            y2="7.61509"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FFCF5A" />
                            <stop offset="1" stop-color="#FEA254" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1876_3106"
                            x1="4.83944"
                            y1="8.44096"
                            x2="6.09142"
                            y2="4.89235"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FF886D" />
                            <stop
                              offset="1"
                              stop-color="#FF886D"
                              stop-opacity="0"
                            />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_1876_3106"
                            x1="3.57869"
                            y1="2.44746"
                            x2="2.71781"
                            y2="3.66527"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FFDA72" />
                            <stop offset="1" stop-color="#F7A967" />
                          </linearGradient>
                          <linearGradient
                            id="paint3_linear_1876_3106"
                            x1="2.82256"
                            y1="4.01074"
                            x2="2.82256"
                            y2="2.95551"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FDA071" />
                            <stop
                              offset="1"
                              stop-color="#FDA071"
                              stop-opacity="0"
                            />
                          </linearGradient>
                          <radialGradient
                            id="paint4_radial_1876_3106"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(3.2102 5.69876) rotate(135.588) scale(2.05973 2.08164)"
                          >
                            <stop offset="0.365" stop-color="#FFBC64" />
                            <stop offset="1" stop-color="#FF8F6B" />
                          </radialGradient>
                          <clipPath id="clip0_1876_3106">
                            <rect
                              width="11"
                              height="8.18605"
                              fill="white"
                              transform="translate(0.483398)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </h1>

                    {generateAugLoading ? (
                      <div className="wrapper">
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: PromotionHourGlass,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice"
                            }
                          }}
                          height={200}
                          width={200}
                          speed={0.5}
                        />
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: PromotionStars
                          }}
                          height={250}
                          width={250}
                          style={{
                            position: "absolute",
                            top: "-30px",
                            left: "15%",
                            zIndex: "1"
                          }}
                        />

                        <p className="heading">AI's got this!</p>
                        <p className="sub">
                          Our AI's analysing applicants detail to provide you
                          insights
                        </p>
                      </div>
                    ) : (
                      !click && (
                        <div className="container">
                          <h2>Score</h2>

                          <div className="retry">
                            <Button onClick={generateAiRelevancyScore}>
                              <Icon
                                icon="lucide:refresh-ccw"
                                className="normal-icon"
                              />
                              Generate
                            </Button>
                            <p>Click on “Generate” to get the score</p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <>
                    <AiRating data={data} click={click} />
                  </>
                )}
              </div>
            </div>
          )}
          {view === "expand" && (
            <ExpandedView data={data} setActiveStep={setActiveStep} />
          )}
        </>
      )}
      <NotesDrawer
        data={data}
        open={open}
        setOpen={setOpen}
        setDeleteOpen={setDeleteOpen}
        particularNote={particularNote}
        setParticularNote={setParticularNote}
      />
    </div>
  );
};

export default layoutTwo(CandidateDetails);
