import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const deleteGroup = createAsyncThunk("deleteGroup", async (deleteId: any) => {
  const { data } = await axios.delete(`${GATEWAY_BASE + ADMIN_BASE}/deleteGroup`, {
    data: {
      group_id: deleteId,
    },
  });
  return data;
});

const deleteGroupSlice = createSlice({
  name: "deleteGroup",
  initialState: {
    loading: false,
    deleteGroupData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.deleteGroupData = action.payload;
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default deleteGroupSlice.reducer;
