import { Icon } from "@iconify/react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import React from "react";

interface RequestCardProps {
  request: any;
}

const RequestCard = ({ request }: RequestCardProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="request-card">
      <div className="img-wrapper">
        <img src={request.display_picture} alt="" />
      </div>

      <div className="card-content">
        <div className="card-body">
          <div className="main">
            <p className="date-time">
              {moment(request?.start_date).format("ll")}
              <Icon icon="ph:dot-outline-fill" />
              {moment(request?.start_date).format("LT")}
            </p>
            <p className="event-name">{request?.name}</p>
            <p className="city-applicants">
              {request?.address?.city && (
                <>
                  <span className="city">{request?.address?.city}</span>
                  <Icon icon="ph:dot-outline-fill" />
                </>
              )}
              <span className="applicants">
                {request?.registrations_count}+ Applicants
              </span>
            </p>
            <p className="event-scheduled-date">
              Posted on {moment(request?.start_date).format("ll")}
            </p>
          </div>

          <div className="icon-cta">
            <IconButton onClick={handleClick}>
              <Icon icon="charm:menu-kebab" width={20} height={20} />
            </IconButton>
            <Menu
              className="event-card-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <MenuItem>
                <div className="menu-item-wrapper">
                  <Icon icon="lucide:eye" width={20} height={20} />
                  <span>View</span>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="menu-item-wrapper">
                  <Icon icon="lucide:pencil" width={20} height={20} />
                  <span>Request an Edit</span>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="menu-item-wrapper">
                  <Icon icon="lucide:trash" width={20} height={20} />
                  <span>Delete</span>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
