import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

type RequestBodyType = {
  jobId: string;
  action: "bulk_status_change";
  application_ids: Array<string>;
  status: number;
};

export const bulkUpdateApplication = createAsyncThunk(
  "bulkUpdateApplication",
  async (body: RequestBodyType) => {
    const { action, status, application_ids } = body;
    const { data } = await axios.patch(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/applications/bulkUpdate/${body.jobId}`,
      {
        action,
        status,
        application_ids
      }
    );
    return data;
  }
);

const bulkUpdateApplicationSlice = createSlice({
  name: "bulkUpdateApplication",
  initialState: {
    loading: false,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(bulkUpdateApplication.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(bulkUpdateApplication.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(bulkUpdateApplication.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default bulkUpdateApplicationSlice.reducer;
