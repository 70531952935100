import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

interface selectedAiReGenerateAvgScoreProps {
  job_id: string | null;
  application_ids: string[];
}

export const selectedAiReGenerateAvgScore = createAsyncThunk(
  "selectedAiReGenerateAvgScore",
  async (reqData: selectedAiReGenerateAvgScoreProps) => {
    const { data } = await axios.post(
      `${
        GATEWAY_BASE + JOB_CREATE_BASE
      }/api/v1/application/forced/relevancy_score/selected/job`,
      reqData
    );
    return data;
  }
);

const selectedAiReGenerateAvgScoreSlice = createSlice({
  name: "selectedAiReGenerateAvgScore",
  initialState: {
    loading: false,
    selectedAiReGenerateAvgScoreData: null,
    error: false,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectedAiReGenerateAvgScore.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(selectedAiReGenerateAvgScore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.selectedAiReGenerateAvgScoreData = action.payload;
    });
    builder.addCase(selectedAiReGenerateAvgScore.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  }
});

export default selectedAiReGenerateAvgScoreSlice.reducer;
