import React from 'react'
import EcnButton from '../StyledComponents/V2/EcnButton';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

const UnlockPremium = () => {
  const navigate = useNavigate();

  const currentParams = new URLSearchParams(window.location.search);
  currentParams.set('plan', 'true');

  return (
    <div className='unlock-premium'>
      <div className="unlock-premium-container">
        <div className='unlock-premium-line'>
          <img src="https://ecn.blob.core.windows.net/ecn/images/Pattern.png" alt="Pattern Image" />
        </div>
        <div className="unlock-premium-image">
          <img src="https://s1.ecnstatic.com/ecn/images/Dropbox.png" alt="Dropbox Image" />
        </div>
        <div className="unlock-premium-body">
          <h4 className="unlock-premium-title">
            Unlock more features in our premium plan
          </h4>
          <p className="unlock-premium-description">
            Mail communication, Effortless Event Management, Streamlined Job Promotions & so much more!
          </p>
          <div>
            <EcnButton
              startIcon={<Icon icon="lucide:unlock" />}
              variant="contained"
              className="unlock-premium-button"
              onClick={() => navigate(`?${currentParams.toString()}`)}
            >
              Unlock
            </EcnButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnlockPremium