import React from "react";

import Cards from "./Cards";


const CardGrid = () => {

  return (
    <div className="card-grid">
      <Cards />
    </div>
  );
};

export default CardGrid;
