import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

interface getNotificationProps {
  filter: string;
  limit: string;
}

export const getNotification = createAsyncThunk(
  "getNotification",
  async (reqData: getNotificationProps) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + ADMIN_BASE}/getNotifications/recruit?skip=0&limit=${
        reqData.limit
      }`
    );
    return data;
  }
);

const getNotificationSlice = createSlice({
  name: "getNotification",
  initialState: {
    loading: false,
    notificationList: null as any,
    error: false,
    success: false,
  },
  reducers: {
    addNotification: (state, action) => {
      state.loading = false;
      state.notificationList = {
        result: [action.payload, ...state.notificationList?.result],
        total_count: state.notificationList?.total_count + 1,
        unread_count: state.notificationList?.unread_count + 1,
      };
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.notificationList = action.payload;
    });
    builder.addCase(getNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { addNotification } = getNotificationSlice.actions;

export default getNotificationSlice.reducer;
