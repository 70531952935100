import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const removeTemplate = createAsyncThunk(
  "removeTemplate",
  async (id: any) => {
    try {
      const { data } = await axios.delete(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/templates/delete/${id}`
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const removeTemplateSlice = createSlice({
  name: "removeTemplate",
  initialState: {
    loading: false,
    removeTemplateData: null,
    error: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(removeTemplate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeTemplate.fulfilled, (state, action) => {
      state.loading = false;
      state.removeTemplateData = action.payload;
    });
    builder.addCase(removeTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default removeTemplateSlice.reducer;
