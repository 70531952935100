import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const BASE = process.env.REACT_APP_API_GATEWAY_BASE;

// For getting state data
export const getStateData = createAsyncThunk(
  "state/getStateData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${BASE}/core-backend/v1/api/v1/state`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

const getStateSlice = createSlice({
  name: "state",
  initialState: {
    loading: false,
    stateData: null as any,
    stateToken: null as any,
    error: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStateData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStateData.fulfilled, (state, action) => {
      state.loading = false;
      state.stateData = action.payload;
    });
    builder.addCase(getStateData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

export default getStateSlice.reducer;
