import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { Close, Done } from "@mui/icons-material";
import { useAppSelector } from "../../../../../redux/hooks";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FFF",
    background: "#0034bb !important",
    border: "1px solid #0034bb",
    borderRadius: "7px",
    marginRight: "10px",
  },
  "& .MuiRating-iconHover": {
    color: "#FFF",
    background: "#0034BB",
    border: "none",
    borderRadius: "7px",
    padding: "1px",
  },
  "& .MuiRating-iconEmpty": {
    color: "#121212",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "7px",
    marginRight: "10px",
    background: "transparent",
  },
  "& .Mui-disabled": {
    color: "#FFF",
    background: "#0034bb !important",
    border: "1px solid #0034bb",
    borderRadius: "7px",
    marginRight: "10px",
  },

});


const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: (
      <span className="icon-num_1">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          1
        </span>
      </span>
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: (
      <span className="icon-num_2">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          2
        </span>
      </span>
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: (
      <span className="icon-num_3">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          3
        </span>
      </span>
    ),
    label: "Neutral",
  },
  4: {
    icon: (
      <span className="icon-num_4">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          4
        </span>
      </span>
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <span className="icon-num_5">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          5
        </span>
      </span>
    ),
    label: "Very Satisfied",
  },
  6: {
    icon: (
      <span className="icon-num_6">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          6
        </span>
      </span>
    ),
    label: "Very Dissatisfied",
  },
  7: {
    icon: (
      <span className="icon-num_7">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          7
        </span>
      </span>
    ),
    label: "Dissatisfied",
  },
  8: {
    icon: (
      <span className="icon-num_8">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          8
        </span>
      </span>
    ),
    label: "Neutral",
  },
  9: {
    icon: (
      <span className="icon-num_9">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          9
        </span>
      </span>
    ),
    label: "Satisfied",
  },
  10: {
    icon: (
      <span className="icon-num_10">
        <span
          className="path1"
          style={{
            fontFamily: "Poppins",
            marginTop: "2px",
            marginBottom: "-2px",
          }}
        >
          10
        </span>
      </span>
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const RatingTabV2: React.FC<{
  data: any;
  score: any;
  num: number;
  type: any;
  userData: any;
}> = ({ data, score, num, userData, type }) => {
  const [selected, setSelected] = useState(data.value);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState<any>(-1);

  const { error, success } = useAppSelector(
    (state: any) => state.putScoreUpdate
  );

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { scoreUpdateData, loading: scoreLoading } = useAppSelector(
    (state: any) => state.putScoreUpdate
  );

  useEffect(() => {
    if (error || success) {
      setLoading(false);
    }
  }, [success, error]);

  useEffect(() => {
    if (selected !== data.value) {
      setSelected(data.value);
    }
  }, [data]);

  const valueHandler = (newValue: any) => {
    if (newValue === selected) {
      setSelected(0);
      score(num, 0);
      setIndex(-1);
      setLoading(true);
    } else {
      setSelected(newValue);
      score(num, newValue);
      setIndex(num);
      setLoading(true);
    }
  };

  console.log("userInfo", data);

  return (
    <div className="rating-data">
      <div className="title">
        <p className="name">
          {data?.name}
          {loading && <CircularProgress size="16px" color="info" />}{" "}
          {error && <Close style={{ color: "red", fontSize: "16px" }} />}{" "}
          {success && <Done style={{ color: "green", fontSize: "16px" }} />}
        </p>
        <p>{data?.weight * 100}%</p>
      </div>

      <StyledRating
        value={type === "default" ? 0 : selected}
        onChange={(e, newValue) => valueHandler(newValue)}
        IconContainerComponent={IconContainer}
        max={10}
        readOnly={
          !(
            userInfo?.id === userData?.recruiter_id ||
            userData?.recruiter_id === "000000000000000000000000"
          ) || scoreLoading
        }
      />
    </div>
  );
};

export default RatingTabV2;
