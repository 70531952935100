import { Icon } from "@iconify/react";
import { Dialog, DialogContent, IconButton, Rating } from "@mui/material";
import Button from "@mui/material/Button";
import { enqueueSnackbar } from "notistack";

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { emailAction } from "../../../redux/slices/MailsSlices/emailActionSlice";
import { getReplies } from "../../../redux/slices/MailsSlices/getRepliesSlice";
import EcnButton from "../../StyledComponents/EcnButton";
import Loader from "../../UI/Loader";
import MessageCard from "./MessageCard";
import ReplyCard from "./ReplyCard";
import { downloadAttachment } from "../../../redux/slices/MailsSlices/downloadAttachmentSlice";

const Replies = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const id = searchParams.get("id") || "";
  const type = searchParams.get("type") || "";
  const back = searchParams.get("back-to") || "";

  const { repliesData, loading } = useAppSelector(
    (state: any) => state.allRepliesData
  );
  const { emailActionData } = useAppSelector((state: any) => state.emailAction);
  const { composedData, success: composedDataSuccess } = useAppSelector(
    (state: any) => state.composedEmail
  );

  const [openDelete, setOpenDelete] = useState(false);
  const [click, setClick] = useState(false);
  const [click2, setClick2] = useState(false);
  const [isStar, setIsStar] = useState(false);

  const [data, setData] = useState<any>();

  useEffect(() => {
    if (id) dispatch(getReplies({ id: id, skip: 0, limit: 100 }));
  }, [emailActionData, composedData, id]);

  useEffect(() => {
    if (emailActionData && click) {
      enqueueSnackbar(`${emailActionData?.message} successfully!`, {
        variant: "success",
      });
    }
  }, [emailActionData]);

  useEffect(() => {
    if (emailActionData && click2) {
      enqueueSnackbar("Email deleted successfully!", {
        variant: "success",
      });

      setOpenDelete(false);
      navigate("?view=inbox");
    }
  }, [emailActionData]);

  useEffect(() => {
    if (repliesData) {
      setIsStar(repliesData?.result[0]?.is_starred);
    }
  }, [repliesData]);

  useEffect(() => {
    if (repliesData) {
      setData(repliesData);
    }
  }, [repliesData]);

  const starHandle = (e: any) => {
    const allIds = repliesData?.result?.map((item: any) => item.id);
    if (e === "delete") {
      setClick2(true);
    } else {
      setClick(true);
      setIsStar(!isStar);
    }

    dispatch(
      emailAction({
        email_ids: allIds,
        email_action: type === "bin" ? "perma_delete" : e,
        __comment__:
          "Actions can be star, unstar, delete, restore, mark_spam, unmark_spam, read, unread",
      })
    );
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  return (
    <div className="replies">
      {!data ? (
        <Loader />
      ) : (
        <>
          <IconButton onClick={() => navigate(`?view=${back}`)}>
            <Icon icon="carbon:arrow-left" />
          </IconButton>
          <div className="top">
            <h2>{repliesData?.result[0]?.subject}</h2>
            <div className="top-right">
              {/* {!isReply &&
                repliesData?.result?.some(
                  (item: any) => item?.outbound_email === false
                ) && (
                  <Button
                    onClick={() => setIsReply(true)}
                    className="reply-button"
                  >
                    <Icon icon="lucide:reply" width={24} height={24} /> Reply{" "}
                  </Button>
                )} */}

              {!isStar ? (
                <IconButton onClick={() => starHandle("star")}>
                  {" "}
                  <Icon icon="lucide:star" width={20} height={20} />
                </IconButton>
              ) : (
                <IconButton onClick={() => starHandle("unstar")}>
                  {" "}
                  <Icon
                    icon="iconoir:star-solid"
                    width={20}
                    height={20}
                    color="#F7CA69"
                  />
                </IconButton>
              )}
              <IconButton onClick={() => setOpenDelete(true)}>
                <Icon
                  icon="lucide:trash-2"
                  width={24}
                  height={24}
                  color="rgba(0, 0, 0, 0.8)"
                />
              </IconButton>
            </div>
          </div>
          {data?.result?.map((item: any, index: any) => (
            <MessageCard item={item} index={index} key={index} />
          ))}
        </>
      )}
      <Dialog
        open={openDelete}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "20px 80px",
              borderRadius: "7px",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
      >
        <DialogContent>
          <h2
            style={{
              fontSize: "25px",
              fontWeight: "400",
              color: "rgba(33, 33, 33, 1)",
            }}
          >
            Are you sure you want to delete email ?{" "}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              marginTop: "20px",
            }}
          >
            <EcnButton
              variant="outlined"
              className="btn-outlined"
              sx={{ maxWidth: "196px !important" }}
              onClick={handleClose}
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Cancel
            </EcnButton>
            <EcnButton
              variant="outlined"
              className="btn-contained"
              onClick={() => starHandle("delete")}
              style={{
                border: "1px solid rgba(186, 0, 0, 1)",
                color: "rgba(186, 0, 0, 1)",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Delete
            </EcnButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Replies;
