import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLocation = createAsyncThunk(
  "getLocation",
  async (searchData: any) => {
    const { data } = await axios.get(
      `https://atlas.microsoft.com/search/address/json?api-version=1.0&entityType=Municipality&query=${searchData}&subscription-key=cTbC9G2Tkk76cfNABIKl5rrAdh0K5_PP_1pmc1zCN3Y`
    );
    return data;
  }
);

const getLocationSlice = createSlice({
  name: "getLocation",
  initialState: {
    loading: false,
    getLocationData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.getLocationData = action.payload;
    });
    builder.addCase(getLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getLocationSlice.reducer;
