import axios from "axios";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const MIXPANEL_PROXY_BASE = process.env.REACT_APP_MIXPANEL_PROXY_BASE || "";

interface MixPanelEvents {
  eventName:
    | "ENTERPRISE_RECRUITER_SIGNIN_CLICK"
    | "ENTERPRISE_RECRUITER_LEFT_NAVIGATION_BAR_DASHBOARD_REDIRECT"
    | "ENTERPRISE_RECRUITER_LEFT_NAVIGATION_BAR_JOB_LIST_REDIRECT"
    | "ENTERPRISE_RECRUITER_LEFT_NAVIGATION_BAR_RECRUITER_LIST"
    | "ENTERPRISE_RECRUITER_LEFT_NAVIGATION_BAR_SETTINGS_REDIRECT"
    | "ENTERPRISE_RECRUITER_DASHBOARD_VIEW_JOB_DETAILS_REDIRECT"
    | "ENTERPRISE_RECRUITER_DASHBOARD_CREATE_JOB_REDIRECT"
    | "ENTERPRISE_RECRUITER_JOB_POSTING_NEXT_STEP_CLICK"
    | "ENTERPRISE_RECRUITER_JOB_POSTING_SAVE_AS_DRAFT"
    | "ENTERPRISE_RECRUITER_JOB_POSTING_CANCELLATION"
    | "ENTERPRISE_RECRUITER_JOB_POSTING_SKIP_QUESTIONS"
    | "ENTERPRISE_RECRUITER_JOB_POSTING_EDIT"
    | "ENTERPRISE_RECRUITER_JOB_POSTING_FINISH"
    | "ENTERPRISE_RECRUITER_JOB_LIST_STATISTICS_REDIRECT_VIA_NAME"
    | "ENTERPRISE_RECRUITER_JOB_LIST_VIEW_STATS_CTA"
    | "ENTERPRISE_RECRUITER_JOB_LIST_VIEW_CANDIDATES_CTA"
    | "ENTERPRISE_RECRUITER_JOB_LIST_EDIT_JOB_REDIRECT"
    | "ENTERPRISE_RECRUITER_JOB_LIST_DELETE_JOB_INITIATION"
    | "ENTERPRISE_RECRUITER_JOB_LIST_DELETE_JOB_CONFIRMATION"
    | "ENTERPRISE_RECRUITER_JOB_LIST_DELETE_JOB_CANCELLATION"
    | "ENTERPRISE_RECRUITER_JOB_STATISTICS_JOB_SELECT"
    | "ENTERPRISE_RECRUITER_JOB_STATISTICS_VIEW_CANDIDATES_REDIRECT"
    | "ENTERPRISE_RECRUITER_JOB_STATISTICS_DURATION_FILTER_UPDATE"
    | "ENTERPRISE_RECRUITER_JOB_STATISTICS_GRAPH_VIEW_UPDATE"
    | "ENTERPRISE_RECRUITER_JOB_STATISTICS_GRAPH_DATA_UPDATE"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_JOB_SELECT"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_SEARCH"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_FILTER"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_LAYOUT_DENSITY_UPDATE"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_EXPORT_DOWNLOAD"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_EXPORT_PRINT"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_CANDIDATE_DETAILS_REDIRECT_VIA_NAME"
    | "ENTERPRISE_RECRUITER_JOB_CANDIDATES_RESUME_VIEW_CLICK"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_VIEW_RESUME"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_DOWNLOAD_RESUME"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_STATUS_UPDATE"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_EDIT_SCORE_PARAMS_REDIRECT"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_NEXT_APPLICATION_CLICK"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_PREVIOUS_APPLICATION_CLICK"
    | "ENTERPRISE_RECRUITER_SCORE_MODIFIER_CANCELLATION"
    | "ENTERPRISE_RECRUITER_SCORE_MODIFIER_SAVE_CHANGES"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_SEARCH"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_CREATE_NEW_GROUP_REDIRECT"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_ASSIGN_JOB_INITIATION"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_ASSIGN_JOB_CANCELLATION"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_ASSIGN_JOB_UPDATE"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_REMOVE_ASSIGN_JOB_INITIATION"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_REMOVE_ASSIGN_JOB_CANCELLATION"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_REMOVE_ASSIGN_JOB_UPDATE"
    | "ENTERPRISE_RECRUITER_RECRUITER_LIST_GROUP_LIST_REDIRECT"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_RECRUITER_LIST_REDIRECT"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_SEARCH"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_EDIT_GROUP'S_ASSIGNED_JOB_INITIATION"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_EDIT_GROUP'S_ASSIGNED_JOB_CANCELLATION"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_EDIT_GROUP'S_ASSIGNED_JOB_SAVE_CHANGES"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_GROUP_DETAILS_REDIRECT"
    | "ENTERPRISE_RECRUITER_GROUP_LIST_CREATE_NEW_GROUP_REDIRECT"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_SEARCH"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_ADD_NEW_MEMBER_REDIRECT"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_GROUP_SETTINGS_VIEW"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_UPDATE_SETTINGS"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_DELETE_GROUP_MEMBER_INITIATION"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_DELETE_GROUP_MEMBER_CONFIRMATION"
    | "ENTERPRISE_RECRUITER_GROUP_DETAILS_DELETE_GROUP_MEMBER_CANCELLATION"
    | "ENTERPRISE_RECRUITER_ADD_MEMBERS_ADD_MORE_CLICK"
    | "ENTERPRISE_RECRUITER_SETTINGS_NOTIFICATION_UPDATE"
    | "ENTERPRISE_RECRUITER_SETTINGS_PASSWORD_CHANGE_REQUEST"
    | "ENTERPRISE_RECRUITER_SETTINGS_PASSWORD_CHANGE_RESEND_REQUEST"
    | "ENTERPRISE_RECRUITER_SETTINGS_SUPPORT_MESSAGE_SEND"
    | "ENTERPRISE_RECRUITER_CANDIDATE_DETAILS_SCORE_UPDATE";

  url: string;
  properties: any;
  distinctId: string;
}

export const trackMixpanelEvents = (e: MixPanelEvents) => {
  try {
    const res = axios.post(`${GATEWAY_BASE + MIXPANEL_PROXY_BASE}/trackEvent`, {
      event_name: e.eventName,
      enterprise: true,
      url: e.url,
      properties: e.properties,
      distinct_id: e.distinctId,
    });
  } catch (error) {
    console.log(error);
  }
};

interface MixPanelProfile {
  distinctId: string;
  properties: any;
}

export const updateMixpanelProfile = (e: MixPanelProfile) => {
  try {
    const res = axios.post(`${GATEWAY_BASE + MIXPANEL_PROXY_BASE}/updateProfile`, {
      properties: e.properties,
      enterprise: true,
      distinct_id: e.distinctId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const trackAndUpdateUserEvent = (e: MixPanelEvents) => {
  updateMixpanelProfile(e);
  trackMixpanelEvents(e);
};
