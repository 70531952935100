import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { downloadAttachment } from "../../../redux/slices/MailsSlices/downloadAttachmentSlice";

const Attachment: React.FC<{ data: any }> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { downloadData } = useAppSelector(
    (state: any) => state.downloadAttachment
  );

  const downloadAttachmentHandler = (
    attachmentData: string,
    attachmentFileName: string
  ) => {
    try {
      // Decode base64 data
      const byteCharacters = atob(attachmentData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create Blob from byte array
      const blob = new Blob([byteArray], { type: "application/octet-stream" });

      // Create URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create link element
      const link = document.createElement("a");
      link.href = url;
      link.download = attachmentFileName;

      // Append link to document body
      document.body.appendChild(link);

      // Trigger click event
      link.click();

      // Remove the link element after clicking
      link.remove();

      // Revoke the URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading attachment:", error);
    }
  };

 

  return (
    <div className="attach">
      {data &&
        data?.attachments?.length > 0 &&
        data?.attachments?.map((ite: any) => (
          <p
            onClick={() =>
              downloadAttachmentHandler(
                ite?.attachmentData,
                ite?.attachmentFileName
              )
            }
            className="file"
          >
            {ite?.attachmentFileName}
            <Icon icon="lucide:download" width={24} height={24} />
          </p>
        ))}
    </div>
  );
};

export default Attachment;
