import { Box, Button } from "@mui/material";
import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { reset } from "../../../redux/slices/JobPromotions/getJobPromoFieldSlice";

const Cards = () => {
  const navigate = useNavigate();
  const dispatch=useAppDispatch();

  const { jobPromoFieldData, errorData, loading } = useAppSelector(
    (state: any) => state.getJobPromoField
  );

  const {
    promoRequestData,
    loading: promoRequestLoading,
    error,
  } = useAppSelector((state: any) => state.promoRequestData);


  return (
    <div className="cards">
      {loading && (
        <div className="loading-card">
          <h1>Hang On !</h1>
          <p>We've are getting the Promotions Page ready for you ! </p>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              sx={{
                backgroundColor: "#A7CAED",
                "& .MuiLinearProgress-bar": { backgroundColor: "#1876D1" },
              }}
            />
          </Box>
        </div>
      )}
      {!loading &&
        (promoRequestData ||
          errorData?.message === "request-already-submitted") && (
          <div className="success-card">
            <h1>Details Received !</h1>
            <p>
              We've got your information and our team will contact you very soon
              !{" "}
            </p>
            <div className="image">
              <img
                src="https://s1.ecnstatic.com/ecn/images/common/MicrosoftTeams-image (26).png"
                alt=""
              />
            </div>
            <div className="button">
              <Button
                className="submit-button"
                onClick={() => {navigate("/jobs"); dispatch(reset());}}
              >
                View Jobs
              </Button>
            </div>
          </div>
        )}

      {!loading &&
        (error || errorData?.message === "promotion-not-allowed") && (
          <div className="error-card">
            <h1>Oh noo.. !</h1>
            <p>This Job is not eligible for Promotions</p>
            <div className="image">
              <img
                src="https://s1.ecnstatic.com/ecn/images/common/MicrosoftTeams-image (27).png"
                alt=""
              />
            </div>
            <div className="button">
              <Button
                className="submit-button"
                onClick={() => {navigate("/jobs"); dispatch(reset());}}
              >
                View Jobs
              </Button>
            </div>
          </div>
        )}
    </div>
  );
};

export default Cards;
