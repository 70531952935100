import React from "react";

const Questions: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="questions">
      <h1>Questions & Answers</h1>

      {data?.job_questions?.map((item: any) => (
        <>
          {item?.type === 0 && (
            <div className="ques">
              <p>{item?.name}</p>
              <p className="ans">{item?.values[0]}</p>
            </div>
          )}
          {item?.type === 5 && (
            <div className="ques">
              <p>{item?.name}</p>
              {item?.values?.map((ans: any) => (
                <p className="ans">{ans}</p>
              ))}
            </div>
          )}

          {item?.type === 2 && (
            <div className="ques">
              <p>{item?.name}</p>

              <p className="ans">{item?.values[0]}</p>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default Questions;
