import { useAppSelector } from "../../redux/hooks";
import Loader from "../UI/Loader";
import SideBar from "../Nav/SideBar";
import NewSideBar from "../Nav/NewSideBar";
import Header from "../Nav/Header";

export default function layoutTwo(Component: any) {
  function LayoutTwo(props: any) {
    const { loading } = useAppSelector((state: any) => state.signOut);
    return (
      <div className="layout-two">
        <Header />
        <div className="page">
          <div className="page-left">
            <NewSideBar />
          </div>
          <div className="page-right">
            {loading ? <Loader /> : <Component {...props} />}
          </div>
        </div>
      </div>
    );
  }

  return LayoutTwo;
}
