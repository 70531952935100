import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

interface bulkForcedAiScoreProps {
  job_id: string | null;
  user_id: string | null;
}

export const bulkForcedAiScore = createAsyncThunk(
  "bulkForcedAiScore",
  async (reqData: bulkForcedAiScoreProps) => {
    try {
      const { data } = await axios.post(
        `${
          GATEWAY_BASE + JOB_CREATE_BASE
        }/api/v1/application/forced/relevancy_score/job`,
        reqData
      );
      return data;
    } catch (error: any) {
      throw error.response?.data?.statusCode;
    }
  }
);

const bulkForcedAiScoreSlice = createSlice({
  name: "bulkForcedAiScore",
  initialState: {
    loading: false,
    bulkForcedAiScoreData: null,
    error: false,
    success: false,
    errorData: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(bulkForcedAiScore.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(bulkForcedAiScore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.bulkForcedAiScoreData = action.payload;
    });
    builder.addCase(bulkForcedAiScore.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errorData = action.error;
    });
  }
});

export default bulkForcedAiScoreSlice.reducer;
