import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import Candidates from "./Graph/Candidates";
import Diversity from "./Graph/Diversity";
import Functions from "./Graph/Functions";
import GraduationYear from "./Graph/GraduationYear";
import Line from "./Graph/Line";
import SourceAndAi from "./Graph/SourceAndAi";
import University from "./Graph/University";

const PromotionAnalitics: React.FC<{ active: any }> = ({ active }) => {
  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

 
  return (
    <div className="promotion-analytics">
      <University data={promoAnalytics} />
      <SourceAndAi />
      <Line />
      <Functions />
      <GraduationYear active={active} />
      <Diversity />
      <Candidates />
    </div>
  );
};

export default PromotionAnalitics;
