import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const regenerateAvgRelevancyScore = createAsyncThunk(
  "regenerateAvgRelevancyScore",

  async (AvgData: any) => {
    try {
      const { data } = await axios.post(
        `${
          GATEWAY_BASE + JOB_CREATE_BASE
        }/api/v1/application/forced/relevancy_score`,
        AvgData
      );
      return data;
    } catch (error: any) {
      // Throw the entire error object to be caught in the rejected case
      throw error.response?.data?.statusCode;
    }
  }
);

const regenerateAvgRelevancyScoreSlice = createSlice({
  name: "regenerateAvgRelevancyScore",
  initialState: {
    loading: false,
    regenerateAvgRelevancyScoreData: null,
    error: false,
    success: false,
    errorData: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(regenerateAvgRelevancyScore.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(regenerateAvgRelevancyScore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.regenerateAvgRelevancyScoreData = action.payload;
    });
    builder.addCase(regenerateAvgRelevancyScore.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errorData = action.error;
    });
  }
});

export default regenerateAvgRelevancyScoreSlice.reducer;
