import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getJobMemberAndGroup = createAsyncThunk(
  "getJobMemberAndGroup",
  async (id: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + ADMIN_BASE}/getJobGroupsAndMembers`,
      id
    );
    return data;
  }
);

const getJobMemberAndGroupSlice = createSlice({
  name: "getJobMemberAndGroup",
  initialState: {
    loading: false,
    memberData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobMemberAndGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobMemberAndGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.memberData = action.payload;
    });
    builder.addCase(getJobMemberAndGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getJobMemberAndGroupSlice.reducer;
