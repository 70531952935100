import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getReplies = createAsyncThunk(
  "getReplies",
  async (idData: any) => {
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/emails/replies/${
          idData?.id
        }?skip=${idData?.skip}&limit=${idData?.limit}`
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const getRepliesSlice = createSlice({
  name: "getReplies",
  initialState: {
    loading: false,
    repliesData: null,
    error: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.repliesData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReplies.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getReplies.fulfilled, (state, action) => {
      state.loading = false;
      state.repliesData = action.payload;
    });
    builder.addCase(getReplies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});
export const { reset } = getRepliesSlice.actions;
export default getRepliesSlice.reducer;
