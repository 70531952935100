import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";
import { RootState } from "../../store";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

const allowedIds = [
  "660299e74b1e7ea4f4a8c678",
  "65f02c2b0d79a389f38f11d8"
  // Add more IDs here as needed
];

export const postFilterJob = createAsyncThunk(
  "postFilterJob",
  async (filterData: any, { getState }) => {
    const state = getState() as RootState;
    const { userInfo } = state.userDetails;

    console.log(userInfo);
    try {
      if (allowedIds.includes(userInfo?.id)) {
        const { data } = await axios.post(
          `${
            GATEWAY_BASE + JOB_CREATE_BASE
          }/api/v1/dashboard/demo/get_filtered_jobs?skip=${
            filterData.skip ?? 0
          }&limit=${filterData.limit ?? 10}`,
          filterData
        );

        return data;
      } else {
        const { data } = await axios.post(
          `${
            GATEWAY_BASE + JOB_CREATE_BASE
          }/api/v1/dashboard/get_filtered_jobs?skip=${
            filterData.skip ?? 0
          }&limit=${filterData.limit ?? 10}`,
          filterData
        );

        return data;
      }
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const postFilterJobSlice = createSlice({
  name: "postFilterJob",
  initialState: {
    loading: false,
    filterJobData: null,
    error: null as any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postFilterJob.pending, (state, action) => {
      state.loading = true;
      // state.filterJobData = null;
    });
    builder.addCase(postFilterJob.fulfilled, (state, action) => {
      state.loading = false;
      state.filterJobData = action.payload;
    });
    builder.addCase(postFilterJob.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  }
});

export default postFilterJobSlice.reducer;
