import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getSentEmail = createAsyncThunk(
  "getSentEmail",
  async (inboxData: any) => {
    try {
      const { data } = await axios.get(
        `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/emails/folders/sent?skip=${
          inboxData?.skip
        }&limit=${inboxData?.limit}&job_id=${inboxData?.job_id}`
      );
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

const getSentEmailSlice = createSlice({
  name: "getSentEmail",
  initialState: {
    loading: false,
    sentData: null,
    error: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSentEmail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSentEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.sentData = action.payload;
    });
    builder.addCase(getSentEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getSentEmailSlice.reducer;
