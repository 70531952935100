import { Icon } from "@iconify/react";
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { downloadAttachment } from "../../../redux/slices/MailsSlices/downloadAttachmentSlice";
import Attachment from "./Attachment";
import axios from "../../../redux/axiosInterceptor";
import ReplyCard from "./ReplyCard";
const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

const MessageCard: React.FC<{
  item: any;
  index: any;
}> = ({ item, index }) => {
  const dispatch = useAppDispatch();
  const { repliesData } = useAppSelector((state: any) => state.allRepliesData);
  const { downloadData, loading } = useAppSelector(
    (state: any) => state.downloadAttachment
  );
  const [isFindClass, setIsFindClass] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [fineNames, setFilesName] = useState<any>();

  useEffect(() => {
    if (item) {
      setIsFindClass(!item?.outbound_email);
    }
  }, [item]);

  useEffect(() => {
    const fetchData = async () => {
      if (item?.id) {
        try {
          const { data } = await axios.get(
            `${GATEWAY_BASE + JOB_CREATE_BASE
            }/api/v1/emails/download_attachments/${item?.id}`
          );

          setFilesName(data);
          // Dispatch your data or do something with it here
        } catch (error) {
          console.log("datta", error);
        }
      }
    };

    fetchData();
  }, [item?.id]);

  const toggleVisibility = () => {
    const gmailQuotes = document.querySelectorAll(".gmail_quote");

    gmailQuotes.forEach((quote) => {
      quote.classList.toggle("hidden");
    });
  };

  const isReplyHandler = () => {
    setIsReply(true);
  };

  return (
    <>
      <div
        className={item?.outbound_email ? "my-card" : ""}
        id={`quote-${index}`}
      >
        {item?.outbound_email && (
          <Avatar className="avatar">
            {item?.from_name[0] + item?.from_name[1]}
          </Avatar>
        )}
        <div className="message-card">
          <div className="message-card-top">
            <div className="top-left">
              {!item?.outbound_email && (
                <Avatar className="avatar">
                  {item?.from_name[0] + item?.from_name[1]}
                </Avatar>
              )}
              <div className="name">
                <h3>{item?.from_name}</h3>
                <p>{item?.from_email}</p>
                {!item?.outbound_email && <p style={{ fontSize: "12px", color: "rgba(33, 33, 33, 0.70)" }}>to me</p>}
              </div>


            </div>
            <div className="top-right">
              <h3>{moment.unix(item?.createdAt).format("dddd DD MMMM")}</h3>
              <p>{moment.unix(item?.createdAt).format("h:mm A")}</p>
            </div>
          </div>
          {item?.outbound_email && <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
            <p style={{ fontSize: "12px", color: "rgba(33, 33, 33, 0.70)" }}>to</p>
            {item.to_emails.map((mail: any) => (
              <>
                <p style={{ fontSize: "12px", color: "rgba(33, 33, 33, 0.70)" }}>{mail}</p>
                {item?.to_emails?.length > 1 ? ", " : ""}
              </>

            ))}

          </div>}
          <div className="message-body">
            <div dangerouslySetInnerHTML={{ __html: item?.body }} />

            {loading && (
              <div className="attach">
                {item?.attachments?.length != 0 &&
                  item?.attachments?.map((ite: any) => (
                    <p className="file" style={{ cursor: "progress" }}>
                      {ite?.attachmentFileName}
                    </p>
                  ))}
                {/* <CircularProgress size={16} /> */}
              </div>
            )}
            {!loading && item.attachments && item.attachments.length > 0 && (
              <Attachment data={fineNames} />
            )}
          </div>

          {isFindClass && (
            <Tooltip title="Hide expended content" onClick={toggleVisibility}>
              <div className={"menu-icon "}>
                <Icon
                  icon="charm:menu-kebab"
                  style={{ transform: "rotate(90deg)" }}
                />
              </div>
            </Tooltip>
          )}

          {!isReply && !item?.outbound_email && (
            <Button className="reply" onClick={isReplyHandler}>
              <Icon icon="lucide:reply" width={24} height={24} /> Reply
            </Button>
          )}
        </div>
      </div>
      {isReply && <ReplyCard setIsReply={setIsReply} item={item} isReply={isReply} />}
    </>
  );
};

export default MessageCard;
