import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getJobListPagination = createAsyncThunk(
  "getJobListPagination",
  async (uri: any) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/enterprise/jobs/recruiter?skip=${
        uri.skip
      }&limit=${uri.limit}`
    );

    return data;
  }
);

const getJobListPaginationSlice = createSlice({
  name: "getJobListPagination",
  initialState: {
    loading: false,
    getJobsListData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobListPagination.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobListPagination.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.getJobsListData = action.payload;
    });
    builder.addCase(getJobListPagination.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getJobListPaginationSlice.reducer;
