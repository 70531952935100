import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import ResumePopupTable from "./ResumePopupTable";

const ManualResumeScreenPopup = ({
    open,
    setOpen,
    rows,
    selectedRows,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    rows: any;
    selectedRows: any;
}) => {

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="manual-resume-screen-popup"
        >
            <div className="static-content">
                <div>
                    <h6>Screen Resumes</h6>
                    <Icon icon="lucide:x" fontSize={24} onClick={() => setOpen(false)} />
                </div>
                <p>Select your desired number of candidates for screening the resumes.</p>
                <p className="sub-text">Note : This will cost you <span>10 credit</span> per candidate so keep that in mind</p>
            </div>

            <ResumePopupTable
                selectedRows={selectedRows}
                rows={rows}
                setOpen={setOpen}
            />

        </Dialog>
    )
}

export default ManualResumeScreenPopup
