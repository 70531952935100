import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMemberList } from "../../redux/slices/Group Creation/getMemberListSlice";
import GloableAccess from "./GloableAccess";
import JobAccess from "./JobAccess";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const AccessTab = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const { profile, errorData } = useAppSelector((state: any) => state.editUser);
  const { getMemberListData, success } = useAppSelector(
    (state: any) => state.getAllMembers
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  useEffect(() => {
    dispatch(
      getMemberList({
        filters: [
          { type: "signup", signup_completed: true }
        ],
        op: "and",
        skip: 0,
        limit: 1000,
      })
    );
  }, [profile]);

  return (
    <div className="access-tab">
      {/* <Tabs value={activeStep} onChange={handleChange} className="tabs">
        <Tab label="Global Access" />
        <Tab label="Job A
        ccess" />
      </Tabs> */}
      <div className="tab-switch">
        <h1>All Users  <span>({getMemberListData?.total_count})</span></h1>

      </div>
      <div className="invite-button">
        <button onClick={() => navigate("/invites")}><Icon icon="lucide:plus" /> Add User</button>

      </div>
      <div className="global-accesss">

        {activeStep === 0 && <GloableAccess />}
        {/* {activeStep === 1 && <JobAccess />} */}
      </div>
    </div>
  );
};

export default AccessTab;
