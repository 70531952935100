import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getTimeZones = createAsyncThunk("getTimeZones", async () => {
  const { data } = await axios.get(`${GATEWAY_BASE + ADMIN_BASE}/getTimeZones`);
  return data;
});

const getTimeZonesSlice = createSlice({
  name: "getTimeZones",
  initialState: {
    loading: false,
    timeZones: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTimeZones.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTimeZones.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.timeZones = action.payload;
    });
    builder.addCase(getTimeZones.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getTimeZonesSlice.reducer;
