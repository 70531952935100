import React from "react";

const Fallback: React.FC<{ message: any }> = ({ message }) => {
  return (
    <div className="fallback">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="49"
        viewBox="0 0 40 49"
        fill="none"
      >
        <path
          d="M26.6583 2H13.1364C11.8918 2 10.8828 3.00899 10.8828 4.25363V8.7609C10.8828 10.0056 11.8918 11.0145 13.1364 11.0145H26.6583C27.9029 11.0145 28.9119 10.0056 28.9119 8.7609V4.25363C28.9119 3.00899 27.9029 2 26.6583 2Z"
          stroke="#212121"
          stroke-opacity="0.6"
          stroke-width="2.64815"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.9098 6.50098H33.4171C34.6125 6.50098 35.7589 6.97585 36.6042 7.82112C37.4495 8.6664 37.9244 9.81284 37.9244 11.0082V42.5591C37.9244 43.7545 37.4495 44.901 36.6042 45.7462C35.7589 46.5915 34.6125 47.0664 33.4171 47.0664H6.37348C5.17808 47.0664 4.03164 46.5915 3.18636 45.7462C2.34108 44.901 1.86621 43.7545 1.86621 42.5591V11.0082C1.86621 9.81284 2.34108 8.6664 3.18636 7.82112C4.03164 6.97585 5.17808 6.50098 6.37348 6.50098H10.8807M26.6562 22.2764L13.1344 35.7982M13.1344 22.2764L26.6562 35.7982"
          stroke="#212121"
          stroke-opacity="0.6"
          stroke-width="2.64815"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h4>{message}.</h4>
    </div>
  );
};

export default Fallback;
