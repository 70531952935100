import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import ToolTips from "./toolTips";
import Fallback from "../../Fallback";

const SourceAndAi = () => {
  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );
  const emailReached = promoAnalytics?.source_analytics?.results?.email_reached || 0;
  const platformReached =
    promoAnalytics?.source_analytics?.results?.platform_reached || 0;
  const socialReached = promoAnalytics?.source_analytics?.results?.social_reached || 0;
  // Calculate the total sum of reached counts
  const totalReached = emailReached + platformReached + socialReached;

  return (
    <div className="source-ai">
      <div className="source">
        <h1>
          Source Analytics{" "}
          <ToolTips title={promoAnalytics?.ai_promotion_message?.tooltip} />
        </h1>
        {emailReached != 0 || platformReached != 0 || socialReached != 0 ? (
          <div className="wrapper">
            <PieChart
              margin={{ top: 10, left: 20, right: 20, bottom: 10 }}
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: promoAnalytics?.source_analytics?.results?.email_reached,
                      label: "",
                      color: "rgba(149, 169, 234, 1)",
                    },
                    {
                      id: 1,
                      value: promoAnalytics?.source_analytics?.results?.platform_reached,
                      label: "",
                      color: "rgba(198, 209, 238, 1)",
                    },
                    {
                      id: 2,
                      value: promoAnalytics?.source_analytics?.results?.social_reached,
                      label: "",
                      color: "rgba(147, 197, 253, 1)",
                    },
                  ],
                },
              ]}
              height={140}
              slotProps={{ legend: { hidden: true } }}
            />
            <div className="pie-legend">
              <div className="pie-legend-list">
                {" "}
                <div className="pie-legend-list-item">
                  <div
                    className="dots"
                    style={{ background: "rgba(149, 169, 234, 1)" }}
                  ></div>
                  <p>Email </p>
                </div>
                <span>
                  {`${Math.floor((emailReached / totalReached) * 100)}%`}
                </span>
              </div>
              <div className="pie-legend-list">
                {" "}
                <div className="pie-legend-list-item">
                  <div
                    className="dots"
                    style={{ background: "rgba(198, 209, 238, 1)" }}
                  ></div>
                  <p>PlatForm </p>
                </div>
                <span>
                  {`${Math.floor((platformReached / totalReached) * 100)}%`}
                </span>
              </div>
              <div className="pie-legend-list">
                {" "}
                <div className="pie-legend-list-item">
                  <div
                    className="dots"
                    style={{ background: "rgba(147, 197, 253, 1)" }}
                  ></div>
                  <p>Social </p>
                </div>
                <span>
                  {`${Math.floor((socialReached / totalReached) * 100)}%`}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Fallback message="No source analytics data available Yet" />
        )}
      </div>
      {promoAnalytics?.ai_promotion_message?.results?.length ?? 0 ? (
        <div className="ai">
          <h2>
            AI
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              className="star"
            >
              <g clip-path="url(#clip0_471_6779)">
                <g filter="url(#filter0_iii_471_6779)">
                  <path
                    d="M3.86404 4.1663C3.81622 4.35249 3.65338 4.49143 3.461 4.58761C2.91566 4.77605 2.48507 4.95774 2.12844 5.1338C1.81288 5.28989 1.81625 5.78292 2.13266 5.93677C2.5025 6.11649 2.96685 6.28889 3.48266 6.49364C3.62132 6.54849 3.81735 6.70374 3.866 6.89471C4.0234 7.50112 4.21716 8.09751 4.44622 8.68064C4.62088 9.12699 5.05316 9.12642 5.22894 8.68064C5.45675 8.10155 5.63029 7.47633 5.82575 6.88149C5.84952 6.79123 5.89516 6.70821 5.95863 6.63978C6.02211 6.57135 6.10147 6.51961 6.18969 6.48914C6.63861 6.32828 7.0815 6.15107 7.51747 5.95786C7.87185 5.80177 7.87916 5.29524 7.52928 5.1293C7.09099 4.92029 6.64323 4.73174 6.18744 4.56427C6.09844 4.53249 6.01807 4.4804 5.9527 4.41216C5.88734 4.34391 5.83877 4.26137 5.81085 4.17108C5.59879 3.52139 5.46435 2.97014 5.23879 2.39808C5.063 1.95202 4.60204 1.9658 4.42597 2.41158C4.20154 2.98196 4.04488 3.54924 3.86404 4.1663Z"
                    fill="url(#paint0_linear_471_6779)"
                  />
                </g>
                <path
                  d="M3.86355 4.16435C3.81573 4.35053 3.65289 4.48947 3.46052 4.58566C2.91517 4.7741 2.48458 4.95578 2.12795 5.13185C1.81239 5.28794 1.81577 5.78097 2.13217 5.93482C2.50202 6.11453 2.96636 6.28694 3.48217 6.49169C3.62083 6.54653 3.81686 6.70178 3.86552 6.89275C4.02291 7.49917 4.21667 8.09556 4.44573 8.67869C4.62039 9.12503 5.05267 9.12447 5.22845 8.67869C5.45627 8.0996 5.6298 7.47438 5.82527 6.87953C5.84903 6.78927 5.89467 6.70626 5.95815 6.63783C6.02162 6.5694 6.10098 6.51766 6.1892 6.48719C6.63812 6.32633 7.08101 6.14912 7.51698 5.95591C7.87136 5.79982 7.87867 5.29328 7.5288 5.12735C7.0905 4.91834 6.64274 4.72979 6.18695 4.56232C6.09795 4.53053 6.01758 4.47845 5.95222 4.4102C5.88685 4.34195 5.83828 4.25941 5.81036 4.16913C5.5983 3.51944 5.46386 2.96819 5.2383 2.39613C5.06252 1.95007 4.60155 1.96385 4.42548 2.40963C4.20105 2.98 4.04439 3.54728 3.86355 4.16435Z"
                  fill="url(#paint1_linear_471_6779)"
                />
                <g filter="url(#filter1_ii_471_6779)">
                  <path
                    d="M1.59955 2.6746C1.58408 2.73451 1.53177 2.77923 1.4699 2.81017C1.2944 2.87092 1.15574 2.92942 1.04099 2.98623C0.939459 3.03629 0.940303 3.19492 1.0424 3.24442C1.16137 3.30235 1.31071 3.35776 1.47665 3.42357C1.52165 3.44129 1.58437 3.49135 1.6004 3.55295C1.64849 3.74223 1.7143 3.94248 1.78687 4.12754C1.84312 4.27126 1.98233 4.27098 2.03887 4.12754C2.11199 3.94135 2.16824 3.73998 2.23096 3.54845C2.23862 3.51938 2.25333 3.49265 2.27379 3.47062C2.29424 3.44859 2.31982 3.43195 2.34824 3.42217C2.49277 3.37049 2.63537 3.31354 2.77574 3.25145C2.80136 3.24 2.82317 3.22146 2.8386 3.19803C2.85403 3.17459 2.86244 3.14722 2.86282 3.11916C2.8632 3.0911 2.85555 3.06352 2.84077 3.03967C2.82599 3.01581 2.8047 2.99669 2.7794 2.98454C2.65902 2.92773 2.5094 2.86164 2.3474 2.80285C2.31875 2.79263 2.29287 2.77587 2.27183 2.7539C2.25079 2.73193 2.23516 2.70536 2.22618 2.67629C2.15783 2.46704 2.11452 2.28985 2.04196 2.10535C1.98571 1.96192 1.83693 1.96642 1.7804 2.10985C1.70812 2.29351 1.65777 2.47604 1.59955 2.6746Z"
                    fill="url(#paint2_linear_471_6779)"
                  />
                </g>
                <path
                  d="M1.59906 2.6746C1.5836 2.73451 1.53128 2.77923 1.46941 2.81017C1.29391 2.87092 1.15525 2.92942 1.0405 2.98623C0.938971 3.03629 0.939815 3.19492 1.04191 3.24442C1.16088 3.30235 1.31022 3.35776 1.47616 3.42357C1.52116 3.44129 1.58388 3.49135 1.59991 3.55295C1.648 3.74223 1.71381 3.94248 1.78638 4.12754C1.84263 4.27126 1.98185 4.27098 2.03838 4.12754C2.1115 3.94135 2.16775 3.73998 2.23047 3.54845C2.23813 3.51938 2.25284 3.49265 2.2733 3.47062C2.29375 3.44859 2.31933 3.43195 2.34775 3.42217C2.49228 3.37049 2.63488 3.31354 2.77525 3.25145C2.80087 3.24 2.82269 3.22146 2.83812 3.19803C2.85354 3.17459 2.86195 3.14722 2.86233 3.11916C2.86272 3.0911 2.85507 3.06352 2.84028 3.03967C2.8255 3.01581 2.80421 2.99669 2.77891 2.98454C2.65853 2.92773 2.50891 2.86164 2.34691 2.80285C2.31826 2.79263 2.29239 2.77587 2.27134 2.7539C2.2503 2.73193 2.23467 2.70536 2.22569 2.67629C2.15735 2.46704 2.11403 2.28985 2.04147 2.10535C1.98522 1.96192 1.83644 1.96642 1.77991 2.10985C1.70763 2.29351 1.65728 2.47604 1.59906 2.6746Z"
                  fill="url(#paint3_linear_471_6779)"
                />
                <g filter="url(#filter2_i_471_6779)">
                  <path
                    d="M0.884394 6.68684C0.863019 6.77037 0.789894 6.83253 0.70355 6.87584C0.458863 6.96022 0.265363 7.04178 0.105332 7.12109C-0.0361368 7.19112 -0.0347306 7.41218 0.107301 7.48109C0.273238 7.56209 0.481644 7.63943 0.713113 7.7314C0.77555 7.75587 0.8633 7.82562 0.885238 7.9114C0.952738 8.17522 1.04443 8.45478 1.14567 8.71297C1.22414 8.91322 1.41821 8.91293 1.49696 8.71297C1.59933 8.45309 1.67696 8.1724 1.76471 7.90522C1.77537 7.8647 1.79586 7.82743 1.82436 7.79672C1.85286 7.76601 1.8885 7.7428 1.92811 7.72915C2.1297 7.65704 2.32858 7.57758 2.52436 7.49093C2.56 7.4749 2.59032 7.44902 2.61176 7.41635C2.6332 7.38368 2.64487 7.34557 2.6454 7.3065C2.64593 7.26743 2.6353 7.22901 2.61476 7.19577C2.59421 7.16253 2.56461 7.13584 2.52942 7.11884C2.33274 7.0251 2.1318 6.94054 1.92727 6.86543C1.8873 6.85117 1.8512 6.82778 1.82186 6.79712C1.79252 6.76645 1.77073 6.72937 1.75824 6.68881C1.66289 6.39715 1.60271 6.14993 1.50146 5.89287C1.42271 5.69318 1.21542 5.69909 1.13667 5.89934C1.03599 6.15528 0.965394 6.40981 0.884394 6.68684Z"
                    fill="url(#paint4_radial_471_6779)"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_iii_471_6779"
                  x="1.65489"
                  y="1.92115"
                  width="6.28187"
                  height="7.24364"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.149163" dy="-0.149163" />
                  <feGaussianBlur stdDeviation="0.149163" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.937255 0 0 0 0 0.482353 0 0 0 0 0.329412 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_471_6779"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.149163" dy="0.149163" />
                  <feGaussianBlur stdDeviation="0.298326" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.737255 0 0 0 0 0.615686 0 0 0 0 0.415686 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_innerShadow_471_6779"
                    result="effect2_innerShadow_471_6779"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-0.238661" dy="0.149163" />
                  <feGaussianBlur stdDeviation="0.11933" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.901961 0 0 0 0 0.458824 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect2_innerShadow_471_6779"
                    result="effect3_innerShadow_471_6779"
                  />
                </filter>
                <filter
                  id="filter1_ii_471_6779"
                  x="0.905667"
                  y="2"
                  width="2.01679"
                  height="2.29404"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.0596652" dy="0.0596652" />
                  <feGaussianBlur stdDeviation="0.0596652" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.803922 0 0 0 0 0.6 0 0 0 0 0.262745 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_471_6779"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-0.0596652" dy="0.0596652" />
                  <feGaussianBlur stdDeviation="0.0596652" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.423529 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_innerShadow_471_6779"
                    result="effect2_innerShadow_471_6779"
                  />
                </filter>
                <filter
                  id="filter2_i_471_6779"
                  x="0"
                  y="5.6566"
                  width="2.73501"
                  height="3.20669"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.0894978" dy="-0.0894978" />
                  <feGaussianBlur stdDeviation="0.0894978" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.458824 0 0 0 0 0.462745 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_471_6779"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_471_6779"
                  x1="6.76316"
                  y1="4.21271"
                  x2="3.64775"
                  y2="7.49208"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFCF5A" />
                  <stop offset="1" stop-color="#FEA254" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_471_6779"
                  x1="3.56345"
                  y1="9.2786"
                  x2="5.26164"
                  y2="5.6966"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF886D" />
                  <stop offset="1" stop-color="#FF886D" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_471_6779"
                  x1="2.53274"
                  y1="2.68951"
                  x2="1.53008"
                  y2="3.74504"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFDA72" />
                  <stop offset="1" stop-color="#F7A967" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_471_6779"
                  x1="1.9135"
                  y1="4.40823"
                  x2="1.9135"
                  y2="3.24807"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FDA071" />
                  <stop offset="1" stop-color="#FDA071" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                  id="paint4_radial_471_6779"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(2.23102 6.26525) rotate(127.221) scale(1.99012 1.938)"
                >
                  <stop offset="0.365" stop-color="#FFBC64" />
                  <stop offset="1" stop-color="#FF8F6B" />
                </radialGradient>
                <clipPath id="clip0_471_6779">
                  <rect width="9" height="9" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            Promotions Insights
          </h2>
          <div className="blue"></div>
          <div className="white"></div>
          <div className="light-blue"></div>
          <div className="container">
            <Swiper
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {promoAnalytics?.ai_promotion_message?.results?.map(
                (item: any, index: any) => (
                  <SwiperSlide>
                    <div className="ai-card">
                      <p>{item}</p>
                      {promoAnalytics?.ai_promotion_message?.results?.length ===
                        index + 1 && (
                        <button
                          onClick={() =>
                            window.open(
                              `${promoAnalytics?.ai_promotion_message?.redirect_link}`,
                              "_blank"
                            )
                          }
                        >
                          {promoAnalytics?.ai_promotion_message?.button_label}
                        </button>
                      )}
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </div>
      ) : (
        <Fallback message="No  AI promotion data available Yet" />
      )}
    </div>
  );
};

export default SourceAndAi;
