import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { Icon } from "@iconify/react";
import ToolTips from "./toolTips";
import Fallback from "../../Fallback";

const colors = [
  "#59A3F5",
  "#91DDFE",
  "#147CF7",
  "#2756CD",
  "#3D6FF2",
  "#3E85B8",
  "#0FA9FF",
  "#60A5FA",
  "#0E3FBF",
  "#ABE",
  "#7D97DB",
  "#88A9FF",
];

const Functions = () => {
  let mapData: any = [];

  const { promoAnalytics, loading } = useAppSelector(
    (state: any) => state.promoAnalyticsData
  );

  function mapAry(val: any, name: any, index: any) {
    var obj: any = {};
    obj["id"] = index;

    obj["value"] = val;
    obj["label"] = name;
    obj["color"] = colors[index];
    mapData.push(obj);
  }

  promoAnalytics?.job_function?.results?.map((item: any, index: any) =>
    mapAry(item.count, item._id === "" ? "Other" : item._id, index)
  );

 
  return (
    <div className="job-function">
      <div className="chart-icon">
        <Icon icon="cil:bar-chart" className="icon" />
      </div>
      <h1>
        Job Function <ToolTips title={promoAnalytics?.job_function?.tooltip} />
      </h1>
      {promoAnalytics?.job_function?.results?.length ?? 0 ? (
        <div className="wrapper">
          <PieChart
            margin={{ top: 10, left: 40, right: 30, bottom: 0 }}
            series={[
              {
                data: [...mapData],
                cx: 50,
              },
            ]}
            slotProps={{ legend: { hidden: true } }}
            width={200}
            height={150}
          />
          <div className="wrapper-list">
            {mapData?.map((item: any) => (
              <div className="pie-legend-list">
                {" "}
                <div className="pie-legend-list-item">
                  <div
                    className="dots"
                    style={{ background: `${item?.color}` }}
                  ></div>
                  <p>{item?.label} </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Fallback message="No job function rate data available yet" />
      )}
    </div>
  );
};

export default Functions;
