import React from "react";
import ToolTips from "../../../Promotion/Graph/toolTips";

import ListWithNumber from "./ListWithNumber";
import ListWithoutNumber from "./ListWithoutNumber";
import ListWithoutIndex from "./ListWithoutInndex";

const OnlyLists: React.FC<{ item: any }> = ({ item }) => {
  const sortedData = [...item?.charts]?.sort(
    (a: any, b: any) => a.position - b.position
  );

  const renderedComponents = sortedData?.map((item: any, index: any) => {
    const { position, chart_type, chart_results } = item;

    // Handle each case based on position and chart type
    switch (chart_type) {
      case "L1":
        return <ListWithNumber data={item} />;
      case "L2":
        return <ListWithoutNumber data={item} />;
      case "L3":
        return <ListWithoutIndex data={item} />;

      default:
        return null;
    }
  });

  function checkChartResultsNotEmpty(object: any) {
    for (const chart of object.charts) {
      if (chart?.chart_results?.length > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {checkChartResultsNotEmpty(item) && (
        <div
          className="only-list-v2"
          style={
            item?.background_color === "transparent"
              ? { background: "transparent", padding: "5px" }
              : { background: item?.background_color, padding: "30px 20px" }
          }
        >
          <h1 className="title">
            {item?.name} <ToolTips title={item?.tooltip} />
          </h1>
          <div className="only-list-body">{renderedComponents}</div>
        </div>
      )}
    </>
  );
};

export default OnlyLists;
