import { Icon } from "@iconify/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postFilterJob } from "../../../redux/slices/JobSlices/getFilterJobListsSlice";
import { getPrivateJobBoardUrl } from "../../../redux/slices/JobSlices/getPrivateJobBoardUrl";
import EcnCheckboxFilled from "../../StyledComponents/V2/EcnCheckboxFilled";
import debounce from "lodash/debounce";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOBS_BASE =
  GATEWAY_BASE == "https://api.dev.ecndev.io"
    ? "https://jobs.dev.ecndev.io"
    : "https://jobs.onefinnet.com";

const SearchAndFilter: React.FC<{
  setFilterData: any;
  filterData: any;
  setSearchTerm: any;
  searchTerm: any;
}> = ({ setFilterData, filterData, setSearchTerm, searchTerm }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { jobFilterData } = useAppSelector((state: any) => state.getJobFilter);
  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { privateJobBoardUrl } = useAppSelector(
    (state) => state.getPrivateJobBoardUrl
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [optionsMenuanchorEl, setOptionsMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const optionsMenuOpen = Boolean(optionsMenuanchorEl);

  const [expanded, setExpanded] = useState<any>({
    state: false,
    type: []
  });
  const [tempFilterData, setTempFilterData] = useState<any>({
    status: [],
    job_type: [],
    location: []
  });

  useEffect(() => {
    if (!privateJobBoardUrl) {
      dispatch(getPrivateJobBoardUrl());
    }
  }, [privateJobBoardUrl]);

  useEffect(() => {
    // This is done because it shuldn't dispatch at page load
    // as it resets other actions which are considering page query parameters
    // and this dispatch is not
    if (searchTerm == "") return;
    const debouncedSearchHandler = debounce((query) => {
      dispatch(
        postFilterJob({
          job_status: filterData?.status,
          job_types: filterData?.job_type,
          job_locations: filterData?.location,
          query: query
        })
      );
    }, 400);

    debouncedSearchHandler(searchTerm);

    // Cleanup function to clear the timeout
    return () => {
      debouncedSearchHandler.cancel(); // Cancel the debounce timer on unmount or when the dependency changes
    };
  }, [searchTerm]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const statusHandler = (item: any) => {
    if (tempFilterData?.status.find((statusItem: any) => statusItem === item)) {
      setTempFilterData((prev: any) => ({
        ...prev,
        status: prev.status.filter((statusItem: any) => statusItem !== item)
      }));
    } else {
      setTempFilterData((prev: any) => ({
        ...prev,
        status: [...prev.status, item]
      }));
    }
  };

  const typeHandler = (item: any) => {
    if (
      tempFilterData?.job_type.find((statusItem: any) => statusItem === item)
    ) {
      setTempFilterData((prev: any) => ({
        ...prev,
        job_type: prev.job_type.filter((statusItem: any) => statusItem !== item)
      }));
    } else {
      setTempFilterData((prev: any) => ({
        ...prev,
        job_type: [...prev.job_type, item]
      }));
    }
  };

  const locationHandler = (item: any) => {
    if (
      tempFilterData?.location.find((statusItem: any) => statusItem === item)
    ) {
      setTempFilterData((prev: any) => ({
        ...prev,
        location: prev.location.filter((statusItem: any) => statusItem !== item)
      }));
    } else {
      setTempFilterData((prev: any) => ({
        ...prev,
        location: [...prev.location, item]
      }));
    }
  };

  const toggleAccordion = (type: any) => {
    setExpanded((prevExpanded: any) => {
      const index = prevExpanded.type.indexOf(type); // Check if the type exists in the array

      if (index !== -1) {
        // If the type exists in the array, remove it
        const updatedTypes = [...prevExpanded.type];
        updatedTypes.splice(index, 1);
        return {
          state: false,
          type: updatedTypes
        };
      } else {
        // If the type doesn't exist in the array, add it
        return {
          state: true,
          type: [...prevExpanded.type, type]
        };
      }
    });
  };

  const clearHandler = () => {
    setTempFilterData({
      status: [],
      job_type: [],
      location: []
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const optionsMenuCloseHandler = () => {
    setOptionsMenuAnchorEl(null);
  };

  const applyHandler = () => {
    setFilterData({
      status: tempFilterData?.status,
      job_type: tempFilterData?.job_type,
      location: tempFilterData?.location
    });
    dispatch(
      postFilterJob({
        job_status: tempFilterData?.status,
        job_types: tempFilterData?.job_type,
        job_locations: tempFilterData?.location,
        query: ""
      })
    );
    setTempFilterData({
      status: [],
      job_type: [],
      location: []
    });
    setAnchorEl(null);
    setOptionsMenuAnchorEl(null);
  };

  const searchHandler = (e: any) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (e.target.value === "") {
      dispatch(
        postFilterJob({
          job_status: filterData?.status,
          job_types: filterData?.job_type,
          job_locations: filterData?.location,
          query: e.target.value
        })
      );
    }
  };

  return (
    <div className="search-filter">
      <div className="search">
        <Icon
          icon="lucide:search"
          width={24}
          height={24}
          color="rgba(33, 33, 33, 0.6)"
        />
        <input
          placeholder="Search"
          onChange={searchHandler}
          value={searchTerm}
        />
      </div>
      <div className="right">
        {(userInfo?.roles?.includes("enterprise-admin") ||
          userInfo?.roles?.includes("recruiter-admin")) && (
          <Button
            variant="outlined"
            className="create-jobs-button"
            onClick={() => navigate("/job-creations")}
          >
            <Icon icon="lucide:plus" width={18} height={18} /> Create Jobs
          </Button>
        )}
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            setOptionsMenuAnchorEl(e.currentTarget);
          }}
        >
          <Icon icon="charm:menu-kebab" widths={20} height={20} />
        </IconButton>
        <Menu
          id="jobs-options-menu"
          anchorEl={optionsMenuanchorEl}
          open={optionsMenuOpen}
          onClose={optionsMenuCloseHandler}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuItem
            onClick={() => {
              optionsMenuCloseHandler();
              window.open(
                `${JOBS_BASE}/${privateJobBoardUrl?.suggested_url || ""}`,
                "_blank"
              );
            }}
          >
            <div className="options-menu-item">
              <Icon icon="lucide:external-link" width={18} height={18} />
              <span>Private Job Board</span>
            </div>
          </MenuItem>
          <MenuItem
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <div className="options-menu-item">
              <Icon icon="lucide:filter" width={18} height={18} />
              <span>Filter</span>
            </div>
          </MenuItem>
          <MenuItem onClick={() => navigate("/company-details")}>
            <div className="options-menu-item">
              <Icon icon="lucide:edit" width={18} height={18} />
              <span>Company Details</span>
            </div>
          </MenuItem>
        </Menu>
        <Menu
          id="filter-menu"
          aria-labelledby="demo-positioned-button"
          className="menu-container-users"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          PaperProps={{
            style: {
              maxHeight: "500px",
              minWidth: "17ch",
              padding: "4px 5px",
              borderRadius: "7px",
              boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.10)", // Remove box shadow
              marginLeft: "-30px" // Align to the left
            }
          }}
        >
          <MenuItem className="menu-item">
            <Accordion
              disableGutters
              onChange={() => toggleAccordion("status")}
            >
              <AccordionSummary
                expandIcon={
                  expanded.type.includes("status") ? (
                    <Icon icon={"lucide:plus"} color="rgba(0, 52, 187, 1)" />
                  ) : (
                    <Icon
                      icon={"lucide:minus"}
                      color="rgba(0, 52, 187, 1)"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  )
                }
              >
                Status
              </AccordionSummary>
              <AccordionDetails>
                {jobFilterData?.job_status?.map((item: any) => (
                  <div className="option">
                    <EcnCheckboxFilled
                      checked={tempFilterData?.status?.includes(item)}
                      onChange={() => statusHandler(item)}
                    />
                    <p style={{ textTransform: "capitalize" }}>{item}</p>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </MenuItem>
          <MenuItem className="menu-item">
            <Accordion disableGutters onChange={() => toggleAccordion("role")}>
              <AccordionSummary
                expandIcon={
                  expanded?.type?.includes("role") ? (
                    <Icon
                      icon={"lucide:minus"}
                      color="rgba(0, 52, 187, 1)"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  ) : (
                    <Icon icon={"lucide:plus"} color="rgba(0, 52, 187, 1)" />
                  )
                }
              >
                Roles
              </AccordionSummary>
              <AccordionDetails>
                {jobFilterData?.job_types?.map((item: any) => (
                  <div className="option">
                    <EcnCheckboxFilled
                      onChange={() => typeHandler(item)}
                      checked={tempFilterData?.job_type?.includes(item)}
                    />
                    <p style={{ textTransform: "capitalize" }}>{item}</p>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </MenuItem>

          <MenuItem className="menu-item">
            <Accordion
              disableGutters
              onChange={() => toggleAccordion("location")}
            >
              <AccordionSummary
                expandIcon={
                  expanded.type?.includes("location") ? (
                    <Icon
                      icon={"lucide:minus"}
                      color="rgba(0, 52, 187, 1)"
                      style={{ transform: "rotate(90deg)" }}
                    />
                  ) : (
                    <Icon icon={"lucide:plus"} color="rgba(0, 52, 187, 1)" />
                  )
                }
              >
                Location
              </AccordionSummary>
              <AccordionDetails>
                {jobFilterData?.job_locations?.map((item: any) => (
                  <div className="option">
                    <EcnCheckboxFilled
                      onChange={() => locationHandler(item)}
                      checked={tempFilterData?.location?.includes(item)}
                    />
                    <p style={{ textTransform: "capitalize" }}>{item}</p>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </MenuItem>
          <MenuItem className="menu-item item-button">
            <div className="button">
              <Button className="clear" onClick={clearHandler}>
                Clear
              </Button>
              <Button className="apply" onClick={applyHandler}>
                Apply
              </Button>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default SearchAndFilter;
