import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

interface selectedAiGenerateAvgScoreProps {
  job_id: string | null;
  application_ids: string[];
}

export const selectedAiGenerateAvgScore = createAsyncThunk(
  "selectedAiGenerateAvgScore",
  async (reqData: selectedAiGenerateAvgScoreProps) => {
    const { data } = await axios.post(
      `${
        GATEWAY_BASE + JOB_CREATE_BASE
      }/api/v1/application/relevancy_score/selected/job`,
      reqData
    );
    return data;
  }
);

const selectedAiGenerateAvgScoreSlice = createSlice({
  name: "selectedAiGenerateAvgScore",
  initialState: {
    loading: false,
    selectedAiGenerateAvgScoreData: null,
    error: false,
    success: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectedAiGenerateAvgScore.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(selectedAiGenerateAvgScore.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.selectedAiGenerateAvgScoreData = action.payload;
    });
    builder.addCase(selectedAiGenerateAvgScore.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  }
});

export default selectedAiGenerateAvgScoreSlice.reducer;
