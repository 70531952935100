import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getFirmDetails = createAsyncThunk("getFirmDetails", async () => {
  try {
    const { data } = await axios.get(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/firms/details`
    );
    return data;
  } catch (error: any) {
    // Throw the entire error object to be caught in the rejected case
    throw error.response?.data?.statusCode
    ;
  }
});

const getFirmDetailsSlice = createSlice({
  name: "getFirmDetails",
  initialState: {
    loading: false,
    firmDetailsData: null,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFirmDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFirmDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.firmDetailsData = action.payload;
    });
    builder.addCase(getFirmDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getFirmDetailsSlice.reducer;
