import { TextField, TextFieldProps } from "@mui/material";

// Pass className = "ecn-input-v2" to override default styles
const EcnInput = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      
      sx={{
        border: "1 px solid 1px solid rgba(33, 33, 33, 0.20)",
        borderRadius: "8px",

        "& fieldset": {
          border: "1px solid rgba(33, 33, 33, 0.20) !important",
          borderRadius: "8px",
        },
        "&:hover fieldset": {
          border: "2px solid  rgba(0, 52, 187, 0.70) !important",
        },
        "&:focus-within fieldset": {
          border: "2px solid rgba(0, 52, 187, 0.70) !important",
        },
      }}
    />
  );
};

export default EcnInput;
