import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const jobDetails = createAsyncThunk("jobDetails", async (id: any) => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/enterprise/jobs/details/${id}`
  );
  return data;
});

const jobDetailsSlice = createSlice({
  name: "jobDetails",
  initialState: {
    loading: false,
    jobDetailsData: null,
    error: false,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.jobDetailsData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(jobDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(jobDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.jobDetailsData = action.payload;
    });
    builder.addCase(jobDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { reset } = jobDetailsSlice.actions;

export default jobDetailsSlice.reducer;
