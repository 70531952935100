import { KeyboardArrowDown } from "@mui/icons-material";
import { OutlinedInput, Select, SelectProps } from "@mui/material";

const EcnSelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      size="small"
      variant="standard"
      displayEmpty={true}
      input={
        <OutlinedInput
          sx={{
            border: "1 px solid 1px solid rgba(33, 33, 33, 0.20)",
            "& fieldset": {
              border: "1px solid rgba(33, 33, 33, 0.20) !important"
            },
            "&:hover fieldset": {
              border: "1.7px solid  rgba(0, 52, 187, 0.70) !important"
            },
            "&.Mui-focused fieldset": {
              border: "1.7px solid rgba(0, 52, 187, 0.70) !important"
            },
            borderRadius: "8px"
          }}
        />
      }
      IconComponent={KeyboardArrowDown}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },

        PaperProps: {
          sx: {
            maxHeight: 300,
            boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
            borderRadius: "6px",
            marginTop: "10px",
            paddingLeft: "7px"
          }
        }
      }}
    >
      {props.children}
    </Select>
  );
};

export default EcnSelect;
