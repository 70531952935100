import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const deletedCreatedJob = createAsyncThunk(
  "deletedCreatedJob",
  async (id: any) => {
    const { data } = await axios.delete(
      `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/enterprise/jobs/delete`,
      {
        data: {
          _id: id,
        },
      }
    );
    return data;
  }
);

const deletedCreatedJobSlice = createSlice({
  name: "deletedCreatedJob",
  initialState: {
    loading: false,
    deletedCreatedJobData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deletedCreatedJob.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deletedCreatedJob.fulfilled, (state, action) => {
      state.loading = false;
      state.deletedCreatedJobData = action.payload;
    });
    builder.addCase(deletedCreatedJob.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default deletedCreatedJobSlice.reducer;
