import { Icon } from "@iconify/react";
import { ArrowDropDown, KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import EcnSelect from "../StyledComponents/EcnSelect";

const TopSelect: React.FC<{ data: any }> = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view");

  const [status, setStatus] = useState("0");
  const { getJobsListData } = useAppSelector((state: any) => state.jobsList);

  const statusHandler = (e: any) => {
    setStatus(e.target.value);
    if (e.target.value === "0") {
      navigate(`?view=${view}`);
    } else {
      navigate(`?view=${view}&id=${e.target.value}`);
    }
  };

  const composeHandler = () => {
    navigate(`?view=compose&back-to=${view}`);
  };

  return (
    <div className="top-select">
      <div className="field">
        <p>Select job</p>
        <div className="select">
          <FormControl>
            <Select
              size="small"
              variant="standard"
              displayEmpty={true}
              disableUnderline
              value={status}
              input={
                <OutlinedInput
                  sx={{
                    border: "1px solid rgba(33, 33, 33, 0.20)",
                    "& fieldset": {
                      border: "1px solid rgba(33, 33, 33, 0.20) !important",
                    },
                    "&:hover fieldset": {
                      border: "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                    },
                    borderRadius: "8px",
                  }}
                />
              }
              onChange={statusHandler}
              IconComponent={KeyboardArrowDown}
              renderValue={(selected) => {
                if (selected === "0") {
                  return <div className="placeholder">All jobs</div>;
                }
                const selectedStatus = getJobsListData?.result?.find(
                  (item: any) => item._id === selected
                );

                return (
                  <div>
                    {selectedStatus ? selectedStatus?.job_title : ""}{" "}
                    {/* Render the text property */}
                  </div>
                );
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                    boxShadow: "0px 7px 30px 7px rgba(0, 0, 0, 0.1)",
                    borderRadius: "6px",
                    marginTop: "10px",
                    backgroundColor: "#FFF",
                    padding: "10px 10px",
                    border: "1px solid rgba(33, 33, 33, 0.20)",
                  },
                },
              }}
            >
              <MenuItem
                value={"0"}
                sx={
                  status === "0"
                    ? {
                        background: "rgba(0, 52, 187, 0.08)!important",
                        borderRadius: "9px",
                        marginBottom: "5px",
                        fontSize: "14px !important",
                        fontWeight: "400",
                        color: "#0034bb !important",
                        padding: "8px 15px",
                      }
                    : {
                        background: "#fff !important",
                        marginBottom: "5px",
                        fontSize: "14px !important",
                        color: "rgba(33,33,33,.7)",
                        fontWeight: "400",
                        padding: "8px 15px",
                        "&:hover": {
                          backgroundColor: "rgba(0, 52, 187, 0.08) !important",
                          color: "rgba(0, 52, 187, 1)",
                          borderRadius: "9px",
                        },
                      }
                }
              >
                All jobs
              </MenuItem>
              {getJobsListData?.result?.map((item: any) => (
                <MenuItem
                  value={item?._id}
                  sx={
                    item?._id === status
                      ? {
                          background: "rgba(0, 52, 187, 0.08)!important",
                          borderRadius: "9px",
                          marginBottom: "5px",
                          fontSize: "14px !important",
                          fontWeight: "400",
                          color: "#0034bb !important",
                          padding: "8px 15px",
                        }
                      : {
                          background: "#fff !important",
                          marginBottom: "5px",
                          fontSize: "14px !important",
                          color: "rgba(33,33,33,.7)",
                          fontWeight: "400",
                          padding: "8px 15px",
                          "&:hover": {
                            backgroundColor:
                              "rgba(0, 52, 187, 0.08) !important",
                            color: "rgba(0, 52, 187, 1)",
                            borderRadius: "9px",
                          },
                        }
                  }
                >
                  {item?.job_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      {data?.length != 0 && (
        <Button variant="contained" className="button" onClick={composeHandler}>
          <Icon icon="lucide:pen" width={20} height={20} /> Compose
        </Button>
      )}
    </div>
  );
};

export default TopSelect;
