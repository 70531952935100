import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const scoreParamUpdate = createAsyncThunk(
  "scoreParamUpdate",
  async (scoreData: any) => {
    const { data } = await axios.put(
      `${
        GATEWAY_BASE + JOB_CREATE_BASE
      }/api/v2/enterprise/jobs/update_score_params`,
      scoreData
    );

    return data;
  }
);

const scoreParamUpdateSlice = createSlice({
  name: "scoreParamUpdate",
  initialState: {
    loading: false,
    scoreParamUpdateData: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(scoreParamUpdate.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(scoreParamUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.scoreParamUpdateData = action.payload;
    });
    builder.addCase(scoreParamUpdate.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
  },
});

export default scoreParamUpdateSlice.reducer;
