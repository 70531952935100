import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const changePassword = createAsyncThunk(
  "changePassword",
  async (password: any) => {
    const { data } = await axios.patch(
      `${GATEWAY_BASE + ADMIN_BASE}/recruiterPasswordChange`,
      password
    );
    return data;
  }
);

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: {
    loading: false,
    password: null,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.password = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  },
});

export default changePasswordSlice.reducer;
