import { Icon } from "@iconify/react";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { removeTemplate } from "../../../redux/slices/MailsSlices/removeTemplateSlice";

const Cards: React.FC<{ item: any; setSelectedTemplate: any }> = ({
  item,
  setSelectedTemplate,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [click, setClick] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isInfoClick, setIsInfoClick] = useState(false);
  const [day, setDay] = useState<any>();

  const { removeTemplateData } = useAppSelector(
    (state: any) => state.removeTemplate
  );

  useEffect(() => {
    if (removeTemplateData && click) {
      enqueueSnackbar("Template deleted successfully!", {
        variant: "success",
      });
    }
  }, [removeTemplateData]);

  useEffect(() => {
    if (item) {
      const yourDate = moment.unix(
        item?.updatedAt === 0 ? item?.createdAt : item?.updatedAt
      );

      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "days").startOf("day");

      const dateLabelValue = yourDate.isSame(today, "d")
        ? "Today"
        : yourDate.isSame(yesterday, "d")
        ? "Yesterday"
        : yourDate.format("MMMM D, YYYY");
      setDay(dateLabelValue);
    }
  }, [item]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editHandler = (e: any) => {
    setSelectedTemplate(e);
    setAnchorEl(null);
    navigate(`?view=edit&id=${e.id}`);
  };

  const viewHandler = (e: any) => {
    setSelectedTemplate(e);
    setAnchorEl(null);
    navigate("?view=view");
  };

  const deleteHandler = (e: any) => {
    setIsDelete(true);
    setAnchorEl(null);
  };
  const yesDeleteHandler = (e: any) => {
    setClick(true);
    dispatch(removeTemplate(e.id));
  };

 

  return (
    <div className="list-card">
      {!isDelete ? (
        <div className={open ? "card active" : "card"}>
          <h2>
            {item?.subject.length > 30
              ? item?.subject.slice(0, 30) + "..."
              : item?.subject}{" "}
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className="icon-button"
            >
              <Icon icon="charm:menu-kebab" width={18} height={18} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: "200px",
                  width: "15ch",
                  padding: "0px 10px",
                },
              }}
            >
              <MenuItem
                onClick={() => editHandler(item)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "rgba(33, 33, 33, 0.7)",
                  "&:hover": {
                    background: "rgba(0, 52, 187, 0.10)",
                    color: "rgba(0, 52, 187, 1)",
                    borderRadius: "5px",
                  },
                }}
              >
                <Icon icon="lucide:pencil" /> Edit
              </MenuItem>
              <MenuItem
                onClick={() => viewHandler(item)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "rgba(33, 33, 33, 0.7)",
                  "&:hover": {
                    background: "rgba(0, 52, 187, 0.10)",
                    color: "rgba(0, 52, 187, 1)",
                    borderRadius: "5px",
                  },
                }}
              >
                <Icon icon="lucide:eye" /> View
              </MenuItem>
              {!item?.job_acknowlegement_template && (
                <MenuItem
                  onClick={() => deleteHandler(item)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "rgba(33, 33, 33, 0.7)",
                    "&:hover": {
                      background: "rgba(0, 52, 187, 0.10)",
                      color: "rgba(0, 52, 187, 1)",
                      borderRadius: "5px",
                    },
                  }}
                >
                  <Icon icon="lucide:trash" />
                  Delete
                </MenuItem>
              )}
            </Menu>
          </h2>

          <Typography variant="h6" component="p">
            <ReactMarkdown
              className="markdown-data"
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {item?.body}
            </ReactMarkdown>
          </Typography>

          {item?.job_acknowlegement_template && (
            <div className="template-card-bottom">
              <div className="text">
                {isInfoClick ? (
                  <p>Default template</p>
                ) : (
                  <p>Application acknowledgement email</p>
                )}
                <Icon
                  icon="lucide:info"
                  onClick={() => setIsInfoClick(!isInfoClick)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          )}
          {!item?.job_acknowlegement_template && (
            <div className="edit-text">
              {item?.updatedAt === 0 ? (
                <p className="created">Created {day}</p>
              ) : (
                <p className="created">Edited {day}</p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="feedback">
          <h1>Are you sure you want to delete?</h1>
          <div className="button">
            <Button onClick={() => setIsDelete(false)}>
              <Icon icon="lucide:x" width={18} height={18} /> No
            </Button>
            <Button onClick={() => yesDeleteHandler(item)}>
              <Icon icon="lucide:check" width={18} height={18} /> Yes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;
