import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const JOB_CREATE_BASE = process.env.REACT_APP_JOB_CREATE_BASE || "";

export const getAllResume = createAsyncThunk("getAllResume", async (id: any) => {
  const { data } = await axios.get(
    `${GATEWAY_BASE + JOB_CREATE_BASE}/api/v1/application/download_all_resume/${id}`
  );
  return data;
});

const getAllResumeSlice = createSlice({
  name: "getAllResume",
  initialState: {
    loading: false,
    getAllResumeData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllResume.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllResume.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllResumeData = action.payload;
    });
    builder.addCase(getAllResume.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getAllResumeSlice.reducer;
