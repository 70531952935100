import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";

import layoutTwo from "../components/layout/layoutTwo";
import SearchPageGroup from "../components/SearchBar/SearchPageGroup";
import HeadBreadCrumbSearch from "../components/SearchBar/HeadBreadCrumbSearch";
import { useSearchParams } from "react-router-dom";
import { postSearch } from "../redux/slices/Header/postSearchSlice";

const Search = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const q = searchParams.get("q") || "";

  const { searchResults, loading } = useAppSelector((state) => state.postSearch);

  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    if (!searchResults && !loading && q) {
      dispatch(postSearch(q));
    }
  }, [searchResults, loading, q]);

  useEffect(() => {
    if (searchResults) {
      const totalItems = Object.values(searchResults).reduce((acc, { data }) => {
        // Check if data exists and is an array, then add its length to the accumulator
        return acc + (Array.isArray(data) ? data.length : 0);
      }, 0);
      setTotalResults(totalItems);
    }
  }, [searchResults]);

  if (totalResults === 0) {
    return (
      <div className="global-search-fallback">
        <img
          src="https://s1.ecnstatic.com/ecn/images/enterprise/404_notification.png"
          alt="404"
        />
        <p>No Results Found</p>
      </div>
    );
  }

  return (
    <div className="global-search">
      <HeadBreadCrumbSearch />
      <h4 className="main-heading">Search results for "{q}"</h4>
      <p className="result-count">{totalResults} results</p>

      <div className="results-wrapper">
        {Object.entries(searchResults ?? {}).map(
          ([category, { data, display_name }]) =>
            data?.length > 0 && (
              <SearchPageGroup
                key={category}
                data={data}
                displayName={display_name}
              />
            )
        )}
      </div>
    </div>
  );
};

export default layoutTwo(Search);
