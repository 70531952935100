import React, { useState } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import ToolTips from "../../../Promotion/Graph/toolTips";
import Lists from "./Lists";
import PieChart from "./PieChart";
import BarGraph from "../BarGraph";
import { colors } from "../../../../../helpers/TempData";
const BarAndList: React.FC<{ item: any }> = ({ item }) => {
  const [hoverValue, setHoverValue] = useState<any>("");
  const sortedData = [...item?.charts]?.sort(
    (a: any, b: any) => a.position - b.position
  );

  const renderedComponents = sortedData?.map((item: any) => {
    switch (item?.chart_type) {
      case "BAR":
        return (
          <BarGraph
            setHoverValue={setHoverValue}
            customsColor={colors}
            item={item}
          />
        );

      case "LIST":
        return <Lists hoverValue={hoverValue} item={item} />;

      default:
        return null;
    }
  });

  function checkChartResultsNotEmpty(object: any) {
    for (const chart of object.charts) {
      if (chart?.chart_results?.length > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {checkChartResultsNotEmpty(item) && (
        <div
          className="lists-and-pie-v2"
          style={{
            background: `${
              item?.background_color === "" ? "#f8f8f8" : item?.background_color
            }`,
          }}
        >
          <h2>
            {item?.name} <ToolTips title={item?.tooltip} />
          </h2>
          <div className="body">{renderedComponents}</div>
        </div>
      )}
    </>
  );
};

export default BarAndList;
