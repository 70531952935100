import React, { useState, useRef, useEffect } from "react";

const ParametersCard: React.FC<{ item: any }> = ({ item }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [item.value]);

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className="parameter-card">
      <h1>
        {item?.score} <span>/10</span>
      </h1>
      <h1>{item?.key?.replace(/_/g, " ")}</h1>

      <p
        className={"p"}
      >
        {item?.value}
      </p>
    </div>
  );
};

export default ParametersCard;
