import completeOnboardingSlice from "./completeOnboardingSlice";
import getAiCreditsSlice from "./getAiCreditsSlice";
import getTopPickStatusSlice from "./getTopPickStatusSlice";
import getTopPicksSlice from "./getTopPicksSlice";
import getUnitsDataSlice from "./getUnitsDataSlice";
import postAiReviewSlice from "./postAiReviewSlice";
import getDashboardHomeSlice from "./getDashboardHomeSlice";
import getStateDataSlice from "./getStateDataSlice";
import patchStateDataSlice from "./patchStateDataSlice";

export const allDashboardSlices = {
  getUnitsData: getUnitsDataSlice,
  getAiCredits: getAiCreditsSlice,
  getTopPickStatus: getTopPickStatusSlice,
  postAiReview: postAiReviewSlice,
  getTopPicks: getTopPicksSlice,
  completeOnboarding: completeOnboardingSlice,
  getDashboardHomeData: getDashboardHomeSlice,
  getStateData: getStateDataSlice,
  patchStateData: patchStateDataSlice
};
