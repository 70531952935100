import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE || "";

export const analytics = createAsyncThunk("analytics", async (analData: any) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + ANALYTICS_BASE}/analytics`,
    analData
  );
  return data;
});

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    loading: false,
    analyticsGraph: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(analytics.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(analytics.fulfilled, (state, action) => {
      state.loading = false;
      state.analyticsGraph = action.payload;
    });
    builder.addCase(analytics.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default analyticsSlice.reducer;
