import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import React from "react";

const ListWithNumber: React.FC<{ data: any }> = ({ data }) => {

  return (
    <>
      {data?.chart_results?.length != 0 && (
        <div
          className="list-container"
          style={{
            width: `${data?.width * 7}%`,
            background: `${
              data?.background_color === ""
                ? "transparent"
                : data?.background_color
            }`,
          }}
        >
          <h1 className="container-title">{data?.chart_label}</h1>

          <div className="container-body">
            {data?.chart_results?.map((item: any, index: any) => (
              <div className="wrapper">
                <div className="body-left">
                  <p>{index + 1}</p>
                  <LightTooltip
                    title={item?.label}
                    placement="top"
                    className="tool"
                  >
                    <p className="uni-list">{item?.label}</p>
                  </LightTooltip>
                </div>
                <button>
                  {data?.show_percentage && `${item?.percentage}%`}{" "}
                  {data?.show_value && `${item?.value}`}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ListWithNumber;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(225, 233, 245, 1)",
    color: "#121212",
    boxShadow: "none",
    fontSize: 10,
    fontWeight: 400,
    padding: "7px 10px",
    backgroundClip: "padding-box",
    marginRight: "140px",
  },
}));
