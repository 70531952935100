import getNotificationSlice from "./getNotificationSlice";
import updateNotificationSlice from "./updateNotificationSlice";
import changePasswordSlice from "./changePasswordSlice";
import getActivityLogSlice from "./getActivityLogSlice";
import contactUsSlice from "./contactUsSlice";
import updateRecruiterPasswordSlice from "./updateRecruiterPasswordSlice";

export const allSettingsSlices = {
  getNotifications: getNotificationSlice,
  updateNotifications: updateNotificationSlice,
  changePasswordRequest: changePasswordSlice,
  activityLog: getActivityLogSlice,
  helpContact: contactUsSlice,
  updateRecruiterPasswords: updateRecruiterPasswordSlice,
};
