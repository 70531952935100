import composedEmailsSlice from "./composedEmailsSlice";
import getPlaceholderSlice from "./getPlaceholderSlice";
import getTemplateSlice from "./getTemplateSlice";
import createAndUpdateTemplateSlice from "./createAndUpdateTemplateSlice";
import getInboxSlice from "./getInboxSlice";
import getSentSlice from "./getSentSlice";
import getDraftSlice from "./getDraftSlice";
import getStaredSlice from "./getStaredSlice";
import getDeletedSlice from "./getDeletedSlice";
import emailActionSlice from "./emailActionSlice";
import getRepliesSlice from "./getRepliesSlice";
import getInBoxFirstTimeSlice from "./getInBoxFirstTimeSlice";
import removeTemplateSlice from "./removeTemplateSlice";
import downloadAttachmentSlice from "./downloadAttachmentSlice";
import getDraftEmailDetailsSlice from "./getDraftEmailDetailsSlice";

export const allMailsSlices = {
  composedEmail: composedEmailsSlice,
  placeholderList: getPlaceholderSlice,
  templateList: getTemplateSlice,
  createAndUpdateTemplate: createAndUpdateTemplateSlice,
  getInboxList: getInboxSlice,
  getSentList: getSentSlice,
  getDraftList: getDraftSlice,
  getStaredList: getStaredSlice,
  getDeleteList: getDeletedSlice,
  emailAction: emailActionSlice,
  allRepliesData: getRepliesSlice,
  getInBoxFirstTime: getInBoxFirstTimeSlice,
  removeTemplate: removeTemplateSlice,
  downloadAttachment: downloadAttachmentSlice,
  getDraftEmailDetails: getDraftEmailDetailsSlice,
};
