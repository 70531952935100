import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getUserSettings = createAsyncThunk("getUserSettings", async () => {
  const { data } = await axios.get(`${GATEWAY_BASE + ADMIN_BASE}/getUserSettings`);
  return data;
});

const getUserSettingsSlice = createSlice({
  name: "getUserSettings",
  initialState: {
    loading: false,
    userSettings: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserSettings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.userSettings = action.payload;
    });
    builder.addCase(getUserSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getUserSettingsSlice.reducer;
