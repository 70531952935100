import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import Lottie from "react-lottie";
import RequestsNotFoundAnimation from "../../assets/lottie/requests_not_found.json";
import { useQueryParams } from "../../helpers/HOC/useQueryParams";
import { getRequestList } from "../../redux/slices/EventSlices/getRequestListSlice";
import RequestCard from "./RequestCard";

const PAGE_SIZE = 10;

const RequestsTab = () => {
  const [searchParams, updateQueryParams] = useQueryParams();
  const searchQuery = searchParams.get("q");
  const [page, setPage] = useState<number>(1);
  const dispatch = useAppDispatch();

  const { requestListData } = useAppSelector((state) => state.getRequests);

  useEffect(() => {
    dispatch(
      getRequestList({
        skip: (page - 1) * PAGE_SIZE,
        limit: page * PAGE_SIZE
      })
    );
  }, []);

  useEffect(() => {
    if (searchQuery) setPage(1);
    const currentPage = searchQuery ? 1 : page;
    dispatch(
      getRequestList({
        skip: (currentPage - 1) * PAGE_SIZE,
        limit: currentPage * PAGE_SIZE,
        q: searchQuery || ""
      })
    );
  }, [searchQuery]);

  const pageChangeHandler = (e: any, page: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    updateQueryParams({ page: page });
    setPage(page);
    dispatch(
      getRequestList({ skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE })
    );
  };

  return (
    <div className="requests-tab">
      {requestListData && requestListData.result?.length == 0 ? (
        <div className="requests-not-found">
          <Lottie
            speed={0.5}
            options={{
              loop: true,
              autoplay: true,
              animationData: RequestsNotFoundAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            width={316}
            height={237}
          />
          <div className="text">No request found</div>
        </div>
      ) : (
        <>
          <div className="requests-list">
            {requestListData?.result?.map((request: any) => (
              <RequestCard request={request} />
            ))}
          </div>
          <div className="pagination-wrapper">
            <Pagination
              page={page}
              onChange={pageChangeHandler}
              count={
                requestListData?.total_count
                  ? Math.ceil(requestListData.total_count / PAGE_SIZE)
                  : 1
              }
              color="primary"
              className="job-pagination"
            />
          </div>
        </>
      )}
    </div>
  );
};
export default RequestsTab;
