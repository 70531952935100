import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  LinearProgressProps,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EcnButton from "../StyledComponents/EcnButton";
import { inviteUser } from "../../redux/slices/InviteUserFlow/inviteUserSlices";
import EcnCheckboxV2 from "../StyledComponents/V2/EcnCheckboxV2";
import { Icon } from "@iconify/react";
import PreviewList from "./PreviewList";
import { useSnackbar } from "notistack";
import { getPlans } from "../../redux/slices/userSlices/getLicensePlanSlice";
import { getLicense } from "../../redux/slices/userSlices/getLicenseListSlice";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, paid: any, add: any }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: "40px" }}>
      <Box sx={{ width: '90%', mr: 1, }}>
        <LinearProgress variant="determinate" {...props} style={{ height: "15px" }} />
      </Box>
      <Box sx={{ minWidth: 50 }}>
        <Typography
          variant="body2"
          sx={{ color: "#212121", fontSize: "18px" }}
        >
          {`${props.paid?.used_count + props.add}/ ${props.paid?.unused_count + props.paid?.used_count} `}
        </Typography>

      </Box>
    </Box>
  );
}

const Preview: React.FC<{ userData: any; setActive: any, setUserData: any }> = ({
  setUserData,
  userData,
  setActive,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();


  const { userInfo, loading, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );
  const { licenseList } = useAppSelector((state: any) => state.getLicenseLists);

  const { inviteUserData } = useAppSelector(
    (state: any) => state.inviteUsersData
  );

  const [clickedEmails, setClickedEmails] = useState<string[]>([]);
  const { licensePlans } = useAppSelector((state) => state.getPlansLicense);
  const [license, setLicense] = useState<any>();

  useEffect(() => {
    if (userInfo?.paid) {
      dispatch(getPlans("enterprise-recruiter-plan-platinum"));
    }
    else {
      dispatch(getPlans("enterprise-recruiter-plan-free"));
    }

  }, []);

  useEffect(() => {
    dispatch(getLicense())
  }, [])

  useEffect(() => {
    if (licenseList) {
      const license = licenseList.find((item: any) => item?.id === (userInfo?.paid ? "enterprise-recruiter-plan-platinum" : "enterprise-recruiter-plan-free"));
      setLicense(license);
    }
  }, [licenseList]);

  useEffect(() => {
    if (inviteUserData) {
      navigate("?view=invite-user&step=user-detail");
      window.location.reload();
    }
  }, [inviteUserData]);

  const inviteUserHadler = () => {
    const usersToInvite = userData?.filter((user: any) =>
      clickedEmails?.includes(user.email)
    );
    dispatch(inviteUser(usersToInvite));



  };

  const backHandler = () => {
    setActive((pre: any) => pre - 1);
    navigate("?view=invite-user&step=user-detail");

  };


  console.log("licenseList", license)
  return (
    <div className="preview">
      <div className="top">
        <Box sx={{ width: '100%' }}>
          {license?.used_count != 0 && <p>{license?.used_count + clickedEmails?.length} Access Assigned</p>}
          <LinearProgressWithLabel value={
            ((license?.used_count
              + clickedEmails?.length) /
              (license?.unused_count + license?.used_count)) *
            100
          } paid={license} add={clickedEmails?.length} />
        </Box>
        <h3 className="review-title">Review Invite</h3>
        <p className="para">Review your invited users and their assigned roles one final time before sending the invitations.</p>


        <div className="preview-table">
          <div className="table-heading">
            <div className="table-heading-left">
              {/* <EcnCheckboxV2 /> */}
              <p>Users</p>

            </div>
            <div className="table-heading-right">
              <p >Roles</p>
            </div>

          </div>

        </div>
        <div className="list-user">
          {userData?.map((item: any) => (
            <PreviewList item={item} setUserData={setUserData} userData={userData} setClickedEmails={setClickedEmails} clickedEmails={clickedEmails} license={license} />
          ))}
        </div>


      </div>
      <div className="bottom-button">
        <div className="left">
          <EcnButton
            variant="outlined"
            color="secondary"
            className="btn-outlined"
            onClick={backHandler}
          >
            Back
          </EcnButton>
          <EcnButton
            className="btn-text"
            color="secondary"
            onClick={() => navigate("?view=invite-user&step=user-detail")}
          >
            Cancel
          </EcnButton>
        </div>
        <EcnButton
          variant="contained"
          className="btn-contained"
          onClick={inviteUserHadler}
          disabled={clickedEmails.length === 0}
        >
          Send invite
        </EcnButton>
      </div>
    </div>
  );
};

export default Preview;
