import { useCallback, useEffect, useMemo, useState, FC, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";

import EcnButton from "../StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Button, CircularProgress } from "@mui/material";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { logoUpload } from "../../redux/slices/JobSlices/logoUploadSlice";

const baseStyles = {
  display: "flex",
  width: "95%",
  alignItems: "center",
  padding: "20px",

  cursor: "pointer",
  color: "#ffff",
  transition: "border .3s ease-in-out",
  margin: "auto",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZone: React.FC<{
  data?: any;
  setData?: any;
  setOpen?: any;
  success?: any;
  setSuccess?: any;
  edit?: any;
  setEdit?: any;
  replaceLogo: any;
  setReplaceLogo: any;
}> = ({
  data,
  setData,
  setOpen,
  success,
  setSuccess,
  edit,
  setEdit,
  replaceLogo,
  setReplaceLogo,
}) => {
  const dispatch = useAppDispatch();

  const { imgUploadData, loading, error } = useAppSelector(
    (state: any) => state.logoUploadfile
  );
  const onDrop = useCallback((acceptedFiles: any) => {
    setReplaceLogo(false);
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setSuccess(true);
    setImageUrl(acceptedFiles[0].preview);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/svg": [".svg"],
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyles,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<any>();
  const [firstCroppedAreaPixel, setFirstCroppedAreaPixel] = useState<any>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageUrl, setImageUrl] = useState<any>("");
  const [mediaSize, setMediaSize] = useState<any>();
  const [uploadClick, setUploadClick] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [progress, setProgress] = useState<number>(0);
  const [uploadError, setUploadError] = useState<boolean>(false);

  useEffect(() => {
    if (firstCroppedAreaPixel === null) {
      setFirstCroppedAreaPixel(croppedAreaPixels);
    }
    if (firstCroppedAreaPixel && croppedAreaPixels !== firstCroppedAreaPixel) {
      setEdit(true);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (replaceLogo) {
      setSuccess(true);
      setImageUrl(data?.display_picture);
    }
  }, [replaceLogo]);

  useEffect(() => {
    if (
      !loading &&
      imgUploadData &&
      uploadClick &&
      Array.isArray(imgUploadData)
    ) {
      setData({
        ...data,
        display_picture: imgUploadData!.length > 0 ? imgUploadData[0] : "",
      });
      setProgress(100);
      setTimeout(() => {
        setOpen(false);
        setSuccess(false);
        setUploadClick(false);
      }, 500);
    }
  }, [imgUploadData, loading]);

  useEffect(() => {
    if (!loading && error && uploadClick) {
      setUploadError(true);
    }
  }, [error, loading]);

  // clean up
  useEffect(
    () => () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (uploadClick) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? prevProgress : prevProgress + 5
        );
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploadClick]);

  const onCropComplete = useCallback(
    async (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const mediaSizeHandler = useCallback(async (mediaSize: any) => {
    setMediaSize(mediaSize);
    const num = Math.floor(
      Math.max(300 - mediaSize.width, 300 - mediaSize.height)
    );
    setZoom(1 + Math.ceil(num / 20) * 0.1);
  }, []);

  const undoHandler = () => {
    const num = Math.floor(
      Math.max(300 - mediaSize.width, 300 - mediaSize.height)
    );
    setZoom(1 + Math.ceil(num / 20) * 0.1);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        imageUrl,
        croppedAreaPixels
      );

      setCroppedImage(croppedImage);
      setUploadClick(true);
      const img = convertToFile(croppedImage!);
      const formData = new FormData();
      formData.append("folder", "profile");
      formData.append("files", img);

      dispatch(logoUpload(formData));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const convertToFile = (base64: string) => {
    let arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const extension = mime.split("/")[1];
    const fileName = new Date().getTime() + `.${extension}`;
    return new File([u8arr], fileName, { type: mime });
  };

  const zoomInHandler = async () => {
    if (zoom < 2.5) setZoom((prev) => prev + 0.1);
  };

  const zoomOutHandler = () => {
    const newVal = zoom - 0.1;
    if (newVal >= 1) setZoom((prev) => prev - 0.1);
  };

  return (
    <>
      {uploadError ? (
        <div className="add-file-v2">
          <div className="wrapper">
            <div className="error">
              <img src={croppedImage} alt="" />
              <h3>Upload failed!</h3>
              <Button
                variant="outlined"
                className="btn-oulined"
                onClick={showCroppedImage}
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      ) : success ? (
        <div className="add-file-v2">
          <div className="wrapper">
            {!uploadClick || replaceLogo ? (
              <div className="filename">
                <div className="container">
                  <div className="cropper">
                    {" "}
                    <Cropper
                      image={imageUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={1 / 1}
                      cropShape="round"
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      setMediaSize={mediaSizeHandler}
                      cropSize={{ width: 300, height: 300 }}
                      style={{
                        mediaStyle: { width: "300px" },
                        containerStyle: {
                          height: "300px",
                          width: "300px",
                          margin: "auto",
                        },
                      }}
                    />
                  </div>

                  {!replaceLogo && (
                    <div className="zoom-buttons">
                      <Button className="btn-text" onClick={zoomInHandler}>
                        <Icon icon="fad:zoomin" className="icon" />
                        Zoom in
                      </Button>
                      <Button className="btn-text" onClick={zoomOutHandler}>
                        <Icon icon="fad:zoomout" className="icon" />
                        Zoom out
                      </Button>
                    </div>
                  )}

                  {replaceLogo && (
                    <div className="cancel-and-replace">
                      <Button className="cancel" onClick={() => setOpen(false)}>
                        Cancel
                      </Button>
                      <div {...getRootProps()} className="replace">
                        <input {...getInputProps()} />
                        Replace
                      </div>
                    </div>
                  )}
                </div>

                {!replaceLogo && (
                  <div className="buttons">
                    {edit ? (
                      <>
                        <Button
                          variant="outlined"
                          className="btn-oulined"
                          startIcon={
                            <Icon icon="lucide:undo-2" className="undo-icon" />
                          }
                          onClick={undoHandler}
                        >
                          Undo
                        </Button>
                        <Button
                          variant="contained"
                          onClick={showCroppedImage}
                          className="save-changes"
                        >
                          Save changes
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        className="btn-contained"
                        onClick={showCroppedImage}
                      >
                        Upload image
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="progress">
                <div className="image-loader">
                  <img src={croppedImage} alt="" />
                  <CircularProgress
                    variant="determinate"
                    thickness={1.1}
                    size="325px"
                    value={progress}
                    className="circular-progress"
                  />
                </div>

                <h3>Please wait</h3>
                <div className="in-progress">
                  <p>Uploading in progress</p>
                  <Icon icon="line-md:upload-loop" className="icon" />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="wrapper-upload-v2">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />

            <div className="centeral-part">
              <div className="button">
                <EcnButton>
                  {/* <Icon icon="uim:image-v" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    className="icon" 
                  >
                    <path
                      opacity="0.5"
                      d="M27.7082 2.91797H7.2915C6.13225 2.92143 5.02146 3.38348 4.20174 4.2032C3.38201 5.02292 2.91997 6.13371 2.9165 7.29297V20.2138L8.57484 14.5555C9.40857 13.7619 10.5155 13.3194 11.6665 13.3194C12.8175 13.3194 13.9244 13.7619 14.7582 14.5555L18.945 18.7657L20.24 17.4707C21.062 16.6533 22.174 16.1945 23.3332 16.1945C24.4923 16.1945 25.6044 16.6533 26.4263 17.4707L32.0832 23.1305V7.29297C32.0797 6.13371 31.6177 5.02292 30.7979 4.2032C29.9782 3.38348 28.8674 2.92143 27.7082 2.91797Z"
                      fill="#0034BB"
                      fill-opacity="0.7"
                    />
                    <path
                      d="M14.7582 14.5564C13.9244 13.7629 12.8175 13.3203 11.6665 13.3203C10.5155 13.3203 9.40857 13.7629 8.57484 14.5564L2.9165 20.2148V27.7106C2.91997 28.8698 3.38201 29.9806 4.20174 30.8004C5.02146 31.6201 6.13225 32.0821 7.2915 32.0856H27.7082C28.2965 32.0852 28.8786 31.9662 29.4199 31.7357C29.9611 31.5052 30.4503 31.1679 30.8582 30.7439L14.7582 14.5564Z"
                      fill="#0034BB"
                      fill-opacity="0.7"
                    />
                    <path
                      opacity="0.25"
                      d="M32.0833 23.1244L26.4264 17.4675C25.6045 16.6502 24.4925 16.1914 23.3333 16.1914C22.1742 16.1914 21.0621 16.6502 20.2402 17.4675L18.9452 18.7625L30.8525 30.734C31.6429 29.9246 32.0848 28.8382 32.0833 27.7065V23.1244Z"
                      fill="#0034BB"
                      fill-opacity="0.05"
                    />
                  </svg>
                </EcnButton>
              </div>

              <div className="text">
                <p className="sub-text"> Drag photo here </p>

                <p className="sub-text-2">or</p>
              </div>
              <Button className="btn-outlined">
                <Icon icon="lucide:monitor" className="icon" />
                Select from device
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DropZone;
