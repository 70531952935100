import { Icon } from "@iconify/react";
import {
  alpha,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Tooltip,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import DargeAndUploadFile from "../Composed/DargeAndUploadFile";
import Placeholders from "../Composed/Placeholders";
import { getPlaceholder } from "../../../redux/slices/MailsSlices/getPlaceholderSlice";
import { composedEmails } from "../../../redux/slices/MailsSlices/composedEmailsSlice";
import { getTemplate } from "../../../redux/slices/MailsSlices/getTemplateSlice";
import TemplateLists from "../Composed/TemplateLists";
import { enqueueSnackbar } from "notistack";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 500,
    padding: "10px 15px",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "10px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        color: "rgba(0, 52, 187, 1)",
        background: "rgba(0, 52, 187, 0.10)",
        borderRadius: "10px",
        fontSize: "14px !important",
        fontWeight: "500",
      },
    },
  },
}));

const RepliedBody: React.FC<{
  setIsReply: any;
  item: any;
  isReply: any;
  draftData: any;
  setIsDraftData: any;
}> = ({ setIsReply, item, isReply, draftData, setIsDraftData }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const st = searchParams.get("status") || -1;

  const { userInfo, errorData } = useAppSelector(
    (state: any) => state.userDetails
  );

  const { repliesData } = useAppSelector((state: any) => state.allRepliesData);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const quillRef = useRef<ReactQuill>(null);

  const [text, setText] = useState<any>("");
  const [emailText, setEmailText] = useState<any>("");
  const [openAttach, setOpenAttach] = useState<any>();
  const [smallFiles, setSmallFiles] = useState<any>([]);
  const [allFiles, setAllFiles] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [openPlaceHolder, setOpenPlaceHolder] = useState<any>(false);
  const [openTemplate, setOpenTemplate] = useState<any>(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [template, setTemplate] = useState<any>([]);
  const [click, setClick] = useState(false);

  const [value, setValue] = React.useState("");

  const { placeholderData } = useAppSelector(
    (state: any) => state.placeholderList
  );
  const { templateData } = useAppSelector((state: any) => state.templateList);

  const {
    composedData,
    success: composedDataSuccess,
    error,
    loading,
  } = useAppSelector((state: any) => state.composedEmail);

  useEffect(() => {
    dispatch(getPlaceholder());
  }, []);

  useEffect(() => {
    dispatch(getTemplate());
  }, []);

  useEffect(() => {
    if (composedData && click) {
      setClick(false);
      enqueueSnackbar("Email sent successfully!", {
        variant: "success",
      });
      setIsReply(false);
      setClick(false);
    }
  }, [composedData, click]);

  useEffect(() => {
    if (error && click) {
      setClick(false);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      navigate("?view=sent");
      setIsReply(false);
      setClick(false);
    }
  }, [error]);

  const focusQuillEditor = useCallback(() => {
    if (quillRef && quillRef.current) {
      quillRef.current.focus(); // Focus the Quill editor
    }
  }, [quillRef]);

  useEffect(() => {
    const textBodyDiv = document.querySelector(".text-body");

    const handleClick = () => {
      focusQuillEditor(); // Call the function to focus the Quill editor
    };

    if (textBodyDiv) {
      textBodyDiv.addEventListener("click", handleClick);
    }

    // Cleanup function
    return () => {
      if (textBodyDiv) {
        textBodyDiv.removeEventListener("click", handleClick);
      }
    };
  }, [focusQuillEditor]);

  const toggleDrawer = () => {
    setOpenAttach(false);
    setOpenPlaceHolder(false);
    setOpenTemplate(false);
  };

  const removeHandler = (indexToRemove: number) => {
    setAllFiles((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
    setSmallFiles((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
    setFileList((prevFiles: any) =>
      prevFiles.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const placeHandler = () => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection(true);
      if (selection) {
        const textToInsert = ` {{${value}}}`;
        editor.insertText(selection.index, textToInsert);
        setOpenPlaceHolder(false);
      }
    }
  };

  const TemplateHandler = () => {
    const foundItem = templateData.find((item: any) => item.id === template);
    setText(foundItem?.subject);
    setEmailText(foundItem?.body);
    setIsDraftData(foundItem?.body);
    // if (quillRef && quillRef.current) {
    //   const editor = quillRef.current.getEditor();
    //   const selection = editor.getSelection(true);
    //   if (selection) {
    //     const textToInsert = ` ${foundItem?.body}`;
    //     editor.insertText(selection.index, textToInsert);
    //     setOpenPlaceHolder(false);
    //   }
    // }
    setOpenTemplate(false);
  };

  const sendHandler = () => {
    let subjectData = repliesData?.result[0]?.subject;

    if (!subjectData.startsWith("Re: ")) {
      subjectData = "Re: " + subjectData;
    }

    setClick(true);

    dispatch(
      composedEmails({
        email_id: "",
        __comment__: "Keep the email id empty for new emails",
        job_id: item?.job_id,
        user_ids: [item?.from_user_id],
        sender_name: userInfo?.first_name + " " + userInfo?.last_name,
        subject: subjectData,
        body: emailText,
        is_draft: false,
        attachments: smallFiles,
      })
    );
  };

  const emailTextHandler = (e: any) => {
    setEmailText(e);
    setIsDraftData(e);
  };

  return (
    <div className="reply-email">
      <div className="text-body">
        <div className="quill">
          <ReactQuill
            onChange={emailTextHandler}
            value={emailText}
            ref={quillRef}
            theme="snow"
            bounds={".app"}
            className="quill-candidatesdetail"
            modules={modules}
            formats={formats}
            placeholder="start typing from here"
          />
          <div className="attach">
            {fileList?.map((item: any, index: any) => (
              <div className="file">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  {item.attachmentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <IconButton>
                      <Icon icon="vscode-icons:file-type-word" />
                    </IconButton>
                  ) : item.attachmentType === "application/pdf" ? (
                    <IconButton>
                      <Icon icon="vscode-icons:file-type-pdf2" />
                    </IconButton>
                  ) : item.attachmentType ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                    <IconButton>
                      <Icon icon="vscode-icons:file-type-excel" />
                    </IconButton>
                  ) : item.attachmentType === "image/png" ||
                    item.attachmentType === "image/jpeg" ? (
                    <IconButton>
                      <Icon icon="lucide:image" />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M10.0013 1.33203H4.0013C3.64768 1.33203 3.30854 1.47251 3.05849 1.72256C2.80844 1.9726 2.66797 2.31174 2.66797 2.66536V13.332C2.66797 13.6857 2.80844 14.0248 3.05849 14.2748C3.30854 14.5249 3.64768 14.6654 4.0013 14.6654H12.0013C12.3549 14.6654 12.6941 14.5249 12.9441 14.2748C13.1942 14.0248 13.3346 13.6857 13.3346 13.332V4.66536L10.0013 1.33203Z"
                          stroke="#0034BB"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.33594 1.33203V3.9987C9.33594 4.35232 9.47641 4.69146 9.72646 4.94151C9.97651 5.19156 10.3156 5.33203 10.6693 5.33203H13.3359"
                          stroke="#0034BB"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                  )}
                  <p>{item?.attachmentFileName}</p>
                </div>
                <Icon
                  icon="lucide:x-circle"
                  height={24}
                  width={24}
                  onClick={() => removeHandler(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="buttons">
        <div className="button-left">
          <Tooltip title="Placeholder">
            <Button
              className="placeholder"
              onClick={() => {
                setOpenPlaceHolder(true);
                setValue("");
              }}
            >
              <Icon icon="lucide:braces" height={24} width={24} />
            </Button>
          </Tooltip>

          <Tooltip title="Templates">
            <Button
              className="placeholder"
              onClick={() => {
                setOpenTemplate(true);
                setTemplate("");
              }}
            >
              <Icon icon="lucide:library-big" height={24} width={24} />
            </Button>
          </Tooltip>

          <Tooltip title="Attach">
            <Button onClick={() => setOpenAttach(true)} className="placeholder">
              <Icon icon="fluent:attach-20-filled" height={24} width={24} />
            </Button>
          </Tooltip>
        </div>
        <div className="button-right">
          <Button variant="contained" onClick={sendHandler} disabled={loading}>
            <Icon icon="lucide:send-horizontal" height={24} width={24} /> Send
          </Button>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={openAttach}
        onClose={toggleDrawer}
        className="open-attachment"
      >
        <div className="top" onClick={() => setOpenAttach(false)}>
          <IconButton>
            <Icon icon="material-symbols:arrow-back" />{" "}
          </IconButton>
          <IconButton>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        <h1 className="title">Attach document</h1>
        <DargeAndUploadFile
          setOpenAttach={setOpenAttach}
          setSmallFiles={setSmallFiles}
          smallFiles={smallFiles}
          setAllFiles={setAllFiles}
          allFiles={allFiles}
          setFileList={setFileList}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={openPlaceHolder}
        onClose={toggleDrawer}
        className="open-placeholder"
      >
        <div className="top" onClick={() => setOpenAttach(false)}>
          <IconButton>
            <Icon icon="material-symbols:arrow-back" />{" "}
          </IconButton>
          <IconButton>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        <h1 className="title">Select Placeholder</h1>
        <p className="total">{placeholderData?.length} placeholder available</p>
        <Placeholders setValue={setValue} value={value} />
        <div className="member-list-bottom">
          <Button className="cancel" onClick={toggleDrawer}>
            Cancel
          </Button>
          <Button className="select" onClick={placeHandler}>
            Use Placeholder
          </Button>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={openTemplate}
        onClose={toggleDrawer}
        className="open-placeholder"
      >
        <div className="top" onClick={() => setOpenTemplate(false)}>
          <IconButton>
            <Icon icon="material-symbols:arrow-back" />{" "}
          </IconButton>
          <IconButton>
            <Icon icon="ic:baseline-close" />{" "}
          </IconButton>
        </div>
        <h1 className="title">Select Template</h1>
        <p className="total template-total">
          {templateData?.length} templates available
        </p>
        <TemplateLists template={template} setTemplate={setTemplate} />
        <div className="member-list-bottom">
          <Button className="cancel" onClick={toggleDrawer}>
            Cancel
          </Button>
          <Button className="select" onClick={TemplateHandler}>
            Use Template
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default RepliedBody;

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
