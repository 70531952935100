import React, { useState } from 'react'
import EcnCheckboxV2 from '../StyledComponents/V2/EcnCheckboxV2'
import { Icon } from '@iconify/react'
import { Autocomplete, Box } from '@mui/material'
import EcnInput from '../StyledComponents/EcnInput'
import { useSnackbar } from 'notistack'
import { useAppSelector } from '../../redux/hooks'

const PreviewList: React.FC<{ item: any, setUserData: any, userData: any, setClickedEmails: any, clickedEmails: any, license: any }> = ({ item, setUserData, userData, setClickedEmails, clickedEmails, license }) => {
    const [isEdit, setIsEdit] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const { userInfo, loading, errorData } = useAppSelector(
        (state: any) => state.userDetails
    );



    const editHandler = () => {
        setIsEdit(true)
    }

    const roleTypeHandler = (e: any, newValue: any) => {
        const updatedUsers = userData.map((user: any) =>
            user.email === item.email ? { ...user, roles: [newValue?.role] } : user
        );
        setUserData(updatedUsers);
        setIsEdit(false); // Exit edit mode
    };

    const deleteHandler = () => {
        const updatedUsers = userData?.filter((user: any) => user?.email !== item?.email);
        setUserData(updatedUsers); // Update the user list
    };

    const handleEmailClick = (email: any) => {
        const remainingLimit = license?.unused_count;

        setClickedEmails((prevEmails: any) => {
            if (prevEmails?.includes(email)) {
                return prevEmails.filter((existingEmail: any) => existingEmail !== email);
            } else if (prevEmails.length < remainingLimit) {
                return [...prevEmails, email];
            } else {
                enqueueSnackbar(
                    `You can invite only ${remainingLimit} more ${userInfo.paid ? "as a paid user" : "as a free user"}`,
                    {
                        variant: "error",
                    }
                );
                return prevEmails;
            }
        });

        if (clickedEmails?.includes(email)) {
            setIsEdit(false);
        }
    };






    return (
        <div className="user">
            <div className="user-left" onClick={() => handleEmailClick(item.email)}>
                <EcnCheckboxV2 checked={clickedEmails?.includes(item.email)} />
                <p>{item?.email}</p>
            </div>

            <div className="user-right">
                {isEdit ?
                    <div className='select-product'>
                        <Autocomplete
                            autoSelect
                            autoHighlight
                            disableClearable
                            options={[
                                { label: "Admin", role: "recruiter-admin", description: "Full Administrative Access" },
                                { label: "Editor", role: "recruiter-reader", description: "Review and Edit Access" },
                                { label: "User", role: "enterprise-member", description: "View Only" },


                            ]}
                            style={{ width: "250px" }}
                            getOptionLabel={(option) => option.label} // Using `label` for the option text
                            onChange={(e: any, newValue: any) => roleTypeHandler(e, newValue)}
                            noOptionsText="No options found"
                            renderInput={(params) => (
                                <Box sx={{ position: "relative" }}>
                                    <EcnInput
                                        {...params}
                                        placeholder="Select role"
                                        className="ecn-input"
                                    />
                                </Box>
                            )}
                            renderOption={(props, option, { selected }) => (
                                <Box
                                    component="li"
                                    {...props}
                                    sx={{
                                        margin: "0px 10px",
                                        marginBottom: "0px !important",
                                        borderRadius: "8px !important",
                                        padding: "10px 15px !important",
                                        bgcolor: selected ? "rgba(0, 52, 187, 0.05)" : "transparent",
                                        "&:hover": {
                                            bgcolor: "rgba(0, 52, 187, 0.05)",
                                            color: "rgba(0, 52, 187, 1)"
                                        },
                                    }}
                                >

                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={{ fontSize: "14px", fontWeight: "500" }}>{option.label}</p>
                                        <p style={{ fontSize: "12px", fontWeight: "500", color: "rgba(33, 33, 33, 0.6)" }}>{option.description}</p>
                                    </div>
                                </Box>
                            )}
                        />

                    </div> : <h1>
                        {item.roles[0] === "recruiter-admin" ? "Talent Admin" : item.roles[0] === "recruiter-reader" ? "Talent Editor" : "User"}

                    </h1>}
                {clickedEmails?.includes(item?.email) && <div className="icons">
                    <Icon icon="lucide:pencil" className="edit" onClick={editHandler} />
                    <Icon icon="lucide:trash-2" className="delete" onClick={deleteHandler} />
                </div>}
            </div>
        </div>
    )
}

export default PreviewList