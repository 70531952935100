import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

interface getNotificationProps {
  filter: string;
  limit: string;
}

export const getUnreadNotification = createAsyncThunk(
  "getUnreadNotification",
  async (limit: string) => {
    const { data } = await axios.get(
      `${
        GATEWAY_BASE + ADMIN_BASE
      }/getNotifications/recruit?skip=0&limit=${limit}&readfirst=true`
    );
    return data;
  }
);

const getUnreadNotificationSlice = createSlice({
  name: "getUnreadNotification",
  initialState: {
    loading: false,
    unreadNotificationList: null as any,
    error: false,
    success: false,
  },
  reducers: {
    addUnreadNotification: (state, action) => {
      state.loading = false;
      state.unreadNotificationList = {
        result: [action.payload, ...state.unreadNotificationList?.result],
        total_count: state.unreadNotificationList?.total_count + 1,
        unread_count: state.unreadNotificationList?.unread_count + 1,
      };
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnreadNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUnreadNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.unreadNotificationList = action.payload;
    });
    builder.addCase(getUnreadNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { addUnreadNotification } = getUnreadNotificationSlice.actions;

export default getUnreadNotificationSlice.reducer;
