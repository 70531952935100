const StarsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
    >
      <g filter="url(#filter0_iii_8651_9430)">
        <path
          d="M7.37869 4.43972C7.29777 4.75482 7.02218 4.98995 6.69662 5.15273C5.77371 5.47163 5.04499 5.77911 4.44146 6.07707C3.90741 6.34123 3.91313 7.17561 4.4486 7.43597C5.0745 7.74012 5.86033 8.03189 6.73327 8.3784C6.96792 8.47121 7.29968 8.73395 7.38202 9.05714C7.64838 10.0834 7.97629 11.0927 8.36395 12.0796C8.65953 12.8349 9.3911 12.834 9.68858 12.0796C10.0741 11.0995 10.3678 10.0414 10.6986 9.03477C10.7388 8.88201 10.8161 8.74152 10.9235 8.62571C11.0309 8.5099 11.1652 8.42235 11.3145 8.37078C12.0742 8.09855 12.8238 7.79865 13.5616 7.47167C14.1613 7.2075 14.1737 6.35028 13.5816 6.06945C12.8398 5.71573 12.082 5.39665 11.3107 5.11322C11.1601 5.05943 11.0241 4.9713 10.9134 4.85579C10.8028 4.74029 10.7206 4.60061 10.6734 4.44781C10.3145 3.34832 10.087 2.41541 9.70524 1.44728C9.40776 0.692385 8.62764 0.715708 8.32968 1.47013C7.94985 2.4354 7.68474 3.39544 7.37869 4.43972Z"
          fill="url(#paint0_linear_8651_9430)"
        />
      </g>
      <path
        d="M7.37771 4.43581C7.29679 4.75091 7.02121 4.98604 6.69564 5.14882C5.77273 5.46772 5.04401 5.7752 4.44048 6.07316C3.90644 6.33733 3.91215 7.17171 4.44762 7.43206C5.07352 7.73621 5.85936 8.02798 6.73229 8.37449C6.96695 8.46731 7.2987 8.73004 7.38104 9.05323C7.64741 10.0795 7.97531 11.0888 8.36297 12.0757C8.65855 12.831 9.39012 12.8301 9.68761 12.0757C10.0731 11.0956 10.3668 10.0375 10.6976 9.03086C10.7378 8.87811 10.8151 8.73761 10.9225 8.62181C11.0299 8.506 11.1642 8.41844 11.3135 8.36688C12.0733 8.09465 12.8228 7.79474 13.5606 7.46776C14.1603 7.2036 14.1727 6.34637 13.5806 6.06555C12.8388 5.71183 12.0811 5.39274 11.3097 5.10932C11.1591 5.05553 11.0231 4.96739 10.9125 4.85189C10.8018 4.73639 10.7196 4.5967 10.6724 4.44391C10.3135 3.34441 10.086 2.4115 9.70427 1.44337C9.40678 0.688479 8.62666 0.711802 8.3287 1.46622C7.94888 2.43149 7.68376 3.39153 7.37771 4.43581Z"
        fill="url(#paint1_linear_8651_9430)"
      />
      <g filter="url(#filter1_ii_8651_9430)">
        <path
          d="M3.545 1.91608C3.51882 2.01746 3.43029 2.09314 3.32558 2.1455C3.02857 2.24831 2.79392 2.34731 2.59972 2.44346C2.42789 2.52818 2.42932 2.79663 2.6021 2.8804C2.80344 2.97845 3.05618 3.07222 3.337 3.1836C3.41316 3.21358 3.5193 3.29831 3.54643 3.40254C3.62782 3.72287 3.7392 4.06177 3.862 4.37496C3.9572 4.61818 4.1928 4.6177 4.28847 4.37496C4.41223 4.05986 4.50742 3.71907 4.61356 3.39493C4.62652 3.34573 4.65142 3.30049 4.68604 3.26322C4.72066 3.22594 4.76394 3.19777 4.81204 3.18122C5.05664 3.09376 5.29796 2.99739 5.53552 2.8923C5.57888 2.87293 5.6158 2.84156 5.64191 2.80189C5.66802 2.76222 5.68224 2.71591 5.68289 2.66843C5.68354 2.62094 5.6706 2.57426 5.64558 2.53389C5.62056 2.49352 5.58452 2.46115 5.54171 2.4406C5.33799 2.34446 5.08478 2.2326 4.81062 2.13312C4.76213 2.11582 4.71835 2.08745 4.68274 2.05027C4.64713 2.01309 4.62067 1.96812 4.60547 1.91894C4.48981 1.56481 4.41651 1.26495 4.29371 0.952711C4.19851 0.709965 3.94672 0.71758 3.85105 0.960326C3.72873 1.27114 3.64353 1.58004 3.545 1.91608Z"
          fill="url(#paint2_linear_8651_9430)"
        />
      </g>
      <path
        d="M3.545 1.91706C3.51882 2.01844 3.43029 2.09412 3.32558 2.14648C3.02857 2.24929 2.79392 2.34829 2.59972 2.44443C2.42789 2.52916 2.42932 2.79761 2.6021 2.88138C2.80344 2.97943 3.05618 3.07319 3.337 3.18457C3.41316 3.21456 3.5193 3.29928 3.54643 3.40352C3.62782 3.72385 3.7392 4.06274 3.862 4.37593C3.9572 4.61916 4.1928 4.61868 4.28847 4.37593C4.41223 4.06084 4.50742 3.72004 4.61356 3.3959C4.62652 3.34671 4.65142 3.30147 4.68604 3.26419C4.72066 3.22691 4.76394 3.19875 4.81204 3.18219C5.05664 3.09473 5.29796 2.99836 5.53552 2.89328C5.57888 2.87391 5.6158 2.84254 5.64191 2.80287C5.66802 2.7632 5.68224 2.71689 5.68289 2.6694C5.68354 2.62192 5.6706 2.57524 5.64558 2.53487C5.62056 2.4945 5.58452 2.46213 5.54171 2.44158C5.33799 2.34543 5.08478 2.23358 4.81062 2.1341C4.76213 2.11679 4.71835 2.08843 4.68274 2.05125C4.64713 2.01407 4.62067 1.9691 4.60547 1.91991C4.48981 1.56579 4.41651 1.26593 4.29371 0.953687C4.19851 0.710941 3.94672 0.718557 3.85105 0.961303C3.72873 1.27211 3.64353 1.58102 3.545 1.91706Z"
        fill="url(#paint3_linear_8651_9430)"
      />
      <g filter="url(#filter2_i_8651_9430)">
        <path
          d="M2.33557 8.70145C2.2994 8.84281 2.17564 8.948 2.02952 9.0213C1.61542 9.16409 1.28795 9.30213 1.01713 9.43635C0.777711 9.55487 0.780091 9.92898 1.02046 10.0456C1.30128 10.1827 1.65398 10.3136 2.0457 10.4692C2.15137 10.5106 2.29987 10.6287 2.337 10.7738C2.45123 11.2203 2.6064 11.6934 2.77775 12.1304C2.91055 12.4693 3.23897 12.4688 3.37224 12.1304C3.54549 11.6906 3.67686 11.2155 3.82537 10.7634C3.84341 10.6948 3.87809 10.6317 3.92632 10.5798C3.97456 10.5278 4.03487 10.4885 4.10191 10.4654C4.44306 10.3434 4.77964 10.2089 5.11097 10.0623C5.17128 10.0351 5.22258 9.99133 5.25887 9.93604C5.29515 9.88075 5.31491 9.81625 5.31581 9.75013C5.31671 9.684 5.29872 9.61899 5.26395 9.56273C5.22918 9.50648 5.17908 9.46131 5.11953 9.43254C4.78667 9.2739 4.44662 9.13079 4.10048 9.00369C4.03283 8.97955 3.97175 8.93996 3.92209 8.88807C3.87243 8.83618 3.83556 8.77342 3.81442 8.70478C3.65306 8.21119 3.5512 7.79281 3.37985 7.35777C3.24658 7.01983 2.89579 7.02983 2.76252 7.36872C2.59212 7.80186 2.47265 8.23261 2.33557 8.70145Z"
          fill="url(#paint4_radial_8651_9430)"
        />
      </g>
      <defs>
        <filter
          id="filter0_iii_8651_9430"
          x="3.87701"
          y="0.788243"
          width="10.2459"
          height="11.9616"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.104335" dy="-0.104335" />
          <feGaussianBlur stdDeviation="0.104335" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.937255 0 0 0 0 0.482353 0 0 0 0 0.329412 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8651_9430"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.104335" dy="0.104335" />
          <feGaussianBlur stdDeviation="0.208669" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.737255 0 0 0 0 0.615686 0 0 0 0 0.415686 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_8651_9430"
            result="effect2_innerShadow_8651_9430"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.166935" dy="0.104335" />
          <feGaussianBlur stdDeviation="0.0834677" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.901961 0 0 0 0 0.458824 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_8651_9430"
            result="effect3_innerShadow_8651_9430"
          />
        </filter>
        <filter
          id="filter1_ii_8651_9430"
          x="2.42995"
          y="0.774414"
          width="3.29441"
          height="3.82494"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0417339" dy="0.0417339" />
          <feGaussianBlur stdDeviation="0.0417339" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.803922 0 0 0 0 0.6 0 0 0 0 0.262745 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8651_9430"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.0417339" dy="0.0417339" />
          <feGaussianBlur stdDeviation="0.0417339" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.882353 0 0 0 0 0.423529 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_8651_9430"
            result="effect2_innerShadow_8651_9430"
          />
        </filter>
        <filter
          id="filter2_i_8651_9430"
          x="0.838867"
          y="7.04677"
          width="4.53916"
          height="5.33799"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0626008" dy="-0.0626008" />
          <feGaussianBlur stdDeviation="0.0626008" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.458824 0 0 0 0 0.462745 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8651_9430"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8651_9430"
          x1="12.285"
          y1="4.51826"
          x2="7.01266"
          y2="10.0681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFCF5A" />
          <stop offset="1" stop-color="#FEA254" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8651_9430"
          x1="6.86985"
          y1="13.0909"
          x2="9.74377"
          y2="7.02892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF886D" />
          <stop offset="1" stop-color="#FF886D" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8651_9430"
          x1="5.12428"
          y1="1.94131"
          x2="3.42744"
          y2="3.72763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDA72" />
          <stop offset="1" stop-color="#F7A967" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8651_9430"
          x1="4.07714"
          y1="4.85095"
          x2="4.07714"
          y2="2.88757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDA071" />
          <stop offset="1" stop-color="#FDA071" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_8651_9430"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.61453 7.98796) rotate(127.221) scale(3.36798 3.27977)"
        >
          <stop offset="0.365" stop-color="#FFBC64" />
          <stop offset="1" stop-color="#FF8F6B" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default StarsIcon;
